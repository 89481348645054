import {
    get,
    post,
    put,
    del,
    ApiError
} from 'aws-amplify/api';
import dateFormat from 'dateformat';
import moment from 'moment-timezone';

import {
    returnApi,
    returnReqInit
} from './user_functions';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle click away on elem that contains DropDownList - if the clicked on elem is not related then click away is true
export function handleClickAway(e) {
    if (!e.currentTarget.contains(e.relatedTarget)) {
        return true;
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle converting bits to megabytes
export function convertBitsToMegabytes(bits, raw) {
    try {
        if (!isNaN(bits)) {
            let val = (bits / 1e6);
            return raw ? val : val.toFixed(2);
        } else {
            return 'NaN';
        }
    } catch (error) {
        console.log('error caught @ convertBitsToMegabytes', error);
        return '~';
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// given an array of two dates order_deadline, shipping_deadline], returns a formatted string eg. ['2021-20-02', '2021-22-02] > 20th Feb, 2021 - 22nd Feb, 2021
export function formatDatesForLeadTime(arrayOfDates) {
    try {
        let first = dateFormat(arrayOfDates[1], 'dS mmm, yyyy');
        let second = dateFormat(arrayOfDates[0], 'dS mmm, yyyy');

        if (first && second) {
            return `${first} - ${second}`;
        } else {
            return null;
        }
    } catch (error) {
        return null;
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// given an int returns it to two fixed decimal places eg. 3.4 > 3.40, 2 > 2.00
export function returnNumberTo2DecimalPlaces(num) {
    try {
        return parseFloat(num).toFixed(2);
    } catch (error) {
        return '-';
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// given an array of multiple api requests will call all of them at the same and all have resolved will return the combined data > arrayOfReqs = [{apiToCall: 'api', url: '/fetch-this-data'}]
// Function to call multiple API requests and return combined results data
/**
 * Calls multiple API requests and returns combined results data.
 * @param {Array} arrayOfReqs - An array of objects representing API requests.
 * @returns {Promise} A promise that resolves with the combined results data or rejects if any of the API requests fail.
 */
export function callApiAndReturnCombinedResultsData(arrayOfReqs) {
    return new Promise(async (resolve, reject) => {
        try {
            let promiseArray = [];

            // Loop through each request in the array
            for (const { method, path, body } of arrayOfReqs) {
                promiseArray.push(handleApiReq(method, path, body)); // Push each API request promise to the array
            }

            Promise.all(promiseArray).then(results => {
                if (results.some(result => !result.success)) {
                    reject(); // Reject the promise if any of the API requests fail
                } else {
                    let combinedResultsData = {};

                    // Combine the data from all the successful API requests
                    for (const result of results) {
                        combinedResultsData = {
                            ...combinedResultsData,
                            ...result.data
                        };
                    }

                    resolve(combinedResultsData); // Resolve the promise with the combined results data
                }
            }).catch(error => {
                handleError('45510dyJ', 'api req failed', true); // Handle error if Promise.all() fails
            });
        } catch (error) {
            handleError('7701nte', error, true); // Handle error if any other exception occurs
            reject(error);
        }
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export async function resolveBodyPromise(body, which) {
    return new Promise(async (resolve, reject) => {
        try {
            resolve(await body[which]());
        } catch(error) {
            reject(error);
        }
    });
}

// function to handle single request
export async function handleApiReq(method, path, body, responseType) {
    let apiName = await returnApi();
    let partialOptions = returnReqInit(body);

    let params = {
        apiName,
        path,
        options: {
            ...partialOptions
        }
    }

    return new Promise(async (resolve, reject) => {
       let result;
       try {
           if (method === 'get') {
               result = await get(params).response;
           } else if (method === 'post') {
               result = await post(params).response;
           } else if (method === 'put') {
               result = await put(params).response;
           } else if (method === 'del') {
               result = await del(params).response;
           }

           let data = await resolveBodyPromise(result.body, responseType || 'json');

           resolve(data);
       } catch (error) {
           if (error instanceof ApiError) {
               let errorData;
               try {
                   errorData = error.response.body ? JSON.parse(error.response.body) : 'unknown';
               } catch (error) {
                   console.error('error parsing response body', error);
               }

               reject({
                   response: {
                       ...error.response,
                       data: errorData
                   },
               })
           } else {
               reject(error);
           }
       }
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// tests given string against the email validating regexp > returns boolean
export function validateEmailAddress(string) {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(string.toLowerCase());
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// return element or string for the lead_time elem
export function returnListTitleLabel(className, { lead_times }, maxModifier, loading, difference) {
    try {
        return <p className={className}>{lead_times ? lead_times.map(val => maxModifier ? val + maxModifier : val).join('-') + ' working days' : '-'}{loading || !difference ? '' : ` (${difference})`}</p>;
    } catch (error) {
        return null;
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function sortPartsByFileKey(parts) {
    parts.sort((a, b) => {
        if (a.file_key && b.file_key) {
            try {
                let aMatch = a.file_key.match(/(\d+)\.stl$/)[1];
                let bMatch = b.file_key.match(/(\d+)\.stl$/)[1];

                return parseInt(aMatch) - parseInt(bMatch);
            } catch (error) {
                return true;
            }
        } else {
            return true;
        }
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// parse query string parameters
export function queryStringParams() {
    const params = new URLSearchParams(
        window ? window.location.search : {}
    );

    return new Proxy(params, {
        get(target, prop) {
            return target.get(prop)
        },
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// return a random integer between provided min and max values
export function rando(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle pushing data to the data layer, this updates google analytics
export function handlePushToDataLayer(eventObj, check) {
    // don't update the data layer if we are not in the prod environment
    if (process.env.REACT_APP_BUILD_ENV === 'prod') {
        if (window.dataLayer && window.dataLayer.push) {
            if (check) {
                const {
                    event,
                    transaction_id
                } = eventObj;

                if (window.dataLayer.some(x => x.event === event && x.transaction_id === transaction_id)) {
                    return;
                }
            }
            window.dataLayer.push(eventObj);
        }
    } else {
        if (!window.fakeLayer) window.fakeLayer = [];
        console.log('f4k3 l4y3r 3v3n7', JSON.stringify(eventObj, null, 4), check);

        if (check) {
            const {
                event,
                transaction_id
            } = eventObj;

            if (window.fakeLayer.some(x => x.event === event && x.transaction_id === transaction_id)) {
                return;
            }
        }

        window.fakeLayer.push(eventObj);
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle error that will be sent to backend
export async function handleError(location, error, dont_show_alert, custom_msg) {
    if (process.env.REACT_APP_BUILD_ENV !== 'prod') return;

    if (!dont_show_alert) {
        window.alert(
            custom_msg ? custom_msg : 'Something went wrong...'
        );
    }

    let reqBody = {
        body: {
            location,
            created_at: dateFormat(),
            error
        }
    };

    let params = {
        apiName: 'restapi',
        path: '/post-error',
        options: {
            body: reqBody
        }
    }

    try {
        await post(params);
    } catch (error) {
        console.error('error caught from handleError', error);
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// return boolean
function isDateWeekend(date) {
    return date.day() === 0 || date.day() === 6;
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// return boolean, uses holidays.json
function isDateHoliday(holidays, date, check_bypass) {
    let isHoliday = false;
    for (const holiday of holidays) {
        if (holiday.date) {
            let holidayDate = moment(holiday.date).format('YYYY-MM-DD');
            if (date.format('YYYY-MM-DD') === holidayDate) {
                if (check_bypass && holiday.bypass) {
                    console.log('SKIP HOLIDAY BYPASS_', holiday);
                }

                if (!check_bypass || (check_bypass && !holiday.bypass)) {
                    isHoliday = true;
                }
            }
        }
    }
    return isHoliday;
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// note: this function was originally only on the backend, it's now here to speed up the time it took to handle a new total price change
// function to calculate the total price, vat and MOC
export function calculateTotalPrice(quoteData, partsData, shippingOptions, definitions) {
    // the rate of vat that will be applied to the order
    let vatRate = quoteData.vat;
    let {
        value: currencyVal
    } = quoteData.currency;

    let moc = quoteData.admin_locked ? quoteData.price && isNumber(quoteData.price.moc_rate) ? quoteData.price.moc_rate : 30 : null;
    // altering the value of moc for the front end according to the currency

    // if the minimum order price for the quote was updated by an admin then we use that instead
    let mocRate = isNumber(moc) ? parseFloat(moc) : !!(quoteData && quoteData.price && quoteData.price.moc_rate && isNumber(quoteData.price.moc_rate)) ? parseFloat(quoteData.price.moc_rate) : 30 * currencyVal;

    // reduce all part prices into one value, don't include parts that have been removed from the total
    let totalLineItems = partsData.length && partsData.filter(part => part.warnings.remove_from_total === false).length ? partsData.filter(part => part.warnings.remove_from_total === false).map(part => part.price.total ? part.price.total : 0).reduce((a, c) => {
        return a + c;
    }) : false;

    // reduce all the part vat totals into one value, don't include parts that have been removed from the total
    let vatTotalLineItems = partsData.length && partsData.filter(part => part.warnings.remove_from_total === false).length ? partsData.filter(part => part.warnings.remove_from_total === false).map(part => part.price.vat_total ? part.price.vat_total : 0).reduce((a, c) => {
        return a + c;
    }) : false;

    // how much moc is the user getting charged
    let mocTotal = totalLineItems === false ? 0 : totalLineItems >= mocRate ? 0 : mocRate - totalLineItems;

    // the cost of vat applied to moc
    let vatMoc = parseFloat((mocTotal * vatRate).toFixed(2));

    // calculate the colour set up fee here
    // per unique colour with a set up value, add to an array, and also determine the total
    let colourSetUpFeeVal = 0;
    let colourSetUpSplits = [];
    let overwritingColourSetUpFee = false;
    if (quoteData.admin_locked && quoteData.locked) {
        colourSetUpFeeVal = !!quoteData.price.colour_setup_cost ? quoteData.price.colour_setup_cost : 0;
        colourSetUpSplits = !!quoteData.price.colour_setup_splits ? quoteData.price.colour_setup_splits : [];
        overwritingColourSetUpFee = true;
    }

    let colourSetUpFee = {
        value: colourSetUpFeeVal,
        split: colourSetUpSplits
    };

    let uniqueColourVals = partsData.length && !overwritingColourSetUpFee ? [...new Set(partsData.map(part => part.colour_val))] : [];
    for (const colourVal of uniqueColourVals) {
        let colourValData = definitions.colour_values[colourVal];

        if (colourValData && colourValData.setup_cost) {
            colourSetUpFee.value += colourValData.setup_cost;
            colourSetUpFee.split.push({
                ...colourValData,
                colour_val: colourVal,
                vat_setup_cost: parseFloat((colourValData.setup_cost * vatRate).toFixed(2))
            });
        }
    }

    // the cost of vat applied to the colour set up fee (reduce from split vat values)
    let vatColourSetUpFee = colourSetUpFee.split.length ? colourSetUpFee.split.map(colour => colour.vat_setup_cost).reduce((a, c) => a + c) : 0;

    // value of shipping method
    let shippingTotal = returnShippingCost(shippingOptions, quoteData.selected_shipping_option);
    shippingTotal = isNumber(shippingTotal) ? shippingTotal * currencyVal : shippingTotal;

    // the cost of vat applied to the shipping method
    let vatShipping = parseFloat((shippingTotal * vatRate).toFixed(2));

    // the total value of all the vat being applied
    let vatTotal = parseFloat((vatTotalLineItems + vatMoc + vatShipping + vatColourSetUpFee).toFixed(2));

    // the total value of the order without vat applied
    let totalExclVat = parseFloat((totalLineItems + mocTotal + shippingTotal + colourSetUpFee.value).toFixed(2));

    // the total value of the order with vat applied
    let totalInclVat = parseFloat((vatTotal + totalExclVat).toFixed(2));

    return {
        vat_rate: vatRate,
        moc_rate: mocRate,
        total_line_items: totalLineItems,
        vat_total_line_items: vatTotalLineItems,
        moc_total: mocTotal,
        vat_moc: vatMoc,
        shipping_total: shippingTotal,
        colour_setup_cost: colourSetUpFee.value,
        colour_setup_splits: colourSetUpFee.split,
        vat_colour_setup_cost: vatColourSetUpFee,
        vat_shipping: vatShipping,
        vat_total: vatTotal,
        total_excl_vat: totalExclVat,
        total_incl_vat: totalInclVat
    };
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle clearing the value of provided ref
export function clearRefValue(refToClear) {
    if (refToClear && refToClear.current) refToClear.current.value = null;
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// function that given the base_price_values, tech / mat / finish ids and status returns a boolean indicating if that specific match is loading
export function isPriceLoading(part_status, lead_time, material_id, finish_id, colour_id, base_price_values) {
    let isLoading = false;
    try {
        if (
            !part_status
            || !lead_time
            || !material_id
            || !finish_id
            || !colour_id
            || (!base_price_values && (part_status !== 'SUCCESSFUL' && part_status !== 'FAILED'))
        ) {
            isLoading = true;
        }

        if (part_status === 'FAILED') {
            isLoading = false;
        } else if (!isLoading) {
            if (part_status === 'SUCCESSFUL' && !base_price_values) {
                isLoading = false;
            } else {
                let basePriceValue = base_price_values[material_id][finish_id][colour_id][lead_time];

                if ((!basePriceValue.success || typeof basePriceValue.base_price !== 'number') && part_status !== 'FAILED') {
                    isLoading = false;
                }
            }
        }
    } catch (error) {
        isLoading = true;
    }

    return isLoading;
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// function to calculate the difference in price between production methods
export function calculateProductionDifference(subtotal, which_lead_time, partsData, currency, partPriceTrigger) {
    let objToReturn = {
        label: '-',
        raw: null
    };

    try {
        if (!subtotal) return objToReturn;
        let filteredParts = partsData.filter(part => part.warnings.remove_from_total === false);
        if (!filteredParts.length) return objToReturn;

        const {
            value: currencyVal
        } = currency;

        let partWillTriggerRFQ = false;
        let whichLeadTimeTotal = 0;
        for (const part of filteredParts) {
            const {
                material_id,
                finish_id,
                colour_id,
                price,
                base_price_values,
                qty_discounts
            } = part;

            let partPriceObj = base_price_values[material_id][finish_id][colour_id][which_lead_time.value.type];

            const {
                base_price,
                success
            } = partPriceObj;

            // apply currency here
            const basePriceToUse = isNumber(base_price) ? parseFloat((base_price * currencyVal).toFixed(2)) : base_price;

            if (success) {
                let discount = qty_discounts[Object.keys(qty_discounts).filter(qty => parseInt(qty) <= price.qty).sort((a, b) => b - a)[0]];
                let isDiscounted = !!(1 - discount);
                let unitPrice = basePriceToUse && isDiscounted ? basePriceToUse * discount : basePriceToUse ? basePriceToUse : null;

                let partValue = returnNumberTo2DecimalPlaces(unitPrice) * price.qty;

                if (partPriceTrigger && partValue >= partPriceTrigger) {
                    partWillTriggerRFQ = true;
                }

                whichLeadTimeTotal += partValue;
            }
        }

        let roundedSubtotal = returnNumberTo2DecimalPlaces(subtotal);
        let roundedWhichTotal = returnNumberTo2DecimalPlaces(whichLeadTimeTotal);

        let difference = roundedWhichTotal - roundedSubtotal;
        let negativeDifference = difference < 0;

        let valueToReturn = `${negativeDifference ? '-' : '+'}${returnCurrencySignAndOrValue(currency, Math.abs(difference))}`;

        return {
            label: valueToReturn,
            raw: difference,
            rfqTrigger: partWillTriggerRFQ
        };
    } catch (error) {
        return objToReturn;
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function determineIfEmailIsSatisfied(ref) {
    let valuesToReturn = {
        satisfied: true,
        warning: null
    };

    if (!ref.current) {
        valuesToReturn.satisfied = false;
        return valuesToReturn;
    }

    if (ref.current.getAttribute('indicatewarning')) ref.current.removeAttribute('indicatewarning');

    let trimmedValue = ref.current.value ? ref.current.value.trim() : null;

    if (!trimmedValue || !trimmedValue.length) {
        valuesToReturn.satisfied = false;
        valuesToReturn.warning = 'Please provide an email address';
        ref.current.setAttribute('indicatewarning', 'true');
    } else if (!validateEmailAddress(trimmedValue)) {
        valuesToReturn.satisfied = false;
        valuesToReturn.warning = 'Please enter a valid email address';
        ref.current.setAttribute('indicatewarning', 'true');
    }

    return valuesToReturn;
}

// given an array of input refs and config, determines if all the required fields are satisfied
// returns satisfied boolean
// returns values of refs
export function determineIfRequiredSatisfiedReturnValues(refs, config, returnValues) {
    let satisfied = true;
    let valuesToReturn = {};
    let elemWarnings = {}

    for (const batch of refs) {
        for (const key in batch) {
            if (key === 'country') {
                let country_name = batch[key].value && batch[key].value.name ? batch[key].value.name : null;
                let country_code = batch[key].value && batch[key].value.code ? batch[key].value.code : null;

                if (country_name && country_code) {
                    if (batch[key].node.parentNode.classList.contains('SelectInputSelectWarning')) batch[key].node.parentNode.classList.remove('SelectInputSelectWarning');
                    if (returnValues) {
                        valuesToReturn = {
                            ...valuesToReturn,
                            country_name,
                            country_code
                        };
                    }
                } else {
                    satisfied = false;
                    batch[key].node.parentNode.classList.add('SelectInputSelectWarning');
                }
            } else if (config[key]) {
                let trimmedValue = batch[key].value ? batch[key].value.trim() : null;

                if (trimmedValue && trimmedValue.length) {
                    if (batch[key].getAttribute('indicatewarning')) batch[key].removeAttribute('indicatewarning');
                    if (returnValues) valuesToReturn[key] = trimmedValue;
                } else if ((!trimmedValue || !trimmedValue.length) && config[key].required) {
                    satisfied = false;
                    batch[key].setAttribute('indicatewarning', 'true');
                }
            }
        }
    }

    return {
        satisfied,
        values: returnValues && satisfied ? valuesToReturn : null,
        elemWarnings
    };
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// function to parse and return selected shipping cost value either formatted3 or not
export function returnShippingCost(shippingOptions, selectedShippingOption, format) {
    let shippingOptionIndex = shippingOptions.findIndex(option => option.service_code === selectedShippingOption);

    if (shippingOptionIndex !== -1) {
        try {
            let rawValue = shippingOptions[shippingOptionIndex].stripe_json.shipping_rate_data.fixed_amount.amount;

            return format ? `£ ${(rawValue / 100).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}` : rawValue / 100;
        } catch (error) {
            return '-'
        }
    } else {
        return format ? '-' : null;
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

const COOKIE_NAME = '_biscuit';

export function setCookie(name, value, days, path) {
    const expires = new Date(Date.now() + days * 864e5).toUTCString()
    document.cookie = name + '=' + encodeURIComponent(value) + '; expires=' + expires + '; path=' + path
}

export function getCookie(name) {
    try {
        return document.cookie.split('; ').reduce((r, v) => {
            const [n, ...val] = v.split('=');
            return n === name ? decodeURIComponent(val.join('=')) : r;
        }, '');
    } catch (error) {
        console.log('cookie err', error);
        return null;
    }
}

export function getSetBiscuit(value) {
    let currentCookie = getCookie(COOKIE_NAME);
    if (value) {
        if (!currentCookie) {
            setCookie(COOKIE_NAME, value, 7, '/');
        } else {
            let cookieToSet = value + currentCookie;
            if (cookieToSet.length > (88 * 3)) cookieToSet = cookieToSet.slice(0, -88);
            setCookie(COOKIE_NAME, cookieToSet, 7, '/');
        }
    } else {
        return currentCookie ? currentCookie : 'missing';
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function returnShippingAndDeliveryEstimate(holidays, start_date, production_days, delivery_days) {
    let valuesToReturn = {
        production_estimate: null,
        delivery_estimate: null
    };

    try {
        if (!holidays || !start_date) return valuesToReturn;

        let originalDate;
        try {
            // convert created at date to time representative of that in the uk
            originalDate = moment(start_date).tz('Europe/London');

            if (!originalDate.isValid()) {
                originalDate = null;
            }
        } catch (error) {
            console.log('error caught @ returnFormattedEstimatedDates / moment', error);
            originalDate = null;
        }

        if (!originalDate) return valuesToReturn;

        // cutoff for order deadlines is 2pm uk time
        let pastCutOff = !isDateHoliday(holidays, originalDate) && !isDateWeekend(originalDate) && originalDate.hour() >= 14;

        let daysToWaitBeforeProduction = pastCutOff ? 2 : 1;
        let startProductionDate = null;
        let daysPriorToProduction = 0;
        let daysPriorToProductionCounter = 1;

        while (!startProductionDate) {
            let date = moment(start_date).add(daysPriorToProductionCounter, 'd');

            if (!isDateWeekend(date)) {
                if (!isDateHoliday(holidays, date)) {
                    daysPriorToProduction++;
                }
            }

            if (daysPriorToProduction === daysToWaitBeforeProduction) {
                startProductionDate = date;
            }

            daysPriorToProductionCounter++;

            if (daysPriorToProductionCounter > 10000) {
                startProductionDate = null;
                break;
            }
        }

        if (!startProductionDate) {
            return valuesToReturn;
        }

        let daysProducing = 0;
        let productionCounter = 0;
        let maxProductionDays = parseFloat(production_days);
        let endProductionDate = null;

        // loop whilst there is no endProductionDate
        while (!endProductionDate && !(daysProducing > maxProductionDays)) {
            // date to check is start date + productionCounter value
            let date = moment(startProductionDate).add(productionCounter, 'd');

            if (!isDateWeekend(date)) {
                if (!isDateHoliday(holidays, date)) {
                    // if the current date is a work day increment days worked
                    daysProducing++;
                }
            }

            // if the total number of days worked equals the production_days return the endProductionDate
            if (daysProducing === maxProductionDays) {
                endProductionDate = date;
            }

            productionCounter++;
            if (productionCounter > 10000) {
                endProductionDate = date;
                break;
            }
        }

        if (!endProductionDate) return valuesToReturn;

        valuesToReturn.production_estimate = endProductionDate.format('ddd, Do MMM, YYYY');

        if (typeof delivery_days !== 'number') return valuesToReturn;

        let startDeliveryDateCounter = 1
        let startDeliveryDate = null;

        // while we have not found a start date for the delivery yet, loop until date is not a weekend or a holiday (+ initial start value)
        while (!startDeliveryDate) {
            if (startDeliveryDateCounter > 10000) {
                startDeliveryDate = true;
                return valuesToReturn;
            }

            let date = moment(endProductionDate).add(startDeliveryDateCounter, 'd');

            if (!isDateWeekend(date)) {
                if (!isDateHoliday(holidays, date, true)) {
                    startDeliveryDate = date;
                }
            }

            startDeliveryDateCounter++;
        }

        let daysDelivering = 0;
        let deliveryCounter = 0;
        let maxDeliveryDays = parseFloat(delivery_days);
        let endDeliveryDate = null;

        // loop whilst there is no endDeliveryDate
        while (!endDeliveryDate) {
            // date to check is start date + deliveryCounter value
            let date = moment(startDeliveryDate).add(deliveryCounter, 'd');

            if (!isDateWeekend(date)) {
                if (!isDateHoliday(holidays, date, true)) {
                    // if the current date is a work day increment days worked
                    daysDelivering++;
                }
            }

            // if the total number of days worked equals the production_days return the endDeliveryDate
            if (daysDelivering === maxDeliveryDays) {
                endDeliveryDate = date;
            }

            deliveryCounter++;
            if (deliveryCounter > 1000) {
                endDeliveryDate = null;
                break;
            }
        }

        if (!endDeliveryDate) return valuesToReturn;
        valuesToReturn.delivery_estimate = endDeliveryDate.format('ddd, Do MMM, YYYY');
    } catch (error) {
        console.log('error caught @ returnShippingAndDeliveryEstimate', error);
    } finally {
        return valuesToReturn;
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function isNumber(val) {
    return !isNaN(parseFloat(val)) && !isNaN(val - 0);
}

export function returnCurrencySignAndOrValue(currencyObj, valueToReturn, valueToConvert, spaced, furtherMaths, fractionOverride) {
    try {
        // !currencyObj, attempt to parse from local storage
        if (!currencyObj) {
            currencyObj = getSetCurrencyLSValue();
        }

        const {
            unit_sign,
            value
        } = currencyObj;

        let fractionToUse = isNumber(fractionOverride) ? fractionOverride : 2;

        if (isNumber(valueToReturn)) {
            return `${unit_sign}${(spaced ? ' ' : '') + (valueToReturn).toLocaleString('en-US', {minimumFractionDigits: fractionToUse, maximumFractionDigits: fractionToUse})}`;
        } else if (isNumber(valueToConvert)) {
            if (furtherMaths) {
                return `${unit_sign}${(spaced ? ' ' : '') + (furtherMaths(parseFloat(valueToConvert) * value)).toLocaleString('en-US', {minimumFractionDigits: fractionToUse, maximumFractionDigits: fractionToUse})}`;
            } else {
                return `${unit_sign}${(spaced ? ' ' : '') + (parseFloat(valueToConvert) * value).toLocaleString('en-US', {minimumFractionDigits: fractionToUse, maximumFractionDigits: fractionToUse})}`;
            }
        } else {
            return `${unit_sign}${(spaced ? ' ' : '') + (valueToReturn)}`;
        }
    } catch (error) {
        return '_'
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function getSetCurrencyLSValue(set) {
    if (set) {
        localStorage.setItem('_3dp_currency', JSON.stringify(set));
        window.dispatchEvent(new Event('storage'));
    } else {
        let val = null;
        try {
            val = JSON.parse(localStorage.getItem('_3dp_currency'));
        } catch (error) {
            console.error('error caught @ parsing cLSValue', error);
        }
        return val;
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function returnRFQTooltip() {
    return (
        <>
            <p className="Bold12">Why Am I Seeing This Warning?</p>
            <p className="Reg12">Your quote configuration has aspects that may affect pricing, such as the geometry of your 3D designs or the quantity you've chosen. We must analyse your 3D files more closely to ensure you get the best deal.</p>
            <br></br>
            <p className="Bold12">What Is an RFQ (Request For Quote)?</p>
            <p className="Reg12">Submitting an RFQ lets our team review your 3D files and manufacturing specifications. We'll then provide a tailored quote, considering any volume discounts and optimisations for your project.</p>
            <br></br>
            <p className="Bold12">What Should I Do Next?</p>
            <p className="Reg12">For our best pricing, click "Request For Quote" and follow the prompts. We'll review it and send you a custom quote.</p>
        </>
    );
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function getFileExtension(fileName) {
    // Use the lastIndexOf method to find the last dot (.) in the file name
    const lastDotIndex = fileName.lastIndexOf('.');

    // Check if a dot was found and the dot is not the first character in the file name
    if (lastDotIndex !== -1 && lastDotIndex !== 0) {
        // Use substring to extract the part of the file name after the last dot
        const extension = fileName.substring(lastDotIndex + 1);
        return extension.toLowerCase(); // Convert the extension to lowercase (optional)
    } else {
        // If no extension found, return an empty string or null, depending on your preference
        return ''; // or return null;
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// function to return boolean indicating if there are part warnings
// iterate over given part array and return true if any part has warnings
export function determineIfThereIsWarnings(parts) {
    return parts.some(part =>
        part.warnings.status
        || part.warnings.remove_from_total
        || (part.warnings.materials.length && part.warnings.materials.some(warning => warning.id === part.material_id))
        || (part.warnings.surface_finishes.length && part.warnings.surface_finishes.some(warning => warning.id === part.material_id))
        || (part.warnings.colour_finishes.length && part.warnings.colour_finishes.some(warning => warning.id === part.material_id))
        || (typeof part.price.success === 'boolean' && !part.price.success)
    );
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// function to navigate the options tree with the selected options per part and return the total maximum lead time value
// props:
// parts - array of parts
// optionsTree - object representing the options tree
export function determineMaximumLeadTime(parts, optionsTree) {
    let leadTimes = [];

    try {
        // loop over parts
        for (const part of parts) {
            const {
                material_id,
                finish_id,
                colour_id
            } = part;

            let leadTime = 0;

            // walk over the options and accumulate the lead time value
            // material
            if (optionsTree[material_id] && !isNaN(optionsTree[material_id].ltv)) {
                leadTime += optionsTree[material_id].ltv;

                // surface finish
                if (optionsTree[material_id][finish_id] && !isNaN(optionsTree[material_id][finish_id].ltv)) {
                    leadTime += optionsTree[material_id][finish_id].ltv;

                    // colour finish
                    if (optionsTree[material_id][finish_id][colour_id] && !isNaN(optionsTree[material_id][finish_id][colour_id].ltv)) {
                        leadTime += optionsTree[material_id][finish_id][colour_id].ltv;
                    }
                }
            }

            leadTimes.push(leadTime);
        }
    } catch (error) {
        console.log('error caught @ determineMaximumLeadTime', error);
    } finally {
        // return the largest lead time value
        return leadTimes.length ? leadTimes.sort((a, b) => b - a)[0] : 0;
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// function to return the lowest unit price for given part with the maximum quantity discount that doesn't trigger rfq limitations
export function returnMinUnitPrice(materialId, basePriceValues, rfqLimits, xtra) {
    if (!basePriceValues || !rfqLimits) return null;

    // iterate over every possible finish in the material and return the lowest unit price per finish
    let unitPrices = [];

    // iterate over surface finishes
    for (const finishId in basePriceValues[materialId]) {
        const {
            qty
        } = basePriceValues[materialId][finishId];

        if (qty) {
            const {
                break_values
            } = qty;

            // iterate over colour finishes
            for (const colourId in basePriceValues[materialId][finishId]) {
                const {
                    std
                } = basePriceValues[materialId][finishId][colourId];

                if (std) {
                    let price = std.base_price;

                    // iterate over discount values backwards
                    for (const qt of Object.keys(break_values).sort((a, b) => parseInt(b) - parseInt(a))) {
                        let discountedPrice = price * break_values[qt];
                        if ((discountedPrice * qt < rfqLimits.price_part && discountedPrice * qt < rfqLimits.price_quote) || xtra) {

                            unitPrices.push(discountedPrice);
                            break;
                        }
                    }
                }
            }
        }
    }

    return unitPrices.length ? unitPrices.sort((a, b) => a - b)[0] : null;
}