import React from 'react';

import clsx from 'clsx';

import './TextInput.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function TextInput(props) {
    const {
        disabled,
        label,
        name,
        xtra,
        type,
        pattern,
        autoComplete,
        required,
        refToUse,
        rootStyleOverwrite,
        inputStyleOverwrite,
        warning,
        lpignore,
        onBlur,
        onChange,
        writtenWarning,
        editPath,
        maxlength,
        enterSubmit,
        labelStyleOverwrite
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            style={rootStyleOverwrite}
            className="TextInputWrap"
        >
            <p
                style={labelStyleOverwrite}
                className={clsx('Reg16', required ? 'InputRequired' : null)}
            >
                {label}
            </p>

            {!!writtenWarning && <p className="Reg12 InputWrittenWarning">{writtenWarning}</p>}

            <input
                disabled={disabled}
                ref={refToUse}
                type={type}
                name={name}
                onBlur={disabled ? null : onBlur}
                onChange={disabled ? null : onChange}
                onKeyDown={
                    disabled ? null
                    : !!enterSubmit ? e => e.code === 'Enter' ? enterSubmit() : null
                    : null
                }
                onInput={
                    disabled ? null
                    : pattern ? e => e.target.value = e.target.value.replace(new RegExp(pattern[0], pattern[1]), '') : null
                }
                autoComplete={disabled ? 'off' : autoComplete}
                autoCorrect="off"
                autoCapitalize="none"
                spellCheck="false"
                style={inputStyleOverwrite}
                className={clsx('TextInput',  'Reg16', warning ? 'TextInputWarning' : null, disabled ? 'TextInputDisabled' : null)}
                data-lpignore={lpignore ? "true" : null}
                edit_path={editPath ? editPath : null}
                maxLength={maxlength}
            ></input>

            {xtra && <p className="Reg12">{xtra}</p>}
        </div>
    );
};