import React,{
    useEffect,
    useState,
    useRef
} from 'react';

import { isMobile } from 'react-device-detect';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import clsx from 'clsx';
import _ from 'lodash';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    Tooltip,
    Skeleton
} from '@mui/material';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import LoadingSVG from '../LoadingSVG/LoadingSVG';
import QuantityInput from '../QuantityInput/QuantityInput';
import ColourElem from '../ColourElem/ColourElem';
import QuotePartCollapsable from '../QuotePartCollapsable/QuotePartCollapsable';
import InformationDisplay from "../InformationDisplay/InformationDisplay";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import ViewInArIcon from '@mui/icons-material/ViewInAr';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    returnRFQTooltip
} from '../../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./QuotePart.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function QuotePart(props) {
    const {
        quoteLocked,
        adminLocked,
        quoteExpired,
        position_index,
        keys,
        isLastPart,
        partIniting,
        partLoading,
        partPriceLoading,
        partLoadingStatus,
        thumbnail,
        xtra,
        filename,
        dimensions,
        bodies,
        quantity,
        quantityLoading,
        inputTimeoutActive,
        basePrice,
        unitPrice,
        discount,
        totalPrice,
        thumbnailErrorUrl,
        handlePartSettingsClick,
        handlePartSettingsClose,
        hideBorderBottom,
        hideBorderRight,
        handleQuantityClick,
        handleQuantityInputOnChange,
        quantityChangeTimeoutArray,
        togglePartViewer,
        status,
        handleDeletePart,
        collapseOpen,
        handlePartOptionClick,
        handleApplyToAllClick,
        partsData,
        partData,
        partWarning,
        statusFailed,
        loadingMessage,
        deleting,
        handleOpenBulkPricingModal,
        handleClonePart,
        cloningActive,
        tempOverwritesActive,
        partLoadingPercentage,
        windowBreakpoint,
        maxPartQuantity,
        partReqForQuotes,
        openReqForQuotesModal,
        currencyUnitSign,
        underReview,
        removedFromTotal,
        definitions,
        optionsTree,
        spec,
        colour_val,
        forcedDefinitionsIndices,
        labels,
        handleQuotePartOverlayRefresh,
        reducedWarnings,
        basePriceValues,
        requestForQuoteTriggers,
        handleOpenInfoDisplayImgCarousel
    } = props;

    const mainRef = useRef();
    const imgRef = useRef();
    const loadingRef = useRef();
    const inputRef = useRef();

    // set part thumbnail hover
    const [thumbnailHover, setThumbnailHover] = useState(false);
    // set part loaded
    const [hasLoaded, setHasLoaded] = useState(false);
    const [loadingInfoTimeout, setLoadingInfoTimeout] = useState(false);
    const [loadingInfoFadeout, setLoadingInfoFadeout] = useState(false);

    const specLoading = spec.filter(x => !x).length;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // handle the error thrown when the image fails to load, set to the default image
    const handleImgError = () => {
        if (imgRef && imgRef.current && hasLoaded) {
            imgRef.current.src = thumbnailErrorUrl;
        }
    };

    // if the image successfully loaded hide the loading elem and reveal the image
    const handleImgLoad = () => {
        if (imgRef?.current?.style && loadingRef?.current?.style) {
            imgRef.current.style.opacity = 1;
            loadingRef.current.style.opacity = 0;
        }
    };

    // handle the blur event on the input
    const handleInputBlur = () => {
        // if there is no current timeout for the input
        if (!quantityChangeTimeoutArray.some(x => x.id === position_index)) {
            if (inputRef?.current?.value) {
                // if the current content of the input elem doesn't match the value in the state then change the value to match
                if (parseInt(inputRef.current.value) !== quantity) {
                    inputRef.current.value = quantity;
                }
            }
        }
    };

    // returns true or false indicating if part settings can be applied to all other parts
    const canApplyToAll = () => {
        return partsData.length > 1;
    };

    const returnIndexThumbInfo = () => {
        return (
            <React.Fragment>
                {(windowBreakpoint?.w > 1200 || isNaN(windowBreakpoint?.w)) && <h4 className={clsx('Bold12', 'PartIndex')}>{position_index + 1}.</h4>}

                <div
                    onMouseEnter={!thumbnail || quoteExpired || status === 'FAILED' ? null : () => setThumbnailHover(true)}
                    onMouseLeave={!thumbnail || quoteExpired || status === 'FAILED'? null : () => setThumbnailHover(false)}
                    onClick={!thumbnail || quoteExpired || status === 'FAILED' ? null : () => togglePartViewer(partData)}
                    className={clsx('PartThumbnailWrap', thumbnailHover ? 'ImgThumbnailWrapHover' : null, !thumbnail || quoteExpired || status === 'FAILED' ? 'PartThumbnailWrapNoHover' : null)}
                >
                    {windowBreakpoint?.w <= 1200 &&
                        <React.Fragment>
                            <h4 className={clsx('Bold12', 'PartIndex')}>{position_index + 1}.</h4>
                            {windowBreakpoint?.w <= 768 && <ViewInArIcon className="PartViewerIconSmall"/>}
                        </React.Fragment>
                    }

                    <img
                        ref={imgRef}
                        src={thumbnail ? thumbnail : !hasLoaded ? '' : thumbnailErrorUrl}
                        onError={handleImgError}
                        onLoad={handleImgLoad}
                        alt={filename ? filename : '3d-part'}
                        className={clsx('PartThumbnail', thumbnailHover ? 'ImgThumbnailHover' : null)}
                    >
                    </img>

                    <div
                        ref={loadingRef}
                        className="PartThumbnailLoadingWrap"
                    >
                        <LoadingSVG
                            size={50}
                        />
                    </div>

                    <div className={clsx('OpenPartViewer', thumbnailHover ? 'OpenPartViewerHover' : null)}>
                        <ViewInArIcon className={clsx('OpenPartViewerIcon', thumbnailHover ? 'OpenPartViewerHoverIcon' : null)}/>
                    </div>
                </div>

                <div className="PartInfoWrap">
                    <Tooltip
                        arrow
                        title={filename}
                    >
                        <h3 className="Bold16">{filename ? filename : '-'}</h3>
                    </Tooltip>

                    <div>
                        <p className="Bold12">{dimensions ? dimensions : '-'}</p>
                        <p className="Bold12">{bodies ? bodies : '-'}</p>
                    </div>

                    <Tooltip
                        arrow
                        title={
                            partWarning && !quoteLocked ? ''
                            : quoteLocked ? 'Your quote is locked.'
                            : ''
                        }
                    >
                        <p className={
                            clsx(
                                'Bold12',
                                'CloneDeleteP',
                                quoteLocked || quoteExpired ? 'ApplyAllPDisabled' : null
                            )
                        }>
                            <span
                                onClick={
                                    quoteLocked || quoteExpired || cloningActive || status !== 'SUCCESSFUL' ? null
                                        : () => handleClonePart(keys, filename)
                                }
                                className={
                                    cloningActive || status !== 'SUCCESSFUL' ? 'CloneLoading'
                                        : null
                                }
                            >Clone</span> | <span onClick={quoteLocked || quoteExpired || deleting ? null : () => handleDeletePart(keys)}>Delete</span></p>
                    </Tooltip>

                    <Tooltip
                        arrow
                        title={
                            partWarning && !quoteLocked ? ''
                            : quoteLocked ? 'Your quote is locked.'
                            : ''
                        }
                    >
                            <p className={clsx('Bold12', quoteLocked || quoteExpired || !canApplyToAll() ? 'ApplyAllPDisabled' : null)} onClick={canApplyToAll() && (!quoteLocked || quoteExpired) ? () => handleApplyToAllClick(keys.SK) : null}><span>Apply settings to all</span></p>
                    </Tooltip>
                </div>
            </React.Fragment>
        );
    };

    const returnPartWarning = (warning, reqForQuote) => {
        return (
            <div className={clsx('QuotePartWarning', reqForQuote ? 'QuotePartReqForWarning' : null)}>
                <div className="QuotePartWarningIconWrap">
                    <PriorityHighIcon className="QuotePartWarningIcon"/>
                </div>

                {(!!warning && !reqForQuote) &&
                    <p className="Bold12"><span>Warning.</span>{warning.message}{warning.readMore ? <Tooltip arrow title={
                        <React.Fragment>
                            <p>{warning.readMore.match(/(.+)\|(.+)/)[1]}</p>
                            <p>{warning.readMore.match(/(.+)\|(.+)/)[2]}</p>
                        </React.Fragment>
                    }><span>More Info.</span></Tooltip> : null}</p>
                }
                {reqForQuote &&
                    <p className="Bold12 QuotePartRequestForQuote">To ensure competitive pricing for this part, please submit a Request For Quote. {<Tooltip arrow title={
                        returnRFQTooltip()
                    }><span>More Info.</span></Tooltip>}</p>
                }
            </div>
        );
    };

    const returnQuantityInput = flexed => {
        return (
            <QuantityInput
                label="Quantity:"
                refToUse={inputRef}
                disabled={quoteLocked || quoteExpired || !maxPartQuantity || removedFromTotal}
                handleOnChange={removedFromTotal || quoteLocked || quoteExpired || !maxPartQuantity ? null : e => handleQuantityInputOnChange(keys.SK, e.target.value)}
                handleOnBlur={handleInputBlur}
                handleOnInput={removedFromTotal || quoteLocked || quoteExpired || !maxPartQuantity ? null : e => e.target.value = e.target.value.replace(/\D/g, '')}
                locked={quoteLocked || quoteExpired || status === 'FAILED'}
                quoteLocked={quoteLocked}
                partWarning={!!partWarning}
                addDisabled={removedFromTotal || quoteLocked || quoteExpired || status === 'FAILED' || !maxPartQuantity || quantity >= maxPartQuantity}
                handleAddOnClick={removedFromTotal || quoteLocked || quoteExpired || !maxPartQuantity ? null : () => handleQuantityClick(keys.SK, quantity, true)}
                subtractDisabled={removedFromTotal || quantity <= 1 || quoteLocked || quoteExpired || status === 'FAILED' || !maxPartQuantity}
                handleSubtractOnClick={removedFromTotal || quoteLocked || quoteExpired || !maxPartQuantity ? null : () => handleQuantityClick(keys.SK, quantity, false)}
                bulkPrices={!quoteLocked && !quoteExpired && maxPartQuantity}
                partPriceLoading={partPriceLoading}
                handleBulkPricesClick={handleOpenBulkPricingModal}
                flexed={flexed}
            />
        );
    };

    const returnPartCollapsedContent = () => {
        return (
            <QuotePartCollapsable
                key={'qpc__' + position_index}
                SK={keys.SK}
                definitions={definitions}
                optionsTree={optionsTree}
                xtra={xtra}
                spec={spec}
                activeColourVal={colour_val}
                handleOptionSelect={handlePartOptionClick}
                forcedDefinitionsIndices={forcedDefinitionsIndices}
                handleQuotePartOverlayRefresh={handleQuotePartOverlayRefresh}
                reducedWarnings={reducedWarnings}
                noClick={quoteLocked || quoteExpired || status === 'FAILED'}
                noMouseOver={quoteLocked || quoteExpired || status === 'FAILED' || tempOverwritesActive}
                basePriceValues={basePriceValues}
                requestForQuoteTriggers={requestForQuoteTriggers}
                windowBreakpoint={windowBreakpoint}
                partStatusTrigger={status}
            />
        );
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        // change the content / value of the input elems to match the data from the parts
        if (!inputTimeoutActive && inputRef?.current && !inputRef.current?.value) {
            inputRef.current.value = quantity;
        } else if (!inputTimeoutActive && inputRef && inputRef.current) {
            inputRef.current.value = quantity;
        }
    }, [inputRef, quantity, quantityLoading, inputTimeoutActive]);

    useEffect(() => {
        if (!inputTimeoutActive && inputRef?.current && !inputRef.current?.value) {
            inputRef.current.value = quantity;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowBreakpoint]);

    useEffect(() => {
        if (!partLoading && !hasLoaded) {
            setHasLoaded(true);
            setLoadingInfoTimeout(true);
        }
    }, [partLoading, hasLoaded]);

    useEffect(() => {
        if (loadingInfoTimeout) {
            setLoadingInfoFadeout(true);
        }
    }, [loadingInfoTimeout]);

    useEffect(() => {
        if (loadingInfoFadeout) {
            let timeout = setTimeout(() => {
                setLoadingInfoTimeout(false);
                setLoadingInfoFadeout(false);
            }, 800);

            return () => clearTimeout(timeout);
        }
    }, [loadingInfoFadeout]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const colourOptionLabelHex = labels?.colour?.hex || '#FFFFFF';
    const splitPartInfoMode = windowBreakpoint?.w > 768 && windowBreakpoint?.w <= 1024 && collapseOpen;

    return (
        <div
            ref={mainRef}
            onMouseEnter={
                !collapseOpen ?
                    () => {
                        if (mainRef?.current?.setAttribute) {
                            mainRef.current.setAttribute('quote-part-hovered', 'true');
                        }
                    }
                    : null
            }
            onMouseLeave={() => {
                if (mainRef?.current?.setAttribute) {
                    mainRef.current.setAttribute('quote-part-hovered', 'false');
                }
            }}
            className={clsx(
                'QuotePartWrap',
                hideBorderBottom ? 'QuotePartHideBorderBottom' : null,
                hideBorderRight ? 'QuotePartHideBorderRight' : null,
                !!(partWarning && status !== 'RUNNING' && !_.isEmpty(partWarning)) ? 'QuotePartWarningBackground' : '',
                hideBorderRight && !!(partWarning && status !== 'RUNNING' && !_.isEmpty(partWarning)) ? 'QuoteWarningOverlayBackground' : null,
                deleting ? 'DeletingPart' : null,
                isLastPart && !collapseOpen ? 'QuotePartLastChild' : null,
            )}
        >
            {(collapseOpen && windowBreakpoint?.w <= 768) &&
                <div className="QuotePartWrapCollapsedBorder"></div>
            }

            {(partIniting || (partLoadingStatus && partLoadingStatus !== 'WAITING') || tempOverwritesActive) && !deleting &&
                <div className={clsx('PartLoadingWrap', isLastPart && !collapseOpen ? 'QuotePartLastChild' : null)}></div>
            }

            {(status === 'RUNNING' || status === 'INIT' || status === 'CONVERTING' || loadingInfoTimeout) &&
                <div className={clsx(
                    'PartInfoOverlay',
                    loadingInfoFadeout || !partPriceLoading ? 'PartInfoOverlayFadeout' : null,
                    isLastPart && !collapseOpen ? 'QuotePartLastChild' : null
                )}>
                    <div className={clsx(
                        'PartLoadingStatus',
                        status === 'CONVERTING' ? 'PartLoadingStatusConverting' : null
                    )}>
                        <p>{loadingMessage}</p>
                        <p></p>
                    </div>

                    <div className={clsx(
                        'PartLoadingBar',
                        status === 'CONVERTING' ? 'PartLoadingBarConverting' : null
                    )}>
                        <div
                            style={status === 'CONVERTING' ? {
                                animation: `converting 1.8s ease-in-out ${1 - (3 / (position_index + 1) )}s infinite`
                            } : {
                                transform: partLoadingPercentage || loadingInfoTimeout ? `translateX(-${loadingInfoTimeout ? '0%' : partLoadingPercentage})` : 'translateX(-100%)'
                            }}
                        ></div>
                    </div>
                </div>
            }

            <div className="QuotePartLeft">
                {windowBreakpoint?.w <= 1200 && ((partWarning && status !== 'RUNNING' && !_.isEmpty(partWarning)) || partReqForQuotes) &&
                    returnPartWarning(partWarning, partReqForQuotes)
                }

                <div
                    className={clsx('QuotePart', collapseOpen && 'QuotePartCollapse', splitPartInfoMode && 'QuotePartSplit')}
                >
                    {(windowBreakpoint?.w > 1200 || isNaN(windowBreakpoint?.w)) && ((partWarning && status !== 'RUNNING' && !_.isEmpty(partWarning)) || partReqForQuotes) &&
                        returnPartWarning(partWarning, partReqForQuotes)
                    }

                    {windowBreakpoint?.w <= 768 &&
                        <div className="QuotePartThumbInfo">
                            {returnIndexThumbInfo()}
                        </div>
                    }

                    {(windowBreakpoint?.w > 768 || isNaN(windowBreakpoint?.w)) && returnIndexThumbInfo()}

                    {(!collapseOpen || windowBreakpoint?.w <= 768) &&
                        <div className="TechnologyFinishWrap">
                            <Tooltip
                                title={
                                    quoteLocked || quoteExpired || status === 'FAILED' || underReview ? 'Your quote is locked.' : ''
                                }
                            >
                                <div
                                    onClick={
                                        quoteLocked || quoteExpired || status === 'FAILED' || underReview || specLoading ? null
                                            : !collapseOpen ?
                                                () => handlePartSettingsClick(mainRef.current, position_index)
                                                : handlePartSettingsClose
                                    }
                                    className={
                                        clsx(
                                            (windowBreakpoint?.w <= 1024 || isMobile) && 'TechnologyFinishWrapRootMobile',
                                            collapseOpen && 'TechnologyFinishWrapRootCollapsed',
                                            (quoteLocked || quoteExpired || status === 'FAILED' || underReview) && 'TechnologyFinishWrapRootDisabled',
                                            'TechnologyFinishWrapRoot'
                                        )
                                    }
                                >
                                    <div className="TechnologyFinishInfo">
                                        <div className="Bold12">
                                            <p>Material:</p>
                                            <p>{labels?.material}</p>
                                        </div>

                                        <div className="Bold12">
                                            <p>Surface{windowBreakpoint?.w <= 480 ? '' : ' Finish'}:</p>
                                            <p>{labels?.finish}</p>
                                        </div>

                                        <div className="Bold12">
                                            <p>Colour:</p>
                                            <Tooltip title={labels?.colour?.text || ''}>
                                                <div>
                                                    <ColourElem
                                                        hex={colourOptionLabelHex}
                                                        styleOverwrite={{
                                                            width: windowBreakpoint?.w <= 480 ? 20 : 30,
                                                            height: windowBreakpoint?.w <= 1024 || isMobile ? 12 : 15,
                                                            marginRight: 5,
                                                            boxSizing: 'border-box',
                                                            border: colourOptionLabelHex === '#FFFFFF' ? 'var(--border-main)' : 'none'
                                                        }}
                                                    />
                                                    <p>{labels?.colour?.text}</p>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <div
                                        className="TechnologyFinishEdit"
                                    >
                                        <p className="Bold12">{collapseOpen && (windowBreakpoint?.w <= 1024 || isMobile) ? 'HIDE' : 'EDIT'}</p>
                                    </div>
                                </div>
                            </Tooltip>

                            {windowBreakpoint?.w <= 1024 &&
                                <div className="FlexedQuantityInputWrap">
                                    <div>
                                        {returnQuantityInput(true)}
                                    </div>
                                </div>
                            }
                        </div>
                    }

                    {(windowBreakpoint?.w <= 768 && collapseOpen) &&
                        returnPartCollapsedContent()
                    }

                    <div className={clsx('QuantityPriceWrap', splitPartInfoMode && 'QuantityPriceWrapSplit')}>
                        {(windowBreakpoint?.w > 1024 || isNaN(windowBreakpoint?.w) || splitPartInfoMode) && returnQuantityInput(false)}

                        <div className="PartPriceWrap">
                            {
                                statusFailed || partWarning ?
                                    <div className="PartWarningPriceStatement">
                                        <p className="Bold12">Your part cannot be quoted at this time.</p>

                                        <p className="Bold12">Please <span
                                            onClick={deleting ? null : () => handleDeletePart(keys)}>delete</span> the
                                            part or submit a <span
                                                onClick={openReqForQuotesModal}>Request for Quote</span></p>
                                    </div>
                                    :
                                    <React.Fragment>
                                        <div>
                                            {!partPriceLoading &&
                                                <React.Fragment>
                                                    <h4 className="Bold12">Base Price:</h4>
                                                    <p className="Bold12">{!!partWarning || quoteExpired || adminLocked || partReqForQuotes || underReview ? `${currencyUnitSign || ''} -` : basePrice}</p>
                                                </React.Fragment>
                                            }
                                            {partPriceLoading &&
                                                <React.Fragment>
                                                    <Skeleton variant="text" sx={{fontSize: 12}}
                                                              className="SkeletonPriceElem"
                                                              width={windowBreakpoint?.w <= 768 ? 70 : 160}/>

                                                    {windowBreakpoint?.w <= 768 &&
                                                        <Skeleton variant="text" sx={{fontSize: 12}}
                                                                  className="SkeletonPriceElem" width={60}/>
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>

                                        <div>
                                            {!partPriceLoading &&
                                                <React.Fragment>
                                                    <h4 className="Bold12">Discount:</h4>
                                                    <p className="Bold12">{!!partWarning || quoteExpired || adminLocked || partReqForQuotes || underReview ? '-' : discount}</p>
                                                </React.Fragment>
                                            }
                                            {partPriceLoading &&
                                                <React.Fragment>
                                                    <Skeleton variant="text" sx={{fontSize: 12}}
                                                              className="SkeletonPriceElem"
                                                              width={windowBreakpoint?.w <= 768 ? 65 : 160}/>

                                                    {windowBreakpoint?.w <= 768 &&
                                                        <Skeleton variant="text" sx={{fontSize: 12}}
                                                                  className="SkeletonPriceElem" width={20}/>
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>

                                        <div>
                                            {!partPriceLoading &&
                                                <React.Fragment>
                                                    <h4 className="Bold12">Unit Price:</h4>
                                                    <p className="Bold12">{!!partWarning || quoteExpired || partReqForQuotes || underReview ? `${currencyUnitSign || ''} -` : unitPrice}</p>
                                                </React.Fragment>
                                            }
                                            {partPriceLoading &&
                                                <React.Fragment>
                                                    <Skeleton variant="text" sx={{fontSize: 12}}
                                                              className="SkeletonPriceElem"
                                                              width={windowBreakpoint?.w <= 768 ? 70 : 160}/>

                                                    {windowBreakpoint?.w <= 768 &&
                                                        <Skeleton variant="text" sx={{fontSize: 12}}
                                                                  className="SkeletonPriceElem" width={70}/>
                                                    }
                                                </React.Fragment>
                                            }
                                        </div>

                                        <div>
                                            <h4 className={windowBreakpoint?.w <= 1200 && windowBreakpoint?.w > 768 ? 'Bold14' : 'Bold16'}>Total:</h4>

                                            {!partPriceLoading &&
                                                <p className={windowBreakpoint?.w <= 1200 && windowBreakpoint?.w > 768 ? 'Bold12' : 'Bold16'}>{!!partWarning || quoteExpired || partReqForQuotes || underReview ? `${currencyUnitSign || ''} -` : totalPrice}</p>}
                                            {partPriceLoading &&
                                                <Skeleton className="SkeletonTotalPriceElem" variant="text"
                                                          sx={{fontSize: windowBreakpoint?.w <= 1200 && windowBreakpoint?.w > 768 ? 12 : 16}}
                                                          width={80}/>}
                                        </div>
                                    </React.Fragment>
                            }
                        </div>
                    </div>
                </div>

                {((windowBreakpoint?.w > 768 || isNaN(windowBreakpoint?.w)) && collapseOpen) &&
                    returnPartCollapsedContent()
                }
            </div>

            {collapseOpen && (windowBreakpoint?.w > 768 || isNaN(windowBreakpoint?.w)) &&
                <div className="QuotePartRight">
                    <InformationDisplay
                        // "definitions" for the info panel
                        definitions={definitions?.info_panel ? definitions.info_panel : null}
                        materialId={spec[forcedDefinitionsIndices.indexOf('materials')]}
                        finishId={spec[forcedDefinitionsIndices.indexOf('surface_finishes')]}
                        colourId={spec[forcedDefinitionsIndices.indexOf('colour_finishes')]}
                        colourVal={colour_val}
                        biggerDefinitions={definitions}
                        optionsTree={optionsTree}
                        handleOpenInfoDisplayImgCarousel={handleOpenInfoDisplayImgCarousel}
                    />
                </div>
            }
        </div>
    );
};