import React, {
    useState
} from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import clsx from 'clsx';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import CloseIcon from '@mui/icons-material/Close';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./UploadingPart.css";

export default function UploadingPart(props) {
    const {
        name,
        message,
        progress,
        totalSize,
        index,
        started,
        notValid,
        timeRemaining,
        handleRemovePart,
        windowBreakpoint
    } = props;

    // set is user hovering over uploading part
    const [hovering, setHovering] = useState(false);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            key={`${name}${message ? Date.now() + Math.random() : ''}`}
            className={clsx(
                "UploadingFile",
                message ? "UploadingFileMessage" : null,
                progress > 0 && progress < 100 ? "UploadingFileLoading" : null,
                progress === 100 ? "UploadingFileLoaded" : null,
                hovering ? "UploadingFileHovering" : null
            )}
        >
            <p
                className={clsx('Bold12', 'ProgressName', hovering ? 'ProgressNameHovering' : null, message ? "ProgressNameMessage" : null)}
            >{name}</p>

            <div className="ProgressWrap">
                <div
                    style={
                        message ? { transform: 'translateX(0%)' }
                        : { transform: `translateX(-${100 - progress}%)` }
                    }
                    className={clsx(
                        "Progress",
                        message ? "ProgressMessage" : null,
                        progress === 100 ? "ProgressLoaded" : null
                    )}
                >
                    {null}
                </div>
            </div>

            {(windowBreakpoint?.w > 768 || isNaN(windowBreakpoint?.w)) &&
                <div className={clsx('Bold12', 'ProgressEndWrap')}>
                    <p className="ProgressP">{
                        message ? '0%'
                        : progress + '%'
                    }</p>

                    <p>{totalSize}mb</p>

                    <p className={clsx(
                        'ProgressStatus',
                        !notValid ? 'MarginRightMessage' : ''
                    )}>{
                        message ? ` · ${message}`
                        : !started ? ' · Waiting'
                        : progress === 100 ? ' · Upload completed'
                        : ` · ${timeRemaining} seconds left`
                    }</p>

                    {notValid &&
                        <div
                            onClick={() => handleRemovePart(index)}
                            onMouseEnter={() => setHovering(true)}
                            onMouseLeave={() => setHovering(false)}
                            className="RemovePartWrap"
                        >
                            <CloseIcon className="RemovePart" />
                        </div>
                    }
                </div>
            }

            {(started && progress < 100) &&
                <div className="UploadingSheenWrap">
                    <div className="UploadingSheen"></div>
                </div>
            } 
        </div>
    );

};