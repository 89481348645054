import React, {
    Component,
    createRef
} from 'react';

import {
    withRouter
} from 'react-router-dom';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import TechnologyExplanation from '../FooterImgLinks/FooterImgLinks';
import DropDownList from '../Misc/DropDownList/DropDownList';
import PrivacyPolicy from '../Misc/PrivacyPolicy/PrivacyPolicy';
import UploadDropZone from '../Misc/UploadDropZone/UploadDropZone';
import ErrorBoundary from '../Misc/ErrorBoundary/ErrorBoundary';
import MoreInfoI from '../Misc/MoreInfoI/MoreInfoI';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    handleApiReq,
    handleClickAway,
    callApiAndReturnCombinedResultsData,
    validateEmailAddress,
    handlePushToDataLayer,
    handleError,
    getSetBiscuit,
    getCookie
} from '../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./Upload.css";
import FooterImgLinks from "../FooterImgLinks/FooterImgLinks";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

class Upload extends Component {
    constructor(props) {
        super(props);

        this.technologyList = createRef();

        this.state = {
            technologyListOpen: false,
            technologyListOptions: null,
            listTitleLabel: 'Select Technology',
            listTitleDescription: '_',
            dragActive: false,
            concurrencyLimit: null,
            fileLimit: null,
            userInfoFormMessage: null,
            selectedDefaultMaterialId: 1,
            selectedDefaultFinishId: 1,
            selectedDefaultColourId: 1,
            selectedDefaultColourVal: 1,
            userInfo: {
                email: null
            },
            movingToNextStage: false,
            expectedParts: null
        };
    };

    async componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0
        });

        // set loading to true as we will be fetching options from the config
        this.props.handleLoading(true);

        // remove qid data
        localStorage.removeItem('qid');

        // fetch options from the config
        callApiAndReturnCombinedResultsData([
            {method: 'get', path: '/fetch-config/upload-options'},
            {method: 'get', path: '/fetch-config/general-options'},
            {method: 'get', path: '/fetch-config/definitions'},
        ]).then(result => {
            let defaultOption = result.initial_spec_options[0];

            let defaults = this.handleParseDefaultsFromDefinitions(result.definitions, defaultOption.definition_id);

            this.setState({
                technologyListOptions: result.initial_spec_options,
                listTitleLabel: defaultOption.label,
                listTitleValue: defaultOption.value,
                listTitleDescription: defaultOption.detail,
                definitions: result.definitions,
                selectedDefaultMaterialId: defaults.material_id,
                selectedDefaultFinishId: defaults.finish_id,
                selectedDefaultColourId: defaults.colour_id,
                selectedDefaultColourVal: defaults.colour_val,
                selectedDefinitionId: defaults.definition_id,
                concurrencyLimit: result.concurrency_limit,
                currencyOptions: result.currency_options,
                fileLimit: result.file_limit,
                industryOptions: result.industry_options
            }, () => this.props.handleLoading(false));
        }).catch(error => {
            handleError('12469QhS', error);
        });
    };

    // given the definitions, using id to match with 'materials', parse the defaults and return as an object
    handleParseDefaultsFromDefinitions = (definitions, id) => {
        let defaults = {
            material_id: null,
            finish_id: null,
            colour_id: null,
            colour_val: null,
            definition_id: null
        };

        let material = definitions.materials[id];

        if (material) {
            const {
                spec,
                colour_val
            } = material.defaults;

            defaults.material_id = spec[0];
            defaults.finish_id = spec[1];
            defaults.colour_id = spec[2];
            defaults.colour_val = colour_val;
            defaults.definition_id = id;
        }

        return defaults;
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // handle the toggle on the technology select elem
    handleTechnologyListOpenClose = e => {
        this.setState({
            technologyListOpen: !this.state.technologyListOpen ? true : e.currentTarget === e.target ? false : this.state.technologyListOpen
        });
    };

    // handle the blur function of the technology select elem
    handleListClickAway = (e, desiredBoolean) => {
        e.stopPropagation();

        if (handleClickAway(e)) {
            this.setState({
                technologyListOpen: desiredBoolean
            });
        }
    };

    // handle the mouse move event on the technology select elem
    handleListMouseMove = e => {
        if (e.target.id === 'SelectTechnologySelect' && e.currentTarget.id === e.target.id) {
            if (!e.currentTarget.classList.contains('SelectTechnologySelectHover')) {
                e.currentTarget.classList.add('SelectTechnologySelectHover');
            }
        } else {
            if (e.currentTarget.classList.contains('SelectTechnologySelectHover')) {
                e.currentTarget.classList.remove('SelectTechnologySelectHover');
            }
        }
    };

    // handle the mouse leave event on the technology select elem
    handleListMouseLeave = e => {
        if (e.target.classList.contains('SelectTechnologySelectHover')) {
            e.target.classList.remove('SelectTechnologySelectHover');
        }
    };

    // handle the user technology selection
    handleListSelection = (e, option) => {
        e.stopPropagation();

        let defaults = this.handleParseDefaultsFromDefinitions(this.state.definitions, option.definition_id);

        this.setState({
            listTitleLabel: option.label,
            listTitleValue: option.value,
            listTitleDescription: option.detail,
            technologyListOpen: false,
            selectedDefaultMaterialId: defaults.material_id,
            selectedDefaultFinishId: defaults.finish_id,
            selectedDefaultColourId: defaults.colour_id,
            selectedDefaultColourVal: defaults.colour_val,
            selectedDefinitionId: defaults.definition_id
        });
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // handle the next stage of the site
    handleNextStage = async (overwriteEmail, currencyInit) => {
        if (!this.state.movingToNextStage) {
            this.props.handleLoading(true);

            this.props.toggleActiveModal(null);

            try {
                await this.handleInfoUpdate(overwriteEmail, currencyInit);
            } catch (error) {
                handleError('59414JNM', error);
                return;
            }

            this.setState({
                movingToNextStage: true
            }, () => {
                handlePushToDataLayer({
                    event: 'continue_to_quote_click'
                });

                localStorage.setItem('expected_parts', JSON.stringify(this.state.expectedParts));

                this.props.history.push(`/quote/${this.state.quoteHash}`);
            });
        }
    };

    // before parts start uploading, insert a row into ddb that will contain the quote data
    handleQuoteInsert = () => {
        return new Promise(async (resolve, reject) => {
            this.props.handleLoading(true);

            let gtm_data = getCookie('_gtm_traffic_info');
            gtm_data = !!gtm_data ? gtm_data : null;

            handleApiReq(
                'post',
                '/crud',
                {
                    action: 'INSERT_QUOTE',
                    quote_id: 'no_quote',
                    attach_config_data: true,
                    init_currency: localStorage.getItem('dc'),
                    gtm_data
                }
            ).then(result => {
                // set the mackerel cookie
                if (result.data.cookie_to_set) getSetBiscuit(result.data.cookie_to_set);

                this.setState({
                    quoteHash: result.data.quote_hash,
                    detectedCurrency: result.data.detected_currency
                }, () => {
                    localStorage.setItem('qid', this.state.quoteHash);
                    if (this.state.detectedCurrency) localStorage.setItem('dc', this.state.detectedCurrency);
                    this.props.handleLoading(false);
                    resolve();
                });
            }).catch(error => {
                this.props.handleLoading(false);
                reject();
            });
        });
    };

    // when user has filled out the email / phone inputs send to db
    handleInfoUpdate = (overwriteEmail, currencyInit) => {
        return new Promise(async (resolve, reject) => {
            this.props.handleLoading(true);

            if (currencyInit) {
                let valToSet = currencyInit.match(/^\w+/)[0];
                if (valToSet) {
                    localStorage.setItem('dc', valToSet);
                }
            }

            handleApiReq(
                'put',
                '/crud',
                {
                    action: 'UPDATE_QUOTE_EMAIL',
                    quote_id: this.state.quoteHash,
                    attach_quote_data: true,
                    attach_config_data: true,
                    email: this.props.isAdmin && overwriteEmail ? overwriteEmail.toLowerCase() : this.props.loggedIn && !this.props.isAdmin ? null : this.state.userInfo.email.toLowerCase(),
                    material_id: this.state.selectedDefaultMaterialId,
                    finish_id: this.state.selectedDefaultFinishId,
                    colour_id: this.state.selectedDefaultColourId,
                    colour_val: this.state.selectedDefaultColourVal,
                    assigned: !!(this.props.isAdmin && !overwriteEmail),
                    currency_init: localStorage.getItem('dc')
                }
            ).then(() => {
                this.props.handleLoading(false);
                resolve();
            }).catch(error => {
                this.props.handleLoading(false);
                reject(error);
            });
        });
    };

    handleUploadFinished = expectedParts => {
        this.setState({
            expectedParts
        }, () => {
            this.props.toggleActiveModal('user_info_modal', {
                userInfo: this.state.userInfo,
                setUserInfo: (val, callback) => this.setState({
                    userInfo: val
                }, () => callback(this.state.userInfo.email)),
                handleNextStage: this.handleNextStage,
                industryOptions: this.state.industryOptions,
                detectedCurrency: this.state.detectedCurrency,
                currencyOptions: this.state.currencyOptions,
                tcLink: this.state.tcLink
            });
        });
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    render() {
        const {
            listTitleValue,
            listTitleLabel,
            listTitleDescription,
            technologyListOpen,
            technologyListOptions,
            quoteHash,
            selectedDefinitionId,
            fileLimit
        } = this.state;

        return (
            <div className="Page">
                <div className="PageContentWrap">
                    {this.props.windowBreakpoint?.w <= 768 &&
                        <div id="UploadHeader">
                            <div id="UploadHeaderTitleWrap">
                                <h1 className="Bold30">Start a 3D People Quote</h1>

                                <h3 className="Reg16">Upload your files for an instant online quote</h3>
                            </div>
                        </div>
                    }

                    <div className="PageContent">
                        {(this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) &&
                            <div id="UploadHeader">
                                <div id="UploadHeaderTitleWrap">
                                    <h1 className="Bold30">Start a 3D People Quote</h1>

                                    <h3 className="Reg16">Upload your files for an instant online quote</h3>
                                </div>
                            </div>
                        }

                        <div id="SelectTechnologyWrap">
                            <div id="SelectTechnologyLeft">
                                <h4 className="Bold12 StepReference">Start Your Quote</h4>

                                <div id="SelectTechnologyTitle">
                                    <h3 className="Bold16">Select Technology</h3>

                                    <MoreInfoI
                                        tooltip={
                                            <React.Fragment>
                                                <p>The selected technology will be applied to all parts in the quote and production requirements will be set to our defaults.</p>
                                                <br></br>
                                                <p>All production requirements are editable per part on the next page.</p>
                                            </React.Fragment>
                                        }
                                    />
                                </div>
                            </div>

                            <div
                                id="SelectTechnologySelect"
                                tabIndex="0"
                                ref={this.technologyList}
                                onClick={this.handleTechnologyListOpenClose}
                                onBlur={e => this.handleListClickAway(e, false)}
                                onMouseMove={this.handleListMouseMove}
                                onMouseLeave={this.handleListMouseLeave}
                            >
                                <div id="SelectTechnologySelectLeft">
                                    {this.props.windowBreakpoint?.w <= 768 &&
                                        <h3 className="Bold16">{listTitleValue?.toUpperCase()}</h3>
                                    }
                                    {(this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) &&
                                        <React.Fragment>
                                            <h3 className="Bold16">{listTitleLabel}</h3>
                                            <p id="TechnologySelectNames" className="Reg16">{listTitleDescription}</p>
                                        </React.Fragment>
                                    }
                                </div>

                                <ExpandMoreIcon
                                    id="SelectTechnologyIcon"
                                    className={technologyListOpen ? "OpenIcon" : null}
                                />

                                <DropDownList
                                    keyToUse="technology-list-options"
                                    anchorEl={this.technologyList.current}
                                    bordered={'left'}
                                    open={technologyListOpen}
                                    handleSelection={this.handleListSelection}
                                    options={technologyListOptions}
                                    isOptionHidden={({ definition_id }) => {
                                        return definition_id === selectedDefinitionId;
                                    }}
                                    returnElem={({ label, detail, value }) => {
                                        return this.props.windowBreakpoint?.w <= 768 ? (
                                            <h3 className="Bold16 TechnologyListOptionH3">{value?.toUpperCase()}</h3>
                                        ) : (
                                            <React.Fragment>
                                                <h3 className="Bold16 TechnologyListOptionH3">{label}</h3>
                                                <p className="Reg16 TechnologyListOptionP">{detail}</p>
                                            </React.Fragment>
                                        )
                                    }}
                                />
                            </div>
                        </div>

                        <ErrorBoundary
                            componentWrapped="UploadDropZone @ Upload"
                        >
                            <UploadDropZone
                                mode="child"
                                stepReference="Secure Upload"
                                stepTitle="Upload Your Files"
                                handleLoading={this.props.handleLoading}
                                windowBreakpoint={this.props.windowBreakpoint}
                                fileLimit={fileLimit}
                                handleStartIndexFrom={num => {
                                    this.setState({
                                        startIndexFrom: num
                                    });
                                }}
                                handleQuoteInsert={this.handleQuoteInsert}
                                quoteHash={quoteHash}
                                loading={this.props.loading}
                                handleUploadEnd={arr => this.handleUploadFinished(arr)}
                                handleNextStage={this.handleNextStage}
                                movingToNextStage={this.state.movingToNextStage}
                                validEmail={!!(this.props.loggedIn && this.props.loggedIn.email && !this.props.isAdmin) || !!(this.state.userInfo.email && this.state.userInfo.email.length && validateEmailAddress(this.state.userInfo.email))}
                                isAdmin={this.props.isAdmin}
                            />
                        </ErrorBoundary>

                        <PrivacyPolicy />
                    </div>
                </div>

                <FooterImgLinks />
            </div>
        );
    };
}

export default withRouter(Upload);