import React, {
    useState,
    useRef,
    useEffect
} from 'react';

import clsx from 'clsx';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    setNickname
} from '../../../functions/user_functions';

import {
    ClickAwayListener
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import './NicknameModal.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function NicknameModal(props) {
    const {
        handleClose,
        setTableLoading,
        updateRow,
        uuid,
        initname
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const nicknameRef = useRef();

    // value for nickname warning message
    const [nicknameWarning, setNicknameWarning] = useState(null);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // handle the keydown on nickname input
    const handleNicknameKeyDown = e => {
        // clear warning when value is changed
        if (nicknameWarning) setNicknameWarning(null);

        // if enter was pressed, attempt to save nickname
        if (e.code === 'Enter') {
            handleSaveNickname();
        }
    };

    const handleSaveNickname = async () => {
        if (!nicknameRef || !nicknameRef.current) return;

        // trim nickname
        let nickname = nicknameRef.current.value.trim();

        // max char length of 30
        if (nickname.length > 30) {
            setNicknameWarning("Can't be longer than 30 characters");
            return;
        }

        setTableLoading(true);
        handleClose();

        let nicknameResult = await setNickname({ uuid, nickname });
        updateRow(nicknameResult);
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        if (nicknameRef?.current && initname) nicknameRef.current.value = initname;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
    
    return (
        <div
            id="UserTableNicknameWrap"
            className="FullHeightWidth"
        >
            <ClickAwayListener
                onClickAway={handleClose}
            >
                <div id="UserTableNickname">
                    <CloseIcon
                        id="UserTableNicknameModalClose"
                        onClick={handleClose}
                    />

                    <p className={clsx('Bold14', nicknameWarning ? 'UserTableNicknameWarning' : null)}>{nicknameWarning ? nicknameWarning : 'Create a nickname'}</p>

                    <input
                        ref={nicknameRef}
                        placeholder='Nickname'
                        onKeyDown={handleNicknameKeyDown}
                        maxLength={30}
                    >
                    </input>

                    <div>
                        <button onClick={handleClose}>
                            Cancel
                        </button>

                        <button onClick={handleSaveNickname}>
                            Save
                        </button>
                    </div>
                </div>
            </ClickAwayListener>
        </div>
    );
};