import React, {
    Component,
    createRef
} from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import clsx from 'clsx';
import axios from 'axios';
import * as THREE from 'three';
import {
    STLLoader
} from 'three/examples/jsm/loaders/STLLoader';
import {
    OrbitControls
} from 'three/examples/jsm/controls/OrbitControls';
import {
    CSS2DObject,
    CSS2DRenderer
} from './CSS2DRenderer';
import {
    MeshLine,
    MeshLineMaterial
} from 'three.meshline';

import {
    Slider
} from '@mui/material';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    handleApiReq,
    handleError
} from '../../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ThicknessIcon from '../../Icons/ThicknessIcon';
import MeasureIcon from '../../Icons/MeasureIcon';
import ShadedIcon from '../../Icons/ShadedIcon';
import WireframeIcon from '../../Icons/WireframeIcon';
import XrayIcon from '../../Icons/XrayIcon';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./PartViewer.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

const toolsActions = [
    {
        label: 'Thickness',
        id: 'thickness',
        icon: size => <ThicknessIcon size={size} className="PartViewerActionIcon" />
    },
    {
        label: 'Measure',
        id: 'measure',
        icon: size => <MeasureIcon size={size} className="PartViewerActionIcon" />
    }
];

const viewActions = [
    {
        label: 'Shaded',
        id: 'shaded',
        icon: size => <ShadedIcon size={size} className="PartViewerActionIcon PartViewerActionIconFill" />
    },
    {
        label: 'X-ray',
        id: 'xray',
        icon: size => <XrayIcon size={size} className="PartViewerActionIcon PartViewerActionIconFill" />
    },
    {
        label: 'Wireframe',
        id: 'wireframe',
        icon: size => <WireframeIcon size={size} className="PartViewerActionIcon" />
    }
];

const vertexShader = `
    uniform float c;
    uniform float p;
    varying float intensity;
    void main()
    {
        vec3 vNormal = normalize( normalMatrix * normal );
        intensity = pow( c - dot(vNormal, vec3(0.,0.0, 1.0)), p );
        gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
`;

const fragmentShader = `
    uniform vec3 glowColor;
    varying float intensity;
    void main()
    {
        vec3 glow = glowColor * intensity;
        gl_FragColor = vec4( glow, 1.0 );
    }
`;

const mainBlue = 0x005FFE;

class PartViewer extends Component {
    constructor(props) {
        super(props);

        this.loadingBarRef = createRef();
        this.distanceLabelRef = createRef();
        this.axisLabelRefs = createRef();
        this.axisLabelRefs.current = {
            'X': null,
            'Y': null,
            'Z': null
        };
        this.axisInputRefs = createRef();
        this.axisInputRefs.current = {
            'X': null,
            'Y': null,
            'Z': null
        };

        this.state = {
            initialized: 0,
            loading: true,
            modelData: null,
            cancelToken: null,
            loadingDotsInterval: null,
            loadingDots: '.',
            loadingError: false,
            loadingComplete: false,
            showClearMeasures: false,
            sectionAnalysisOpen: (this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)),
            sectionAnalysisSliderValues: {
                'X': null,
                'Y': null,
                'Z': null
            },
            sectionAnalysisInputActive: {
                'X': 0,
                'Y': 0,
                'Z': 0
            },
            toolsOpen: (this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)),
            viewsOpen: (this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)),
            selectedView: 'shaded',
            selectedTool: null,
            sliding: false,
            sliderMouseUpTimeout: null,
        };

        this.disposing = false;

        this.windowOffset = 0;
        this.CancelToken = axios.CancelToken;
        this.cancelExecutor = null;

        this.tempV = new THREE.Vector3();
        this.distanceAB = 0.0;

        // 0 means mm, 1 means cms, 2 means dms, 3 means ms
        this.orderOfMagnitude = 0;
        this.pom = Math.pow(10, this.orderOfMagnitude);

        this.mouse = new THREE.Vector2();

        this.mouseRayCaster = new THREE.Raycaster();
        this.mouseRayCaster.firstHitOnly = true;

        this.orbitControlsActive = false;

        this.measurement_mode = 0;

        this.oldA = -1;
        this.oldB = -1;
        this.oldC = -1;

        this.pointAisFixed = false;

        // clipping planes
        this.clippingPlaneX = new THREE.Plane(new THREE.Vector3(-1, 0, 0), 0.0);
        this.clippingPlaneY = new THREE.Plane(new THREE.Vector3(0, -1, 0), 0.0);
        this.clippingPlaneZ = new THREE.Plane(new THREE.Vector3(0, 0, -1), 0.0);

        // base material
        this.baseMaterial = new THREE.MeshPhysicalMaterial({
            clearcoat: 0.5,
            clearcoatRoughness: 0.4,
            reflectivity: 1,
            transmission: 0.1,
            side: THREE.DoubleSide,
            clippingPlanes: [this.clippingPlaneX, this.clippingPlaneY, this.clippingPlaneZ],
            wireframe: false,
            transparent: true,
            vertexColors: false
        });

        this.baseMaterial.color.set(0xd5dbe3);

        // xray material
        this.xrayMaterial = new THREE.ShaderMaterial(
            {
            uniforms: {
                "c": {type: "f", value: 1.0},
                "p": {type: "f", value: 3},
                glowColor: {type: "c", value: new THREE.Color(0x84ccff)}
            },
            vertexShader,
            fragmentShader,
            side: THREE.FrontSide,
            blending: THREE.AdditiveBlending,
            transparent: true,
            depthWrite: false,
        });

        // measure material
        this.measureMaterial = new THREE.MeshPhysicalMaterial({
            side: THREE.DoubleSide,
            wireframe: false,
            transparent: true,
            vertexColors: true
        });

        this.lineMaterial = null;

        this.thicknessRayCaster = new THREE.Raycaster();

        this.pointA = null;
        this.pointB = null;
        this.lineAB = null;

        this.labelsLimit = 8;

        this.pointsA = [];
        this.pointsB = [];
        this.linesAB = [];
        this.labelsAB = [];

        this.mouseIsDown = false;
        this.mouseHasMoved = 0;

        this.labelFontFamily = 'monospace';
        this.labelFontSize = 14;
        this.labelBorderSize = 2;
        this.labelColor = {
            r: 0,
            g: 95,
            b: 254,
            a: 1
        };
        this.labelTextColor = {
            r: 255,
            g: 255,
            b: 255,
            a: 1
        };
    };

    componentDidMount() {
        // the animation for the loading text dots
        let intervalToSet = setInterval(this.handleLoadingDots, 400);
        this.setState({
            loadingDotsInterval: intervalToSet
        });

        window.addEventListener('resize', this.handleWindowResize);
    };

    componentDidUpdate() {
        // initialize the download and threejs code
        if (!this.state.initialized) {
            this.setState({ initialized: this.state.initialized + 1 }, () => {
                if (this.state.initialized === 1) {
                    this.init();
                }
            });
        }
    };

    componentWillUnmount() {
        // stop the animation
        if (this.state.partData && this.frameId) this.stop();

        // dispose of all the three.js elements
        this.disposeScene();

        // clear the loading text dots animation
        this.handleClearLoadingDotsInterval();

        // remove resize eventlistener
        window.removeEventListener('resize', this.handleWindowResize)

        // if the download is still in progress, cancel the request
        if (!this.state.loadingComplete && this.cancelExecutor) this.cancelExecutor('Request aborted');
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    handleWindowResize = () => {
        try {
            if (this.renderer) {
                this.canvasWidth = this.mount.clientWidth;
                this.canvasHeight = this.mount.clientHeight;

                this.camera.aspect = this.mount.clientWidth / this.mount.clientHeight;
                this.camera.updateProjectionMatrix();

                this.renderer.setSize(this.canvasWidth, this.canvasHeight);
            }
        } catch (error) {
            console.log('err caught @ canv resize', error);
        }
    };

    // if the user clicks off the modal close the viewer
    handleClickAway = e => {
        if (this.state.sliding) return;
        if (e.target.id === 'PartViewer') this.props.handleClose();
    };

    // handles the animation for the loading text dots
    handleLoadingDots = () => {
        this.setState({
            loadingDots: this.state.loadingDots.length >= 3 ? '' : '.'.repeat(this.state.loadingDots.length + 1)
        });
    };

    // initializes the download, then sets modelData
    init = async () => {
        let modelData = await this.fetchPartFile(this.props.partData.file_key);

        if (modelData && ! this.state.loadingError) {
            this.setState({
                modelData
            });
        } else {
            this.handleLoadError();
        }
    };

    // fetch the download url then download the file
    fetchPartFile = name => new Promise(async (resolve, reject) => {
        if (!name) resolve(null);

        try {
            // fetch the presigned get url
            let downloadUrl = await handleApiReq(
                'get',
                `/fetch-get-url?n=${name}`,
                null,
                'text'
            );

            if (downloadUrl) {
                // creates a cancel token to be used if the user closes the modal and the model is still downloading
                let cancelTokenExecutor = new this.CancelToken(c => {
                    this.cancelExecutor = c
                });

                // set the options for axios to use
                let options = {
                    headers: {
                        accept: 'application/json'
                    },
                    onDownloadProgress: this.handleDownload,
                    responseType: 'arraybuffer',
                    cancelToken: cancelTokenExecutor
                };

                // download the file from S3
                axios.get(downloadUrl, options).then(result => {
                    resolve(result.data);
                }).catch(error => {
                    handleError('53526SqT', error);
                    resolve(null);
                });
            } else {
                resolve(null);
            }
        } catch (error) {
            handleError('34244lhq', error);
            resolve(null);
        }
    });

    // handles the loading bar animation
    handleDownload = e => {
        if (e.total && this.loadingBarRef && this.loadingBarRef.current) {
            this.loadingBarRef.current.style.transform = `translateX(-${100 - ((e.loaded / e.total) * 100)}%)`;
        }
    };

    // handles a loading error
    handleLoadError = () => {
        this.setState({
            loadingError: true
        });

        if (this.loadingBarRef && this.loadingBarRef.current) {
            this.loadingBarRef.current.style.transform = 'translateX(0%)';
            this.loadingBarRef.current.style.backgroundColor = '#CC0018';
        }

        this.handleClearLoadingDotsInterval();
    };

    // once the three.js mount is detected handles the initialization of the scene
    handleMountRef = ref => {
        if (ref && !this.mount && this.state.modelData) {
            this.mount = ref;

            this.initScene();
        }
    };

    // handles the clearing of the loading text dots
    handleClearLoadingDotsInterval = () => {
        if (this.state.loadingDotsInterval) {
            clearInterval(this.state.loadingDotsInterval);
            this.setState({
                loadingDotsInterval: null
            });
        }
    };

    getPoint = (x, y, z, size = 0.03) => {
        const material = new THREE.MeshBasicMaterial({ color: mainBlue });

        let point = new THREE.Mesh(new THREE.SphereGeometry(size, 20, 20), material);

        point.position.set(x, y, z);
        return point;
    };

    computeCentersData = () => {
        const ps = this.mesh.geometry.attributes.position.array;
        const cnt = ps.length / 9;
        this.centers_ps = new Float32Array(cnt * 3);
        this.centers_ns = new Float32Array(cnt * 3);

        for (let i = 0; i < cnt; i++) {
            // Computing centers
            const x0 = ps[9 * i];
            const y0 = ps[9 * i + 1];
            const z0 = ps[9 * i + 2];
            const x1 = ps[9 * i + 3];
            const y1 = ps[9 * i + 4];
            const z1 = ps[9 * i + 5];
            const x2 = ps[9 * i + 6];
            const y2 = ps[9 * i + 7];
            const z2 = ps[9 * i + 8];
            this.centers_ps[3 * i] = (x0 + x1 + x2) / 3.0;
            this.centers_ps[3 * i + 1] = (y0 + y1 + y2) / 3.0;
            this.centers_ps[3 * i + 2] = (z0 + z1 + z2) / 3.0;

            // Computing normals
            const dx01 = x1 - x0;
            const dy01 = y1 - y0;
            const dz01 = z1 - z0;
            const dx02 = x2 - x0;
            const dy02 = y2 - y0;
            const dz02 = z2 - z0;
            const nx = dy01 * dz02 - dz01 * dy02;
            const ny = dz01 * dx02 - dx01 * dz02;
            const nz = dx01 * dy02 - dy01 * dx02;
            const n = Math.sqrt(nx * nx + ny * ny + nz * nz);
            this.centers_ns[3 * i] = nx / n;
            this.centers_ns[3 * i + 1] = ny / n;
            this.centers_ns[3 * i + 2] = nz / n;
        }
    };

    computeCenters() {
        const centersGeometry = new THREE.BufferGeometry();
        centersGeometry.computeBoundingBox();
        centersGeometry.setAttribute('position', new THREE.BufferAttribute(this.centers_ps, 3));
        centersGeometry.setAttribute('color', new THREE.BufferAttribute(this.centers_cols, 3));
        const centersMaterial = new THREE.PointsMaterial({ size: .1, vertexColors: true });

        this.centers = new THREE.Points(centersGeometry, centersMaterial);
    };

    computeDistanceAB() {
        const pA = this.pointA.position;
        const pB = this.pointB.position;
        const dx = pB.x - pA.x;
        const dy = pB.y - pA.y;
        const dz = pB.z - pA.z;
        const dst = Math.sqrt(dx * dx + dy * dy + dz * dz);
        const precision = 2;
        const p10 = Math.pow(10, precision);
        this.distanceAB = Math.round(dst * p10 + Number.EPSILON) / p10;
    };

    clearMeasurements(all) {
        const cnt = this.linesAB.length;

        if (all) {
            for (let i = 0; i < cnt; i++) {
                this.sceneLabels.remove(this.pointsA[i]);
                this.sceneLabels.remove(this.pointsB[i]);
                this.sceneLabels.remove(this.linesAB[i]);
                this.scene.remove(this.labelsAB[i]);
            }

            this.pointsA = [];
            this.pointsB = [];
            this.linesAB = [];
            this.labelsAB = [];

            this.pointA.visible = false;
            this.pointB.visible = false;
            this.lineAB.visible = false;

            if (this.distanceLabelRef && this.distanceLabelRef.current) {
                this.distanceLabelRef.current.style.opacity = 0;
            }
        } else {
            const diff = cnt - this.labelsLimit;

            if (diff > 0) {
                for (let i = 0; i < diff; i++) {
                    this.sceneLabels.remove(this.pointsA[i]);
                    this.sceneLabels.remove(this.pointsB[i]);
                    this.sceneLabels.remove(this.linesAB[i]);
                    this.scene.remove(this.labelsAB[i]);
                }

                this.pointsA = this.pointsA.slice(diff);
                this.pointsB = this.pointsB.slice(diff);
                this.linesAB = this.linesAB.slice(diff);
                this.labelsAB = this.labelsAB.slice(diff);
            }
        }

        this.setState({
            showClearMeasures: !!this.linesAB.length
        });
    };

    handleMouseDown = () => {
        this.mouseIsDown = true;
    };

    handleMouseUp = () => {
        this.mouseIsDown = false;

        if (this.mouseHasMoved < 3) {
            if (this.measurement_mode === 2) {
                this.addMeasurement();
            } else if (this.measurement_mode === 1) {
                if (this.pointAisFixed) {
                    this.addMeasurement();
                    this.pointAisFixed = false;

                    if (this.distanceLabelRef && this.distanceLabelRef.current) {
                        this.distanceLabelRef.current.style = 0;
                    }
                } else {
                    this.pointAisFixed = true;
                }
            }
        }

        this.mouseHasMoved = 0;
    };

    mouseCast = e =>  {
        const bounds = this.renderer.domElement.getBoundingClientRect();

        const x1 = e.clientX - bounds.left;
        const x2 = bounds.right - bounds.left;
        this.mouse.x = (x1 / x2) * 2 - 1;
        const y1 = e.clientY - bounds.top;
        const y2 = bounds.bottom - bounds.top;
        this.mouse.y = -(y1 / y2) * 2 + 1;

        this.mouseRayCaster.setFromCamera(this.mouse, this.camera);

        return this.mouseRayCaster.intersectObjects([this.mesh]);
    };

    handleMouseMove = e => {
        if (this.orbitControlsActive) {
            if (this.mouseIsDown) this.mouseHasMoved++;
            return;
        }

        const found = this.mouseCast(e);

        if (found.length > 0) {
            const p = found[0].point;
            const face = found[0].face;
            const color = this.mesh.geometry.attributes.color;

            if (this.oldA >= 0) color.setXYZ(this.oldA, .83, .85, .88);
            if (this.oldB >= 0) color.setXYZ(this.oldB, .83, .85, .88);
            if (this.oldC >= 0) color.setXYZ(this.oldC, .83, .85, .88);

            color.setXYZ(face.a, 0, 0, 1);
            color.setXYZ(face.b, 0, 0, 1);
            color.setXYZ(face.c, 0, 0, 1);

            this.oldA = face.a;
            this.oldB = face.b;
            this.oldC = face.c;

            color.needsUpdate = true;

            if (this.measurement_mode === 2) {
                if (this.orbitControlsActive) return;

                const nx = this.centers_ns[3 * found[0].faceIndex];
                const ny = this.centers_ns[3 * found[0].faceIndex + 1];
                const nz = this.centers_ns[3 * found[0].faceIndex + 2];
                const n = new THREE.Vector3(nx, ny, nz);

                const pA = this.pointA.position;
                this.pointA.visible = true;

                pA.x = p.x;
                pA.y = p.y;
                pA.z = p.z;

                const dir = new THREE.Vector3(-n.x, -n.y, -n.z);
                const epsilon = -.01;

                this.thicknessRayCaster.set(new THREE.Vector3(p.x + epsilon * n.x, p.y + epsilon * n.y, p.z + epsilon * n.z), dir);

                const intersects = this.thicknessRayCaster.intersectObjects([this.mesh]);

                if (intersects.length > 0) {
                    const ip = intersects[0].point;

                    this.pointB.visible = true;
                    const pB = this.pointB.position;

                    pB.x = ip.x;
                    pB.y = ip.y;
                    pB.z = ip.z;

                    this.meshLineAB.setPoints([this.pointA.position, this.pointB.position]);
                    this.lineAB.visible = true;
                }
            } else if (this.measurement_mode === 1) {
                if (this.orbitControlsActive) return;

                if (this.pointAisFixed) {
                    const pB = this.pointB.position;
                    this.pointB.visible = true;

                    pB.x = p.x;
                    pB.y = p.y;
                    pB.z = p.z;

                    this.meshLineAB.setPoints([this.pointA.position, this.pointB.position]);
                    this.lineAB.visible = true;
                } else {
                    const pA = this.pointA.position;
                    this.pointA.visible = true;

                    pA.x = p.x;
                    pA.y = p.y;
                    pA.z = p.z;
                }
            } else {
                this.pointA.visible = false;
                this.pointB.visible = false;
            }
        }
    };

    sceneTraverse = (obj, fn) => {
        if (!obj) return

        fn(obj)

        if (obj.children && obj.children.length > 0) {
            obj.children.forEach(o => {
                this.sceneTraverse(o, fn)
            });
        }
    };

    disposeScene = () => {
        this.disposing = true;
        this.sceneTraverse(this.scene, o => {
            if (o.geometry) {
                o.geometry.dispose();
            }

            if (o.material) {
                if (o.material.length) {
                    for (let i = 0; i < o.material.length; ++i) {
                        o.material[i].dispose();
                    }
                } else {
                    o.material.dispose();
                }
            }
        });
        this.sceneTraverse(this.sceneLabels, o => {
            if (o.geometry) {
                o.geometry.dispose();
            }

            if (o.material) {
                if (o.material.length) {
                    for (let i = 0; i < o.material.length; ++i) {
                        o.material[i].dispose();
                    }
                } else {
                    o.material.dispose();
                }
            }
        });

        this.scene = null;
        this.sceneLabels = null;
        this.directionalLight1 = null;
        this.directionalLight2 = null;
        this.directionalLight3 = null;
        this.directionalLight4 = null;
        this.controls = null;
        this.renderer && this.renderer.renderLists.dispose();
        this.centers = null;
        this.boxAtts = null;
        this.helper = null;
        this.size = null;
        this.orderOfMagnitude = null;
        this.pom = null;
        this.pointA = null;
        this.pointB = null;
        this.meshLineAB = null;
        this.clippingPlaneX = null;
        this.clippingPlaneY = null;
        this.clippingPlaneZ = null;
        this.baseMaterial = null;
        this.xrayMaterial = null;
        this.measureMaterial = null;
        this.lineMaterial = null;
    };

    // initialize the threejs code and scene
    initScene = () => {
        let errorCaught = false;

        try {
            // canvas height / width equal to that of the mount
            this.canvasWidth = this.mount.clientWidth;
            this.canvasHeight = this.mount.clientHeight;

            // scene
            this.scene = new THREE.Scene();
            this.scene.background = new THREE.Color(0xffffff);

            this.sceneLabels = new THREE.Scene();

            // renderer
            this.renderer = new THREE.WebGLRenderer({ antialias: true, powerPreference: "high-performance" });
            this.renderer.autoClear = false;
            this.renderer.localClippingEnabled = true;
            this.renderer.setClearColor("#263238");
            this.renderer.setPixelRatio( window.devicePixelRatio );
            this.renderer.setSize(this.canvasWidth, this.canvasHeight);

            // label renderer
            this.labelRenderer = new CSS2DRenderer();
            this.labelRenderer.setSize(this.canvasWidth, this.canvasHeight);
            this.labelRenderer.domElement.style.position = 'absolute';
            this.labelRenderer.domElement.style.top = '0px';
            this.labelRenderer.domElement.style.pointerEvents = 'none';

            // camera
            this.camera = new THREE.PerspectiveCamera(25, this.canvasWidth / this.canvasHeight, 0.1, 6000);

            // controls
            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            this.controls.maxDistance = 4000;
            this.controls.enableDamping = true;
            this.controls.dampingFactor = 0.1;
            this.controls.addEventListener('start', () => this.orbitControlsActive = true);
            this.controls.addEventListener('end', () => this.orbitControlsActive = false);

            // lights
            this.light = new THREE.AmbientLight(0x404040 , 2);
            this.scene.add(this.light);

            this.directionalLight1 = new THREE.DirectionalLight(0xffffff, 0.25);
            this.directionalLight1.position.set(0,100,100);
            this.scene.add(this.directionalLight1);

            this.directionalLight2 = new THREE.DirectionalLight(0xffffff, 0.25);
            this.directionalLight2.position.set(0,100,-100);
            this.scene.add(this.directionalLight2);

            this.directionalLight3 = new THREE.DirectionalLight(0xffffff, 0.25);
            this.directionalLight3.position.set(100,-100,0);
            this.scene.add(this.directionalLight3);

            this.directionalLight4 = new THREE.DirectionalLight(0xffffff, 0.25);
            this.directionalLight4.position.set(-100,-100,0);
            this.scene.add(this.directionalLight4);

            // STL loader
            let loader = new STLLoader();

            // geometry
            this.geometry = loader.parse(this.state.modelData);
            this.geometry.computeVertexNormals();

            const colors = []
            for (let i = 0; i < this.geometry.attributes.position.count; i++) {
                colors.push(.83, .85, .88, .4);
            }
            this.geometry.setAttribute('color', new THREE.BufferAttribute(new Float32Array(colors), 4));

            // mesh
            this.mesh = new THREE.Mesh(this.geometry, this.baseMaterial);

            this.computeCentersData();

            this.computeCenters();

            this.centers.visible = false;

            this.mesh.geometry.center();
            this.scene.add(this.mesh);

            // Adding centers
            const pointsGeometry = new THREE.BufferGeometry();
            const pointsMaterial = new THREE.PointsMaterial({size: 0.1, sizeAttenuation: false});

            pointsMaterial.color.set(0x000000);
            pointsGeometry.setAttribute('position', this.centers_ps);
            pointsGeometry.setAttribute('itemSize', 3);

            // fit mesh to the camera
            this.boxAtts = this.fitCameraToObject(this.camera, this.mesh, 7);
            this.helper = this.boxAtts[0];
            this.size = this.boxAtts[1];

            let maxSize = Math.max(this.size.x, this.size.y, this.size.z);
            this.orderOfMagnitude = Math.floor(Math.log10(maxSize));
            this.pom = Math.pow(10, this.orderOfMagnitude);

            this.lineMaterial = new MeshLineMaterial({
                color: mainBlue,
                sizeAttenuation: true,
                lineWidth: 0.007 * this.pom,
                depthWrite: false,
                depthTest: false
            });

            this.pointA = this.getPoint(0, 0, 0, 0.01 * this.pom);
            this.pointB = this.getPoint(0, 0, 0, 0.01 * this.pom);
            this.pointA.visible = false;
            this.pointB.visible = false;

            const lineGeometry = new THREE.BufferGeometry().setFromPoints([this.pointA.position, this.pointB.position]);
            this.meshLineAB = new MeshLine();
            this.meshLineAB.setGeometry(lineGeometry);
            this.lineAB = new THREE.Mesh(this.meshLineAB, this.lineMaterial);

            this.lineAB.visible = false;
            this.sceneLabels.add(this.pointA);
            this.sceneLabels.add(this.pointB);
            this.sceneLabels.add(this.lineAB);

            // section analysis
            let xMax = (this.size.x / 2.0) * 1.1;
            let yMax = (this.size.y / 2.0) * 1.1;
            let zMax = (this.size.z / 2.0) * 1.1;

            this.setState({
                sectionAnalysisMinMaxVals: {
                    x: {
                        max: xMax,
                        min: -xMax
                    },
                    y: {
                        max: yMax,
                        min: -yMax
                    },
                    z: {
                        max: zMax,
                        min: -zMax
                    },
                }
            });

            this.clippingPlaneX.constant = xMax;
            this.clippingPlaneY.constant = yMax;
            this.clippingPlaneZ.constant = zMax;

            this.setState({
                sectionAnalysisSliderValues: {
                    'X': xMax,
                    'Y': yMax,
                    'Z': zMax
                }
            });

            this.renderer.domElement.addEventListener('mousemove', this.handleMouseMove);
            this.renderer.domElement.addEventListener('mousedown', this.handleMouseDown)
            this.renderer.domElement.addEventListener('mouseup', this.handleMouseUp);
        } catch (error) {
            console.log('error @ init', error);
            errorCaught = true;
        }

        if (errorCaught) {
            this.handleLoadError();
        } else {
            // once the scene has been setup start animating
            this.setState({
                loadingComplete: true
            }, () => {
                // clear loading text dots
                this.handleClearLoadingDotsInterval();

                // append the threejs canvas to the mount
                this.mount.appendChild(this.renderer.domElement);
                this.mount.appendChild(this.labelRenderer.domElement);

                // begin the animation
                this.start();
            });
        }
    };

    // initially fits the object within the frame of the camera
    fitCameraToObject = (camera, object, offset) => {
        offset = offset || 1.25;

        const boundingBox = new THREE.Box3();

        boundingBox.setFromObject(object);
        const helper = new THREE.BoxHelper(object, 0xff0000);
        helper.update();

        const center = new THREE.Vector3();
        boundingBox.getCenter(center);

        const size = new THREE.Vector3();
        boundingBox.getSize(size);

        const maxDim = Math.max(size.x, size.y, size.z);
        const fov = camera.fov * (Math.PI / 180);
        let cameraZ = Math.abs(maxDim / 4 * Math.tan(fov * 2.1));

        cameraZ *= offset

        let mountX =
            this.props.windowBreakpoint?.w <= 480 ? 70
            : this.props.windowBreakpoint?.w <= 768 ? 40
            : 20;

        camera.position.z = cameraZ + mountX;

        camera.lookAt(center);

        return [helper, size];
    };

    setCurrentMeasureLabel() {
        if (this.distanceLabelRef && this.distanceLabelRef.current) {
            this.distanceLabelRef.current.style.opacity = 1;
            this.distanceLabelRef.current.textContent = ("Active distance: " + this.distanceAB.toFixed(2) + 'mm');
        }
    };

    // first animation frame
    start = () => {
        if (!this.frameId) {
            this.frameId = requestAnimationFrame(this.animate);
        }
    };

    // cancel the current animation frame
    stop = () => {
        cancelAnimationFrame(this.frameId);
    };

    renderScene = () => {
        if (this.disposing) return;
        if (this.renderer) {
            this.renderer.render(this.scene, this.camera);
            this.renderer.clear();
            this.renderer.render( this.scene, this.camera );
            this.renderer.clearDepth();
            this.renderer.render( this.sceneLabels, this.camera );
        }
        if (this.labelRenderer) this.labelRenderer.render(this.scene, this.camera);
    };

    animate = () => {
        if (this.disposing) return;
        // draw to the canvas
        this.renderScene();

        // update the orbit controls
        this.controls.update();

        if (this.mesh) {
            if (this.measurement_mode === 0) {
                if (this.distanceLabelRef && this.distanceLabelRef.current) {
                     this.distanceLabelRef.current.textContent = "";
                }
            } else if (this.measurement_mode === 1) {
                this.computeDistanceAB()
                if (this.pointAisFixed) {
                    this.setCurrentMeasureLabel();
                }
            } else if (this.measurement_mode === 2) {
                this.computeDistanceAB();
                this.setCurrentMeasureLabel();
            }
        }

        // next animation frame
        this.frameId = window.requestAnimationFrame(this.animate);
    };

    handleAxisPClick = which => {
        this.setState({
            sectionAnalysisInputActive: {
                'X': which === 'X',
                'Y': which === 'Y',
                'Z': which === 'Z'
            }
        }, () => {
            if (this.axisInputRefs && this.axisInputRefs.current[which]) {
                let elem = this.axisInputRefs.current[which];
                elem.value = this[`clippingPlane${which}`].constant.toFixed(2);
                elem.focus();
            }
        });
    };

    countDecimals = val => {
        if(Math.floor(val) === val) return 0;
        return val.toString().split(".")[1].length || 0;
    };

    handleSectionAnalysisChange = (which, val) => {
        this[`clippingPlane${which}`].constant = val;

        this.axisLabelRefs.current[which].innerText = val.toFixed(2) + ' mm';

        this.setState({
            sectionAnalysisSliderValues: {
                ...this.state.sectionAnalysisSliderValues,
                [which]: val
            }
        });
    };

    handleSectionAnalysisInputChange = (which, min, max, target) => {
        let val = target.value;

        if (val[val.length - 1] === '.') return;
        if (isNaN(val)) {
            target.value = target.value.replace(/[^\d.-]/g, '');
            return;
        }

        val = parseFloat(val);

        if (isNaN(val)) return;

        if (val < min) {
            val = Math.round(min * 100) / 100;
        } else if (val > max) {
            val = Math.round(max * 100) / 100;
        }

        if (!isNaN(val) && this.countDecimals(val) > 2) {
            val = Math.round(val * 100) / 100;
        }

        target.value = val;

        this.handleSectionAnalysisChange(which, val);
    };

    returnAxis = (label, ref, disable, iRef) => {
        let disabled = true;

        if (!label || !this.state.sectionAnalysisMinMaxVals) return;

        const {
            max,
            min
        } = this.state.sectionAnalysisMinMaxVals[label.toLowerCase()];

        let value = this.state.sectionAnalysisSliderValues[label]

        disabled = !!disable;

        return (
            <div className="SectionAnalysisAxisWrap">
                <div className={disabled ? 'SectionAnalysisAxisDisabled' : null}>
                    <p className="Bold12">{label}</p>

                    <Slider
                        size="small"
                        aria-label="Small"
                        valueLabelDisplay="off"
                        min={min}
                        max={max}
                        value={value}
                        step={0.01}
                        onChange={(e, v) => {
                            if (!this.state.sliding) this.setState({ sliding: true });
                            this.handleSectionAnalysisChange(label, v)
                        }}
                        onChangeCommitted={e => {
                            if (this.state.sliderMouseUpTimeout) clearTimeout(this.state.sliderMouseUpTimeout);
                            let timeoutToSet = setTimeout(() => this.setState({
                                sliderMouseUpTimeout: null,
                                sliding: false
                            }), 100);
                            this.setState({
                                sliderMouseUpTimeout: timeoutToSet
                            });
                        }}
                        sx={{
                            height: 3,
                            width: this.props.windowBreakpoint?.w <= 480 ? 150 : 200,
                            padding: '13px 0px !important'
                        }}
                        classes={{
                            track: 'SectionAnalysisAxisTrack',
                            rail: 'SectionAnalysisAxisRail',
                            thumb: 'SectionAnalysisAxisThumb'
                        }}
                    />
                </div>

                <p
                    onClick={disabled ? null : () => this.handleAxisPClick(label)}
                    className={clsx('Reg12', this.state.sectionAnalysisInputActive[label] ? 'HideAxisP' : null, disabled ? 'DisableAxisP' : null)}
                    ref={ref}
                >{value.toFixed(2)} mm</p>

                <div
                    onBlur={() => this.setState({
                        sectionAnalysisInputActive: {
                            'X': false,
                            'Y': false,
                            'Z': false
                        }
                    })}
                    className={clsx('SectionAnalysisAxisInputWrap', this.state.sectionAnalysisInputActive[label] ? null : 'HideAxisInput')}
                >
                    <input
                        type="text"
                        ref={iRef}
                        autoFocus={true}
                        onBlur={() => this.setState({
                            sectionAnalysisInputActive: {
                                'X': false,
                                'Y': false,
                                'Z': false
                            }
                        })}
                        className={clsx('Reg12', 'SectionAnalysisAxisInput')}
                        onInput={disabled ? null : e => this.handleSectionAnalysisInputChange(label, min, max, e.target)}
                    ></input>

                    <p className='Reg12'>mm</p>
                </div>
            </div>
        );
    };

    handleViewChange = which => {
        if (which === this.state.selectedView) return;

        if (which === 'shaded' || which === 'wireframe') {
            this.renderer.localClippingEnabled = true;
            this.baseMaterial.wireframe = which === 'wireframe';
            this.mesh.material = this.baseMaterial;
            this.scene.background = new THREE.Color(0xffffff);
        }

        if (which === 'xray') {
            this.renderer.localClippingEnabled = false;
            this.mesh.material = this.xrayMaterial;
            this.scene.background = new THREE.Color(0x0B002B);
        }

        if (this.distanceLabelRef && this.distanceLabelRef.current) {
            this.distanceLabelRef.current.style.opacity = 0;
        }

        this.mesh.material.opacity = 1.0;

        this.measurement_mode = 0;

        this.pointA.visible = false;
        this.pointB.visible = false;
        this.lineAB.visible = false;

        this.setState({
            selectedView: which,
            selectedTool: null,
            sectionAnalysisOpen: which === 'xray' ? false : this.state.sectionAnalysisOpen
        });
    };

    handleToolsChange = which => {
        this.renderer.localClippingEnabled = false;
        this.baseMaterial.wireframe = false;
        this.mesh.material = this.measureMaterial;
        this.scene.background = new THREE.Color(0xffffff);

        this.pointA.visible = false;
        this.pointB.visible = false;
        this.lineAB.visible = false;

        this.measurement_mode = which === 'measure' ? 1 : 2;

        if (this.distanceLabelRef && this.distanceLabelRef.current) {
            this.distanceLabelRef.current.style.opacity = 0;
        }

        this.setState({
            selectedView: null,
            selectedTool: which,
            sectionAnalysisOpen: false
        });
    };

    handleGridToggle = () => {
        this.setState({
            gridVisible: !this.state.gridVisible
        }, () => {
            this.gridHelper.visible = this.state.gridVisible;
        });
    };

    addMeasurement = () =>  {
        const pointANew = this.pointA.clone();
        const pointBNew = this.pointB.clone();

        const lineGeometry = new THREE.BufferGeometry().setFromPoints([pointANew.position, pointBNew.position]);
        const meshLine = new MeshLine();
        meshLine.setGeometry(lineGeometry);
        const lineABNew = new THREE.Mesh(meshLine, this.lineMaterial);

        const labelAB = this.returnLabelElem(this.distanceAB.toFixed(2) + " mm");

        const dx = pointBNew.position.x - pointANew.position.x;
        const dy = pointBNew.position.y - pointANew.position.y;
        const dz = pointBNew.position.z - pointANew.position.z;

        labelAB.position.set(pointANew.position.x + dx / 2.0, (pointANew.position.y + dy / 2.0) + (0.03 * this.pom), pointANew.position.z + dz / 2.0);

        this.pointsA.push(pointANew);
        this.pointsB.push(pointBNew);
        this.linesAB.push(lineABNew);
        this.labelsAB.push(labelAB);

        this.clearMeasurements();

        this.sceneLabels.add(pointANew);
        this.sceneLabels.add(pointBNew);
        this.sceneLabels.add(lineABNew);
        this.scene.add(labelAB);
    };

    returnLabelElem = txt => {
        let label = document.createElement('p');
        label.classList.add('PartViewerLabel');
        label.classList.add('Bold10');
        label.innerText = txt;

        return new CSS2DObject(label);;
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    render() {
        const {
            partData,
            handleClose
        } = this.props;

        const {
            modelData,
            loadingDots,
            loadingError,
            loadingComplete,
            sectionAnalysisOpen,
            toolsOpen,
            viewsOpen,
            selectedView,
            selectedTool,
            showClearMeasures
        } = this.state;

        let axisSlidersDisabled = !!(selectedTool || selectedView === 'xray');

        return (
            <div
                id="PartViewer"
                className="FullHeightWidth"
                onClick={this.handleClickAway}
            >
                <div className="PageContentWidth">
                    <div id="PartViewerHeader">
                        <div id="PartViewerHeaderStartWrap">
                            <h4 className="Bold12">3D VIEWER</h4>
                            <h3 className="Bold16">{partData.filename}</h3>
                        </div>

                        <button
                            onClick={handleClose}
                            id="ClosePartViewButton"
                        >
                            <CloseIcon id="ClosePartViewButtonIcon" />
                        </button>
                    </div>

                    <p
                        id="PartViewerLoadingP"
                        className={clsx('Bold14', loadingError ? 'PartViewerLoadingPError' : null, loadingComplete ? 'PartViewerLoadingPComplete' : null)}
                    >{loadingError ? 'ERROR' : `LOADING FILE${loadingDots}`}</p>

                    <div
                        id="PartViewerLoadingWrap"
                        className={clsx(loadingComplete ? 'PartViewerLoadingWrapComplete' : null)}
                    >
                        <div ref={this.loadingBarRef}></div>
                    </div>

                    {modelData &&
                        <div
                            ref={ref => this.handleMountRef(ref)}
                            id="PartViewerMount"
                        >
                            <div id="PartViewerTopLeftInfo">
                            {(!(this.props.windowBreakpoint?.w <= 768) && this.props.partData.converted) &&
                                <div className="PartViewerConvertedWarning">
                                    <div className="PartViewerConvertedWarningIconWrap">
                                        <PriorityHighIcon className="PartViewerConvertedWarningIcon"/>
                                    </div>

                                    <p className="Bold12">You're viewing a .stl conversion; manufacturing will use your original CAD file</p>
                                </div>
                            }

                                <p className="Bold12" ref={this.distanceLabelRef}></p>
                            </div>

                            <div id="PartViewerUtilsWrap">
                                <div className="PartViewerUtils">
                                    <div
                                        onClick={() => this.setState({ sectionAnalysisOpen: !sectionAnalysisOpen })}
                                        className={clsx('PartViewerUtilsHeader', sectionAnalysisOpen ? 'PartViewerUtilsOpen' : null, 'SectionAnalysisHeader')}
                                    >
                                        <h4 className="Bold12">Section Analysis</h4>
                                        <ExpandMoreIcon className="PartViewerUtilsExpandIcon" />
                                    </div>

                                    {sectionAnalysisOpen &&
                                        <React.Fragment>
                                            {this.returnAxis('X', elem => this.axisLabelRefs.current['X'] = elem, axisSlidersDisabled, elem => this.axisInputRefs.current['X'] = elem)}
                                            {this.returnAxis('Y', elem => this.axisLabelRefs.current['Y'] = elem, axisSlidersDisabled, elem => this.axisInputRefs.current['Y'] = elem)}
                                            {this.returnAxis('Z', elem => this.axisLabelRefs.current['Z'] = elem, axisSlidersDisabled, elem => this.axisInputRefs.current['Z'] = elem)}
                                        </React.Fragment>
                                    }
                                </div>

                                <div className="PartViewerUtils">
                                    <div
                                        onClick={() => this.setState({ toolsOpen: !toolsOpen })}
                                        className={clsx('PartViewerUtilsHeader', toolsOpen ? 'PartViewerUtilsOpen' : null)}
                                    >
                                        <h4 className="Bold12">Tools</h4>
                                        <ExpandMoreIcon className="PartViewerUtilsExpandIcon" />
                                    </div>

                                    {toolsOpen && toolsActions.map(action => (
                                        <div
                                            onClick={() => this.handleToolsChange(action.id)}
                                            key={action.id}
                                            className={clsx('PartViewerUtilsAction', selectedTool === action.id ? 'PartViewerUtilsSelectedAction' : null)}
                                        >
                                            <div>
                                                {action.icon(this.props.windowBreakpoint?.w <= 480 ? 15 : undefined)}
                                            </div>

                                            <p className={clsx('Bold12', selectedTool === action.id ? 'MainBlue' : null)}>{action.label}</p>
                                        </div>
                                    ))}
                                </div>

                                <div className="PartViewerUtils">
                                    <div
                                        onClick={() => this.setState({ viewsOpen: !viewsOpen })}
                                        className={clsx('PartViewerUtilsHeader', viewsOpen ? 'PartViewerUtilsOpen' : null)}
                                    >
                                        <h4 className="Bold12">Views</h4>
                                        <ExpandMoreIcon className="PartViewerUtilsExpandIcon" />
                                    </div>

                                    {viewsOpen && viewActions.map(action => (
                                        <div
                                            onClick={() => this.handleViewChange(action.id)}
                                            key={action.id}
                                            className={clsx('PartViewerUtilsAction', selectedView === action.id ? 'PartViewerUtilsSelectedAction' : null)}
                                        >
                                            <div>
                                                {action.icon(this.props.windowBreakpoint?.w <= 480 ? 15 : undefined)}
                                            </div>

                                            <p className={clsx('Bold12', selectedView === action.id ? 'MainBlue' : null)}>{action.label}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>

                            <div id="ClearMeasurementsWrap" className={(this.props.windowBreakpoint?.w <= 768 && this.props.partData.converted) ? 'ClearMeasurementsWrapHigher' : ''}>
                                <p
                                    onClick={() => this.clearMeasurements(true)}
                                    className={clsx('Bold16', showClearMeasures ? 'ClearMeasurementsVisible' : null)}
                                >
                                    clear measurements
                                </p>
                            </div>

                            {(this.props.windowBreakpoint?.w <= 768 && this.props.partData.converted) &&
                                <div className="PartViewerConvertedWarningWrap">
                                    <div className="PartViewerConvertedWarning">
                                        <div className="PartViewerConvertedWarningIconWrap">
                                            <PriorityHighIcon className="PartViewerConvertedWarningIcon"/>
                                        </div>

                                        <p className="Bold12">You're viewing a .stl conversion; manufacturing will use your original CAD file</p>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        );
    };
}

export default PartViewer;