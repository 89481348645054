import React from "react";

export default function ThicknessIcon(props) {
    const { 
        size,
        rootStyle,
        className,
        id
    } = props;

    let width = size ? size : 22;
    let height = size ? size : 22;

    return (
        <svg
            width={width + 'px'}
            id={id}
            className={className}
            height={height + 'px'}
            viewBox={`0 0 22 22`}
            style={rootStyle}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M10.6179 13.0117V21.2366" 
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path 
                d="M13.7144 16.1079L10.6182 13.0117L7.52197 16.1079" 
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path 
                d="M21.2365 10.6183H0" 
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path 
                d="M10.6179 8.22488V0" 
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path 
                d="M7.52197 5.12866L10.6182 8.22486L13.7144 5.12866" 
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
        </svg>
    );
};
