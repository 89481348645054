import React, {
    useState,
    useEffect,
    useRef,
    useContext
} from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import TextInput from '../Misc/TextInput/TextInput';
import UploadIcon from '../Icons/UploadIcon';

import General from '../../context/general';

import {
    Checkbox,
    Tooltip,
    CircularProgress
} from '@mui/material';

import {
    handleError,
    handleApiReq,
    convertBitsToMegabytes,
    handlePushToDataLayer
} from '../../functions/utils';

import axios from 'axios';
import clsx from 'clsx';
import Dropzone from 'react-dropzone';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function PurchaseOrder(props) {
    const {
        quoteData,
        quoteId,
        handleNewSnackbar,
        agreeRef,
        inputWarnings,
        handleSubmitPreChecks,
        handlePostSuccessfulPayment,
        message,
        handleMessage,
        loggedIn,
        handleNavToConf,
        setIsLoading,
        isLoading,
        applyLink,
        pOLoading,
        setPOLoading,
        handleSuccessfulPOUpload
    } = props;

    const {
        termsAndConditions
    } = useContext(General);

    const [dragActive, setDragActive] = useState(false);
    const [pOMessage, setPOMessage] = useState(null);

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const purchaseOrderPDFRef = useRef({});

    // handle the drop function on the dropzone, send files to the upload handler function
    const handleOnDrop = acceptedFiles => {
        setDragActive(false);

        handleUploadOnChange(acceptedFiles);
    };

    const handleUploadOnChange = async files => {
        if (pOLoading) return;
        if (!files || !files.length) {
            handleError('22095UPG', 'no files?', true);
            return;
        }

        setPOMessage(null);

        let file = files[0];
        let fileName = file.name.length > 50 ? file.name.slice(0,47) + '...' : file.name;
        let message = null;

        if (!file) {
            message = 'Something went wrong';
        }

        if (!message) {
            if (convertBitsToMegabytes(file.size) > 10) {
                message = 'Max File Size 10Mb';
            }
        }

        if (!message) {
            if (!/pdf$/i.test(file.name)) {
                message = 'Unsupported file type';
            }
        }

        if (!message) {
            setPOLoading(true);
            setIsLoading(true);

            setPOMessage({
                warning: false,
                message: `0% uploaded: ${fileName}`
            });

            let signedUrl;

            try {
                signedUrl = await handleApiReq(
                    'get',
                    `/fetch-attachment-upload-url?qid=${quoteId}&n=${encodeURIComponent(file.name)}&m=po`
                );

                if (signedUrl && signedUrl.url) {
                    let options = {
                        headers: {
                            'Content-Type': file.type
                        },
                        onUploadProgress: e => handleProgress(e, fileName),
                    };

                    axios.put(signedUrl.url, file, options).then(response => {
                        setPOMessage({ warning: false, message: `uploaded: ${fileName}` });
                        handleSuccessfulPOUpload(fileName, signedUrl.key);
                        setPOLoading(false);
                        setIsLoading(false);
                        purchaseOrderPDFRef.current = {
                            value: signedUrl.key
                        };
                    }).catch(error => {
                        setPOLoading(false);
                        setIsLoading(false);
                        handleNewSnackbar('something went wrong', 'error');
                        handleError('10441lAE', error, true);
                        setPOMessage({
                            warning: true,
                            message: `Upload failed`
                        });
                    });
                }
            } catch (error) {
                setIsLoading(false);
                handleError('43079lHQ', error, true);
                handleNewSnackbar('something went wrong', 'error');
            }
        } else {
            setPOMessage({
                warning: true,
                message: `${message} ${fileName}`
            });
        }
    };

    const handleProgress = (e, fileName, override) => {
        let progress = override ? override : Math.round((e.loaded / e.total) * 100);

        setPOMessage({
            warning: false,
            message: `${progress}% uploaded: ${fileName}`
        });
    };

    const handleSubmit = (e, values) => {
        if (isLoading) return;

        handlePushToDataLayer({
            event: 'payment_clicked',
            gateway: 'Purchase Order'
        });

        handleApiReq(
            'put',
            '/crud',
            {
                action: 'HANDLE_SUBMIT_PURCHASE_ORDER',
                values,
                quote_id: quoteId,
                attach_quote_data: true
            }
        ).then(async () => {
            await handlePostSuccessfulPayment();

            handleNavToConf();
        }).catch(error => {
            handlePushToDataLayer({
                event: 'server_error',
                error: 'failed to complete purchase order',
                error_message: error.error_message
            });

            handleMessage('Something went wrong', true);
            handleNewSnackbar('something went wrong', error);
            handleError('64790xgl', error, true);
        });
    };

    const handleTypeForm = () => {
        if (!loggedIn) return;

        try {
            let valsToUse = {
                account_number: loggedIn['custom:account_number'],
                email: loggedIn.email,
                name: loggedIn['custom:full_name']
            };

            for (const key in valsToUse) {
                if (!valsToUse[key]) {
                    return;
                }
            }

            window.open(`${applyLink}?${Object.keys(valsToUse).map(key => `${key}=${encodeURIComponent(valsToUse[key])}`).join('&')}`, '_blank', 'noreferrer');
        } catch (error) {
            return;
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        if (quoteData && quoteData.purchase_order) {
            purchaseOrderPDFRef.current = {
                value: quoteData.purchase_order.key
            };

            setPOMessage({
                warning: false,
                message: quoteData.purchase_order.original_name.length > 50 ? quoteData.purchase_order.original_name.slice(0,47) + '...' : quoteData.purchase_order.original_name
            });
        }
    }, [quoteData]);

    useEffect(() => {
        if (inputWarnings && inputWarnings.purchaseOrderPDF) {
            setPOMessage({
                warning: true,
                message: inputWarnings.purchaseOrderPDF
            });
        }
    }, [inputWarnings]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    if (!loggedIn || !loggedIn['custom:trade_account'] || loggedIn['custom:trade_account'] === '0') {
        return (
            <div id="POApplyWrap">
                <p className="Bold16">You need approval to pay by Purchase Order</p>
                <br></br>
                <p className="Reg16">Apply for a credit account to pay later with 30 day credit terms.<br></br><br></br> It takes a few minutes to apply and you could receive an instant response.</p>

                <Tooltip
                    arrow
                    title={!loggedIn ? 'Please login to use this feature' : ''}
                >
                    <button
                        onClick={!loggedIn ? null : handleTypeForm}
                        id="POApplyButton"
                        className={clsx('Bold16', !loggedIn ? 'CheckoutButtonDisabled' : null)}
                    >
                        Apply for a credit account
                    </button>
                </Tooltip>
            </div>
        );
    }

    return (
        <React.Fragment>
            <div id="POInputs">
                <p className="Bold16">Accounts Payable Contact Details</p>
                <p className="Reg16">Add the details of the person responsible for paying</p>

                <div id="PONameInputs">
                    <TextInput
                        required={true}
                        label="First Name"
                        refToUse={firstNameRef}
                        warning={(inputWarnings && !!inputWarnings.firstName)}
                        writtenWarning={inputWarnings && inputWarnings.firstName ? inputWarnings.firstName : null}
                        maxlength={50}
                        disabled={isLoading}
                        rootStyleOverwrite={{
                            width: 'calc(50% - 10px)'
                        }}
                    />
                    <TextInput
                        required={true}
                        label="Last Name"
                        refToUse={lastNameRef}
                        warning={(inputWarnings && !!inputWarnings.lastName)}
                        writtenWarning={inputWarnings && inputWarnings.lastName ? inputWarnings.lastName : null}
                        maxlength={50}
                        disabled={isLoading}
                        rootStyleOverwrite={{
                            width: 'calc(50% - 10px)'
                        }}
                    />
                </div>

                <TextInput
                    required={true}
                    label="Accounts Email Address"
                    xtra="An invoice will be sent to this address"
                    refToUse={emailRef}
                    warning={(inputWarnings && !!inputWarnings.email)}
                    disabled={isLoading}
                    writtenWarning={inputWarnings && inputWarnings.email ? inputWarnings.email : null}
                    maxlength={100}
                    rootStyleOverwrite={{
                        marginTop: '20px'
                    }}
                />
            </div>

            <div id="UploadPO">
                <p className="Bold16">Upload Purchase Order</p>
                <p className="Reg16">To avoid delays with your order,  please check the details on your purchase order match your quote</p>

                <Dropzone
                    onDrop={handleOnDrop}
                    onDragEnter={() => setDragActive(true)}
                    onDragLeave={() => setDragActive(false)}
                    disabled={pOLoading || isLoading}
                    noClick={true}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div
                            {...getRootProps({ className: "dropzone" })}
                        >
                            <input
                                type="file"
                                accept=".pdf, application/pdf"
                                hidden
                                disabled={pOLoading || isLoading}
                                id="po-upload-input"
                                onChange={e => handleUploadOnChange(e.target.files)}
                            />

                            <label htmlFor="po-upload-input">
                                <div
                                    id="POUploadButton"
                                    dragactive={dragActive.toString()}
                                    warning={(inputWarnings && !!inputWarnings.purchaseOrderPDF && !purchaseOrderPDFRef.current.value) ? 'true' : 'false'}
                                    className={clsx('Bold16', pOLoading || isLoading ? 'POUploadButtonDisabled' : null)}
                                >
                                    <UploadIcon
                                        id="UploadPurchaseOrderIcon"
                                    />
                                    {'Upload Purchase Order (PDF)'}
                                </div>
                            </label>
                        </div>
                    )}
                </Dropzone>

                {pOMessage && <p id="pOUploadMessage" className="Reg12" warning={pOMessage.warning.toString()}>{pOMessage.message}</p>}
            </div>

            <div id="POSubmit">
                <div id="CheckoutTerms">
                    <Checkbox
                        size="medium"
                        inputProps={{
                            ref: agreeRef,
                            defaultChecked: false
                        }}
                        classes={{
                            root: "QuoteOverlayWarningModalCheckbox"
                        }}
                    />

                    <p className="Reg12">I have read, understand and agree to the <a className="MainBlue" target="_blank" rel="noreferrer" href={termsAndConditions}>Terms of Sale</a>. I confirm that my design complies with the terms.</p>
                </div>

                <button
                    onClick={e => handleSubmitPreChecks(e, 'purchase_order', { firstNameRef, lastNameRef, emailRef, purchaseOrderPDFRef }, handleSubmit)}
                    id="CheckoutButton"
                    disabled={pOLoading || isLoading}
                    className="Bold16"
                >
                    {isLoading && <CircularProgress
                        className="PaymentLoadingSpinner"
                        size={18}
                    />}
                    {!isLoading && 'Submit Order'}
                </button>

                {message && <div className="Reg12" id="PaymentMessage">{message}</div>}
            </div>
        </React.Fragment>
    );
};