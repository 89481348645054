import {
    signUp,
    signIn,
    signOut,
    resetPassword,
    getCurrentUser,
    updatePassword,
    fetchAuthSession,
    updateUserAttributes,
    confirmResetPassword,
    resendSignUpCode,
    fetchUserAttributes,
    confirmSignUp,
} from 'aws-amplify/auth';

import React from 'react';

import _ from 'lodash';
import {
    handleApiReq,
    getSetBiscuit
} from './utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function getAuthenticatedUser(){
    return new Promise(async (resolve, reject) => {
        let objToReturn = {
            username: null,
            attributes: null,
            apiXtra: ''
        }

        try {
            const currentUser = await getCurrentUser();

            const {
                username
            } = currentUser;

            objToReturn.username = username;

            const authSession = await fetchAuthSession();

            try {
                if (authSession.tokens.accessToken.payload['cognito:groups'].includes('admin')) {
                    objToReturn.apiXtra = 'd';
                }
            } catch (error) {
                objToReturn.apiXtra = 'x';
            }

            const attributes = await fetchUserAttributes();

            objToReturn.attributes = attributes;

            resolve(objToReturn);
        } catch (error) {
            reject(error);
        };
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// function that determines if a password has met the criteria to be valid and returns error message signifying what is missing / required if not.
export function hasPasswordFailed(val) {
    let message = false;
    let len = {
        active: false,
        default: <span className="UserModalMessageInActive">Minimum 8 characters</span>,
        alt: <span className="UserModalMessageActive">Minimum 8 characters</span>
    };
    let num = {
        active: false,
        default: <span className="UserModalMessageInActive">A number</span>,
        alt: <span className="UserModalMessageActive">A number</span>
    };
    let up = {
        active: false,
        default: <span className="UserModalMessageInActive">An uppercase letter</span>,
        alt: <span className="UserModalMessageActive">An uppercase letter</span>
    };

    // password is less than 8 characters long
    if (val.length < 8) {
        message = true;
        len.active = true;
    }
    // password doesn't contain an integer
    if ((val.match(/\d/) || []).length < 1) {
        message = true;
        num.active = true;
    }
    // password doesn't contain an uppercase letter
    if ((val.match(/[A-Z]/) || []).length < 1) {
        message = true;
        up.active = true;
    }

    if (message) {
        // returns message element stating all criteria and underlining missing criteria
        return (
            <React.Fragment>
                <p>For security your password must contain:</p>
                <ul className="UserModalActiveMessageUL">
                    <li>{len.active ? len.alt : len.default}</li>
                    <li>{num.active ? num.alt : num.default}</li>
                    <li>{up.active ? up.alt : up.default}</li>
                    {/*<li>{spec.active ? spec.alt : spec.default}</li>*/}
                </ul>
            </React.Fragment>
        );
    } else return false;
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handles error messages returned from functions using Amplify Auth
const handleErrorMessage = message => {
    // default message
    let messageToReturn = 'Something went wrong';
    // specific messages
    if (/User does not exist/i.test(message)) messageToReturn = 'No account is associated with this email';
    if (/email already exists/i.test(message)) messageToReturn = 'Account already exists';
    if (/invalid verification code provided/i.test(message)) messageToReturn = 'Invalid code';
    if (/invalid email address format/i.test(message)) messageToReturn = 'Invalid email address';
    if (/account with the given email already exists/i.test(message)) messageToReturn = 'An account with this email already exists';
    if (/invalid phone number format./i.test(message)) messageToReturn = 'Invalid phone number';
    if (/user is already confirmed/i.test(message)) messageToReturn = 'User is already confirmed';
    if (/user is not confirmed.|user needs to be authenticated to call this api./i.test(message)) messageToReturn = 'This account needs to be verified first';
    if (/Cannot reset password for the user as there is no registered\/verified email or phone_number/.test(message)) messageToReturn = 'This account needs to be verified first';
    if (/incorrect username or password/i.test(message)) messageToReturn = 'Incorrect email or password';
    if (/limit exceeded/i.test(message)) messageToReturn = 'Limit exceeded. Stop spamming.';
    if (/custom:full_name must not be null/i.test(message)) messageToReturn = 'Name value is required';
    if (/email must not be null/i.test(message)) messageToReturn = 'Email address is required';
    if (/custom:phone_no must not be null/i.test(message)) messageToReturn = 'Phone number value is required';

    return messageToReturn;
};

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle register the user
// requires email, password, first name, last name and a telephone no
export async function attemptRegister(handleLoading, e, p, n, t, i) {
    try {
        handleLoading(true);

        let domain = /[^@]*$/.test(e) ? e.match(/[^@]*$/)[0] : null;

        let params = {
            username: e,
            password: p,
            // attributes s now under the key "userAttributes"
            options: {
                userAttributes: {
                    given_name: n,
                    family_name: domain,
                    'custom:full_name': n,
                    'custom:phone_no': t,
                    'custom:industry': i,
                    'custom:domain': domain,
                    'custom:account_number': 'init',
                    'custom:trade_account': '0'
                }
            }
        };

        await signUp(params);

        handleLoading(false);

        return ({
            success: true
        });
    } catch (error) {
        handleLoading(false);

        let message = handleErrorMessage(error.message);

        return {
            success: false,
            message
        };
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle verify the user
// requires email and verification code
export async function attemptVerify(handleLoading, e, c) {
    try {
        handleLoading(true);

        await confirmSignUp({
            username: e,
            confirmationCode: c
        });

        handleLoading(false);

        return {
            success: true
        };
    } catch (error) {
        handleLoading(false);

        let message = handleErrorMessage(error.message);

        return {
            success: false,
            message
        };
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle login the user
// requires email and password
// can return user_sub if r is true
export async function attemptLogin(handleLoading, e, p, r) {
    try {
        handleLoading(true);
        let user = await signIn({
            username: e,
            password: p
        });

        let attributes = await fetchUserAttributes();

        handleLoading(false);

        return ({
            success: true,
            user_sub: r ? user.attributes.sub : null,
            n: attributes['custom:full_name'],
            t: attributes['custom:phone_no'],
            e: attributes.email
        });
    } catch (error) {
        handleLoading(false);

        let message = handleErrorMessage(error.message);

        return {
            success: false,
            message
        };
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle logout the user
export async function attemptLogout() {
    try {
        await signOut();

        return {
            success: true
        };
    } catch (error) {
        return {
            success: false
        };
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle the first stage of forgot password flow
// requires email
export async function attemptForgotPasswordInitial(handleLoading, e) {
    try {
        handleLoading(true);

        await resetPassword({
            username: e
        });

        handleLoading(false);

        return {
            success: true
        };
    } catch (error) {
        handleLoading(false);

        let message = handleErrorMessage(error.message);

        return {
            success: false,
            message
        };
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle the final stage of forgot password flow
// requires email, new password and verification code
export async function attemptForgotPasswordFinal(handleLoading, e, p, c) {
    try {
        handleLoading(true);

        await confirmResetPassword({
            username: e,
            newPassword: p,
            confirmationCode: c
        });

        handleLoading(false);

        return {
            success: true
        };
    } catch (error) {
        handleLoading(false);

        let message = handleErrorMessage(error.message);

        return {
            success: false,
            message
        };
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle resend verification code
// requires email
export async function attemptResendVerificationInitial(handleLoading, e) {
    try {
        handleLoading(true);

        await resendSignUpCode({
            username: e
        });

        handleLoading(false);

        return {
            success: true
        };
    } catch (error) {
        handleLoading(false);

        let message = handleErrorMessage(error.message);

        return {
            success: false,
            message
        };
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle update user attributes
// requires key value pairs in object
export async function attemptUpdateUserAttributes(v) {
    if (v.email && /[^@]*$/.test(v.email)) {
        v['custom:domain'] = v.email.match(/[^@]*$/)[0];
    }

    try {
        await updateUserAttributes({
            userAttributes: v
        });

        return {
            success: true
        };
    } catch (error) {
        let message = handleErrorMessage(error.message);

        return {
            success: false,
            message
        };
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle delete user
export function attemptDeleteUser() {
    return new Promise(async resolve => {
        try {
            handleApiReq(
                'put',
                '/user-crud',
                {
                    action: 'DELETE_USER'
                }
            ).then(result => {
                if (result.success) {
                    resolve({
                        success: true
                    });
                } else {
                    resolve({
                        success: false,
                        message: 'something went wrong'
                    });
                }
            }).catch(error => {
                resolve({
                    success: false,
                    message: 'something went wrong'
                })
            });
        } catch (error) {
            let message = handleErrorMessage(error.message);

            resolve({
                success: false,
                message
            });
        }
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle change password
// requires current password and new password
export async function attemptChangePassword(p, v) {
    try {
        await updatePassword({
            oldPassword: p,
            newPassword: v
        });

        return {
            success: true
        };
    } catch (error) {
        let message = handleErrorMessage(error.message);

        return {
            success: false,
            message
        };
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle checking the recaptcha value
// requires recaptcha token
export function attemptCheckReCAPTCHA(handleLoading, v) {
    return new Promise((resolve, reject) => {
        handleLoading(true);

        handleApiReq(
            'post',
            '/user-crud',
            {
                action: 'CHECK_RECAPTCHA',
                val: v,
                user_id: 'no_user'
            }
        ).then(result => {
            handleLoading(false);

            resolve(result.success);
        });
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle user favouriting a quote or an order
export function setFavourite(data) {
    return new Promise(async (resolve, reject) => {
        handleApiReq(
            'put',
            '/user-crud',
            {
                action: 'FAVOURITE',
                quote_id: data.uuid,
                val: !data.favourite,
                attach_quote_data: true,
                attach_config_data: true
            }
        ).then(result => {
            resolve({
                success: result.success,
                data: result.success ? result.data.new_data : null
            });
        }).catch(error => {
            resolve({
                success: false
            });
        });
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle user nicknaming a quote or an order
export function setNickname(data) {
    return new Promise(async (resolve, reject) => {
        handleApiReq(
            'put',
            '/user-crud',
            {
                action: 'NICKNAME',
                quote_id: data.uuid,
                val: data.nickname,
                attach_quote_data: true,
                attach_config_data: true
            }
        ).then(result => {
            resolve({
                success: result.success,
                data: result.success ? result.data.new_data : null
            });
        }).catch(error => {
            resolve({
                success: false
            });
        });
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle user cloning a quote / order
export function cloneQuoteOrder(data) {
    return new Promise(async (resolve, reject) => {
        handleApiReq(
            'post',
            '/user-crud',
            {
                action: 'CLONE_QUOTE',
                quote_id: data.uuid,
                attach_quote_data: true,
                attach_parts_data: true,
                attach_config_data: true
            }
        ).then(result => {
            resolve(result);
        }).catch(error => {
            resolve({
                success: false
            });
        });
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// returns the correct api depending upon auth status
export async function returnApi() {
    let apiName = 'restapi';

    try {
        let user = await getAuthenticatedUser();
        apiName += user.apiXtra;
    } catch (error) {
        // do nothing
    } finally {
        return apiName;
    }
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// return the reqInit for api with overwrite
export function returnReqInit(body) {
    // renamed queryStringParameters to queryParams as according to the new API
    let val = {
        body,
        queryParams: {
            'bsct': getSetBiscuit()
        }
    }

    // if assuming identity
    let assumingIdentity = JSON.parse(localStorage.getItem('_alien_assumed_identity'));
    if (assumingIdentity) {
        val.queryParams['aa_i'] = assumingIdentity;
    }

    return val;
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle the user clicking the user lock button
export function handleUserLockQuote(data) {
    return new Promise(async (resolve, reject) => {
        handleApiReq(
            'put',
            '/user-crud',
            {
                action: 'LOCK_QUOTE',
                quote_id: data.uuid,
                attach_quote_data: true,
                attach_parts_data: true,
                attach_config_data: true
            }
        ).then(result => {
            resolve(result);
        }).catch(error => {
            resolve({
                success: false
            });
        });
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// handle the user clicking the user lock button
export function handleUserDownloadPDF(data) {
    return new Promise(async (resolve, reject) => {
        handleApiReq(
            'post',
            '/user-crud',
            {
                action: 'DOWNLOAD_PDF',
                quote_id: data.uuid,
                attach_quote_data: true,
                attach_parts_data: true,
                attach_config_data: true
            }
        ).then(result => {
            resolve(result);
        }).catch(error => {
            resolve({
                success: false
            });
        });
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function handleInitNewUser() {
    return new Promise(async (resolve, reject) => {
        handleApiReq(
            'post',
            '/user-crud',
            {
                action: 'INIT_NEW_USER'
            }
        ).then(result => {
            resolve(result);
        });
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function handleInitExistingUser() {
    return new Promise(async (resolve, reject) => {
        handleApiReq(
            'post',
            '/user-crud',
            {
                action: 'INIT_EXISTING_USER'
            }
        ).then(result => {
            resolve(result);
        });
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function updateUserAddresses(refs, which) {
    return new Promise(async (resolve, reject) => {
        try {
            if (!refs || !refs.current) return;

            let isBilling = which.includes('billing');
            let isShipping = which.includes('shipping');

            let infoToDispatch = {};
            if (isBilling) infoToDispatch.billing = {};
            if (isShipping) infoToDispatch.shipping = {};

            const {
                billing,
                shipping
            } = refs.current;

            if (isBilling && !_.isEmpty(billing)) {
                for (const key in billing) {
                    if (key === 'country') {
                        infoToDispatch.billing.country_name = billing.country.value && billing.country.value.name ? billing.country.value.name : null;
                        infoToDispatch.billing.country_code = billing.country.value && billing.country.value.code ? billing.country.value.code : null;
                    } else {
                        let trimmedVal = billing[key].value.trim();
                        infoToDispatch.billing[key] = trimmedVal && trimmedVal.length ? trimmedVal : null;
                    }
                }
            }

            if (isShipping && !_.isEmpty(shipping)) {
                for (const key in shipping) {
                    if (key === 'country') {
                        infoToDispatch.shipping.country_name = shipping.country.value && shipping.country.value.name ? shipping.country.value.name : null;
                        infoToDispatch.shipping.country_code = shipping.country.value && shipping.country.value.code ? shipping.country.value.code : null;
                    } else {
                        let trimmedVal = shipping[key].value.trim();
                        infoToDispatch.shipping[key] = trimmedVal && trimmedVal.length ? trimmedVal : null;
                    }
                }
            }

            handleApiReq(
                'put',
                '/user-crud',
                {
                    action: 'UPDATE_ADDRESS',
                    data: infoToDispatch
                }
            ).then(result => {
                resolve(result);
            }).catch(error => {
                resolve({
                    success: false
                });
            });
        } catch (error) {
            resolve({
                success: false
            });
        }
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function updateUserCurrency(val) {
    return new Promise((resolve, reject) => {
        try {
            handleApiReq(
                'put',
                '/user-crud',
                {
                    action: 'UPDATE_CURRENCY',
                    attach_config_data: true,
                    val
                }
            ).then(result => {
                resolve(result);
            }).catch(error => {
                resolve({
                    success: false
                });
            });
        } catch (error) {
            resolve({
                success: false
            });
        }
    });
}

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export function handleAlienAllShip({ quote_id }) {
    return new Promise((resolve, reject) => {
        try {
            handleApiReq(
                'put',
                '/alien-crud',
                {
                    action: 'ALL_SHIP',
                    quote_id,
                    attach_quote_data: true,
                    attach_config_data: true
                }
            ).then(result => {
                resolve(result);
            }).catch(error => {
                resolve({
                    success: false,
                    message: ['Oops. Looks like something went wrong.', '🌪👽🌪']
                });
            });
        } catch (error) {
            resolve({
                success: false,
                message: ['Oops. Looks like something went wrong.', '💥👽💥']
            });
        }
    });
}

export function handleAlienFetchInfo({ quote_id }) {
    return new Promise((resolve, reject) => {
        try {
            handleApiReq(
                'get',
                `/alien-crud/info/${quote_id}`,
            ).then(({ success, message }) => {
                resolve({
                    success,
                    message: message ? message : ['No data returned!', '🆘']
                });
            }).catch(error => {
                resolve({
                    success: false,
                    message: ['Oops. Looks like something went wrong.', '🌪👽🌪']
                });
            });
        } catch (error) {
            resolve({
                success: false,
                message: ['Oops. Looks like something went wrong.', '💥👽💥']
            });
        }
    });
}

export function handleAlienCustomShip({ quote_id, values }) {
    return new Promise((resolve, reject) => {
        try {
            handleApiReq(
                'put',
                `/alien-crud`,
                {
                    action: 'CUSTOM_SHIP',
                    quote_id,
                    values,
                    attach_quote_data: true
                },
            ).then(({ success, message }) => {
                resolve({
                    success,
                    message: message ? message : ['No data returned!', '🆘']
                });
            }).catch(error => {
                resolve({
                    success: false,
                    message: ['Oops. Looks like something went wrong.', '🌪👽🌪']
                });
            });
        } catch (error) {
            resolve({
                success: false,
                message: ['Oops. Looks like something went wrong.', '💥👽💥']
            });
        }
    });
}

export function handleAlienUnsetCustomShip({ quote_id }) {
    return new Promise((resolve, reject) => {
        try {
            handleApiReq(
                'put',
                `/alien-crud`,
                {
                    action: 'UNSET_CUSTOM_SHIP',
                    quote_id,
                    attach_quote_data: true,
                    attach_config_data: true
                },
            ).then(({ success, message }) => {
                resolve({
                    success,
                    message: message ? message : ['No data returned!', '🆘']
                });
            }).catch(error => {
                resolve({
                    success: false,
                    message: ['Oops. Looks like something went wrong.', '🌪👽🌪']
                });
            });
        } catch (error) {
            resolve({
                success: false,
                message: ['Oops. Looks like something went wrong.', '💥👽💥']
            });
        }
    });
}

export function handleAlienPushOrder({ quote_id }) {
    return new Promise((resolve, reject) => {
        try {
            handleApiReq(
                'post',
                `/alien-crud`,
                {
                    action: 'PUSH_ORDER',
                    quote_id
                },
            ).then(({ success, message }) => {
                resolve({
                    success,
                    message: message ? message : ['No data returned!', '🆘']
                });
            }).catch(error => {
                resolve({
                    success: false,
                    message: ['Oops. Looks like something went wrong.', '🌪👽🌪']
                });
            });
        } catch (error) {
            resolve({
                success: false,
                message: ['Oops. Looks like something went wrong.', '💥👽💥']
            });
        }
    });
}

export function handleAlienDismissibleNote({ quote_id, values }) {
    return new Promise((resolve, reject) => {
        try {
            handleApiReq(
                'put',
                `/alien-crud`,
                {
                    action: 'HANDLE_DISMISSIBLE_NOTE',
                    quote_id,
                    values
                },
            ).then(({ success, message }) => {
                resolve({
                    success,
                    message: message ? message : ['No data returned!', '🆘']
                });
            }).catch(error => {
                resolve({
                    success: false,
                    message: ['Oops. Looks like something went wrong.', '🌪👽🌪']
                });
            });
        } catch (error) {
            resolve({
                success: false,
                message: ['Oops. Looks like something went wrong.', '💥👽💥']
            });
        }
    });
}

export function handleAlienCustomLeadTime({ quote_id, values }) {
    return new Promise((resolve, reject) => {
        try {
            handleApiReq(
                'put',
                `/alien-crud`,
                {
                    action: 'CUSTOM_LEAD_TIME',
                    quote_id,
                    values
                },
            ).then(({ success, message }) => {
                resolve({
                    success,
                    message: message ? message : ['No data returned!', '🆘']
                });
            }).catch(error => {
                resolve({
                    success: false,
                    message: ['Oops. Looks like something went wrong.', '🌪👽🌪']
                });
            });
        } catch (error) {
            resolve({
                success: false,
                message: ['Oops. Looks like something went wrong.', '💥👽💥']
            });
        }
    });
}

export function handleAlienAssumeIdentity({ values }) {
    return new Promise((resolve, reject) => {
        try {
            if (typeof values.quex === 'string' && !values.quex.length) {
                resolve({
                    success: true,
                    message: ['Alien action success.', 'Reverting identity.'],
                    data: {
                        revert: true
                    }
                });
            } else {
                handleApiReq(
                    'put',
                    `/alien-crud`,
                    {
                        action: 'ASSUME_IDENTITY',
                        values
                    },
                ).then(({ success, message, data }) => {
                    resolve({
                        success,
                        message: message ? message : ['No data returned!', '🆘'],
                        data
                    });
                }).catch(error => {
                    resolve({
                        success: false,
                        message: ['Oops. Looks like something went wrong.', '🌪👽🌪']
                    });
                });
            }
        } catch (error) {
            resolve({
                success: false,
                message: ['Oops. Looks like something went wrong.', '💥👽💥']
            });
        }
    })
}

export function handleAlienFetchUserInfo() {
    return new Promise((resolve, reject) => {
        try {
            handleApiReq(
                'get',
                `/alien-crud/user-info`,
            ).then(({ success, data }) => {
                resolve({
                    success,
                    data
                });
            }).catch(error => {
                resolve({
                    success: false,
                    message: ['Oops. Looks like something went wrong.', '🌪👽🌪']
                });
            });
        } catch (error) {
            resolve({
                success: false,
                message: ['Oops. Looks like something went wrong.', '💥👽💥']
            });
        }
    });
}

export function handleAlienUpdateTradeAccount(val) {
    return new Promise((resolve, reject) => {
        try {
            handleApiReq(
                'put',
                `/alien-crud`,
                {
                    action: 'UPDATE_USER_TRADE_ACCOUNT',
                    val
                },
            ).then(({ success, message, data }) => {
                resolve({
                    success,
                    message: message ? message : ['No data returned!', '🆘'],
                    data
                });
            }).catch(error => {
                resolve({
                    success: false,
                    message: ['Oops. Looks like something went wrong.', '🌪👽🌪']
                });
            });
        } catch (error) {
            resolve({
                success: false,
                message: ['Oops. Looks like something went wrong.', '💥👽💥']
            });
        }
    });
}

export function handleAlienUpdateUserNote(val) {
    return new Promise((resolve, reject) => {
        try {
            handleApiReq(
                'put',
                `/alien-crud`,
                {
                    action: 'UPDATE_USER_DEFAULT_NOTE',
                    val
                },
            ).then(({ success, message, data }) => {
                resolve({
                    success,
                    message: message ? message : ['No data returned!', '🆘'],
                    data
                });
            }).catch(error => {
                resolve({
                    success: false,
                    message: ['Oops. Looks like something went wrong.', '🌪👽🌪']
                });
            });
        } catch (error) {
            resolve({
                success: false,
                message: ['Oops. Looks like something went wrong.', '💥👽💥']
            });
        }
    });
}

export function handleAlienHideQuoteOrder(quote_id) {
    return new Promise((resolve, reject) => {
        try {
            handleApiReq(
                'put',
                `/alien-crud`,
                {
                    action: 'HIDE_QUOTE_ORDER',
                    quote_id
                },
            ).then(({ success, message, data }) => {
                resolve({
                    success,
                    message: message ? message : ['No data returned!', '🆘'],
                    data
                });
            }).catch(error => {
                resolve({
                    success: false,
                    message: ['Oops. Looks like something went wrong.', '🌪👽🌪']
                });
            });
        } catch (error) {
            resolve({
                success: false,
                message: ['Oops. Looks like something went wrong.', '💥👽💥']
            });
        }
    });
}