import React, {
    useState,
    useEffect,
    useRef
} from 'react';

import clsx from 'clsx';
import axios from 'axios';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    callApiAndReturnCombinedResultsData,
    handleApiReq,
    handleError,
    validateEmailAddress,
    convertBitsToMegabytes,
    getFileExtension
} from '../../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import Authenticator from '../../Authenticator/Authenticator';
import TextInput from '../TextInput/TextInput';
import LoadingSVG from '../LoadingSVG/LoadingSVG';

import {
    Link
} from 'react-router-dom';

import {
    Paper
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';
import CancelIcon from '@mui/icons-material/Cancel';
import FileUploadIcon from '@mui/icons-material/FileUpload';

import './RequestQuote.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function RequestQuote(props) {
    const {
        handleClose,
        tempData,
        quote,
        quoteId,
        loggedIn,
        windowBreakpoint,
        handleNewSnackbar,
        callback
    } = props;

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const emailRef = useRef();
    const phoneNumberRef = useRef();
    const messageRef = useRef();
    const attachLimitRef = useRef();
    const attachSizeLimitRef = useRef();

    const [loading, setLoading] = useState(false);
    const [showAuthenticator, setShowAuthenticator] = useState(false);
    const [industryOptions, setIndustryOptions] = useState(null);
    const [authCallbackData, setAuthCallbackData] = useState(null);
    const [firstNameWarning, setFirstNameWarning] = useState(false);
    const [emailWarning, setEmailWarning] = useState(false);
    const [phoneNumberWarning, setPhoneNumberWarning] = useState(false);
    const [messageLimit, setMessageLimit] = useState(null);
    const [attachmentLimit, setAttachmentLimit] = useState(5);
    const [attachmentSizeLimit, setAttachmentSizeLimit] = useState(100);
    const [attachmentFormatChecks, setAttachmentFormatChecks] = useState([]);
    const [attachedFiles, setAttachedFiles] = useState([]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // wraps the handleClose function
    // we can add temp data to the session storage to retain data if the modal is closed
    const handleRFQModalClose = clearTempData => {
        handleClose({
            tempData: {
                fn: firstNameRef?.current?.value?.trim(),
                ln: lastNameRef?.current?.value?.trim(),
                e: emailRef?.current?.value?.trim(),
                p: lastNameRef?.current?.value?.trim(),
                m: messageRef?.current?.value?.slice(0, messageLimit)
            },
            clearTempData
        });
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // api req to submit form
    const handleSubmitRequestForQuote = () => {
        let firstNameVal;
        let emailVal;
        let phoneNumberVal;

        if (!loggedIn) {
            // validate form fields
            let warningsToSet = {
                firstNameWarning: false,
                emailWarning: false,
                phoneNumberWarning: false
            };

            firstNameVal = firstNameRef?.current?.value?.trim();
            if (!firstNameVal) warningsToSet.firstNameWarning = 'please enter a value';

            emailVal = emailRef?.current?.value?.trim();
            if (!emailVal) {
                warningsToSet.emailWarning = 'please enter a value';
            } else if (!validateEmailAddress(emailVal)) {
                warningsToSet.emailWarning = 'please enter a valid email';
            }

            phoneNumberVal = phoneNumberRef?.current?.value?.trim();
            if (!phoneNumberVal) warningsToSet.phoneNumberWarning = 'please enter a value';

            setFirstNameWarning(warningsToSet.firstNameWarning);
            setEmailWarning(warningsToSet.emailWarning);
            setPhoneNumberWarning(warningsToSet.phoneNumberWarning);

            if (Object.keys(warningsToSet).some(warning => !!warningsToSet[warning])) return;
        }

        setLoading(true);

        handleApiReq(
            'put',
            '/crud',
            {
                action: 'REQUEST_FOR_QUOTE',
                quote_id: quoteId,
                attach_quote_data: true,
                attach_config_data: true,
                first_name: firstNameVal,
                last_name: lastNameRef?.current?.value?.trim(),
                email: emailVal,
                phone_number: phoneNumberVal,
                message: messageRef.current?.value?.slice(0, messageLimit)
            }
        ).then(result => {
            setLoading(false);

            if (result.success) {
                handleNewSnackbar('request submitted', 'success');
                callback();
                handleRFQModalClose(true);
            } else {
                handleNewSnackbar('something went wrong', 'error');
            }
        }).catch(error => {
            setLoading(false);

            if (error?.response?.data?.message) {
                handleNewSnackbar(error.response.data.message, 'info');
            } else {
                handleNewSnackbar('something went wrong', 'error');
                handleError('51628Ncv');
            }
        });
    };

    const checkAttachmentLimit = val => {
        if (val > attachmentLimit) {
            if (attachLimitRef?.current) attachLimitRef.current.classList.add('attachLimitWarning');
        } else {
            if (attachLimitRef?.current && attachLimitRef.current.classList.contains('attachLimitWarning')) attachLimitRef.current.classList.remove('attachLimitWarning');
        }
    };

    const checkAttachmentSizeLimit = arr => {
        let limitBreached = arr.some(x => x.sizeMb > attachmentSizeLimit);

        if (limitBreached) {
            if (attachSizeLimitRef?.current) attachSizeLimitRef.current.classList.add('attachLimitWarning');
        } else {
            if (attachSizeLimitRef?.current && attachSizeLimitRef.current.classList.contains('attachLimitWarning')) attachSizeLimitRef.current.classList.remove('attachLimitWarning');
        }
    };

    const changeAttachmentStatus = (attachment, key, success) => {
        let attachmentsClone = [...attachedFiles];
        let attachmentIndex = attachmentsClone.findIndex(x => x.id === attachment.id);

        if (attachmentIndex !== -1) {
            attachmentsClone[attachmentIndex].loading = false;
            attachmentsClone[attachmentIndex].key = key;

            if (success) {
                attachmentsClone[attachmentIndex].status = 'end';
            } else {
                attachmentsClone[attachmentIndex].failed = true;
                attachmentsClone[attachmentIndex].status = 'end';
            }

            setAttachedFiles(attachmentsClone)
        }
    };

    const handleUploadAttachment = async attachment => {
        let attachmentsClone = [...attachedFiles];
        let attachmentIndex = attachmentsClone.findIndex(x => x.id === attachment.id);

        if (attachmentIndex !== -1) {
            attachmentsClone[attachmentIndex].status = 'uploading';
            setAttachedFiles(attachmentsClone);

            let signedUrl;

            try {
                signedUrl = await handleApiReq(
                    'get',
                    `/fetch-attachment-upload-url?qid=${quoteId}&n=${encodeURIComponent(attachment.name)}&m=rfq`
                );
            } catch (error) {
                changeAttachmentStatus(attachment, null, false);
            }

            if (signedUrl && signedUrl.url) {
                let options = {
                    headers: {
                        'Content-Type': attachment.type
                    }
                };

                axios.put(signedUrl.url, attachment.file, options).then(response => {
                    changeAttachmentStatus(attachment, signedUrl.key, true);
                }).catch(error => {
                    changeAttachmentStatus(attachment, signedUrl.key, false);
                });
            }
        }
    };

    const handleAttachmentInputOnChange = files => {
        let timeAttached = Date.now();

        let attachments = [
            ...attachedFiles,
            ...Array.from(files).map((file, index) => {
                let sizeMb = convertBitsToMegabytes(file.size, true);
                let sizeBreach = sizeMb > attachmentSizeLimit;
                let format = getFileExtension(file.name);
                let formatBreach = attachmentFormatChecks.map(x => x.toUpperCase()).includes(format.toUpperCase());

                return {
                    file,
                    name: file.name,
                    size: file.size,
                    sizeMb,
                    type: file.type,
                    status: sizeBreach || formatBreach ? 'end' : 'init',
                    bad_format: formatBreach,
                    time_attached: timeAttached,
                    id: `${timeAttached}_${index + 1}`,
                    failed: sizeBreach || formatBreach
                };
            })
        ];

        checkAttachmentLimit(attachments.length);
        checkAttachmentSizeLimit(attachments);

        attachments = attachments.slice(0, 5);

        for (let attachment of attachments.filter(attachment => attachment.status === 'init' && attachment.time_attached === timeAttached)) {
            attachment.loading = true;
        }

        setAttachedFiles(attachments);
    };

    const handleDeleteAttachment = async attachment => {
        let attachmentsClone = [...attachedFiles];
        let attachmentIndex = attachmentsClone.findIndex(attachment.key ? x => x.key === attachment.key : x=> x.id === attachment.id);

        if (attachmentIndex !== -1) {
            // if attachment failed to upload then just remove from the array
            // else req server to delete attachment
            const {
                status
            } = attachmentsClone[attachmentIndex];

            if (status === 'end') {
                if (!attachment.key) {
                    attachmentsClone.splice(attachmentIndex, 1);

                    setAttachedFiles(attachmentsClone);

                    checkAttachmentLimit(attachmentsClone.length);
                    checkAttachmentSizeLimit(attachmentsClone);
                } else {
                    attachmentsClone[attachmentIndex].loading = true;

                    setAttachedFiles(attachmentsClone);

                    let deleteResult
                    try {
                        deleteResult = await handleApiReq(
                            'put',
                            '/crud',
                            {
                                action: 'DELETE_RFQ_ATTACHMENT',
                                quote_id: quoteId,
                                val: attachmentsClone[attachmentIndex].key
                            }
                        );

                        if (deleteResult.success) {
                            let attachmentsCloneX = [...attachedFiles];
                            let attachmentIndexX = attachmentsClone.findIndex(attachment.key ? x => x.key === attachment.key : x=> x.id === attachment.id);

                            if (attachmentIndexX !== -1) {
                                attachmentsCloneX.splice(attachmentIndexX, 1);

                                setAttachedFiles(attachmentsCloneX);

                                checkAttachmentLimit(attachmentsCloneX.length);
                                checkAttachmentSizeLimit(attachmentsCloneX);
                            }
                        } else {
                            console.log('made it here')
                            attachmentsClone[attachmentIndex].loading = false;

                            setAttachedFiles(attachmentsClone);

                            handleNewSnackbar('something went wrong', 'error');
                        }
                    } catch (error) {
                        handleNewSnackbar('something went wrong', 'error');
                    }
                }
            }
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        // on mount fetch general options
            setLoading(true);
            callApiAndReturnCombinedResultsData([
                {method: 'get', path: '/fetch-config/general-options'},
                {method: 'get', path: `/crud/rfq-attachments/${quoteId}`}
            ]).then(result => {
                setIndustryOptions(result.industry_options);
                setMessageLimit(result.rfq_message_limit);
                setAttachmentLimit(result.rfq_attachment_limit);
                setAttachmentSizeLimit(result.rfq_attachment_size_limit);
                setAttachmentFormatChecks(result.rfq_forbidden_types);
                setAttachedFiles(result.rfq_attachments);
                setLoading(false);
            }).catch(() => {
                handleNewSnackbar('something went wrong', 'error');
            });

        // set the initial email input value
        if (!!quote?.email && !!emailRef?.current) emailRef.current.value = quote.email;

        if (tempData){
            const {
                fn,
                ln,
                e,
                p,
                m
            } = tempData;

            if (fn && firstNameRef?.current) firstNameRef.current.value = fn;
            if (ln && lastNameRef?.current) lastNameRef.current.value = ln;
            if (e && emailRef?.current) emailRef.current.value = e;
            if (p && phoneNumberRef?.current) phoneNumberRef.current.value = p;
            if (m && messageRef?.current) messageRef.current.value = m;
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (attachedFiles.length) {
            for (let attachment of attachedFiles.filter(x => x.status === 'init')) {
                handleUploadAttachment(attachment);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachedFiles]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    let attachmentDisabled = loading || !attachmentLimit || (attachedFiles.length && attachedFiles.length >= attachmentLimit);
    let submitDisabled = !!attachedFiles.length && attachedFiles.some(x => x.loading || x.failed);

    return (
        <div
            id="RequestQuoteWrap"
            className="FullHeightWidth"
            onClick={e => e.target.id === 'RequestQuoteWrap' && !loading ? handleRFQModalClose(false) : null}
        >
            <Paper
                elevation={6}
                id="RequestQuote"
                className={showAuthenticator ? 'RequestQuoteAuthenticator' : null}
            >
                {loading &&
                    <div id="RequestQuoteLoading">
                        <LoadingSVG
                            size={50}
                        />
                    </div>
                }

                <CloseIcon
                    id="RequestQuoteClose"
                    onClick={() => !loading ? handleRFQModalClose(false) : null}
                />

                <p className={clsx(showAuthenticator ? 'Bold14' : 'Bold16')}>{
                    !showAuthenticator ? 'Request For Quote'
                    : showAuthenticator === 'register' ? 'Register a 3D People account'
                    : 'Enter your details to login'
                }</p>
                <p className={clsx(showAuthenticator ? 'Reg14' : 'Reg16', 'RFQMarginBottom')}>{
                    !showAuthenticator ? 'We will contact you using these details'
                    : showAuthenticator === 'register' ? 'Complete the following steps'
                    : ''
                }</p>

                {!showAuthenticator &&
                    <form
                        autoComplete="on"
                        action=""
                        noValidate={true}
                        data-form-type="other"
                        className="Reg16"
                    >
                        {(!!loggedIn || !!authCallbackData) &&
                            <div id="RequestQuoteUserInfo" className="RFQMarginBottom Flex0">
                                <p className={clsx(windowBreakpoint?.w <= 768 ? 'Bold12' : 'Bold14')}>Full Name</p>
                                <p className={clsx(windowBreakpoint?.w <= 768 ? 'Reg12' : 'Reg14')}>{!!loggedIn ? loggedIn['custom:full_name'] : authCallbackData.n}</p>

                                <p className={clsx(windowBreakpoint?.w <= 768 ? 'Bold12' : 'Bold14')}>Email</p>
                                <p className={clsx(windowBreakpoint?.w <= 768 ? 'Reg12' : 'Reg14')}>{!!loggedIn ? loggedIn['email'] : authCallbackData.e}</p>

                                <p className={clsx(windowBreakpoint?.w <= 768 ? 'Bold12' : 'Bold14')}>Phone Number</p>
                                <p className={clsx(windowBreakpoint?.w <= 768 ? 'Reg12' : 'Reg14')}>{!!loggedIn ? loggedIn['custom:phone_no'] : authCallbackData.t}</p>

                                <Link
                                    to='/user'
                                    className="Reg12"
                                    onClick={() => !loading ? handleRFQModalClose(false) : null}
                                    id="RequestQuoteChangeDetails"
                                >need to change your details?</Link>
                            </div>
                        }

                        {(!loggedIn && !authCallbackData) &&
                            <React.Fragment>
                                <div className="RequestQuoteDoubleInputWrap Flex0">
                                    <TextInput
                                        label="First Name"
                                        type="text"
                                        autoComplete="given-name"
                                        required={true}
                                        refToUse={firstNameRef}
                                        maxlength={100}
                                        rootStyleOverwrite={{
                                            width: 'calc(50% - 10px)',
                                            flex: '0 1 auto'
                                        }}
                                        writtenWarning={firstNameWarning}
                                    />

                                    <TextInput
                                        label="Last Name"
                                        type="text"
                                        autoComplete="family-name"
                                        required={false}
                                        refToUse={lastNameRef}
                                        maxlength={100}
                                        rootStyleOverwrite={{
                                            width: 'calc(50% - 10px)',
                                            flex: '0 1 auto'
                                        }}
                                    />
                                </div>

                                <TextInput
                                    label="Email Address"
                                    type="text"
                                    autoComplete="email"
                                    required={true}
                                    refToUse={emailRef}
                                    writtenWarning={emailWarning}
                                    rootStyleOverwrite={{
                                        width: '100%',
                                        flex: '0 1 auto',
                                        marginBottom: '20px',
                                        marginTop: windowBreakpoint?.w <= 1200 ? null : '20px'
                                    }}
                                />

                                <TextInput
                                    label="Phone Number"
                                    type="tel"
                                    autoComplete="tel"
                                    maxlength={20}
                                    required={true}
                                    pattern={["[^\\d+-]", "g"]}
                                    refToUse={phoneNumberRef}
                                    writtenWarning={phoneNumberWarning}
                                    rootStyleOverwrite={{
                                        width: '100%',
                                        flex: '0 1 auto',
                                        marginBottom: '20px'
                                    }}
                                />

                                <p
                                    id="RequestQuoteCreateAccount"
                                    className="Reg12"
                                >
                                    <span
                                        onClick={() => setShowAuthenticator('register')}
                                    >
                                        Create an account
                                    </span> to save your details
                                </p>
                            </React.Fragment>
                        }

                        <p className={clsx(windowBreakpoint?.w <= 768 ? 'Bold12' : 'Bold16', 'Flex0')}>Send us a message <span className={clsx(windowBreakpoint?.w <= 768 ? 'Reg12' : 'Reg16')}>(optional)</span></p>
                        <p className={clsx(windowBreakpoint?.w <= 768 ? 'Reg12' : 'Reg16', 'Flex0', 'RFQMarginBottom')}>Let us know of any special requirements for your parts</p>

                        <div
                            id="RequestFormTextAreaWrap"
                            className={clsx(!!attachedFiles.length && (windowBreakpoint?.w > 768 || isNaN(windowBreakpoint?.w)) ? 'ReducedHeight' : null)}
                        >
                            <textarea
                                className={clsx(windowBreakpoint?.w <= 768 ? 'Reg12' : 'Reg16', !!attachedFiles.length && (windowBreakpoint?.w > 768 || isNaN(windowBreakpoint?.w)) ? 'ReducedHeight' : null)}
                                ref={messageRef}
                                maxLength={messageLimit}
                            ></textarea>
                        </div>

                        <div id="RFQAttachmentsWrap">
                            {!!attachedFiles.length &&
                                <div
                                    id="RFQAttachmentsList"
                                    className="HideScrollbar"
                                >
                                    {attachedFiles.map(attachment =>
                                        <div
                                            key={attachment.id + attachment.name + attachment.key + '_key'}
                                            className={clsx('Attachment', attachment.loading ? 'AttachmentLoading' : null, attachment.failed && !attachment.loading ? 'AttachmentFailed' : null)}
                                            onClick={attachment.loading ? null : () => handleDeleteAttachment(attachment)}
                                        >
                                            {attachment.failed &&
                                                <p className="Reg10"><span className="AttachmentFailedMessage Bold10">{attachment.bad_format ? 'invalid file' : 'failed'}: </span>{attachment.name}</p>
                                            }

                                            {!attachment.failed &&
                                                <p className="Reg10">{attachment.name}</p>
                                            }

                                            {attachment.loading &&
                                                <p className="Reg10 AttachmentLoadingDots"></p>
                                            }

                                            {!attachment.loading &&
                                                <CancelIcon
                                                    className="DeleteAttachment"
                                                />
                                            }
                                        </div>
                                    )}
                                </div>
                            }

                            <input
                                type="file"
                                accept="*"
                                hidden
                                multiple
                                disabled={attachmentDisabled}
                                id="rfq-attachments-input"
                                onChange={e => handleAttachmentInputOnChange(e.target.files)}
                            >
                            </input>

                            <div id="RFQAttachButtonWrap">
                                <label htmlFor="rfq-attachments-input">
                                    <p
                                        id="RFQAttachFilesButton"
                                        className={clsx(attachmentDisabled ? 'RFQAttachFilesButtonDisabled' : null, windowBreakpoint?.w <= 768 ? 'Reg10' : 'Reg12')}
                                    >{(attachedFiles?.length && attachedFiles.length >= attachmentLimit) || !attachmentFormatChecks.length ? '' :
                                        <>
                                            <FileUploadIcon id="AttachmentUploadIcon" />
                                            {'attach files'}
                                        </>
                                    }</p>
                                </label>

                                <p className={clsx(windowBreakpoint?.w <= 768 ? 'Reg10' : 'Reg12' )}>
                                    <span ref={attachLimitRef}>limit: {attachmentLimit} file{attachmentLimit > 1 ? 's' : ''}</span>
                                    {', '}
                                    <span ref={attachSizeLimitRef}>max file size: {attachmentSizeLimit}mb</span>
                                </p>
                            </div>
                        </div>

                        <button
                            id="RequestQuoteSubmit"
                            className={clsx('Bold16', 'Flex0', submitDisabled ? 'RequestQuoteSubmitDisabled' : null)}
                            disabled={submitDisabled}
                            onClick={!submitDisabled ? e => {
                                e.preventDefault();
                                e.stopPropagation();

                                handleSubmitRequestForQuote();
                            } : null}
                        >
                            Submit Request
                        </button>
                    </form>
                }

                {showAuthenticator &&
                    <Authenticator
                        childMode={showAuthenticator}
                        childModeCallback={data => {
                            setShowAuthenticator(false);
                            if (data?.success) {
                                setAuthCallbackData(data);
                            }
                        }}
                        childModeSwitchCallback={setShowAuthenticator}
                        childModeBackMessage={showAuthenticator === 'register' ? "Don't register." : "Don't login."}
                        childModesetLoading={setLoading}
                        handleNewSnackbar={handleNewSnackbar}
                        industryOptions={industryOptions}
                        loggedIn={loggedIn}
                    />
                }
            </Paper>
        </div>
    );
};