import React from "react";

export default function UploadIcon(props) {
    const { 
        size,
        rootStyle,
        className,
        id
    } = props;

    let width = size ? size + 1 : 18;
    let height = size ? size : 17;

    return (
        <svg
            width={width + 'px'}
            id={id}
            className={className}
            height={height + 'px'}
            viewBox={`0 0 20 20`}
            style={rootStyle}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17 11V16H1V11"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M8.99805 1.61002V11.1922"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M12.7905 5.66531L8.99875 1.61002L5.20703 5.66531"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};
