import React from 'react';
import clsx from 'clsx';

import './OptionsList.css';

import MoreInfoI from '../MoreInfoI/MoreInfoI';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function OptionsList(props) {
    const {
        options,
        infoI,
        title,
        overwrite,
        rootStyle,
        isSelectedOption,
        handleSelection,
        returnElem
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    let optionsLoading = !!(!options || !options.length);

    return (
        <div
            style={rootStyle}
            className="OptionsListWrap"
        >
            <div className="OptionsListTitle">
                <h4 className={clsx('Bold12', optionsLoading && !overwrite ? 'OptionsLoading' : null)}>{optionsLoading && !overwrite ? 'loading' : title}</h4>

                {infoI &&
                    <MoreInfoI
                        returnTooltip={infoI.returnTooltip}
                        tooltip={infoI.tooltip}
                        delay={infoI.delay}
                        styleOverwrite={infoI.styleOverwrite}
                    />
                }
            </div>

            {!overwrite && !optionsLoading && options.map(option => {
                const {
                    key
                } = option;

                let selected = isSelectedOption(option);

                return (
                    <div
                        onClick={selected ? null : e => handleSelection(e, option)}
                        key={key}
                        className={clsx(selected ? 'OptionSelected' : null, 'OptionsListOption')}
                    >
                        {selected &&
                            <div className="SelectedOptionIndicatorWrap">
                                <CheckCircleIcon
                                    className="SelectedOptionIndicator"
                                />
                            </div>
                        }

                        {returnElem(option, selected)}
                    </div>
                )
            })}

            {overwrite}
        </div>
    );
};