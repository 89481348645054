import React,{
    useEffect,
    useState
} from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import CloseIcon from '@mui/icons-material/Close';

import {
    returnCurrencySignAndOrValue
} from '../../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./BulkPricesModal.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function BulkPricesModal(props) {
    const {
        handleClose,
        discountData,
        basePrice,
        handleBulkPricesClick,
        currency,
        rfqLimits
    } = props;

    // set the elems for within the bulk prices modal
    const [linesToRender, setLinesToRender] = useState(null);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // using the provided data format the elems to map over in render
    const formatLines = (basePrice, qtyData) => {
        if (!qtyData) handleClose();

        let linesToSet = [];

        let limit = rfqLimits ? rfqLimits.price_part : null;

        for (const key in qtyData) {
            let unitPrice = parseFloat((basePrice * qtyData[key]).toFixed(2));
            let totalPrice = unitPrice * key;

            let overLimit = false;

            if (limit && (unitPrice >= limit || totalPrice >= limit)) {
                overLimit = 'RFQ';
            }

            linesToSet.push(
                <div key={'BulkLine' + key} onClick={e => {
                    e.stopPropagation();
                    handleBulkPricesClick(key);
                }} className="BulkPricesModalLine">
                    <p className="Reg12">{key}</p>
                    <p className="Reg12">{Math.round(100 - ((qtyData[key]) / 1) * 100)}%</p>
                    <p className="Reg12">{overLimit || returnCurrencySignAndOrValue(currency, unitPrice, null)}</p>
                    <p className="Reg12">{overLimit || returnCurrencySignAndOrValue(currency, totalPrice, null, null)}</p>
                </div>
            );
        }

        setLinesToRender(linesToSet);
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // on mount
    useEffect(() => {
        formatLines(basePrice, discountData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            id="BulkPricesModalWrap"
            className="FullHeightWidth"
            onClick={handleClose}
        >
            <div onClick={e => e.stopPropagation()}>
                <div id="BulkPricesModalTop">
                    <p className="Bold14">Bulk Pricing</p>

                    <button
                        id="BulkPricesModalCloseButton"
                        onClick={handleClose}
                    >
                        <CloseIcon id="BulkPricesModalCloseButtonIcon"/>
                    </button>
                </div>

                <div className="BulkPricesModalLineHeader">
                    <p className="Bold12">Quantity</p>
                    <p className="Bold12">Discount</p>
                    <p className="Bold12">Unit Price</p>
                    <p className="Bold12">Total Price</p>
                </div>

                {linesToRender && linesToRender.length &&
                    <div id="BulkPricesModalBottom">
                        {linesToRender.map(elem => elem)}
                    </div>
                }
            </div>
        </div>
    );
};