 import React, {
    useState,
    useRef,
    useEffect
} from 'react';

import {
    getAuthenticatedUser
} from '../../functions/user_functions';

import clsx from 'clsx';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    handleApiReq,
    clearRefValue,
    handleError,
    callApiAndReturnCombinedResultsData,
    determineIfRequiredSatisfiedReturnValues
} from '../../functions/utils';

import {
    hasPasswordFailed,
    attemptLogout,
    attemptUpdateUserAttributes,
    attemptDeleteUser,
    attemptChangePassword,
    updateUserAddresses,
    updateUserCurrency
} from '../../functions/user_functions';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import AddressHandler from '../Misc/AddressHandler/AddressHandler';
import TextInput from "../Misc/TextInput/TextInput";
import CurrencySelector from '../Misc/CurrencySelector/CurrencySelector';
import AlienUserComponent from '../Alien/AlienUserComponent';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import './User.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function User(props) {
    const {
        handleLoading,
        loggedIn,
        handleNewSnackbar,
        windowBreakpoint,
        toggleActiveModal,
        isAdmin,
        handleNewAlienMessage
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const editInfoRefs = useRef([]);
    const oldPasswordRef = useRef();
    const newPasswordRef = useRef();
    const confirmNewPasswordRef = useRef();
    const addressesInfoRefs = useRef({
        billing: {},
        shipping: {}
    });

    // toggle edit mode for personal information
    const [informationEditActive, setInformationEditActive] = useState(false);
    // state object for warnings
    const [informationWarnings, setInformationWarnings] = useState(null);
    // toggle edit mode for settings
    const [settingsEditActive, setSettingsEditActive] = useState(false);
    // handle error message for password input
    const [changePasswordMessage, setChangePasswordMessage] = useState(null);
    // toggle edit mode for addresses
    const [addressesEditActive, setAddressesEditActive] = useState(null);
    // user data in state
    const [user, setUser] = useState(null);
    // config options for address input elems
    const [addressElemInfo, setAddressElemInfo] = useState(null);
    // warnings for address
    const [addressWarnings, setAddressWarnings] = useState(null);
    // options for the country drop down
    const [countriesOptions, setCountriesOptions] = useState(null);
    const [currencyOptions, setCurrencyOptions] = useState(null);
    const [currencyEditActive, setCurrencyEditActive] = useState(false);
    const [tempCurr, setTempCurr] = useState(null);
    const [currWarning, setCurrWarning] = useState(false);
    const [assuming, setAssuming] = useState(false);
    const [applyLink, setApplyLink] = useState(null);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const initUser = async () => {
        if (!loggedIn) return;
        handleLoading(true);

        await fetchConfigData();
        await fetchUserData();

        handleLoading(false);
    };

    const fetchConfigData = () => new Promise(async (resolve, reject) => {
        callApiAndReturnCombinedResultsData([
            {method: 'get', path: '/fetch-config/address-options'},
            {method: 'get', path: '/fetch-config/countries-options'},
            {method: 'get', path: '/fetch-config/general-options'},
            {method: 'get', path: '/fetch-config/trade-approval-link'},
        ]).then(result => {
            setAddressElemInfo(result.address_inputs);
            setCountriesOptions(result.countries_options);
            setCurrencyOptions(result.currency_options);
            setApplyLink(result.trade_approval_link);

            resolve();
        }).catch(error => {
            handleError('6327yJN', error, true);
            handleNewSnackbar('something went wrong', 'error');
            resolve();
        });
    });

    const fetchUserData = () => new Promise(async (resolve, reject) => {
        handleApiReq(
            'get',
            '/user-crud/user-data'
        ).then(result => {
            if (result.success && result.data.user) {
                setUser(result.data.user);
            } else {
                handleNewSnackbar('something went wrong', 'error');
            }

            resolve();
        }).catch(error => {
            console.log('caught that error here?', error)
            handleError('43416GeO', error, true);
            handleNewSnackbar('something went wrong', 'error');
            resolve();
        });
    });

    // return the user info content elems
    const returnUserInfo = () => {
        return (
            <React.Fragment>
                {userInfoItems && userInfoItems.length && userInfoItems.map((elem, index) =>
                    <div
                        key={elem.path + index}
                        className="UserInfoElem"
                    >
                        <h4 className="Bold16">{elem.label}</h4>
                        <p className="Reg16">{loggedIn[elem.path] || '-'}</p>
                    </div>
                )}
            </React.Fragment>
        );
    };

    // return the user edit info content elems
    const returnEditUserInfo = () => {
        if (!editInfoRefs.current) return null;

        return (
            <React.Fragment>
                {userInfoItems && userInfoItems.length && userInfoItems.map((elem, index) =>
                    <div
                        key={elem.path + index}
                        className="UserInfoElem"
                    >
                        {elem.edit ? (
                                <React.Fragment>
                                    <h4 className="Bold16">{elem.label}</h4>

                                    <div className="UserInfoInputWrap">
                                        {!!(informationWarnings && informationWarnings[elem.path]) &&
                                            <p className="Reg12">{informationWarnings[elem.path]}</p>
                                        }

                                        <TextInput
                                            label={null}
                                            name={elem.label}
                                            autoComplete="off"
                                            refToUse={editInfoRefs ? el => editInfoRefs.current[elem.edit - 1] = el : null}
                                            pattern={elem.path === 'custom:phone_no' ? [/[^\d+-]/, 'g'] : null}
                                            rootStyleOverwrite={{ width: windowBreakpoint?.w <= 768 ? '100%' : '40%' }}
                                            editPath={elem.path}
                                        />
                                    </div>
                                </React.Fragment>
                            )
                            : (
                                <React.Fragment>
                                    <h4 className="Bold16">{elem.label}</h4>
                                    <p className="Reg16">{loggedIn[elem.path] || '-'}</p>
                                </React.Fragment>
                            )
                        }
                    </div>
                )}
            </React.Fragment>
        );
    };

    // return the elems for settings
    const returnSettings = () => {
        return (
            <div
                key="PasswordWrap"
                className="UserInfoElem"
            >
                <h4 className="Bold16">Password</h4>
                <p className="Reg16">***********</p>
            </div>
        );
    };

    // return the change password content elems
    const returnEditSettings = () => {
        return (
            <React.Fragment>
                <div
                    key="PasswordWrap"
                    className="UserInfoElem"
                >
                    <h4 className="Bold16">Password</h4>

                    <form
                        className="UserInfoMultiInputWrap"
                        autoComplete="on"
                        action=""
                        noValidate={true}
                        data-form-type="other"
                    >
                        {changePasswordMessage && <p className="Reg12">{changePasswordMessage}</p>}

                        <TextInput
                            label="Old password"
                            name="old-password"
                            type="password"
                            autoComplete="old-password"
                            refToUse={oldPasswordRef}
                            rootStyleOverwrite={{ marginBottom: 20, width: windowBreakpoint?.w <= 768 ? '100%' : '40%' }}
                        />

                        <TextInput
                            label="New password"
                            name="new-password"
                            type="password"
                            autoComplete="new-password"
                            refToUse={newPasswordRef}
                            rootStyleOverwrite={{ marginBottom: 20, width: windowBreakpoint?.w <= 768 ? '100%' : '40%' }}
                        />

                        <TextInput
                            label="Confirm new password"
                            name="new-password"
                            type="password"
                            autoComplete="new-password"
                            refToUse={confirmNewPasswordRef}
                            rootStyleOverwrite={{ marginBottom: 20, width: windowBreakpoint?.w <= 768 ? '100%' : '40%' }}
                        />
                    </form>
                </div>
            </React.Fragment>
        );
    };

    // handle the initial values of input elems, we set the value once the elems are rendered
    // this allows the user to modifying the current data
    const handleInitialEditInfoValues = refArray => {
        for (let elem of refArray) {
            if (elem && !elem.hasAttribute('initial_set')) {
                // set edit_path value, this value is the key of key value pair of user attributes
                let path = elem.getAttribute('edit_path');
                elem.value = loggedIn[path] || '';
            }
        }
    };

    // handle the user saving their updated info
    const handleUpdateUserData = async () => {
        if (!editInfoRefs || !editInfoRefs.current || !loggedIn) return;

        setInformationWarnings(null);

        let updateValues = false;

        // loop over each input elem
        for (const elem of editInfoRefs.current) {
            let path = elem.getAttribute('edit_path');
            let currValue = loggedIn[path];
            let elemValue = elem.value.length ? elem.value : undefined;

            // if the value has been modified and is different from the current set value, then add to update obj
            if (currValue !== elemValue) {
                // if updateValues is false, change it to an empty object
                if (!updateValues) updateValues = {};
                updateValues[path] = elemValue;
            }
        }

        if (updateValues) {
            handleLoading(true);

            try {
                let updateResult = await attemptUpdateUserAttributes(updateValues);

                if (updateResult.success) {
                    let user = await getAuthenticatedUser();
                    if (user.attributes) {
                        handleNewSnackbar('information successfully updated');
                        setInformationEditActive(false);
                    } else handleNewSnackbar('information successfully updated - auto update failed - please refresh page', 'warning');
                } else {
                    let pathToSet = userInfoItems.filter(elem => new RegExp(elem.label, 'i').test(updateResult.message));
                    pathToSet = pathToSet.length ? pathToSet[0].path : null;

                    if (!pathToSet) {
                        handleNewSnackbar(updateResult.message, 'error');
                    } else {
                        setInformationWarnings({
                            [pathToSet]: updateResult.message
                        });
                    }
                }
            } catch (error) {
                handleNewSnackbar('something went wrong', 'error');
            }

            handleLoading(false);
        }
    };

    // handle user deleting their account
    const handleUserDelete = async () => {
        try {
            handleLoading(true);

            toggleActiveModal(null);

            let deleteResult = await attemptDeleteUser();

            if (deleteResult.success) {
                handleLoading(true);

                // if assuming here then don't log out, instead clear assuming data
                if (assuming) {
                    handleNewAlienMessage(['User deleted.', 'Reverting identity.'])

                    setTimeout(() => {
                        localStorage.removeItem('_alien_assumed_identity');

                        // refresh the page
                        setTimeout(() => {
                            window.location.reload();
                        }, 100);
                    }, 3000);

                    return;
                } else {
                    await attemptLogout();
                }
            } else {
                handleNewSnackbar(deleteResult.message, 'error');
            }

            handleLoading(false);
        } catch (error) {
            handleNewSnackbar('something went wrong', 'error');
        }
    };

    // handle user changing their password
    const handleChangePassword = async () => {
        // clear message
        setChangePasswordMessage(null);

        if (!oldPasswordRef || !oldPasswordRef.current || !oldPasswordRef.current.value || !oldPasswordRef.current.value.length) {
            setChangePasswordMessage('No old password value');
            return;
        }
        if (!newPasswordRef || !newPasswordRef.current || !newPasswordRef.current.value || !newPasswordRef.current.value.length) {
            setChangePasswordMessage('No new password value');
            return;
        }
        if (!confirmNewPasswordRef || !confirmNewPasswordRef.current || !confirmNewPasswordRef.current.value || !confirmNewPasswordRef.current.value.length) {
            setChangePasswordMessage('No confirm new password value');
            return;
        }
        if (newPasswordRef.current.value !== confirmNewPasswordRef.current.value) {
            setChangePasswordMessage('New password does not match');
            return;
        }

        let newPasswordFailed = hasPasswordFailed(newPasswordRef.current.value, 'New password');
        if (newPasswordFailed) {
            setChangePasswordMessage(newPasswordFailed);
            return;
        }

        handleLoading(true);

        let changeResult = await attemptChangePassword(oldPasswordRef.current.value, newPasswordRef.current.value);

        if (changeResult.success) {
            setSettingsEditActive(false);

            handleNewSnackbar('password successfully changed');
        } else {
            setChangePasswordMessage(changeResult.message);
        }

        clearRefValue(oldPasswordRef);
        clearRefValue(newPasswordRef);
        clearRefValue(confirmNewPasswordRef);

        handleLoading(false);
    };

    // return addresses elems
    const returnAddresses = () => {
        return (
            <React.Fragment>
                <div className="UserInfoElem">
                    <h4 className="Bold16">Billing Information</h4>

                    <AddressHandler
                        mode="accountInfo"
                        open={false}
                        which="billing"
                        handleOpenClose={null}
                        address={user && user.billing ? user.billing : {}}
                        addressElemInfo={addressElemInfo}
                        windowBreakpoint={windowBreakpoint}
                    />
                </div>

                <hr className="UserInfoElemDividerHidden"></hr>

                <div className="UserInfoElem">
                    <h4 className="Bold16">Shipping Information</h4>

                    <AddressHandler
                        mode="accountInfo"
                        open={false}
                        which="shipping"
                        handleOpenClose={null}
                        address={user && user.shipping ? user.shipping : {}}
                        addressElemInfo={addressElemInfo}
                        rootStyleOverwrite={{
                            marginBottom: 30
                        }}
                        windowBreakpoint={windowBreakpoint}
                    />
                </div>
            </React.Fragment>
        );
    };

    // return addresses elems for edit mode
    const returnEditAddresses = () => {
        return (
            <React.Fragment>
                <div className="UserInfoElem">
                    <h4 className="Bold16">Billing Information</h4>

                    <AddressHandler
                        mode="accountInfo"
                        open={true}
                        which="billing"
                        handleOpenClose={null}
                        address={user && user.billing ? user.billing : {}}
                        inputRefs={addressesInfoRefs && addressesInfoRefs.current ? addressesInfoRefs.current : null}
                        addressElemInfo={addressElemInfo}
                        countriesOptions={countriesOptions}
                        windowBreakpoint={windowBreakpoint}
                        rootStyleOverwrite={{
                            width: windowBreakpoint?.w <= 768 ? '100%' : 'initial'
                        }}
                    />
                </div>

                <hr className="UserInfoElemDivider"></hr>

                <div className="UserInfoElem">
                    <h4 className="Bold16">Shipping Information</h4>

                    <AddressHandler
                        mode="accountInfo"
                        open={true}
                        which="shipping"
                        handleOpenClose={null}
                        address={user && user.shipping ? user.shipping : {}}
                        inputRefs={addressesInfoRefs && addressesInfoRefs.current ? addressesInfoRefs.current : null}
                        addressElemInfo={addressElemInfo}
                        warnings={addressWarnings}
                        countriesOptions={countriesOptions}
                        rootStyleOverwrite={{
                            marginBottom: windowBreakpoint?.w <= 768 ? 0 : 30,
                            width: windowBreakpoint?.w <= 768 ? '100%' : 'initial'
                        }}
                        windowBreakpoint={windowBreakpoint}
                    />
                </div>
            </React.Fragment>
        );
    };

    // handle user changing addresses
    const handleChangeAddresses = async () => {
        let isSatisfied = determineIfRequiredSatisfiedReturnValues([addressesInfoRefs.current.billing, addressesInfoRefs.current.shipping], addressElemInfo);
        if (!isSatisfied.satisfied) {
            setAddressWarnings({
                main: 'Please fill out all required fields',
                elems: isSatisfied.elemWarnings
            });
            return;
        }

        handleLoading(true);

        let result = await updateUserAddresses(addressesInfoRefs, ['billing', 'shipping']);

        handleLoading(false);

        if (result.success) {
            handleNewSnackbar('address information successfully changed');
            setAddressesEditActive(false);
            setUser(result.data.user);
        } else {
            handleNewSnackbar('something went wrong', 'error');
        }
    };

    // handle the user changing default currency
    const handleChangeCurrency = async () => {
        setCurrWarning(false);
        let valToUse = !!tempCurr ? tempCurr.match(/^\w+/)[0] : false;
        if (!valToUse || valToUse === 'select default currency') {
            setCurrWarning('please select a currency');
            return;
        }

        handleLoading(true);

        let result = await updateUserCurrency(valToUse);

        if (result.success) {
            setUser({
                ...user,
                currency: result.val_to_set
            });

            setTempCurr(null);
            handleNewSnackbar('currency information successfully changed');
            setCurrencyEditActive(false);
        } else {
            handleNewSnackbar('something went wrong', 'error');
        }

        handleLoading(false);
    };

    const returnCurrency = () => {
        let innerText = null;

        if (currencyOptions && user) {
            let selectedCurr = currencyOptions.filter(option => option.label === user.currency)[0];
            if (selectedCurr) {
                const {
                    select_option_label
                } = selectedCurr;

                innerText = select_option_label;
            } else {
                innerText = 'none set'
            }
        }

        return (
            <div
                key="CurrencyWrap"
                className="UserInfoElem"
            >
                <h4 className="Bold16">Currency</h4>
                <p className="Reg16">{innerText}</p>
            </div>
        );
    };

    const returnEditCurrency = () => {
        let innerText = null;

        if (currencyOptions && user) {
            let selectedCurr = currencyOptions.filter(option => option.label === user.currency)[0];
            if (selectedCurr) {
                const {
                    select_option_label
                } = selectedCurr;

                innerText = select_option_label;
            }
        }

        return (
            <div
                key="CurrencyWrap"
                className="UserInfoElem MarginBottomInfoElem"
            >
                <h4 className="Bold16">Currency</h4>

                <CurrencySelector
                    options={currencyOptions}
                    value={tempCurr ? tempCurr : innerText || 'select default currency'}
                    handleOnChange={e => setTempCurr(e.target.value)}
                    selectStyleClass={`UserChangeCurrencySelect Reg${windowBreakpoint?.w <= 1024 ? '14' : '16'}Important`}
                    warning={currWarning}
                    rootStyleClass={'UserChangeCurrencyRoot'}
                    rootStyleOverwrite={{
                        width: windowBreakpoint?.w <= 768 ? '100%' : '40%'
                    }}
                    menuItemStyleOverwrite={{
                        color: 'var(--tones-dark-grey)',
                        padding: '3px 15px',
                        fontSize: windowBreakpoint?.w <= 1024 ? 14 : 16
                    }}
                />
            </div>
        );
    };

    const handleTradeApply = () => {
        if (!applyLink) return;

        try {
            let valsToUse = {
                account_number: loggedIn['custom:account_number'],
                email: loggedIn.email,
                name: loggedIn['custom:full_name']
            };

            for (const key in valsToUse) {
                if (!valsToUse[key]) {
                    return;
                }
            }

            window.open(`${applyLink}?${Object.keys(valsToUse).map(key => `${key}=${encodeURIComponent(valsToUse[key])}`).join('&')}`, '_blank', 'noreferrer');
        } catch (error) {
            return;
        }
    }

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // array of all the user data points to display / edit
    const userInfoItems = [
        {
            label: 'Account Number',
            path: 'custom:account_number',
            edit: false
        },
        {
            label: 'Name',
            path: 'custom:full_name',
            edit: 1
        },
        {
            label: 'Phone Number',
            path: 'custom:phone_no',
            edit: 2
        }
    ];

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0
        });

        initUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // if the user has navigated to the edit menu, then we want to set the initial values of the inputs to match their current set information
        if (informationEditActive) {
            handleInitialEditInfoValues(editInfoRefs.current);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [informationEditActive]);

    useEffect(() => {
        let assuming = JSON.parse(localStorage.getItem('_alien_assumed_identity'));
        if (assuming && isAdmin) {
            setAssuming(true);
        }
    }, [isAdmin]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div className="Page">
            <div className="PageContentWrap">
                {windowBreakpoint?.w <= 768 &&
                    <div id="UserHeader">
                        <div id="UserHeaderTitleWrap">
                            <h1 className="Bold30">My Account</h1>

                            <h3 className="Reg16">View and change your account settings</h3>
                        </div>
                    </div>
                }

                <div className="PageContent">
                    {(windowBreakpoint?.w > 768 || isNaN(windowBreakpoint?.w)) &&
                        <div id="UserHeader">
                            <div id="UserHeaderTitleWrap">
                                <h1 className="Bold30">My Account</h1>

                                <h3 className="Reg16">View and change your account settings</h3>
                            </div>
                        </div>
                    }

                    <div className={clsx('UserSection', 'UserSectionMarginTop')}>
                        <div className="UserSectionHeader">
                            <h3 className="Bold16 UserSectionTitle">Personal Information{assuming ? <span className='UserSectionAssumingSpan'>This is your data!</span> : ''}</h3>
                        </div>

                        <div className="UserSectionContent">
                            {!informationEditActive ? returnUserInfo() : returnEditUserInfo()}
                        </div>

                        <div className="UserSectionButtonWrap">
                            {!informationEditActive &&
                                <button
                                    disabled={settingsEditActive}
                                    onClick={() => setInformationEditActive(true)}
                                    className="Bold16"
                                >
                                    Edit
                                </button>
                            }

                            {informationEditActive &&
                                <React.Fragment>
                                    <button
                                        onClick={() => setInformationEditActive(false)}
                                        className="Bold16 UserRedButton"
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        onClick={handleUpdateUserData}
                                        className="Bold16"
                                    >
                                        Save
                                    </button>
                                </React.Fragment>
                            }
                        </div>
                    </div>

                    <div className="UserSection">
                        <div className="UserSectionHeader">
                            <h3 className="Bold16 UserSectionTitle">Settings{assuming ? <span className='UserSectionAssumingSpan'>This is your data!</span> : ''}</h3>
                        </div>

                        <div className="UserSectionContent">
                            {!settingsEditActive ? returnSettings() : returnEditSettings()}
                        </div>

                        <div className="UserSectionButtonWrap">
                            {!settingsEditActive &&
                                <button
                                    onClick={() => setSettingsEditActive(true)}
                                    className="Bold16"
                                >
                                    Edit
                                </button>
                            }

                            {settingsEditActive &&
                                <React.Fragment>
                                    <button
                                        onClick={() => setSettingsEditActive(false)}
                                        className="Bold16 UserRedButton"
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        onClick={handleChangePassword}
                                        className="Bold16"
                                    >
                                        Save
                                    </button>
                                </React.Fragment>
                            }
                        </div>
                    </div>

                    <div className="UserSection">
                        <div className="UserSectionHeader">
                            <h3 className="Bold16 UserSectionTitle">Trade Account {assuming ? <span className='UserSectionAssumingSpan'>This is your data!</span> : ''}</h3>
                        </div>

                        <div className="UserSectionContent">
                            <div className="UserInfoElem">
                                <h4 className="Bold16">Status</h4>

                                <p>{loggedIn ? loggedIn['custom:trade_account'] === '0' ? 'Inactive' : 'Active' : '-' }</p>
                            </div>
                        </div>

                        {loggedIn && loggedIn['custom:trade_account'] === '0' &&
                            <div className="UserSectionButtonWrap">
                                <button
                                    onClick={handleTradeApply}
                                    className="Bold16"
                                >
                                    Apply
                                </button>
                            </div>
                        }
                    </div>

                    {(isAdmin && assuming) &&
                        <AlienUserComponent
                            handleNewAlienMessage={handleNewAlienMessage}
                        />
                    }

                    <div className="UserSection">
                        <div className="UserSectionHeader">
                            <h3 className="Bold16 UserSectionTitle">Default Address Information</h3>
                        </div>

                        <div className="UserSectionContent">
                            {!addressesEditActive ? returnAddresses() : returnEditAddresses()}
                        </div>

                        <div className="UserSectionButtonWrap">
                            {!addressesEditActive &&
                                <button
                                    onClick={() => setAddressesEditActive(true)}
                                    className="Bold16"
                                >
                                    Edit
                                </button>
                            }

                            {addressesEditActive &&
                                <React.Fragment>
                                    <button
                                        onClick={() => setAddressesEditActive(false)}
                                        className="Bold16 UserRedButton"
                                    >
                                        Cancel
                                    </button>

                                    <button
                                        onClick={handleChangeAddresses}
                                        className="Bold16"
                                    >
                                        Save
                                    </button>
                                </React.Fragment>
                            }
                        </div>
                    </div>

                    {(!!currencyOptions && currencyOptions.length > 1) &&
                        <div className="UserSection">
                            <div className="UserSectionHeader">
                                <h3 className="Bold16 UserSectionTitle">Default Currency</h3>
                            </div>

                            <div className="UserSectionContent">
                                {!currencyEditActive ? returnCurrency() : returnEditCurrency()}
                            </div>

                            <div className="UserSectionButtonWrap">
                                {!currencyEditActive &&
                                    <button
                                        onClick={() => {
                                            setCurrencyEditActive(true);
                                            setCurrWarning(false);
                                        }}
                                        className="Bold16"
                                    >
                                        Edit
                                    </button>
                                }

                                {currencyEditActive &&
                                    <React.Fragment>
                                        <button
                                            onClick={() => {
                                                setCurrencyEditActive(false);
                                                setCurrWarning(false);
                                            }}
                                            className="Bold16 UserRedButton"
                                        >
                                            Cancel
                                        </button>

                                        <button
                                            onClick={handleChangeCurrency}
                                            className="Bold16"
                                        >
                                            Save
                                        </button>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    }

                    <div id="DeleteUserWrap">
                        <button
                            id="DeleteUserButton"
                            onClick={() => toggleActiveModal('delete_user', {
                                handleUserDelete
                            })}
                            className="Bold16"
                        >
                            Delete this account
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};