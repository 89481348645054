import React, { 
    Component, 
    createRef
} from 'react';

import { isMobile } from 'react-device-detect';

import axios from 'axios';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    handleApiReq,
    callApiAndReturnCombinedResultsData,
    convertBitsToMegabytes,
    handlePushToDataLayer,
    handleError
} from '../../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import UploadingPart from '../UploadingPart/UploadingPart';
import UploadIcon from '../../Icons/UploadIcon';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import Dropzone from 'react-dropzone';
import CloseIcon from '@mui/icons-material/Close';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./UploadDropZone.css";
import clsx from 'clsx';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// upload drop zone can be rendered as fullscreen or as a child element fitting inline
const VALID_MODES = [
    'child',
    'fullscreen'
];

class UploadDropZone extends Component {
    constructor(props) {
        super(props);

        this.wrapRef = createRef();
        this.uploadWrapRef = createRef();

        this.state = {
            uploading: [],
            dragActive: false,
            intervalId: null,
            validFileTypes: ['.stl', '.step', '.stp', '.obj', '.3mf'],
            concurrentUploading: [],
            indexFrom: 0,
            initialPrompt: window.localStorage.getItem('_3dp_upload_initial_prompt') !== 'disable'
        };

        this.CancelToken = axios.CancelToken;
    };

    componentDidMount() {
        // set loading to true as we will be fetching options from the config
        this.props.handleLoading(true);

        window.addEventListener("beforeunload", this.onUnload);

        if (this.props.indexFromOverwrite) {
            this.setState({
                indexFrom: this.props.indexFromOverwrite
            });
        }

        // fetch options from the config
        callApiAndReturnCombinedResultsData([
            {method: 'get', path: '/fetch-config/upload-options'}
        ]).then(result => {
            this.setState({
                concurrencyLimit: result.concurrency_limit
            }, () => this.props.handleLoading(false));
        }).catch(error => {
            handleError('30810VHp', error);
        });
    };

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.onUnload);
    };

    getSnapshotBeforeUpdate(prevProps, prevState) {
        // the component is about to update > is there a change in the number of files given
        if (this.state.uploading.length !== prevState.uploading.length) {
            return 'filesAdded';
        } else return null;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot === 'filesAdded' && this.state.concurrentUploading.length < this.state.concurrencyLimit) {
            let uploadingClone = [...this.state.uploading];
            
            this.props.handleStartIndexFrom(this.state.uploading.length);

            // determine what files to start uploading, if the concurrency limit is already met an empty array will be returned
            let filesToUpload = uploadingClone.filter(file => !file.started && !file.message && !file.deleted).slice(0, this.state.concurrencyLimit - this.state.concurrentUploading.length);

            // add the files we are going to upload to the uploading array & indicate that they have started to upload
            for (const file of filesToUpload) { 
                let uploadingIndex = uploadingClone.map(elem => elem.index).indexOf(file.index);
                uploadingClone[uploadingIndex].started = true;
            }

            this.setState({
                uploading: uploadingClone,
                concurrentUploading: [...this.state.concurrentUploading, ...filesToUpload.map(file => file.index)]
            }, () => {
                for (const file of filesToUpload) {
                    // once the state has been updated send the file to the upload function
                    this.handleUploadRequest(file);
                }
            });
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    onUnload = e => {
        const {
            uploading,
            concurrentUploading
        } = this.state;

        let finishedUploading = !(!uploading.length || concurrentUploading.length || uploading.some(file => !file.deleted && file.progress < 100));
        let warnUser = !!(uploading.length && !finishedUploading);

        if (warnUser) {
            e.preventDefault();
            e.returnValue = 'Uploads are in progress, are you sure you want to leave?';
        }

        return warnUser;
    };

    // handle the drop function on the dropzone, send files to the upload handler function
    handleOnDrop = acceptedFiles => {
        this.setState({
            dragActive: false
        }, () => this.handleUploadOnChange(acceptedFiles));
    };

    // handles all the files to be uploaded, creates an object for the state that can be used to track them and their progress
    handleUploadOnChange = async (files, stopPrompt) => {
        if (!files || !files.length) {
            handleError('41986zMC', 'no files?', true);
            return;
        }

        if (stopPrompt) {
            this.setState({
                initialPrompt: false
            });
        }

        handlePushToDataLayer({
            event: 'file_selected',
            file_count: files.length,
            file_extensions: [...files].map(file => file.name.split('.').pop().toUpperCase())
        });

        if (!this.props.quoteHash && this.props.handleQuoteInsert) {
            try {
                await this.props.handleQuoteInsert();
            } catch (error) {
                handleError('1576fcj', error);
                return;
            }
        }

        if (!this.props.quoteHash) return;

        // are there too many files
        let breachingLimit = await handleApiReq(
            'get',
            `/crud/check-file-limit/${this.props.quoteHash}?nif=${files.length}&su=${this.state.uploading.filter(file => !file.uploadComplete).length}&p=${this.props.mode === 'child' ? 'upload' : 'quote'}&nopiq=${this.props.noPartsInQuote ? this.props.noPartsInQuote : 0}&ul=${this.state.uploading.filter(file => !file.deleted).length}`
        );

        if (!breachingLimit.success) {
            if (breachingLimit.message) {
                window.alert(breachingLimit.message);
            } else {
                handleError('14278oUM', 'breachingLimit no message', true);
            }

            return;
        }

        let uploadingClone = [...this.state.uploading];

        // if the files have come through in multiple batches then we need to keep track of an overall index rather than that of two separate arrays
        let indexToUse = this.state.indexFrom;

        for (let i = 0; i < files.length; i++) {
            indexToUse += 1;

            const file = files[i];

            // a failure message can be attached to the object, the object having this value truthy will prevent it from being uploaded
            let message = null;
            let nameToUse = file.name;

            if (!file) {
                message = 'Something went wrong';
            }

            let notValid = false;

            if (!message) {
                // perform some front end checks on the file, note: these tests are NOT rigorous as the user can change the front end, an S3 trigger should perform the correct checks
                if (convertBitsToMegabytes(file.size) > 100) {
                    message = 'Max File Size 100Mb';
                    notValid = true;

                    handlePushToDataLayer({
                        event: 'file_upload_error',
                        error: 'exceeds max file size'
                    });
                }
            }

            let fileType;
            try {
                fileType = file.name.match(/\.\w+$/)[0].toLowerCase();
            } catch (error) {
                console.log('error', error);
                console.log('error parsing file type');
            }

            if (!message && (!fileType || !this.state.validFileTypes.includes(fileType))) {
                message = 'Invalid file type';
                notValid = true;

                handlePushToDataLayer({
                    event: 'file_upload_error',
                    error: 'unsupported file type',
                    file_extension: file.name.split('.').pop().toUpperCase()
                });
            }

            // the obj to use per file
            let uploadObj = {
                index: indexToUse,
                name: nameToUse,
                type: file.type,
                file,
                progress: 0,
                uploaded: 0,
                notValid,
                override: null,
                totalSize: file.size,
                deleted: false,
                timeStarted: null,
                timeRemaining: 10,
                timeRemainingInterval: null,
                started: false,
                message,
                cancelExecutor: null
            };

            // push the file obj to the current file array
            uploadingClone.push(uploadObj);
        }

        // update the state with the new values, componentDidUpdate will send these obj to the upload function
        this.setState({
            uploading: uploadingClone,
            indexFrom: indexToUse
        });
    };

    // handles the upload of given file
    handleUploadRequest = uploadObj => {
        // creates a cancel token to be used if the user has wished the filed to be removed and the upload is still in progress
        let cancelRequest;
        let cancelTokenExecutor = new this.CancelToken(c => {
            cancelRequest = c
        });

        let uploadingClone = [...this.state.uploading];
        let uploadingIndex = uploadingClone.map(elem => elem.index).indexOf(uploadObj.index);

        // add the cancel token to the obj so that it can be accessed if needed
        uploadingClone[uploadingIndex].cancelExecutor = cancelRequest;
        // add the time started in order to calculate the remaining seconds left
        uploadingClone[uploadingIndex].timeStarted = Date.now();

        // update the state and then begin the upload process
        this.setState({
            uploading: uploadingClone
        }, async () => {
            let signedUrl;

            // attempt to fetch an presigned upload url for S3
            try {
                signedUrl = await handleApiReq(
                    'get',
                    `/fetch-upload-url?qid=${this.props.quoteHash}&n=${encodeURIComponent(uploadObj.name)}`
                );
            } catch (error) {
                handleError('31111qOn', error, true);

                handlePushToDataLayer({
                    event: 'file_upload_server_error',
                    error: 'fetching upload url failed',
                    error_message: error.message
                });
            }

            if (signedUrl && signedUrl.url) {
                // set the options for axios to use
                let options = {
                    headers: {
                        'Content-Type': uploadObj.type
                    },
                    onUploadProgress: e => this.handleProgress(e, uploadObj.index),
                    cancelToken: cancelTokenExecutor
                };

                const {
                    upload_pk,
                    upload_sk,
                    upload_file_name,
                    upload_message,
                    created_at,
                    converting
                } = signedUrl;

                let uploadingCloneX = [...this.state.uploading];
                let uploadingXIndex = uploadingCloneX.map(elem => elem.index).indexOf(uploadObj.index);

                uploadingCloneX[uploadingXIndex].PK = upload_pk;
                uploadingCloneX[uploadingXIndex].SK = upload_sk;

                uploadingCloneX[uploadingXIndex].skeletonData = {
                    upload_pk,
                    upload_sk,
                    upload_file_name,
                    upload_message,
                    created_at,
                    converting
                };

                this.setState({
                    uploading: uploadingCloneX
                }, () => {
                    // upload the file to S3
                    axios.put(signedUrl.url, uploadObj.file, options).then(response => {
                        // the upload was successful
                        this.handleProgress(null, uploadObj.index, 100);
                        this.checkForMoreUploads(uploadObj.index);
                    }).catch(error => {
                        // fail the part
                        this.handleFailPart(signedUrl);

                        // the upload failed, update the file elem
                        this.handleProgressStatus(uploadObj.index, 'Something went wrong', true);
                        this.handleProgress(null, uploadObj.index, 100);
                        this.checkForMoreUploads(uploadObj.index);

                        handleError('34093zHM', error, true);

                        handlePushToDataLayer({
                            event: 'file_upload_server_error',
                            error: 'upload to s3 failed',
                            error_message: error.message
                        });
                    });
                });
            } else {
                handleError('17277dXU', 'there seems to be no signed url', true);

                handlePushToDataLayer({
                    event: 'file_upload_server_error',
                    error: 'upload to s3 failed',
                    error_message: 'there seems to be no signed url'
                });

                // the upload failed, update the file elem
                this.handleProgressStatus(uploadObj.index, 'Something went wrong', true);
                await this.handleProgress(null, uploadObj.index, 100);
                this.checkForMoreUploads(uploadObj.index);
            }
        });
    };

    readyToProgress = () => !!(!this.state.concurrentUploading.length && this.state.uploading.filter(elem => !elem.deleted && !elem.notValid).length);

    // check the uploading obj to see if there is another file that can be uploaded
    checkForMoreUploads = index => {
        // removed the file that just resolved from the concurrent uploading array
        let concurrentUploadingClone = [...this.state.concurrentUploading].filter(elem => elem !== index);

        let uploadingClone = [...this.state.uploading];
        // find the next file to upload
        let nextFileToUpload = uploadingClone.filter(file => !file.started && !file.message && !file.deleted).slice(0, 1)[0];

        if (!nextFileToUpload) {
            // there was no file to upload left
            this.setState({
                concurrentUploading: concurrentUploadingClone
            }, () => {
                if (this.readyToProgress()) {
                    // all files indicated have been uploaded, trigger handleUploadEnd
                    if (this.props.mode === 'child') {
                        this.handleEnd();
                    }
                }
            });
        } else {
            let uploadingIndex = uploadingClone.map(elem => elem.index).indexOf(nextFileToUpload.index);
            // indicate that the next file has started to upload
            uploadingClone[uploadingIndex].started = true;

            this.setState({
                uploading: uploadingClone,
                concurrentUploading: [...concurrentUploadingClone, nextFileToUpload.index]
            }, () => {
                if (!nextFileToUpload.message) {
                    // once the state has been updated send the file to the upload function
                    this.handleUploadRequest(nextFileToUpload);
                }
            });
        }
    };

    // the upload process has failed and a message needs to be attached to the file
    handleProgressStatus = (index, message, notValid) => {
        let uploadingClone = [...this.state.uploading];
        let uploadingIndex = uploadingClone.map(elem => elem.index).indexOf(index);

        if (uploadingClone[uploadingIndex]) {
            uploadingClone[uploadingIndex].message = message;

            if (notValid) uploadingClone[uploadingIndex].notValid = true;
        }

        this.setState({
            uploading: uploadingClone
        });
    };

    // the upload request has returned data pertaining to the amount of data uploaded for the file
    handleProgress = async (e, index, override) => {
        if (!this.state.uploading || !this.state.uploading.length) return;

        let uploadingClone = [...this.state.uploading];
        let uploadingIndex = uploadingClone.map(elem => elem.index).indexOf(index);

        if (uploadingClone[uploadingIndex].timeRemainingInterval) {
            // handle the interval counting down the upload eta, clear the previous interval
            await this.handleTimeRemainingInterval(index, true);
        }

        if (override) {
            // if this function was called with override, manually change the progress value of the file obj
            uploadingClone[uploadingIndex].override = true;
            uploadingClone[uploadingIndex].progress = override;
            uploadingClone[uploadingIndex].timeRemaining = 0;            
        } else if (typeof uploadingIndex === 'number' && e.total && e.loaded) {
            // work out the current progress as a percentage and attach to the obj
            uploadingClone[uploadingIndex].progress = (e.loaded / e.total) * 100;
            uploadingClone[uploadingIndex].uploadComplete = uploadingClone[uploadingIndex].progress === 100;
            uploadingClone[uploadingIndex].uploaded = e.loaded;

            let timeElapsed = Date.now() - uploadingClone[uploadingIndex].timeStarted;
            // calculate the new upload speed
            let uploadSpeed = uploadingClone[uploadingIndex].uploaded / (timeElapsed / 1000);

            let valueToSet = Math.round((uploadingClone[uploadingIndex].totalSize - uploadingClone[uploadingIndex].uploaded) / uploadSpeed);
            if (valueToSet <= 0) valueToSet = 1;

            uploadingClone[uploadingIndex].timeRemaining = valueToSet;
        }

        this.setState({
            uploading: uploadingClone
        }, () => {
            if (!override) {
                // handle the interval counting down the upload eta, create a new interval
                this.handleTimeRemainingInterval(index);
            }
        });
    };

    // handles the upload eta interval
    handleTimeRemainingInterval = (index, clear) => new Promise(resolve => {
        let uploadingClone = [...this.state.uploading];
        let uploadingIndex = uploadingClone.map(elem => elem.index).indexOf(index);

        if (clear) {
            // clear the current interval for given file
            if (uploadingClone[uploadingIndex].timeRemainingInterval) {
                clearInterval(uploadingClone[uploadingIndex].timeRemainingInterval);
                uploadingClone[uploadingIndex].timeRemainingInterval = null;
            }
        } else {
            // a counter to track how many seconds the eta has been stuck at 1s remaining
            let beenAt1Counter = 0;

            // add the interval id to the obj in order to be cleared later
            uploadingClone[uploadingIndex].timeRemainingInterval = setInterval(() => {
                let uploadingCloneX = [...this.state.uploading];
                let uploadingIndexX = uploadingCloneX.map(elem => elem.index).indexOf(index);

                let valueToSet = uploadingCloneX[uploadingIndexX].timeRemaining - 1;

                if (valueToSet <= 1) {
                    beenAt1Counter++;

                    if (beenAt1Counter >= 5) {
                        // the counter has been at 1 for 5s, set value to 3 and reset counter
                        beenAt1Counter = 0;
                        valueToSet = 3;
                    } else {
                        valueToSet = 1;
                    };
                } else if (beenAt1Counter > 0) {
                    beenAt1Counter = 0;
                };

                uploadingCloneX[uploadingIndexX].timeRemaining = valueToSet;

                this.setState({
                    uploading: uploadingCloneX
                });
            }, 1000);
        }
        
        this.setState({
            uploading: uploadingClone
        }, resolve);
    });

    // handle the user removing a part
    handleRemovePart = async index => {
        let uploadingClone = [...this.state.uploading];
        let uploadingIndex = uploadingClone.map(elem => elem.index).indexOf(index);

        uploadingClone[uploadingIndex].deleted = true;

        this.setState({
            uploading: uploadingClone
        }, () => {
            if (uploadingClone[uploadingIndex].cancelExecutor && uploadingClone[uploadingIndex].progress < 100) {
                // the there is a cancel token and the part has not been full uploaded yet, cancel the request
                uploadingClone[uploadingIndex].cancelExecutor('Request aborted');
            } else {
                try {
                    handleApiReq(
                        'put',
                        '/crud',
                        {
                            action: 'DELETE_PART_AFTER_UPLOAD',
                            quote_id: this.props.quoteHash,
                            attach_parts_data: true,
                            part_to_delete: uploadingClone[uploadingIndex].name
                        }
                    );
                } catch (error) {
                    handleError('25244Zti', error, true);
                }
            }

            // if there is an interval id for the upload eta countdown then clear that
            if (uploadingClone[uploadingIndex].timeRemainingInterval) clearInterval(uploadingClone[uploadingIndex].timeRemainingInterval);
        });
    };

    // handle user clicking off fullscreen modal
    handleClickAway = e => {
        if (e.target.id === 'UploadDropZoneFullScreen') this.handleEnd();
    };

    handleEnd = async () => {
        // return the skeleton data so that we can track the progress of these parts
        this.props.handleUploadEnd(this.state.uploading.filter(item => !!item.skeletonData).map(item => item.skeletonData));
    };

    handleFailPart = async ({ upload_sk, upload_file_name }) => {
        try {
            await handleApiReq(
                'put',
                '/crud',
                {
                    action: 'FAIL_PART',
                    quote_id: this.props.quoteHash,
                    upload_sk,
                    upload_file_name
                }
            );
        } catch (error) {
            handleError('43505GKo', error, true);
        }
    };

    // user has clicked the don't show initial prompt button, add value to local storage
    handleDontShowInitialPrompt = () => {
        window.localStorage.setItem('_3dp_upload_initial_prompt', 'disable')

        this.setState({
            initialPrompt: false
        });
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    render() {
        const {
            uploading,
            dragActive,
            concurrentUploading,
            initialPrompt,
            validFileTypes
        } = this.state;

        let finishedUploading = !(!uploading.length || concurrentUploading.length || uploading.some(file => !file.deleted && file.progress < 100));

        if (!this.props.mode || !VALID_MODES.includes(this.props.mode)) return null;

        return (
            <React.Fragment>
                <div
                    onClick={!!(this.props.mode === 'fullscreen' && !!(!uploading.length || !uploading.some(part => !part.deleted))) ? this.handleClickAway : null}
                    id={clsx(this.props.mode === 'fullscreen' ? 'UploadDropZoneFullScreen' : null, this.props.mode === 'child' ? 'UploadDropZoneChild' : null)}
                    className={clsx( this.props.mode === 'fullscreen' ? 'FullHeightWidth' : null)}
                >
                    <Dropzone
                        onDrop={this.handleOnDrop}
                        onDragEnter={() => this.setState({ dragActive: true })}
                        onDragLeave={() => this.setState({ dragActive: false })}
                        noClick={true}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div
                                id={clsx(this.props.mode === 'fullscreen' ? 'UploadPartsWrapFullScreen' : null, this.props.mode === 'child' ? 'UploadPartsWrapChild' : null)}
                                {...getRootProps({className: dragActive ? 'dropzone DragActive' : 'dropzone', ref: this.wrapRef})}
                            >
                                {!this.props.hideHeader &&
                                    <div id="UploadPartsHeader">
                                        <div id="UploadingPartsHeaderStartWrap">
                                            {this.props.stepReference && <h4 className={clsx('Bold12', 'StepReference')}>{this.props.stepReference}</h4>}
                                            <h3 className={clsx('Bold16', 'StepTitle')}>{this.props.stepTitle}</h3>
                                        </div>

                                        {/* if there are files uploading indicate the total, the number failed and the number successful */}
                                        {!!(uploading.length && uploading.some(part => !part.deleted)) &&
                                            <div id="UploadingPartsHeaderEndWrap">
                                                <p className="Bold12">
                                                    {`${this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w) ? 'Uploaded: ' : ''}${uploading.filter(part => !part.message && !part.deleted && part.progress === 100).length} / ${uploading.filter(part => !part.deleted).length}`}
                                                    {(this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) && !!uploading.filter(part => !part.deleted).some(part => part.message) &&
                                                        <span>{` (${uploading.filter(part => part.message).length} Failed)`}</span>
                                                    }
                                                </p>

                                                <input
                                                    accept={isMobile ? '*' : validFileTypes && validFileTypes.length ? validFileTypes.join(', ') : '.stl'}
                                                    id="icon-button-file-2"
                                                    type="file"
                                                    multiple
                                                    hidden
                                                    onChange={e => this.handleUploadOnChange(e.target.files)}
                                                />

                                                <label id="UploadButtonLabel2" htmlFor="icon-button-file-2">
                                                    <button
                                                        type="button"
                                                        id="UploadButton2"
                                                        className="Button"
                                                    >
                                                        <UploadIcon
                                                            id="AddMorePartsButton"
                                                        />

                                                        {(this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) && <p className="Bold12">Add More Parts</p>}
                                                    </button>
                                                </label>
                                            </div>
                                        }

                                        {!!((!uploading.length || !uploading.some(part => !part.deleted)) && this.props.mode === 'fullscreen') &&
                                            <button
                                                onClick={this.handleEnd}
                                                id="CloseDropZoneButton"
                                            >
                                                <CloseIcon id="CloseDropZoneButtonIcon" />
                                            </button>
                                        }
                                    </div>
                                }

                                <div
                                    id={clsx(this.props.mode === 'fullscreen' ? 'UploadInfoWrapFullScreen' : null, this.props.mode === 'child' ? 'UploadInfoWrapChild' : null)}
                                    ref={this.uploadWrapRef}
                                    // change the style of the wrapper depending on if there are any files uploading
                                    className={!!(uploading.length && uploading.some(part => !part.deleted)) ? 'UploadInfoWrapUploading' : null}
                                >
                                    {(!uploading.length || !uploading.some(part => !part.deleted)) ?
                                        // there are no files uploading, show the upload button and the information regarding the uploads
                                        <div id="UploadInfo">
                                            {(this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) && <h3 className="Bold16">Drag and drop your files here or</h3>}

                                            <input
                                                accept={isMobile ? '*' : validFileTypes && validFileTypes.length ? validFileTypes.join(', ') : '.stl'}
                                                id="icon-button-file"
                                                type="file"
                                                multiple
                                                hidden
                                                onChange={e => this.handleUploadOnChange(e.target.files)}
                                            />

                                            <label id="UploadButtonLabel" htmlFor="icon-button-file">
                                                <button
                                                    type="button"
                                                    id="UploadButton"
                                                    className="Button"
                                                >
                                                    <p className="Bold16">Browse your computer</p>
                                                </button>
                                            </label>

                                            <input {...getInputProps()} />

                                            <div id="UploadInfoWrap">
                                                <p className="Reg12">Supported format{validFileTypes && validFileTypes.length && validFileTypes.length > 1 ? 's' : ''}: <span className="Bold12">{(validFileTypes && validFileTypes.length) ? validFileTypes.join(' ') : '.stl'}</span></p>
                                                <p className="Reg12">Maximum file size: <span className="Bold12">100mb</span></p>
                                                <p className="Reg12">Maximum files per session: <span className="Bold12">{this.props.fileLimit ? this.props.fileLimit : '~'}</span></p>
                                                <p className="Reg12">Maximum part size: <span className="Bold12">600 x 600 x 600 mm</span></p>
                                            </div>
                                        </div>
                                        // there are files uploading, list all files and their progress
                                        : <React.Fragment>
                                            <div id="UploadOverview">
                                                {!!uploading.length && uploading.map(file => {
                                                    // loop over each file and return its respective elem
                                                    const {
                                                        deleted,
                                                        name,
                                                        message,
                                                        progress,
                                                        totalSize,
                                                        index,
                                                        notValid,
                                                        started,
                                                        timeRemaining
                                                    } = file;

                                                    if (!deleted) return <UploadingPart
                                                        key={`uploading_part_${index}`}
                                                        name={name}
                                                        message={message}
                                                        windowBreakpoint={this.props.windowBreakpoint}
                                                        notValid={notValid}
                                                        progress={typeof progress === 'number' ? Math.round(progress) : null}
                                                        totalSize={convertBitsToMegabytes(totalSize)}
                                                        index={index}
                                                        started={started}
                                                        timeRemaining={timeRemaining}
                                                        handleRemovePart={this.handleRemovePart}
                                                    />
                                                    else return null;
                                                })}
                                            </div>

                                            {this.props.mode === 'fullscreen' &&
                                                <div id="DropzoneFullscreenContinueWrap">
                                                    <button
                                                        id="DropzoneFullscreenContinueButton"
                                                        onClick={finishedUploading && this.readyToProgress() && !initialPrompt ? this.handleEnd : null}
                                                        className={clsx('Bold16', !finishedUploading || !this.readyToProgress() || initialPrompt ? 'DropzoneFullscreenContinueButtonDisabled' : null)}
                                                    >
                                                        Continue
                                                    </button>
                                                </div>
                                            }
                                        </React.Fragment>
                                    }

                                    {this.props.mode === 'child' && (this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) &&
                                        <button
                                            id="DropzoneChildContinueButton"
                                            onClick={finishedUploading && this.readyToProgress() ? this.props.validEmail && !this.props.isAdmin ? this.props.handleNextStage : this.handleEnd : null}
                                            className={clsx('Bold16', !finishedUploading || !this.readyToProgress() || this.props.movingToNextStage ? 'DropzoneFullscreenContinueButtonDisabled' : null)}
                                        >
                                            Continue
                                        </button>
                                    }
                                </div>

                                {(finishedUploading && this.props.mode === 'fullscreen' && initialPrompt && this.readyToProgress()) &&
                                    <div id="DropzoneFullscreenInitialPromptWrap">
                                        <div id="DropzoneFullscreenInitialPrompt">
                                            <button
                                                id="DropzoneFullscreenInitialCloseButton"
                                                onClick={() => this.setState({
                                                    initialPrompt: false
                                                })}
                                            >
                                                <CloseIcon id="DropzoneFullscreenInitialCloseButtonIcon"/>
                                            </button>

                                            <p className='Reg12'>Continue back to the quote, or add more parts.</p>

                                            <button
                                                id="DropzoneFullscreenInitialContinueButton"
                                                onClick={finishedUploading && this.readyToProgress() ? this.handleEnd : null}
                                                className={clsx('Bold16', !finishedUploading || !this.readyToProgress() ? 'DropzoneFullscreenContinueButtonDisabled' : null)}
                                            >
                                                Continue
                                            </button>

                                            <input
                                                accept={isMobile ? '*' : validFileTypes && validFileTypes.length ? validFileTypes.join(', ') : '.stl'}
                                                id="icon-button-file-3"
                                                type="file"
                                                multiple
                                                hidden
                                                onChange={e => this.handleUploadOnChange(e.target.files, true)}
                                            />

                                            <label id="DropzoneFullscreenInitialAddMoreButtonLabel" htmlFor="icon-button-file-3">
                                                <button
                                                    type="button"
                                                    id="DropzoneFullscreenInitialAddMoreButton"
                                                    className={clsx('Bold16', !finishedUploading || !this.readyToProgress() ? 'DropzoneFullscreenContinueButtonDisabled' : null)}
                                                >
                                                    Add More Parts
                                                </button>
                                            </label>

                                            <p
                                                id="DropzoneFullscreenInitialDontShow"
                                                className='Reg10'
                                                onClick={this.handleDontShowInitialPrompt}
                                            >don't show this prompt again</p>
                                        </div>
                                    </div>
                                }
                            </div>
                        )}
                    </Dropzone>
                </div>

                {this.props.mode === 'child' && this.props.windowBreakpoint?.w <= 768 &&
                    <button
                        id="DropzoneChildContinueButton"
                        onClick={finishedUploading && this.readyToProgress() ? this.props.validEmail && !this.props.isAdmin ? this.props.handleNextStage : this.handleEnd : null}
                        className={clsx('Bold16', !finishedUploading || !this.readyToProgress() || this.props.movingToNextStage ? 'DropzoneFullscreenContinueButtonDisabled' : null)}
                    >
                        Continue
                    </button>
                }
            </React.Fragment>
        );
    };
}

export default UploadDropZone;