import React, {
    useEffect,
    useRef
} from 'react';

import _ from 'lodash';
import clsx from 'clsx';

import './AddressHandler.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import TextInput from '../TextInput/TextInput';
import SelectInput from '../SelectInput/SelectInput';

import CloseIcon from '@mui/icons-material/Close';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function AddressHandler(props) {
    const {
        which,
        address,
        email,
        loggedIn,
        open,
        handleOpenClose,
        mode,
        inputRefs,
        rootStyleOverwrite,
        noAddressInfo,
        handleApplyClick,
        addressElemInfo,
        warnings,
        countriesOptions,
        copyInitialValue,
        emptyWarning,
        billingEmailRef,
        billingEmailWarning,
        windowBreakpoint
    } = props;

    const copyCheckboxRef = useRef();

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const returnInput = ({ id, label, xtra, type, pattern, autoComplete, required, select, char_limit }, double) => {
        let refToUse = inputRefs ? el => inputRefs[which][id] = el : null;

        let rootStyleOverwrite = { marginBottom: 20, maxWidth: 'none !important' };
        if (mode === 'accountInfo') rootStyleOverwrite = { ...rootStyleOverwrite, width: windowBreakpoint?.w <= 768 ? '100%' : '40%' };
        if (double) rootStyleOverwrite = { ...rootStyleOverwrite, width: 'calc(50% - 10px)' };

        let writtenWarning = warnings && warnings.elems && warnings.elems[id] ? warnings.elems[id] : null;

        let defaultValue =
            !!(id === 'country' && address && address.country_name && address.country_code) ? { name: address.country_name, code: address.country_code }
            : '';

        if (select) {
            return (
                <SelectInput
                    label={label}
                    xtra={xtra}
                    type={type}
                    name={id}
                    required={required}
                    refToUse={refToUse}
                    defaultValue={defaultValue}
                    writtenWarning={writtenWarning}
                    options={id === 'country' ? countriesOptions : select}
                    rootStyleOverwrite={rootStyleOverwrite}
                />
            );
        } else {
            return (
                <TextInput
                    label={label}
                    xtra={xtra}
                    type={type}
                    name={id}
                    pattern={pattern}
                    autoComplete={autoComplete}
                    required={required}
                    refToUse={refToUse}
                    rootStyleOverwrite={rootStyleOverwrite}
                    writtenWarning={writtenWarning}
                    maxlength={char_limit}
                />
            );
        }
    };

    const returnEmailInput = () => {
        return (
            <TextInput
                disabled={loggedIn}
                label={'Email'}
                xtra={null}
                type={'text'}
                name={'email'}
                pattern={null}
                autoComplete={'off'}
                required={true}
                refToUse={billingEmailRef}
                rootStyleOverwrite={{ marginBottom: 20 }}
                writtenWarning={billingEmailWarning}
                maxlength={100}
            />
        );
    };

    // return group of p tags displaying set address information
    const collateInformation = () => {
        if (mode === 'quote' || mode === 'confirmation') {
            const {
                first_name,
                last_name,
                company,
                address_line_1,
                address_line_2,
                city,
                post_code,
                country_name
            } = address;

            return (
                <div
                    style={rootStyleOverwrite}
                    className="AddressInfoCollated UserInfoMulti ExtraLineHeight"
                >
                    <p className="Reg16">{first_name && last_name ? `${first_name} ${last_name}` : '-'}</p>
                    {company && <p className="Reg16">{company}</p>}
                    <p className="Reg16">{address_line_1 ? address_line_1 : '-'}{address_line_2 ? ', ' + address_line_2 : ''}</p>
                    <p className="Reg16">{city && post_code ? `${city}, ${post_code}` : '-'}</p>
                    <p className="Reg16">{country_name ? country_name : '-'}</p>
                </div>
            );
        } else if (mode === 'accountInfo') {
            const {
                first_name,
                last_name,
                company,
                address_line_1,
                address_line_2,
                city,
                post_code,
                state,
                country_name,
                phone_no
            } = address;

            let show = [
                !!(first_name && last_name),
                !!address_line_1,
                !!(city && post_code),
                !!country_name,
                !!phone_no
            ].some(x => x);

            return (
                <div
                    style={rootStyleOverwrite}
                    className="AddressInfoCollated UserInfoMulti"
                >
                    {show ?
                        (
                            <React.Fragment>
                                <p className="Reg16">{first_name && last_name ? `${first_name} ${last_name}` : '-'}</p>
                                {company && <p className="Reg16">{company}</p>}
                                <p className="Reg16">{address_line_1 ? address_line_1 : '-'}{address_line_2 ? ', ' + address_line_2 : ''}</p>
                                <p className="Reg16">{city && post_code ? `${city}, ${post_code}` : '-'}</p>
                                {state && <p className="Reg16">{state}</p>}
                                <p className="Reg16">{country_name ? country_name : '-'}</p>
                                <p className="Reg16">{phone_no ? phone_no : '-'}</p>
                            </React.Fragment>
                        ) : <p className="Reg16">-</p>
                    }
                </div>
            );
        } else {
            return null;
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const handleInitialEditValuesSet = () => {
        if (!inputRefs || !address || _.isEmpty(address)) return;
        for (const key in inputRefs[which]) {
            if (key === 'country') {
                inputRefs[which].country.value = { name: address.country_name, code: address.country_code }
            } else {
                inputRefs[which][key].value = address[key] ? address[key] : null;
            }
        }
    };

    const handleCheckboxLabelClick = () => {
        if (copyCheckboxRef && copyCheckboxRef.current) {
            copyCheckboxRef.current.checked = !copyCheckboxRef.current.checked
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        if (open && addressElemInfo) handleInitialEditValuesSet();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, addressElemInfo]);

    useEffect(() => {
        if (open && email && billingEmailRef && billingEmailRef.current) {
            billingEmailRef.current.value = loggedIn ? loggedIn.email : email;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, billingEmailRef, loggedIn, email]);

    useEffect(() => {
        if (open && copyCheckboxRef && copyCheckboxRef.current) copyCheckboxRef.current.checked = copyInitialValue;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [copyCheckboxRef, open]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    if (!addressElemInfo) return null;

    if (mode === 'quote') {
        if (open) {
            return (
                <form
                    autoComplete="on"
                    action=""
                    noValidate={true}
                    data-form-type="other"
                    style={rootStyleOverwrite}
                    className="EditAddressWrap"
                >
                    <CloseIcon
                        onClick={() => handleOpenClose(false)}
                        className="EditAddressClose"
                    />

                    <p className="Bold16">Add {which} address</p>

                    <div className="DoubleAddressInput">
                        {returnInput(addressElemInfo.first_name, true)}

                        {returnInput(addressElemInfo.last_name, true)}
                    </div>

                    {(which === 'billing' && !loggedIn) && returnEmailInput()}

                    {returnInput(addressElemInfo.company)}

                    {returnInput(addressElemInfo.address_line_1)}

                    {returnInput(addressElemInfo.address_line_2)}

                    {returnInput(addressElemInfo.city)}

                    {returnInput(addressElemInfo.country)}

                    <div className="DoubleAddressInput">
                        {returnInput(addressElemInfo.post_code, true)}

                        {returnInput(addressElemInfo.state, true)}
                    </div>

                    {returnInput(addressElemInfo.phone_no)}

                    {which === 'shipping' && returnInput(addressElemInfo.vat_number)}

                    <div className="AddressCopyOverWrap">
                        <input
                            ref={copyCheckboxRef}
                            className="Reg16"
                            type="checkbox"
                        ></input>
                        <p
                            className="Reg16"
                            onClick={handleCheckboxLabelClick}
                        >
                            Use as {which === 'billing' ? 'Shipping' : 'Billing'} Address
                        </p>
                    </div>

                    {(warnings && warnings.main) && <p className="Reg12 AddressMainWarning">{warnings.main}</p>}

                    <div className="AddressEndButtonWrap">
                        <button
                            type="button"
                            onClick={() => handleOpenClose(false)}
                            className="Bold16 AddressCancelButton"
                        >
                            Cancel
                        </button>

                        <button
                            type="button"
                            onClick={() => handleApplyClick(which, !!(copyCheckboxRef && copyCheckboxRef.current) ? copyCheckboxRef.current.checked : false )}
                            className="Bold16 AddressApplyButton"
                        >
                            Apply
                        </button>
                    </div>
                </form>
            );
        } else if (noAddressInfo) {
            return (
                <div
                    onClick={() => handleOpenClose(true)}
                    className={clsx('NoAddressButton', emptyWarning ? 'NoAddressWarning' : null)}
                >
                    {emptyWarning &&
                        <div className="EmptyWarningIconWrap">
                            <PriorityHighIcon className="EmptyWarningIcon"/>
                        </div>
                    }

                    <ControlPointIcon className="AddAddressIcon" />

                    <p className="Bold12">Add {which} address</p>
                </div>
            );
        } else {

            return (
                <div className="AddressWrap">
                    <h4 className="Bold16">{which === 'billing' ? 'Bill' : 'Ship'} to:</h4>

                    {collateInformation()}

                    <p
                        onClick={() => handleOpenClose(true)}
                        className="EditExistingAddress Bold12"
                    >
                        Edit
                    </p>
                </div>
            );
        }
    }

    if (mode === 'accountInfo') {
        if (open) {
            return (
                <form
                    autoComplete="on"
                    action=""
                    noValidate={true}
                    data-form-type="other"
                    style={rootStyleOverwrite}
                    className="EditAddressWrapAccountInfo"
                >
                    {returnInput(addressElemInfo.first_name)}

                    {returnInput(addressElemInfo.last_name)}

                    {returnInput(addressElemInfo.company)}

                    {returnInput(addressElemInfo.address_line_1)}

                    {returnInput(addressElemInfo.address_line_2)}

                    {returnInput(addressElemInfo.city)}

                    {returnInput(addressElemInfo.country)}

                    {returnInput(addressElemInfo.post_code)}

                    {returnInput(addressElemInfo.state)}

                    {returnInput(addressElemInfo.phone_no)}

                    {which === 'shipping' && returnInput(addressElemInfo.vat_number)}

                    {(warnings && warnings.main) && <p className="Reg12 AddressMainWarning">{warnings.main}</p>}
                </form>
            );
        } else {
            return collateInformation();
        }
    }

    if (mode === 'confirmation') {
        return (
            <div className="AddressWrap AddressWrapConf">
                <h4 className="Bold16">{which === 'billing' ? 'Bill' : 'Ship'} to:</h4>

                {collateInformation()}
            </div>
        );
    }

    return '-';
};