import React, {
    useContext,
    useState
} from 'react';

import './CookieNotice.css';
import General from "../../../context/general";

export default function CookieNotice(props) {
    const {
        accept,
        update,
        reset
    } = props;

    const {
        cookiesPolicy
    } = useContext(General)

    const [showManage, setShowManage] = useState(false);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            id="CookieNoticeWrap"
            className="FullHeightWidth"
        >
            <div id="CookieNotice">
                <h2 className="Bold30">This site uses cookies</h2>

                <p className="Reg16">
                    Our website uses essential cookies to function properly, including security, network management, and accessibility. We also use analytics cookies to collect information on how you use the website and improve the website performance. The information collected is anonymous and does not personally identify you. If that is okay, click “Accept All Cookies”. Click “Manage Preferences” to personalise your cookie settings.
                    <br></br>
                    <br></br>
                    By continuing to use our website, you consent to our use of cookies. For more information on our use of cookies, please refer to our <a target="_blank" rel="noreferrer" href={cookiesPolicy}>cookie policy page</a>.
                </p>

                {showManage &&
                    <div id="CookieNoticeManage">
                        <div>
                            <input type="checkbox" name="EssentialCookies" defaultChecked  disabled />
                            <label className="Reg16" htmlFor="EssentialCookies">Essential Cookies</label>
                        </div>

                        <div>
                            <input type="checkbox" name="AnalyticsCookies" defaultChecked onChange={(e) => update('analytics', e.target.checked)}/>
                            <label className="Reg16" htmlFor="AnalyticsCookies">Analytics Cookies</label>
                        </div>

                        <div>
                            <input type="checkbox" name="MarketingCookies" defaultChecked onChange={(e) => update('marketing', e.target.checked)}/>
                            <label className="Reg16" htmlFor="MarketingCookies">Marketing Cookies</label>
                        </div>
                    </div>
                }

                <div id="CookieNoticeButtonWrap">
                    <button className="Bold16" onClick={() => {
                        reset();
                        setShowManage(!showManage)
                    }}>{showManage ? 'Cancel Preferences' : 'Manage Preferences'}</button>
                    <button className="Bold16" onClick={accept}>{showManage ? 'Accept Personalised Cookies' : 'Accept All Cookies'}</button>
                </div>
            </div>
        </div>
    );
};