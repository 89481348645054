import React from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./Footer.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function Footer() {
    return (
        <div
            id="Footer"
        >
            <div id="FooterSectionsWrap">
                <div id="FooterSections" className="PageContentWidth">
                    <div className="FooterSection">
                        <h3 className="Bold16">Address</h3>
                        <a target="_blank" rel="noreferrer" href="https://maps.google.com/?q=3D+People+UK">
                            <p className="Reg14">3D People Limited</p>
                            <p className="Reg14">Unit 18 - 19</p>
                            <p className="Reg14">Nathan Way Business Park</p>
                            <p className="Reg14">Thamesmead, SE28 0FS</p>
                            <p className="Reg14">UK</p>
                        </a>
                    </div>

                    <div className="FooterSection">
                        <h3 className="Bold16">Opening Hours</h3>
                        <p className="Reg14">Monday - Friday</p>
                        <p className="Reg14">10am - 6pm</p>
                    </div>

                    <div className="FooterSection">
                        <h3 className="Bold16">Contact</h3>
                        <p className="Reg14">0203 051 2298</p>
                        <p className="Reg14"><a href="mailto:print@3dpeople.uk">print@3dpeople.uk</a></p>
                    </div>

                    <div className="FooterSection">
                        <h3 className="Bold16">Social</h3>
                        <p className="Reg14"><a href="https://facebook.com/3D.People.UK/">Facebook</a></p>
                        <p className="Reg14"><a href="https://www.instagram.com/3d.people.uk/">Instagram</a></p>
                        <p className="Reg14"><a href="https://www.linkedin.com/company/3d-people-uk/about/">Linkedin</a></p>
                    </div>
                </div>
            </div>

            <div id="BottomThemeBar"></div>
        </div>
    );
};