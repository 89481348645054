import React from 'react';
import {
    Switch,
    Route,
    Redirect
} from 'react-router-dom';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import Upload from '../Upload/Upload';
import Quote from '../Quote/Quote';
import Checkout from '../Checkout/Checkout';
import Confirmation from '../Confirmation/Confirmation';
import User from '../User/User';
import Login from '../Login/Login';
import UserTable from '../UserTable/UserTable';
import Challenge from '../Challenge/Challenge';
import ErrorBoundary from '../Misc/ErrorBoundary/ErrorBoundary';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./Main.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function Main(props) {
    const {
        build_env,
        loggedIn,
        checkUpdateUserSubResolved,
        handleNewSnackbar,
        isAdmin,
        handleAlienData,
        componentKeys,
        loading,
        setLoading,
        windowBreakpoint,
        toggleActiveModal,
        handleNewAlienMessage
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // before we initially determine if the user is logged in or not, don't render any main components, this happens when the app mounts
    // loggedIn is initially null, and is then set to falsey or truthy
    if (loggedIn === null) {
        return (
            <div
                id="Main"
                className="FullHeightWidth"
            >
            </div>
        );
    }

    return (
        <div
            id="Main"
            className="FullHeightWidth"
        >
            {/* switch handling all pages of the app */}
            <Switch>
                <Route
                    exact
                    path={'/upload'}
                >
                    <ErrorBoundary
                        componentWrapped="Upload"
                    >
                        <Upload
                            handleLoading={setLoading}
                            loading={loading}
                            loggedIn={loggedIn}
                            handleNewSnackbar={handleNewSnackbar}
                            isAdmin={isAdmin}
                            windowBreakpoint={windowBreakpoint}
                            toggleActiveModal={toggleActiveModal}
                        />
                    </ErrorBoundary>
                </Route>

                <Route
                    path={'/quote/:id'}
                    render={props => {
                        const { id } = props.match.params;

                        return (
                            <ErrorBoundary
                                componentWrapped="Quote"
                            >
                                <Quote
                                    key={!!(componentKeys && componentKeys.quote) ? 'ak_q_' + componentKeys.quote : null}
                                    handleLoading={setLoading}
                                    loading={loading}
                                    build_env={build_env}
                                    loggedIn={loggedIn}
                                    isAdmin={isAdmin}
                                    checkUpdateUserSubResolved={checkUpdateUserSubResolved}
                                    handleNewSnackbar={handleNewSnackbar}
                                    handleAlienData={handleAlienData}
                                    windowBreakpoint={windowBreakpoint}
                                    toggleActiveModal={toggleActiveModal}
                                    id={id}
                                />
                            </ErrorBoundary>
                        );
                    }}
                >
                </Route>

                <Route
                    path={'/checkout/:id'}
                    render={props => {
                        const { id } = props.match.params;

                        return (
                            <ErrorBoundary
                                componentWrapped="Checkout"
                            >
                                <Checkout
                                    handleLoading={setLoading}
                                    loading={loading}
                                    build_env={build_env}
                                    loggedIn={loggedIn}
                                    windowBreakpoint={windowBreakpoint}
                                    checkUpdateUserSubResolved={checkUpdateUserSubResolved}
                                    handleNewSnackbar={handleNewSnackbar}
                                    handleAlienData={handleAlienData}
                                    id={id}
                                />
                            </ErrorBoundary>
                        );
                    }}
                >
                </Route>

                <Route
                    path={'/confirmation/:id'}
                    render={props => {
                        const { id } = props.match.params;

                        return (
                            <ErrorBoundary
                                componentWrapped="Confirmation"
                            >
                                <Confirmation
                                    handleLoading={setLoading}
                                    loading={loading}
                                    loggedIn={loggedIn}
                                    handleNewSnackbar={handleNewSnackbar}
                                    handleAlienData={handleAlienData}
                                    windowBreakpoint={windowBreakpoint}
                                    toggleActiveModal={toggleActiveModal}
                                    id={id}
                                />
                            </ErrorBoundary>
                        );
                    }}
                >
                </Route>

                <Route
                    path={'/challenge/:w/:t/:id'}
                    render={props =>
                        <ErrorBoundary
                            componentWrapped="Challenge"
                        >
                            <Challenge
                                handleLoading={setLoading}
                                loading={loading}
                                loggedIn={loggedIn}
                                handleNewSnackbar={handleNewSnackbar}
                                windowBreakpoint={windowBreakpoint}
                                {...props.match.params}
                            />
                        </ErrorBoundary>
                    }
                >
                </Route>

                {loggedIn &&
                    <Route
                        path={'/user'}
                        render={props =>
                            <ErrorBoundary
                                componentWrapped="User"
                            >
                                <User
                                    handleLoading={setLoading}
                                    loggedIn={loggedIn}
                                    handleNewSnackbar={handleNewSnackbar}
                                    windowBreakpoint={windowBreakpoint}
                                    toggleActiveModal={toggleActiveModal}
                                    handleNewAlienMessage={handleNewAlienMessage}
                                    isAdmin={isAdmin}
                                    {...props.match.params}
                                />
                            </ErrorBoundary>
                        }
                    >
                    </Route>
                }

                {loggedIn &&
                    <Route
                        path={'/quotes'}
                        render={props =>
                            <ErrorBoundary
                                componentWrapped="Quotes"
                            >
                                <UserTable
                                    mode="quotes"
                                    loggedIn={loggedIn}
                                    handleNewSnackbar={handleNewSnackbar}
                                    windowBreakpoint={windowBreakpoint}
                                    toggleActiveModal={toggleActiveModal}
                                    handleNewAlienMessage={handleNewAlienMessage}
                                    isAdmin={isAdmin}
                                    {...props.match.params}
                                />
                            </ErrorBoundary>
                        }
                    >
                    </Route>
                }

                {loggedIn &&
                    <Route
                        path={'/orders'}
                        render={props =>
                            <ErrorBoundary
                                componentWrapped="Orders"
                            >
                                <UserTable
                                    mode="orders"
                                    loggedIn={loggedIn}
                                    handleNewSnackbar={handleNewSnackbar}
                                    windowBreakpoint={windowBreakpoint}
                                    toggleActiveModal={toggleActiveModal}
                                    isAdmin={isAdmin}
                                    {...props.match.params}
                                />
                            </ErrorBoundary>
                        }
                    >
                    </Route>
                }

                {/*
                    /login
                    - a route for the login page
                    - if the user is logged in already then redirect to /quotes
                    - once the user logs in or registers, they will be redirected to /quotes
                */}
                <Route
                    path={'/login'}
                    exact={true}
                    render={() => {
                        if (loggedIn) {
                            return <Redirect to="/quotes" />;
                        }

                        return (
                            <ErrorBoundary
                                componentWrapped="Login"
                            >
                                <Login
                                    handleLoading={setLoading}
                                    loggedIn={loggedIn}
                                    handleNewSnackbar={handleNewSnackbar}
                                    windowBreakpoint={windowBreakpoint}
                                />
                            </ErrorBoundary>
                        );
                    }}
                >
                </Route>

                <Redirect from="*" to="/upload" />
            </Switch>
        </div>
    );
};