import React, {
    useContext,
    useEffect
} from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    Checkbox,
    CircularProgress
} from '@mui/material';

import PoweredBy from './PoweredBy';

import {
    handlePushToDataLayer
} from '../../functions/utils';

import {
    PaymentElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';

import General from "../../context/general";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function CheckoutForm(props) {
    const {
        quoteId,
        billingDetails,
        agreeRef,
        handleSubmitPreChecks,
        handlePostSuccessfulPayment,
        handleMessage,
        message,
        isLoading,
        handleNavToConf
    } = props;

    const {
        termsAndConditions
    } = useContext(General);

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get(
            "payment_intent_client_secret"
        );

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case "succeeded":
                    handleMessage("Payment succeeded!");
                    break;
                case "processing":
                    handleMessage("Your payment is processing.");
                    break;
                case "requires_payment_method":
                    handleMessage(
                        "Your payment was not successful, please try again."
                    );
                    break;
                default:
                    handleMessage("Something went wrong.");
                    break;
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isLoading) return;

        if (!stripe || !elements) {
            return;
        }

        handlePushToDataLayer({
            event: 'payment_clicked',
            gateway: 'Stripe'
        });

        // send off stripe payload here
        const { error } = await stripe.confirmPayment({
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url: window.origin + `/confirmation/${quoteId}`,
                payment_method_data: {
                    billing_details: {
                        address: {
                            ...billingDetails
                        }
                    }
                }
            },
        });

        if (!!error && (error.type === "card_error" || error.type === "validation_error")) {
            handleMessage(error.message, true);
        } else if (!!error) {
            handlePushToDataLayer({
                event: 'error',
                error: 'failed to complete stripe payment',
                error_message: error.error_message
            });
            handleMessage("An unexpected error occurred.", true);
        } else {
            await handlePostSuccessfulPayment();

            handleNavToConf();
        }
    };

    const paymentElementOptions = {
        layout: "tabs",
        fields: {
            billingDetails: {
                address: 'never'
            }
        }
    };

    return (
        <form
            id="CheckoutForm"
            onSubmit={e => handleSubmitPreChecks(e, 'payment', null, handleSubmit)}
        >
            <div className="PaddedFormElem">
                <PaymentElement
                    id="PaymentElement"
                    options={paymentElementOptions}
                />
            </div>

            <div className="PaddedFormElem">
                <div id="CheckoutTerms">
                    <Checkbox
                        inputProps={{
                            ref: agreeRef,
                            defaultChecked: false
                        }}
                        size="medium"
                        classes={{
                            root: "QuoteOverlayWarningModalCheckbox"
                        }}
                    />

                    <p className="Reg12">I have read, understand and agree to the <a className="MainBlue" href={termsAndConditions} rel="noreferrer" target="_blank">Terms of Sale</a>. I confirm that my design complies with the terms.</p>
                </div>

                <button
                    id="CheckoutButton"
                    disabled={isLoading || !stripe || !elements}
                    className="Bold16"
                >
                    {!isLoading && 'Complete Payment'}
                    {isLoading && <CircularProgress
                        className="PaymentLoadingSpinner"
                        size={18}
                    />}
                </button>

                {message && <div className="Reg12" id="PaymentMessage">{message}</div>}

                <a id="StripeAnchor" href="https://stripe.com" rel="noreferrer" target="_blank">
                    <div id="PoweredBy">
                        <p>Powered by</p>

                        <PoweredBy
                            sizeToUse={40}
                            styleOverwrite={{
                                minWidth: '40px'
                            }}
                        />
                    </div>
                </a>
            </div>
        </form>
    );
};
