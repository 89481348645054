import React, {
    useEffect,
    useState,
    useRef
} from 'react';

import clsx from 'clsx';
import dateFormat from 'dateformat';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    setFavourite,
    cloneQuoteOrder,
    handleAlienHideQuoteOrder
} from '../../functions/user_functions';
import {
    handleApiReq,
    handleError,
    rando,
    clearRefValue
} from '../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    Menu,
    Tooltip
} from '@mui/material';

import LoadingSVG from '../Misc/LoadingSVG/LoadingSVG';
import MoreInfoI from '../Misc/MoreInfoI/MoreInfoI';
import ConditionalLink from '../Misc/ConditionalLink/ConditionalLink';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CancelIcon from '@mui/icons-material/Cancel';
import LockIcon from '@mui/icons-material/Lock';
import StarIcon from '@mui/icons-material/Star';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SyncIcon from '@mui/icons-material/Sync';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupsIcon from '@mui/icons-material/Groups';

import './UserTable.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function UserTable(props) {
    const {
        mode,
        handleNewSnackbar,
        windowBreakpoint,
        toggleActiveModal,
        isAdmin,
        handleNewAlienMessage
    } = props;

    const searchRef = useRef();
    const rowsPerPageRef = useRef();

    // value to represent the type of query being made
    const [queryMode, setQueryMode] = useState('default');
    // value to represent the type of search mode
    const [searchMode, setSearchMode] = useState('id');
    // boolean to indicate if the fetch on mount has occurred
    const [initialFetch, setInitialFetch] = useState(false);
    // is data loading for the table
    const [tableLoading, setTableLoading] = useState(false);
    // array for all the items data
    const [itemsData, setItemsData] = useState(null);
    // value for total number of items
    const [totalNoItems, setTotalNoItems] = useState('?');
    // value for rows per page, how many items will be returned in req, how many are visible in table at one time
    const [rowsPerPage, setRowsPerPage] = useState(10);
    // value for the last evaluated key returned from ddb, this will allow us to paginate the data correctly
    const [lastEvaluatedKey, setLastEvaluatedKey] = useState(null);
    // value for the page number seen on table
    const [page, setPage] = useState(0);
    // values of visited pages, use to prevent further requests from happening
    const [visitedPages, setVisitedPages] = useState([0]);
    // value to indicate end of data, disallows further pagination, endOfData will equal the page number where data ends
    const [endOfData, setEndOfData] = useState(false);
    // value to set options menu and it's anchor element
    const [itemOptionsOpen, setItemOptionsOpen] = useState(null);
    const [itemsToRecursivelyCheckTimeout, setItemsToRecursivelyCheckTimeout] = useState(null);
    // array of items to recursively check
    const [itemsToRecursivelyCheck, setItemsToRecursivelyCheck] = useState([]);
    // value to increment > triggering effect
    const [recursiveIncrementor, setRecursiveIncrementor] = useState(0);

    const [itemsToCheckWaitingRoom, setItemsToCheckWaitingRoom] = useState([]);

    const [assuming, setAssuming] = useState(false);

    const recursiveInProgressRef = useRef(false);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // handle the user switching modes
    const handleModeReset = () => {
        window.scrollTo({
            top: 0,
            left: 0
        });

        setQueryMode('default');
        setInitialFetch(false);
        setTableLoading(false);
        setItemsData(null);
        setTotalNoItems('?');
        setLastEvaluatedKey(null);
        setPage(0);
        setVisitedPages([0]);
        setEndOfData(false);

        handleOptionsClose();

        clearRefValue(searchRef);

        fetchItems(true, true);
        fetchTotals(true);
    };

    // fetch data for the table
    const fetchItems = async (reset, mode_reset, newItemToCheck) => {
        if (tableLoading) return;

        setTableLoading(true);

        if (reset) {
            setVisitedPages([0]);
            setPage(0);
            setLastEvaluatedKey(null);
            setEndOfData(false);
        }

        let value;
        if (queryMode === 'search' && !mode_reset) {
             value = searchRef && searchRef.current && searchRef.current.value ? searchMode === 'id' ? searchRef.current.value.trim().toUpperCase() : searchRef.current.value.trim() : false;
            if (!value || !value.length) return;
        }

        if (value) await fetchTotals();

        handleApiReq(
            'get',
            `/user-crud/user-table-data?m=${mode_reset ? 'default' : queryMode}&w=${mode === 'quotes' ? 0 : 1}${value ? `&v=${value}&sm=${searchMode}` : ''}&rpp=${rowsPerPage}${lastEvaluatedKey && !reset && !mode_reset ? `&lek=${JSON.stringify(lastEvaluatedKey)}` : ''}`
        ).then(result => {
            setTableLoading(false);

            if (result.success) {
                // if the quotes data was null then the initial fetch has happened
                if (itemsData === null || mode_reset) setInitialFetch(true);

                setItemsData(!itemsData || !itemsData.length || reset ? result.data.items_data : [...itemsData, ...result.data.items_data]);
                setLastEvaluatedKey(result.data.last_evaluated_key);

                if (!result.data.last_evaluated_key) {
                    setEndOfData(reset ? 0 : page);
                }

                if (newItemToCheck) {
                    if (itemsToRecursivelyCheck.length) {
                        setItemsToCheckWaitingRoom([...new Set([...itemsToCheckWaitingRoom, newItemToCheck])]);
                    } else {
                        setItemsToRecursivelyCheck([newItemToCheck]);
                    }
                }

                // if data loads and items have not finished init, add them to array
                let itemsToCheck = result.data.items_data.filter(item => item.finished_init_loading === false).map(item => item.uuid);
                if (itemsToCheck.length && !itemsToRecursivelyCheck.length && recursiveIncrementor === 0) {
                    setItemsToRecursivelyCheck([...new Set(itemsToCheck)]);
                }
            } else {
                handleNewSnackbar('something went wrong', 'error');
            }
        }).catch(error => {
            setTableLoading(false);
            handleError('18556nPb', error, true);
            handleNewSnackbar('something went wrong', 'error');
        });
    };

    // fetch value for total of matches against current req type
    const fetchTotals = async mode_reset => {
        let value;
        if (queryMode === 'search' && !mode_reset) {
             value = searchRef && searchRef.current && searchRef.current.value ? searchMode === 'id' ? searchRef.current.value.trim().toUpperCase() : searchRef.current.value.trim() : false;
            if (!value || !value.length) return;
        }

        setTotalNoItems('?');

        handleApiReq(
            'get',
            `/user-crud/user-table-total?m=${mode_reset ? 'default' : queryMode}&w=${mode === 'quotes' ? 0 : 1}${value ? `&v=${value}&sm=${searchMode}` : ''}`
        ).then(result => {
            if (result.success) {
                setTotalNoItems(result.data.no_items);
            } else {
                handleNewSnackbar('something went wrong', 'error');
            }
        }).catch(error => {
            handleError('52595wdv', error, true);
            handleNewSnackbar('something went wrong', 'error');
        });
    };

    // user has selected a rows per page value
    const handleRowsPerPageChange = e => {
        setRowsPerPage(parseInt(e.target.value));
    };

    // user has clicked on a page arrow
    const handlePageChange = next => {
        if (next) {
            setPage(page + 1);
        } else {
            if (page > 0) {
                setPage(page - 1);
            }
        }
    };

    // handles key down events on the input
    const handleSearchInput = e => {
        if (tableLoading) return;
        // if target key was enter, then proceed to search using the value
        if (e.code === 'Enter') {
            // if the user has emptied the search bar and pressed enter, we can assume that they want to revert to normal mode
            if (queryMode === 'search' && searchRef && searchRef.current && (!searchRef.current.value || !searchRef.current.value.length)) {
                // handleTerminateSearchMode();
                handleChangeQueryMode('default');
            } else {
                if (queryMode !== 'search') {
                    handleChangeQueryMode('search');
                } else {
                    fetchItems(true);
                }
            }
        }
    };

    // handle the user changing between query modes
    const handleChangeQueryMode = newMode => {
        if (queryMode === 'search' && searchRef && searchRef.current) searchRef.current.value = '';

        setItemsData([]);
        setQueryMode(newMode);
    };

    // handle user clicking on the options icon
    const handleOptionsClick = (e, data) => {
        e.target.classList.add('OpenActions');

        setItemOptionsOpen({
            anchor: e.target,
            data
        });
    };

    const handleOptionsClose = () => {
        if (itemOptionsOpen?.anchor) {
            itemOptionsOpen.anchor.classList.remove('OpenActions');
        }

        setItemOptionsOpen(null);
    };

    // options handler, takes a function to invoke and passes it a callback
    const handleOption = async (functionToInvoke, callback) => {
        if (!itemOptionsOpen || !itemOptionsOpen.data) return;
        handleOptionsClose();
        setTableLoading(true);

        // call the function given
        let invokeResult = await functionToInvoke(itemOptionsOpen.data);

        // when resolved invoke callback
        callback(invokeResult);
    };

    // if a label should be dynamic handle it here
    const handleDynamicLabel = id => {
        if (itemOptionsOpen && itemOptionsOpen.data) {
            if (id === 'favourite') {
                return itemOptionsOpen.data.favourite ? 'Unfavourite' : 'Favourite';
            }
        } else {
            return '...';
        }
    };

    // update specific row with new data after performing an update on it
    const updateRow = ({ success, data }) => {
        if (!success) {
            handleError('23568KKF', 'something went wrong', true);
            handleNewSnackbar('something went wrong', 'error');
            return;
        }

        let indexOfRow = itemsData.findIndex(item => item.uuid === data.uuid);
        if (indexOfRow !== -1) {
            let dataToMutate = [...itemsData];

            // target specific index
            dataToMutate[indexOfRow] = data;
            setItemsData(dataToMutate);
        }

        setTableLoading(false);
    };

    const removeRow = ({ success, body }) => {
        if (!success) {
            handleError('26368KKF', 'something went wrong', true);
            handleNewSnackbar('something went wrong', 'error');
            return;
        }

        let indexOfRow = itemsData.findIndex(item => item.uuid === body.quote_id);

        if (indexOfRow !== -1) {
            let dataToMutate = [...itemsData];

            if (isAdmin) {
                dataToMutate[indexOfRow].user_hidden = true;
            } else {
                dataToMutate.splice(indexOfRow, 1);
            }

            setItemsData(dataToMutate);
        }

        setTableLoading(false);
    };

    // handle show / hide of nickname modal
    const handleShowNicknameModal = () => {
        if (!itemOptionsOpen || !itemOptionsOpen.data) return;

        toggleActiveModal('nickname_modal', {
            uuid: itemOptionsOpen.data.uuid,
            initname: itemOptionsOpen.data.nickname,
            updateRow,
            setTableLoading
        });

        handleOptionsClose();
    };

    // handle the result from cloning
    const handleCloneResult = result => {
        if (result.success) {
            if (mode === 'quotes') {
                fetchItems(true, null, result.data.clone_uuid);
                fetchTotals(true);
            } else {
                setTableLoading(false);
            }

            handleNewSnackbar(`${mode === 'quotes' ? 'Quote' : 'Order'} successfully cloned`, 'success');

            // open item in new tab
            localStorage.setItem('expected_parts', JSON.stringify(result.data.skeleton_data));
            window.open(`${window.location.origin}/quote/${result.data.clone_uuid}`);
        } else {
            setTableLoading(false);

            handleNewSnackbar('something went wrong', 'error');
        }
    };

    // handle the recursive check
    const handleItemsToRecursiveCheck = async (terminate, from_recursion) => {
        try {
            // if terminating, set incrementor back to 0
            if (terminate) {
                setItemsToRecursivelyCheckTimeout(null);
                setRecursiveIncrementor(0);
                return;
            }

            // if no items length then return (shouldn't get here)
            if (!itemsToRecursivelyCheck.length) return;

            // make the req and await result
            let checkResult = await itemsToRecursiveCheckRequest(itemsToRecursivelyCheck);

            if (checkResult.success) {
                let itemsToRecursivelyCheckClone = [...itemsToRecursivelyCheck];
                let itemsDataClone = [...itemsData];

                // loop over each item returned
                // if an item was returned then processing has finished
                for (const item of checkResult.data) {
                    const {
                        uuid
                    } = item;

                    let itemsToRecursiveCheckIndex = itemsToRecursivelyCheckClone.indexOf(uuid);
                    let itemIndex = itemsDataClone.findIndex(item => item.uuid === uuid);

                    // if both indexes are valid > remove from itemsToRecursivelyCheck and overwrite correct index in itemsData
                    if (itemsToRecursiveCheckIndex !== -1 && itemIndex !== -1) {
                        itemsToRecursivelyCheckClone.splice(itemsToRecursiveCheckIndex, 1);
                        itemsDataClone[itemIndex] = item;
                    }
                }

                itemsToRecursivelyCheckClone = [...new Set([...itemsToRecursivelyCheckClone, ...itemsToCheckWaitingRoom])];

                // update the state
                setItemsToRecursivelyCheck(itemsToRecursivelyCheckClone);
                setItemsData(itemsDataClone);
                if (itemsToCheckWaitingRoom.length) {
                    let itemsToCheckWaitingRoomClone = [...itemsToCheckWaitingRoom];
                    let indexesToSplice = []

                    for (let i = 0; i < itemsToCheckWaitingRoomClone.length; i++) {
                        if (itemsToRecursivelyCheckClone.includes(itemsToCheckWaitingRoomClone[i])) indexesToSplice.push(i);
                    }

                    for (const index of indexesToSplice) {
                        itemsToCheckWaitingRoomClone.splice(index, 1);
                    }

                    setItemsToCheckWaitingRoom(itemsToCheckWaitingRoomClone);
                }

                // if there are still more items to check, increase the incrementor
                if (itemsToRecursivelyCheckClone.length) {
                    let timeoutToSet = setTimeout(() => setRecursiveIncrementor(recursiveIncrementor + 1), 2000);
                    setItemsToRecursivelyCheckTimeout(timeoutToSet);
                } else {
                    setItemsToRecursivelyCheckTimeout(null);
                    setRecursiveIncrementor(0);
                    recursiveInProgressRef.current = false;
                }
            } else {
                handleNewSnackbar('something went wrong', 'error');
            }
        } catch (error) {
            handleNewSnackbar('something went wrong', 'error');
        }
    };

    const itemsToRecursiveCheckRequest = arrOfIds => new Promise(async (resolve, reject) => {
        handleApiReq(
            'put',
            '/user-crud',
            {
                action: 'CHECK_WAITING_ITEMS',
                items: arrOfIds,
                attach_config_config: true
            }
        ).then(result => resolve(result)).catch(error => reject(error));
    });

    const handleInvoice = async () => {
        const {
            uuid,
            invoice_url
        } = itemOptionsOpen.data;

        if (invoice_url) {
            window.open(invoice_url);
            handleOptionsClose();
        } else {
            handleApiReq(
                'get',
                `/crud/invoice/${uuid}`
            ).then(result => {
                if (result.success && result.invoice_url) {
                    let itemsClone = [...itemsData];

                    let itemIndex = itemsClone.findIndex(item => item.uuid === uuid);
                    if (itemIndex !== -1) {
                        itemsClone[itemIndex].invoice_url = result.invoice_url + 'poop';

                        setItemsData(itemsClone);
                    }

                    window.open(result.invoice_url);
                } else {
                    handleNewSnackbar('Request took too long, please try again', 'warning');
                }
                handleOptionsClose();
            }).catch(() => {
                handleOptionsClose();
                handleNewSnackbar('Request took too long, please try again', 'warning');
            });
        }
    };

    const deleteQuote = async () => new Promise(async (resolve) => {
        const {
            uuid,
        } = itemOptionsOpen.data;

        handleApiReq(
            'put',
            `/crud`,
            {
                action: 'DELETE_QUOTE',
                quote_id: uuid,
                attach_quote_data: true
            }
        ).then(result => {
            resolve(result);
        }).catch(() => {
            resolve({ success: false });
            handleNewSnackbar('something went wrong', 'error');
        });
    });

    const returnSpecialStatusTooltip = which => {
        if (which === 'placed') {
            return <p>We do not update your order status here. For information on where your order is in the production flow, please email support at <a href="mailto:print@3dpeople.uk">print@3dpeople.uk</a></p>
        } else {
            return ''
        }
    };

    // handles the user clicking on the row, return and stop propagation if clicks on actions
    const handleRowLink = e => {
        if (e.target.classList.contains('UserTableRowActions') || e.target.classList.contains('UserTableRowOptionsIcon')) {
            e.stopPropagation()
            e.preventDefault()
            return;
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // return table elements

    // row
    const returnRow = (skeleton, row_id, row_data) => {
        let isSelected = row_data && itemOptionsOpen && itemOptionsOpen.data && itemOptionsOpen.data.uuid === row_data.uuid;

        return (
            <ConditionalLink
                condition={!skeleton}
                to={row_data?.uuid ? `/${mode === 'quotes' ? 'quote' : 'confirmation'}/${row_data.uuid}` : null}
                target="_blank"
                rel="noopener noreferrer"
                className="UserTableRowLink"
                key={row_id + 'UserTableRow'}
            >
                <div
                    className={clsx(
                        'UserTableRow',
                        isSelected ? 'UserTableRowSelected' : null,
                        skeleton ? 'UserTableRowIsSkeleton' : null,
                        row_data && row_data.admin_hidden ? 'UserTableRowAdminHidden' : null,
                        row_data && row_data.user_hidden ? 'UserTableRowUserHidden' : null
                    )}
                    onClick={handleRowLink}
                >
                    {row_data && row_data.finished_init_loading === false &&
                        <div className="UserTableRowLoading">
                            <LoadingSVG size={30} />
                        </div>
                    }

                    <div className="UserTableIconWrap">
                        {row_data && row_data.under_review &&
                            <Tooltip
                                arrow
                                title={`${mode === 'quotes' ? 'Quote' : 'Order'} is under review`}
                            >
                                <GroupsIcon className={clsx('UserTableRowIcon', 'UserTableUnderReview')}/>
                            </Tooltip>
                        }

                        {row_data && row_data.locked &&
                            <Tooltip
                                arrow
                                title={`${mode === 'quotes' ? 'Quote' : 'Order'} is locked`}
                            >
                                <LockIcon className={clsx('UserTableRowIcon', 'UserTableLocked')}/>
                            </Tooltip>
                        }

                        {row_data && row_data.favourite &&
                            <Tooltip
                                arrow
                                title={`${mode === 'quotes' ? 'Quote' : 'Order'} is a favourite`}
                            >
                                <StarIcon
                                    style={{ animation: `glow ${2 + (row_id * Math.random())}s ease-in-out -${row_id + (rando(0, 7) * Math.random())}s infinite` }}
                                    className={clsx('UserTableRowIcon', 'UserTableFavourite')}
                                />
                            </Tooltip>
                        }
                    </div>

                    {!skeleton && columns && columns.length && columns.filter(column => column.visible).map(column => column.returnContent(row_id, column.id, row_data, column.flex))}

                    {row_data && (row_data.admin_hidden || row_data.user_hidden) &&
                        <div className='UserTableRowAdminHiddenP'>{row_data.admin_hidden ? '👽' : ''}🤫: {row_data.admin_hidden ? 'admin' : 'user'}_hidden</div>
                    }
                </div>
            </ConditionalLink>
        );
    };

    // cells cells cells

    const returnIdContent = (row_id, column_id, { uuid, reference_number, nickname }, flex) => {
        return (
            <div
                key={`${row_id}${column_id}UserTableRowCell`}
                style={{ flex }}
                className="UserTableRowCell"
            >
                <p className={clsx('Bold14', 'UserTableRowIdCell')}>{reference_number}</p>
                {nickname && <p className={clsx('Reg12' , 'UserTableRowIdCell', 'UserTableRowIdCellNickname')}>{nickname}</p>}
            </div>
        );
    };

    const returnPPContent = (row_id, column_id, { no_parts, no_pieces, finished_init_loading }, flex) => {
        return (
            <div
                key={`${row_id}${column_id}UserTableRowCell`}
                style={{ flex }}
                className="UserTableRowCell"
            >
                <p className="Bold14">{finished_init_loading === false ? '-' : `${no_parts || 0} Parts`}</p>
                <p className="Reg12">{finished_init_loading === false ? '-' : `${no_pieces || 0} Pieces`}</p>
            </div>
        );
    };

    const returnDateContent = (row_id, column_id, { created_at, paid_at, last_updated }, flex) => {
        return (
            <div
                key={`${row_id}${column_id}UserTableRowCell`}
                style={{ flex }}
                className="UserTableRowCell"
            >
                <p className="Bold14">{dateFormat(mode === 'quotes' ? created_at : paid_at, 'dd/mm/yy H:MM')}</p>

                <p className="Reg12">{dateFormat(mode === 'quotes' ? last_updated : created_at, 'dd/mm/yy H:MM')}</p>
            </div>
        );
    };

    const returnStatusContent = (row_id, column_id, { status, status_tooltip, special_status_tooltip, expired, expires_at }, flex) => {
        return (
            <div
                key={`${row_id}${column_id}UserTableRowCell`}
                style={{ flex }}
                className="UserTableRowCell"
            >
                <div className="UserTableRowStatusCell">
                    <p className="Bold14">
                        {status}
                        {!!(status_tooltip || special_status_tooltip) &&
                            <MoreInfoI
                                tooltip={special_status_tooltip ? returnSpecialStatusTooltip(special_status_tooltip) : status_tooltip ? status_tooltip : ''}
                                delay={special_status_tooltip ? 500 : 0}
                                small={windowBreakpoint?.w <= 768}
                                styleOverwrite={{
                                    marginLeft: 5,
                                    position: 'relative',
                                    top: windowBreakpoint?.w <= 768 ? -1 : -2
                                }}
                            />
                        }
                    </p>

                    {!!(mode === 'quotes' && expires_at) && <p className="Reg12">Expire{expired ? 'd' : 's'}{windowBreakpoint?.w <= 768 ? ' ' : ' on '}{dateFormat(expires_at, 'dd/mm/yy')}</p>}
                </div>
            </div>
        );
    };

    const returnTotalContent = (row_id, column_id, { total, finished_init_loading }, flex) => {
        return (
            <div
                key={`${row_id}${column_id}UserTableRowCell`}
                style={{ flex }}
                className="UserTableRowCell"
            >
                <p className={clsx('Bold14', 'UserTableRowTotal')}>{finished_init_loading || finished_init_loading === undefined ? total : ''}</p>
            </div>
        );
    };

    const returnOptionsContent = (row_id, column_id, data, flex) => {
        return (windowBreakpoint?.w > 786 || isNaN(windowBreakpoint?.w)) ? (
            <div
                key={`${row_id}${column_id}UserTableRowCell`}
                style={{ flex }}
                className={clsx('UserTableRowCell', 'UserTableRowOptions', data.finished_init_loading === false ? 'UserTableRowOptionsDisabled' : '')}
                >
                    <div
                        className="UserTableRowActions"
                        onClick={e => data.finished_init_loading === false ? null : handleOptionsClick(e, data)}
                    >
                        <p className='Bold12'>Actions</p>

                        <ExpandMoreIcon
                            className={clsx('UserTableActionsIcon', itemOptionsOpen ? "OpenActionIcon" : null)}
                        />
                    </div>
            </div>
        ) : (
            <div
                key={`${row_id}${column_id}UserTableRowCell`}
                style={{ flex }}
                className={clsx('UserTableRowCell', 'UserTableRowOptions', data.finished_init_loading === false ? 'UserTableRowOptionsDisabled' : '')}
                >
                    <MoreVertIcon
                        onClick={e => data.finished_init_loading === false ? null : handleOptionsClick(e, data)}
                        className="UserTableRowOptionsIcon"
                    />
            </div>
        );
    };

    const returnOptionsMenuContent = () => {
        let defaultOptions = menuOptions.filter(option => option.validModes.includes(mode) && !option.disabled).map(option =>
            <div
                key={option.id + 'ItemOption'}
                onClick={option.functionToCall}
                className="UserTableOption"
            >
                <p className='Bold12'>{typeof option.label === 'function' ? option.label() : option.label}</p>

                {option.description &&
                    <p className='Reg12'>{option.description}</p>
                }
            </div>
        )

        let extraOptions = [];

        if (assuming && mode === 'quotes') {
            extraOptions.push(
                <div
                    key={'x_x' + 'ItemOption'}
                    onClick={itemOptionsOpen?.data ? async () => {
                        handleOptionsClose();
                        setTableLoading(true);

                        let result = await handleAlienHideQuoteOrder(itemOptionsOpen.data.uuid)

                        if (result.success) {
                            // update the row with new information
                            let indexOfRow = itemsData.findIndex(item => item.uuid === itemOptionsOpen.data.uuid);
                            let dataToMutate = [...itemsData];

                            dataToMutate[indexOfRow].admin_hidden = result.data;

                            setItemsData(dataToMutate);
                        }


                        handleNewAlienMessage(result.message);
                        setTableLoading(false);
                    } : null}
                    className={clsx('UserTableOption', 'UserTableOptionAlien')}
                >
                    <p className='Bold12'>toggle visibility</p>

                        <p className='Reg12'>toggle the visibility of this {mode === 'quotes' ? 'quote' : 'order'}</p>
                </div>
            )
        }

        let options = [...defaultOptions, ...extraOptions];

        return options;
    };

    const handleFavouriteSort = () => {
        if (queryMode === 'favourite') {
            setQueryMode('default');
        } else {
            setQueryMode('favourite');
        }
    };

    const handleSearchModeToggle = () => {
        setSearchMode(searchMode === 'id' ? 'nickname' : 'id');

        if (searchRef && searchRef.current) searchRef.current.value = '';
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // properties for cells / columns
    const columns = [
        {
            id: 'id',
            label: mode === 'quotes' ? 'Quote' : 'Order',
            flex: windowBreakpoint?.w <= 480 ? 4.5 : windowBreakpoint?.w <= 768 ? 4 : 2,
            returnContent: returnIdContent,
            icon: queryMode === 'favourite' ? <StarIcon id="UserTableSortStarIcon" className={clsx('UserTableMainHeaderIcon', 'UserTableSortStarIconFulfilled')} onClick={handleFavouriteSort} /> : <StarOutlineIcon id="UserTableSortStarIcon" className="UserTableMainHeaderIcon" onClick={handleFavouriteSort} />,
            icon_tooltip: queryMode === 'favourite' ? 'Cancel sort by favourite' : 'Sort by favourite',
            visible: true
        },
        {
            id: 'prts_pcs',
            label: 'Parts / Pieces',
            flex: 2,
            returnContent: returnPPContent,
            visible: windowBreakpoint?.w > 768 || isNaN(windowBreakpoint?.w)
        },
        {
            id: 'dates',
            label: mode === 'quotes' ? windowBreakpoint?.w <= 1024 ? 'Created' : 'Created / Updated' : windowBreakpoint?.w <= 1024 ? 'Paid' : 'Paid / Created',
            flex: windowBreakpoint?.w <= 768 ? 4 : 2.5,
            returnContent: returnDateContent,
            visible: windowBreakpoint?.w > 480 || isNaN(windowBreakpoint?.w)
        },
        {
            id: 'status',
            label: 'Status',
            flex: windowBreakpoint?.w <= 768 ? 4 : 2,
            returnContent: returnStatusContent,
            visible: true
        },
        {
            id: 'total',
            label: 'Total',
            flex: windowBreakpoint?.w <= 480 ? 3 : windowBreakpoint?.w <= 768 ? 2.5 : 2,
            returnContent: returnTotalContent,
            visible: true
        },
        {
            id: 'options',
            label: windowBreakpoint?.w <= 768 ? <SettingsIcon className='UserTableRowSettingsIcon' /> : 'Actions',
            align: windowBreakpoint?.w <= 768 ? 'center' : 'right',
            flex: windowBreakpoint?.w <= 768 ? 1 : 1.8,
            returnContent: returnOptionsContent,
            visible: true
        }
    ];

    // array of options for the table rows
    const menuOptions = [
        {
            validModes: ['quotes', 'orders'],
            id: 'favourite',
            description: 'Save this quote to your favourites',
            label: () => handleDynamicLabel('favourite'),
            functionToCall: () => handleOption(setFavourite, updateRow),
            // icon: <StarIcon className="UserTableOptionIcon" />
        },
        {
            validModes: ['quotes', 'orders'],
            id: 'nickname',
            description: 'Give this quote a nickname so it can be searched for via the search bar',
            label: `Nickname ${mode === 'quotes' ? 'quote' : 'order'}`,
            functionToCall: handleShowNicknameModal,
            // icon: <LocalOfferIcon className="UserTableOptionIcon" />
        },
        {
            validModes: ['quotes', 'orders'],
            id: 'clone',
            label: mode === 'quotes' ? 'Create a copy' : 'Re-order parts',
            description: mode === 'quotes' ? 'Copy your parts and specifications to a new quote' : 'Create a new quote with your parts from this order',
            functionToCall: () => handleOption(cloneQuoteOrder, handleCloneResult),
            // icon: <FileCopyIcon className="UserTableOptionIcon" />
        },
        {
            validModes: ['orders'],
            id: 'invoice',
            label: 'Download Invoice',
            description: 'Download your invoice',
            functionToCall: handleInvoice,
            // icon: <DescriptionIcon className="UserTableOptionIcon" />
        },
        {
            validModes: ['quotes'],
            id: 'delete',
            label: 'Delete quote',
            description: 'Delete your quote',
            functionToCall: () => handleOption(deleteQuote, removeRow),
            // icon: <FileCopyIcon className="UserTableOptionIcon" />
        },
    ];

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // on mount fetch data
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0
        });

        fetchItems(true);
        fetchTotals();

        // if timeout is active clear it
        return () => {
            if (itemsToRecursivelyCheckTimeout) clearTimeout(itemsToRecursivelyCheckTimeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // on mode change reset all values, this triggers on mount so only reset if initial fetch is true
    useEffect(() => {
        if (mode && initialFetch) {
            handleModeReset();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode]);

    useEffect(() => {
        if (queryMode && initialFetch) {
            fetchItems(true);
            fetchTotals();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryMode]);

    // on page change fetch data
    // if after initial fetch && data not loading and endOfData hasn't been determined
    useEffect(() => {
        if (!initialFetch) return;
        if (tableLoading || typeof endOfData === 'number') return;
        if (visitedPages.indexOf(page) === -1) {
            setVisitedPages([...visitedPages, page]);
            fetchItems();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    // on rows per page change reset && fetch data
    // if after initial fetch && data not loading and endOfData hasn't been determined
    useEffect(() => {
        if (!initialFetch) return;
        if (tableLoading) return;
        fetchItems(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage]);

    // handle itemsToRecursivelyCheck mutating, if length and checks not invoked then invoke, if no length and invoked then terminate
    useEffect(() => {
        if (itemsToRecursivelyCheck.length && !itemsToRecursivelyCheckTimeout && recursiveIncrementor === 0) {
            if (!recursiveInProgressRef.current) {
                recursiveInProgressRef.current = true;
                handleItemsToRecursiveCheck();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsToRecursivelyCheck]);

    // handle recursion incrementor
    useEffect(() => {
        if (itemsToRecursivelyCheck.length && itemsToRecursivelyCheckTimeout && recursiveIncrementor > 0) {
            if (recursiveInProgressRef.current) {
                handleItemsToRecursiveCheck(undefined, true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [recursiveIncrementor]);

    useEffect(() => {
        handleOptionsClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowBreakpoint]);

    useEffect(() => {
        let assuming = JSON.parse(localStorage.getItem('_alien_assumed_identity'));
        if (assuming && isAdmin) {
            setAssuming(true);
        }
    }, [isAdmin]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    let tableData = itemsData && itemsData.length ? itemsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : [];
    let noDataRows;
    let noDataRowsMax = rowsPerPage === 5 ? 5 : 10;
    if (tableData.length >= noDataRowsMax) {
        noDataRows = 0;
    } else {
        noDataRows = tableData ? noDataRowsMax - tableData.length : noDataRowsMax;
    }

    let prevPageDisabled = tableLoading || page <= 0;
    let nextPageDisabled = tableLoading || (typeof endOfData === 'number' && page >= endOfData);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <React.Fragment>
            <div className="Page">
                <div className="PageContentWrap">
                    {windowBreakpoint?.w <= 768 &&
                        <div id="UserTableHeader">
                            <div id="UserTableHeaderTitleWrap">
                                <h1 className="Bold30">{`My ${mode === 'quotes' ? 'Quotes' : 'Orders'}`}</h1>

                                <h3 className="Reg16">{mode === 'quotes' ? 'View your quotes, save favourites and give nicknames.' : 'View your orders, save favourites for re-ordering.'}</h3>
                            </div>

                            <div id="UserTableSearchWrap">
                                {queryMode === 'search' &&
                                    <CancelIcon
                                        id="UserTableSearchModeCancel"
                                        onClick={() => handleChangeQueryMode('default')}
                                    />
                                }

                                <Tooltip
                                    arrow
                                    title={`Toggle search to ${searchMode === 'id' ? 'Nickname' : `${mode === 'quotes' ? 'Quote' : 'Order'} ID`}`}
                                >
                                    <div
                                        id="UserTableSearchSwitch"
                                        onClick={handleSearchModeToggle}
                                    >
                                        <SyncIcon id="UserTableSearchSwitchIcon" />
                                    </div>
                                </Tooltip>

                                <input
                                    ref={searchRef}
                                    disabled={tableLoading}
                                    placeholder={searchMode === 'id' ? `${mode === 'quotes' ? 'Quote' : 'Order'} ID` : 'Nickname'}
                                    onKeyDown={handleSearchInput}
                                    className={clsx('Reg16', 'UserTableSearch', queryMode === 'search' ? 'UserTableSearchActive' : null, tableLoading ? 'UserTableSearchDisabled' : null)}
                                >
                                </input>
                            </div>
                        </div>
                    }

                    <div className="PageContent">
                        {(windowBreakpoint?.w > 768 || isNaN(windowBreakpoint?.w)) &&
                            <div id="UserTableHeader">
                                <div id="UserTableHeaderTitleWrap">
                                    <h1 className="Bold30">{`My ${mode === 'quotes' ? 'Quotes' : 'Orders'}`}</h1>

                                    <h3 className="Reg16">{mode === 'quotes' ? 'View your quotes, save favourites and give nicknames.' : 'View your orders, save favourites for re-ordering.'}</h3>
                                </div>

                                <div id="UserTableSearchWrap">
                                    {queryMode === 'search' &&
                                        <CancelIcon
                                            id="UserTableSearchModeCancel"
                                            onClick={() => handleChangeQueryMode('default')}
                                        />
                                    }

                                    <Tooltip
                                        arrow
                                        title={`Toggle search to ${searchMode === 'id' ? 'Nickname' : `${mode === 'quotes' ? 'Quote' : 'Order'} ID`}`}
                                    >
                                        <div
                                            id="UserTableSearchSwitch"
                                            onClick={handleSearchModeToggle}
                                        >
                                            <SyncIcon id="UserTableSearchSwitchIcon" />
                                        </div>
                                    </Tooltip>

                                    <input
                                        ref={searchRef}
                                        disabled={tableLoading}
                                        placeholder={searchMode === 'id' ? `${mode === 'quotes' ? 'Quote' : 'Order'} ID` : 'Nickname'}
                                        onKeyDown={handleSearchInput}
                                        className={clsx('Reg16', 'UserTableSearch', queryMode === 'search' ? 'UserTableSearchActive' : null, tableLoading ? 'UserTableSearchDisabled' : null)}
                                    >
                                    </input>
                                </div>
                            </div>
                        }

                        <div id="UserTableMain">
                            {tableLoading &&
                                <div id="UserTableLoading">
                                    <LoadingSVG />
                                </div>
                            }

                            <div id="UserTableMainHeadersWrap">
                                {!!(columns && columns.length) && columns.filter(column => column.visible).map((column, index) =>
                                    <div
                                        style={{ flex: column.flex, justifyContent: column.align || 'flex-start'}}
                                        key={index + 'ColumnHeader'}
                                        className="UserTableMainHeader"
                                    >
                                        <h3 className="Bold16">{column.label}</h3>

                                        {column.icon &&
                                            <Tooltip
                                                arrow
                                                title={column.icon_tooltip ? column.icon_tooltip : ''}
                                            >
                                                {column.icon}
                                            </Tooltip>
                                        }
                                    </div>
                                )}
                            </div>

                            {!!(tableData && tableData.length) && tableData.map((row, index) => returnRow(false, index, row))}
                            {!!(noDataRows && totalNoItems !== 0) && Array.apply(null, { length: noDataRows }).map((row, index) => returnRow(true, index))}
                            {!!(!tableLoading && totalNoItems === 0) && <div style={{ height: `${80 * rowsPerPage}px` }} id="UserTableNoData" className="Bold16">{`no ${mode === 'quotes' ? 'quotes' : 'orders'} found`}</div>}
                        </div>

                        <div id="UserTableSettingsWrap">
                            <p className="Bold12">Quotes:</p>

                            <p className="Bold12">{(page * rowsPerPage) + 1} - {((page + 1) * rowsPerPage) > totalNoItems ? totalNoItems : (page + 1) * rowsPerPage} of {totalNoItems}</p>

                            <div id="UserTableSettingsArrows">
                                <KeyboardArrowLeftIcon
                                    // if the table is loading OR page is less than or equal to 0 then disable
                                    onClick={prevPageDisabled ? null : () => handlePageChange(false)}
                                    className={clsx('UserTableSettingsIcon', prevPageDisabled ? 'UserTableSettingsDisabled' : null)}
                                />

                                <KeyboardArrowRightIcon
                                    // if the table is loading OR page is greater than or equal to end of data then disable
                                    onClick={nextPageDisabled ? null : () => handlePageChange(true)}
                                    className={clsx('UserTableSettingsIcon', nextPageDisabled ? 'UserTableSettingsDisabled' : null)}
                                />
                            </div>

                            <div id="UserTableSettingsRPP">
                                <p className="Bold12">Results per page:</p>

                                <select
                                    disabled={tableLoading}
                                    onChange={handleRowsPerPageChange}
                                    name="rows_per_page"
                                    defaultValue={"10"}
                                    ref={rowsPerPageRef}
                                    className={tableLoading ? 'UserTableSettingsDisabled' : null}
                                >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Menu
                open={!!itemOptionsOpen}
                onClose={handleOptionsClose}
                transitionDuration={{
                    enter: 200,
                    exit: 0
                }}
                anchorEl={itemOptionsOpen && itemOptionsOpen.anchor ? itemOptionsOpen.anchor : null}
                MenuListProps={{ style: { padding: 0 } }}
                id="UserTableOptionsMenu"
            >
                {returnOptionsMenuContent()}
            </Menu>
        </React.Fragment>
    );
};