import React from 'react';

const ColourElem = ({ hex, styleOverwrite }) => {
    const defaultStyles = {
        backgroundColor: hex,
        width: '100px',
        height: '100px',
        borderRadius: 3
    };

    const mergedStyles = styleOverwrite ? { ...defaultStyles, ...styleOverwrite } : defaultStyles;

    return <div style={mergedStyles}></div>;
};

export default ColourElem;
