import React, {
    useState,
    useEffect
} from 'react';

import clsx from 'clsx';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    callApiAndReturnCombinedResultsData
} from '../../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import Authenticator from '../../Authenticator/Authenticator';
import LoadingSVG from '../LoadingSVG/LoadingSVG';

import {
    Paper,
    Tooltip
} from '@mui/material';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import './UnderReview.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function UnderReview(props) {
    const {
        refNo,
        loggedIn,
        handleCopy,
        handleNewSnackbar
    } = props;

    const [loading, setLoading] = useState(false);
    const [industryOptions, setIndustryOptions] = useState(null);
    const [showAuthenticator, setShowAuthenticator] = useState(false);
    const [authCallbackData, setAuthCallbackData] = useState(null);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        if (!loggedIn) {
            setLoading(true);

            callApiAndReturnCombinedResultsData([
                {method: 'get', path: '/fetch-config/general-options'}
            ]).then(result => {
                setIndustryOptions(result.industry_options);
                setLoading(false);
            }).catch(() => {
                console.error('caught an error @ fetch general options');
                handleNewSnackbar('something went wrong', 'error');
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            id="UnderReviewWrap"
            className="FullHeightWidth"
        >
            <Paper
                elevation={6}
                id="UnderReview"
                className={showAuthenticator ? 'UnderReviewAuthenticator' : null}
            >
                {loading &&
                    <div id="UnderReviewLoading">
                        <LoadingSVG
                            size={50}
                        />
                    </div>
                }

                {!showAuthenticator &&
                    <React.Fragment>
                        <p className="Bold16">Thanks for submitting a Request for Quote</p>
                        <p className="Reg16">We are reviewing your quote {refNo}</p>
                        <p className="Reg16 MarginBottom">Expect to hear from us soon!</p>
                        <p className="Reg16 MarginBottom">In the meantime, if you would like to continue working on this quote, you can do so by making a copy.</p>

                        <div id="UnderReviewButtonWrap">
                            <Tooltip
                                arrow
                                title={!loggedIn && !authCallbackData?.success ? 'Please login to use this feature' : ''}
                            >
                                <button
                                    className={clsx('Bold16', 'MarginBottom', !loggedIn && !authCallbackData?.success ? 'CopyDisabled' : null)}
                                    onClick={loggedIn || authCallbackData?.success ? () => handleCopy(true) : null}
                                >
                                    Copy Quote
                                </button>
                            </Tooltip>

                            <p className="Reg16">or <a id="UnderReviewNewQuote" target="_blank" rel="noreferrer" href={window.location.origin + '/upload'}>Create a new quote</a></p>
                        </div>

                        <p className="Reg16">Track your request by
                            {
                                loggedIn || authCallbackData?.success ? <a id="UnderReviewNewQuote" target="_blank" rel="noreferrer" href={window.location.origin + '/quotes'}> viewing your quotes</a>
                                : <React.Fragment>
                                    <span onClick={() => setShowAuthenticator('register')}> creating an account</span> or <span onClick={() => setShowAuthenticator('login')}>logging in</span>
                                </React.Fragment>
                            }
                        </p>
                    </React.Fragment>
                }

                {showAuthenticator &&
                    <React.Fragment>
                        <p className="Bold14">{showAuthenticator === 'register' ? 'Register a 3D People account' : 'Enter your details to login'}</p>
                        <p className="Reg14 MarginBottom">{showAuthenticator === 'register' ? 'Complete the following steps' : ''}</p>

                        <Authenticator
                           childMode={showAuthenticator}
                           childModeCallback={data => {
                               setShowAuthenticator(false);
                               if (data?.success) {
                                   setAuthCallbackData(data)
                               }
                           }}
                           childModeBackMessage={showAuthenticator === 'register' ? "Don't register." : "Don't login."}
                           childModesetLoading={setLoading}
                           childModeSwitchCallback={setShowAuthenticator}
                           handleNewSnackbar={handleNewSnackbar}
                           industryOptions={industryOptions}
                           loggedIn={loggedIn}
                       />
                    </React.Fragment>
                }
            </Paper>
        </div>
    );
};