import * as React from "react";
import clsx from 'clsx';

const ThemeBar = (props) => (
    <div className={clsx(props.overflow ? 'banner-overflow' : null, 'banner-wrapper')}>
        <div className="mackerel-banner"></div>
    </div>
);

export default ThemeBar;
