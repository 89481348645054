import React from "react";

export default function WireframeIcon(props) {
    const { 
        size,
        rootStyle,
        className,
        id
    } = props;

    let width = size ? size + 4 : 26;
    let height = size ? size : 22;

    return (
        <svg
            width={width + 'px'}
            id={id}
            className={className}
            height={height + 'px'}
            viewBox={`0 0 26 22`}
            style={rootStyle}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M13.25 21.8341L1 14.9196L13.25 1L25.5 14.9196L13.25 21.8341Z"
                strokeWidth="0.25"
                strokeMiterlimit="10"
                strokeDasharray="0.75 0.75"
            />
            <path 
                d="M13.25 1V21.8341"
                strokeWidth="0.25"
                strokeMiterlimit="10"
                strokeDasharray="0.75 0.75"
            />
            <path 
                d="M25.5 14.9196L13.25 8.11401L1 14.9196"
                strokeWidth="0.25"
                strokeMiterlimit="10"
                strokeDasharray="0.75 0.75"
            />
        </svg>
    );
};
