import {
    Component
} from 'react';

import {
    withRouter
} from 'react-router-dom';

import {
    Hub
} from 'aws-amplify/utils';

import {
    getAuthenticatedUser
} from '../../functions/user_functions';

// import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    handleApiReq,
    handleError
} from '../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

class EventHub extends Component {
    constructor(props) {
        super(props);

        // listen to events across amplify auth
        Hub.listen('auth', data => {
            const {
                payload
            } = data;

            this.onAuthEvent(payload);
        });
    };

    async componentDidMount() {
        try {
            await this.handleSetUserState(true);
        } catch (error) {
            this.props.setLoggedIn(false);
        }
    };

    // is called on all auth events
    async onAuthEvent(payload) {
        const {
            event
        } = payload;

        // user signed in, get data and set to app state
        if (event === 'signedIn' || event === 'signInWithRedirect') {
            try {
                this.props.handleLoading(true);

                let isAdmin = await this.handleSetUserState(false, true);

                // if the user has signed in on either quote or confirmation pages, check to see if we need to update the specific quote user_sub values
                let match = /\/(?:quote|confirmation|checkout)\/(\w+)$/.test(this.props.location.pathname) ? this.props.location.pathname.match(/\/(?:quote|confirmation|checkout)\/(\w+)$/)[1] : false;
                // if the user is on the upload page / check the local storage for the quote_id
                if (!match && /\/upload/.test(this.props.location.pathname)) {
                    let idFromStorage = localStorage.getItem('qid');
                    if (idFromStorage) match = idFromStorage;
                }

                if (match && !isAdmin) {
                    handleApiReq(
                        'put',
                        '/crud',
                        {
                            action: 'CHECK_UPDATE_USER_SUB',
                            quote_id: match,
                            attach_config_data: true
                        }
                    ).then(() => {
                        this.props.handleLoading(false);
                        this.props.setCheckUpdateUserSubResolved(true);
                    }).catch(error => {
                        this.props.handleLoading(false);
                        handleError('21415aff', error);
                    });
                }

                if (!!isAdmin) {
                    this.props.handleLoading(false);
                    this.props.handleNewAlienMessage(`welcome back ${isAdmin.name || '...'}`);
                }

                this.props.handleLoading(false);
            } catch (error) {
                this.props.handleLoading(false);
                this.props.handleNewSnackbar('something went wrong', 'error');
            }
        // user logged out, clear data from app state
        } else if (event === 'signedOut') {
            this.props.setLoggedIn(false);
            this.props.handleNewSnackbar('logout successful');
            localStorage.clear();
            this.props.history.push('/upload');
            this.props.history.go();
        // user updated attributes (although I think this will be called any change is made to the user account), set the new data in app state
        } else if (event === 'tokenRefresh') {
            this.props.handleLoading(false);
            await this.handleSetUserState(false);
        };
    };

    // handle setting user values in the state
    // returns boolean if admin or not
    async handleSetUserState(initSet, snackbar) {
        try {
            const {
                attributes,
                apiXtra
            } = await getAuthenticatedUser();

            this.props.setLoggedIn({ ...attributes });

            // fetch the groups from the backend
            if (apiXtra === 'd') {
                this.props.setIsAdmin(true);
                return {
                    name: attributes['custom:full_name'] || attributes.email || null
                };
            } else {
                if (snackbar) this.props.handleNewSnackbar('login successful');
                return false;
            }
        } catch (error) {
            if (initSet) this.props.setLoggedIn(false);
            if (!initSet) this.props.handleNewSnackbar('something went wrong', 'error');
            return false;
        }
    };

    // render no elements
    render() {
        return null;
    };
}

export default withRouter(EventHub);