import React from "react";

import clsx from 'clsx';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./QuotePartSkeleton.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    Skeleton
} from '@mui/material';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function QuotePartSkeleton(props) {
    const {
        index,
        message,
        id,
        isLastPart,
        windowBreakpoint,
        converting
    } = props;

    return (
        <div key={`quote_part_skeleton` + id} className={clsx('QuotePartSkeletonRoot', isLastPart ? 'QuotePartSkeletonLastPart' : null)}>
            <div
                key={`quote_part_skeleton_div` + id}
                className={clsx('QuotePartSkeletonLoadingWrap', isLastPart ? 'QuotePartSkeletonLastPart' : null)}
            >
                {!!message &&
                    <div className={clsx(
                        'QuotePartSkeletonLoadingStatus',
                        converting ? 'QuotePartSkeletonStatusConverting' : null
                    )}>
                        <p>{message}</p>
                        <p></p>
                    </div>
                }

                <div className={converting ? 'QuotePartSkeletonLoadingBarConverting' : null}>
                    {converting && <div style={{
                        animation: `converting 1.8s ease-in-out ${1 - (3 / (index + 1) )}s infinite`
                    }}></div>}
                </div>
            </div>

            <div
                key={`quote_part_skeleton_loading` + id}
                className="QuotePartSkeleton"
            >
                {(windowBreakpoint?.w > 768 || isNaN(windowBreakpoint?.w)) &&
                    <React.Fragment>
                        {(windowBreakpoint?.w > 1200 || isNaN(windowBreakpoint?.w)) &&
                            <React.Fragment>
                                <h4 className="QuotePartSkeletonIndex Bold12">{index + 1}.</h4>

                                <Skeleton
                                    variant="rounded"
                                    height={130}
                                    width={130}
                                    sx={{ borderRadius: 1 }}
                                    className="SkeletonThumbnail"
                                />
                            </React.Fragment>
                        }

                        {windowBreakpoint?.w <= 1200 &&
                            <div className="SkeletonIndexThumbnail">
                                <h4 className="QuotePartSkeletonIndex Bold12">{index + 1}.</h4>

                                <Skeleton
                                    variant="rounded"
                                    height={130}
                                    width={130}
                                    sx={{ borderRadius: 1 }}
                                    className="SkeletonThumbnail"
                                />
                            </div>
                        }

                        <div className="SkeletonInfoWrap">
                            <Skeleton variant="text" sx={{ fontSize: 16 }} width={'80%'} />
                            <Skeleton variant="text" sx={{ fontSize: 12 }} width={'70%'} />
                            <Skeleton variant="text" sx={{ fontSize: 12 }} width={'15%'} />
                            <Skeleton variant="text" className="SkeletonCloneDeleteApply" sx={{ fontSize: 12 }} width={'35%'} />
                            <Skeleton variant="text" className="SkeletonCloneDeleteApply" sx={{ fontSize: 12 }} width={'50%'} />
                        </div>
                    </React.Fragment>
                }

                {windowBreakpoint?.w <= 768 &&
                    <div className="SkeletonIndexThumbnailInfo">
                        <div className="SkeletonIndexThumbnail">
                            <h4 className="QuotePartSkeletonIndex Bold12">{index + 1}.</h4>

                            <Skeleton
                                variant="rounded"
                                height={110}
                                width={110}
                                sx={{ borderRadius: 1 }}
                                className="SkeletonThumbnail"
                            />
                        </div>

                        <div className="SkeletonInfoWrap">
                            <Skeleton variant="text" sx={{ fontSize: 16 }} width={'100%'} />
                            <Skeleton variant="text" sx={{ fontSize: 12 }} width={'70%'} />
                            <Skeleton variant="text" sx={{ fontSize: 12 }} width={'15%'} />
                            <Skeleton variant="text" className="SkeletonCloneDeleteApply" sx={{ fontSize: 12 }} width={'35%'} />
                            <Skeleton variant="text" className="SkeletonCloneDeleteApply" sx={{ fontSize: 12 }} width={'50%'} />
                        </div>
                    </div>
                }

                <div className="SkeletonSetButtonWrap">
                    <Skeleton variant="rounded" sx={{ borderRadius: 1 }} height={'100%'} width={'100%'} />

                    {windowBreakpoint?.w <= 1024 &&
                        <Skeleton variant="rounded" className="SkeletonQuantityInput" sx={{ borderRadius: 1 }} height={'45%'} width={30} />
                    }
                </div>

                {(windowBreakpoint?.w > 768 || isNaN(windowBreakpoint?.w)) &&
                    <div className="SkeletonsQuantityPriceWrap">
                        {(windowBreakpoint?.w > 1024 || isNaN(windowBreakpoint?.w)) &&
                            <div className="SkeletonsQuantityWrap">
                                <Skeleton variant="text" className="SkeletonQuantityText" sx={{ fontSize: 12 }} width={60} />
                                <Skeleton variant="rounded" className="SkeletonQuantityInput" sx={{ borderRadius: 1 }} height={'45%'} width={100} />
                                <Skeleton variant="text" className="SkeletonQuantityBulkText" sx={{ fontSize: 12 }} width={105} />
                            </div>
                        }

                        <div className="SkeletonsPriceWrap">
                            <Skeleton variant="text" sx={{ fontSize: 12 }} className="SkeletonPriceElem" width={windowBreakpoint?.w <= 1200 ? 130 : 160} />
                            <Skeleton variant="text" sx={{ fontSize: 12 }} className="SkeletonPriceElem" width={windowBreakpoint?.w <= 1200 ? 130 : 160} />
                            <Skeleton variant="text" sx={{ fontSize: 12 }} className="SkeletonPriceElem" width={windowBreakpoint?.w <= 1200 ? 130 : 160} />
                            <Skeleton variant="text" sx={{ fontSize: 16 }} className="SkeletonPriceElem" width={windowBreakpoint?.w <= 1200 ? 130 : 160} />
                        </div>
                    </div>
                }

                {windowBreakpoint?.w <= 768 &&
                    <div className="SkeletonsDoublePriceWrap">
                        <div className="SkeletonsPriceWrap">
                            <Skeleton variant="text" sx={{ fontSize: 12 }} className="SkeletonPriceElem" width={70} />
                            <Skeleton variant="text" sx={{ fontSize: 12 }} className="SkeletonPriceElem" width={65} />
                            <Skeleton variant="text" sx={{ fontSize: 12 }} className="SkeletonPriceElem" width={70} />
                            <Skeleton variant="text" sx={{ fontSize: 16 }} className="SkeletonPriceElem" width={50} />
                        </div>

                        <div className="SkeletonsPriceWrap">
                            <Skeleton variant="text" sx={{ fontSize: 12 }} className="SkeletonPriceElem" width={60} />
                            <Skeleton variant="text" sx={{ fontSize: 12 }} className="SkeletonPriceElem" width={20} />
                            <Skeleton variant="text" sx={{ fontSize: 12 }} className="SkeletonPriceElem" width={70} />
                            <Skeleton variant="text" sx={{ fontSize: 16 }} className="SkeletonPriceElem" width={80} />
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};