import React, {
    useEffect,
    useState,
    useRef
} from 'react';

import {
    useHistory
} from 'react-router-dom';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    handleApiReq,
    validateEmailAddress,
    getSetBiscuit
} from '../../functions/utils';
import {
    attemptLogout
} from '../../functions/user_functions';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import Authenticator from '../Authenticator/Authenticator';
import LoadingSVG from '../Misc/LoadingSVG/LoadingSVG';
import TextInput from '../Misc/TextInput/TextInput';

import CloseIcon from '@mui/icons-material/Close';

import './Challenge.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function Challenge(props) {
    const {
        w,
        t,
        id,
        loggedIn,
        handleLoading,
        handleNewSnackbar,
        windowBreakpoint
    } = props;

    const history = useHistory();
    const emailInputRef = useRef();

    const [loading, setLoading] = useState(false);
    const [emailWarning, setEmailWarning] = useState(null);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // handle the callback from authenticator
    const handleChallengeCallback = () => {
        let where = w === 'q' ? 'quote'
            : w === 'c' ? 'confirmation'
            : w === 'ch' ? 'checkout'
            : null;

        if (where === null) {
            history.push('/upload');
        } else {
            history.push(`/${where}/${id}`);
        }
    };

    // handle email verify
    const attemptEmailVerify = () => {
        if (!emailInputRef || !emailInputRef.current) {
            return;
        }

        let value = emailInputRef.current.value.trim();
        if (!value || !value.length) {
            setEmailWarning('please provide an email address');
            return;
        }

        if (!validateEmailAddress(value)) {
            setEmailWarning('please provide a valid email address');
            return;
        }

        handleLoading(true);

        setEmailWarning(false);

        handleApiReq(
            'post',
            '/crud',
            {
                action: 'VERIFY_EMAIL',
                attach_quote_data: true,
                quote_id: id,
                val: value.toLowerCase()
            }
        ).then(result => {
            if (result.success && result.data && result.data.cookie_to_set) {
                getSetBiscuit(result.data.cookie_to_set);
                handleChallengeCallback();
            } else {
                handleLoading(false);
                setEmailWarning('email address does not match');
            }
        }).catch(error => {
            handleLoading(false);
            setEmailWarning('email address does not match');
        });
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // on mount if specific info is missing then redirect to upload
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0
        });

        if (!w || !id) history.push('/upload');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            id="Challenge"
            className={(windowBreakpoint?.w > 480 || isNaN(windowBreakpoint?.w)) ? 'FullHeightWidth' : ''}
        >
            <div id="ChallengeContentWrap">
                <div id="ChallengeContent">
                    {loading &&
                        <div id="ChallengeLoading">
                            <LoadingSVG size={50} />
                        </div>
                    }

                    <CloseIcon
                        id="ChallengeClose"
                        onClick={() => history.push('/upload')}
                    />

                    {t === 'u' && !loggedIn &&
                        <React.Fragment>
                            <p className="Bold14">You must login to proceed.</p>
                            <p className="Reg12">This page is linked to a user account.</p>

                            <Authenticator
                                childMode={'login'}
                                childModeCallback={handleChallengeCallback}
                                disableModeSwitch={true}
                                childModeSetLoading={setLoading}
                                noGuest={true}
                                handleNewSnackbar={handleNewSnackbar}
                                loggedIn={loggedIn}
                            />

                            <br></br>
                            <hr></hr>
                            <br></br>

                            <p className="Reg12">You are currently trying to access a page associated with a user of the 3D People application. If this is your account, please log in to view the page.</p>
                        </React.Fragment>
                    }

                    {t === 'u' && !!loggedIn &&
                        <React.Fragment>
                            <p className="Bold14">Access Denied</p>
                            <br></br>
                            <p className="Reg12">You are currently trying to access a page associated with a user of the 3D People application.</p>
                            <br></br>
                            <p className="Reg12">If this is your account, please log out and login with the correct account to view the page.</p>

                            <button id="ChallengeLogout" className="Bold16" onClick={attemptLogout}>LOGOUT</button>

                            <br></br>
                        </React.Fragment>
                    }

                    {t === 'n' &&
                        <React.Fragment>
                            <p className="Reg12">To access your quote, please provide the associated email address.</p>
                            <br></br>

                            <TextInput
                                label="Email address"
                                name="email"
                                type="text"
                                autoComplete="email"
                                warning={!!emailWarning}
                                writtenWarning={emailWarning}
                                maxlength={100}
                                refToUse={emailInputRef}
                            />

                            <button id="ChallengeEmail" className="Bold16" onClick={attemptEmailVerify}>submit email</button>

                            <br></br>
                        </React.Fragment>
                    }

                    <br></br>
                    <p className="Reg12">Contact support at print@3dpeople.uk if you are unable to access your {t === 'u' ? 'account' : 'quote'}.</p>
                </div>
            </div>
        </div>
    );
};