import React, {
    Component,
    createRef
} from 'react';

import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import clsx from 'clsx';
import _ from 'lodash';

import {
    withRouter,
    Link
} from 'react-router-dom';

import {
    Skeleton,
    Tooltip
} from '@mui/material';

import {
    callApiAndReturnCombinedResultsData,
    handleApiReq,
    handleError,
    validateEmailAddress,
    handlePushToDataLayer
} from '../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import TextInput from '../Misc/TextInput/TextInput';
import CheckoutForm from './CheckoutForm';
import PurchaseOrder from './PurchaseOrder';
import MoreInfoI from '../Misc/MoreInfoI/MoreInfoI';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import './Checkout.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

class Checkout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            payButtonDisabled: true,
            loading: true,
            stripePromise: null,
            stripeCS: null,
            billingDetails: null,
            selectedPaymentOption: 'stripe',
            orderReference: null,
            checkoutMessage: null,
            purchaseOrderInputWarnings: null,
            pONumberWarning: null,
            paymentLoading: false,
            pOLoading: false
        };

        this.orderRefRef = createRef();
        this.agreeRef = createRef();
    };

    componentDidMount() {
        this.init();
    };

    init = () => {
        window.scrollTo({
            top: 0,
            left: 0
        });

        this.props.handleLoading(true);

        handleApiReq(
            'get',
            `/crud/checkout/${this.props.id}`
        ).then(resultX => {
            const {
                paid,
                valid,
                expired
            } = resultX.data.quote_data;
            if (paid) {
                this.props.history.push(`/confirmation/${this.props.id}`);
            } else if (!valid || expired) {
                this.props.history.push(`/quote/${this.props.id}`);
            } else {
                this.setState({
                    data: resultX.data.quote_data,
                    upgrade_data: resultX.data.upgrade_data
                }, () => {
                    callApiAndReturnCombinedResultsData([
                        {method: 'get', path: '/fetch-config/stripe-public-key'},
                        {method: 'get', path: '/fetch-config/trade-approval-link'},
                        {method: 'get', path: `/crud/checkout-config/${this.props.id}`}
                    ]).then(result => {
                        let stripePromise;
                        try {
                            stripePromise = loadStripe(result.stripe_public_key);
                        } catch (error) {
                            console.log('error creating stripe promise', error);
                        }

                        this.props.handleAlienData({
                            quote_id: this.props.id,
                            que: this.state.data.email,
                            ahZzZ: this.state.data.admin_hidden || this.state.data.user_hidden
                        });

                        if (stripePromise) {
                            this.setState({
                                loading: false,
                                stripePromise,
                                stripeCS: result.stripe_config.cs,
                                orderReference: result.stripe_config.order_ref,
                                billingDetails: result.stripe_config.billing_details,
                                applyLink: result.trade_approval_link
                            }, () => {
                                this.props.handleLoading(false);

                                if (this.state.orderReference && this.orderRefRef && this.orderRefRef.current) this.orderRefRef.current.value = this.state.orderReference;
                            });
                        } else {
                            this.props.handleLoading(false);
                            this.props.handleNewSnackbar('something went wrong', 'error');
                        }
                    }).catch(error => {
                        console.log('error', error);
                        handleError('8602hgj', error || 'something went wrong', true);
                        this.props.history.push(`/upload`);
                    });
                });
            }
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.challenge && error.response.data.challenge_type) {
                this.props.handleLoading(false);
                this.props.history.push(`/challenge/ch/${error.response.data.challenge_type}/${error.response.data.challenge}`);
            } else {
                handleError('56816nUB', error || 'something went wrong', true);
                // redirect to upload page
                this.props.history.push(`/upload`);
            }
        });
    }

    handlePaymentOptionSelect = which => {
        this.setState({
            selectedPaymentOption: which,
            checkoutMessage: null,
            purchaseOrderInputWarnings: null,
            pONumberWarning: null
        });
    };

    handleSubmitPreChecks = (e, which, refs, callback) => {
        e.preventDefault();

        if (this.state.paymentLoading) return;

        this.setState({
            checkoutMessage: null,
            purchaseOrderInputWarnings: null,
            pONumberWarning: null,
            paymentLoading: true
        }, async () => {
            try {
                if (!this.agreeRef.current.checked) {
                    this.handleMessage('Please agree to the terms and conditions', true);
                    return;
                }

                let orderRefValue = this.orderRefRef.current.value ? this.orderRefRef.current.value.trim() : null
                if (orderRefValue && orderRefValue.length && orderRefValue !== this.state.orderReference) {
                    await handleApiReq(
                        'put',
                        '/crud',
                        {
                            action: 'UPDATE_USER_SET_REFERENCE',
                            quote_id: this.props.id,
                            val: orderRefValue
                        }
                    );

                    this.setState({
                        orderReference: orderRefValue
                    });
                }

                let valuesToSubmit;

                let validatedResult = await handleApiReq(
                    'get',
                    `/crud/checkout-validated/${this.props.id}`,
                );
                if (!validatedResult.valid) {
                    this.props.history.push(`/quote/${this.props.id}`);
                    return;
                }

                if (which === 'purchase_order') {
                    let refsError;
                    for (const key in refs) {
                        if (!refs[key] || !refs[key].current) {
                            refsError = true;
                            break;
                        }
                    }

                    if (refsError) {
                        this.handleMessage('Something went wrong', true);
                        return;
                    }

                    const {
                        firstNameRef,
                        lastNameRef,
                        emailRef,
                        purchaseOrderPDFRef
                    } = refs;

                    let warningsToSet = {};

                    let firstNameValue = firstNameRef.current.value.trim();
                    if (!firstNameValue || !firstNameValue.length) {
                        warningsToSet.firstName = 'Please enter a value';
                    } else if (firstNameValue.length > 50) {
                        warningsToSet.firstName = 'First name has to be 50 characters or less';
                    }

                    let lastNameValue = lastNameRef.current.value.trim();
                    if (!lastNameValue || !lastNameValue.length) {
                        warningsToSet.lastName = 'Please enter a value';
                    } else if (lastNameValue.length > 50) {
                        warningsToSet.lastName = 'Last name has to be 50 characters or less';
                    }

                    let emailValue = emailRef.current.value.trim();
                    if (!emailValue || !emailValue.length) {
                        warningsToSet.email = 'Please enter an accounts email address';
                    } else if (emailValue.length > 50) {
                        warningsToSet.email = 'Accounts email address has to be 100 characters or less';
                    } else if (!validateEmailAddress(emailValue)) {
                        warningsToSet.email = 'Please enter a valid accounts email address';
                    }

                    // eslint-disable-next-line no-useless-escape
                    let pOKeyRegExp = new RegExp(`${this.props.id}\/purchase_order_${this.props.id}\.pdf`);
                    if (!pOKeyRegExp.test(purchaseOrderPDFRef.current.value)) {
                        warningsToSet.purchaseOrderPDF = 'Please upload a purchase order (pdf)';
                    }

                    let orderRefWarning;
                    if (!orderRefValue || !orderRefValue.length) {
                        orderRefWarning = 'Please enter a order reference / po number'
                    }

                    if (_.isEmpty(warningsToSet) && !orderRefWarning) {
                        valuesToSubmit = {
                            first_name: firstNameValue,
                            last_name: lastNameValue,
                            accounts_email: emailValue,
                            purchase_order_key: purchaseOrderPDFRef.current.value
                        };
                    } else {
                        this.handleMessage('Please fill out all of the required fields', true);
                        this.setState({
                            purchaseOrderInputWarnings: warningsToSet,
                            pONumberWarning: orderRefWarning
                        });
                        return;
                    }
                }

                callback(e, valuesToSubmit);
            } catch (error) {
                this.handleMessage('Something went wrong', true);
                this.props.handleNewSnackbar('something went wrong', 'error');
            }
        });
    };

    handlePostSuccessfulPayment = () => new Promise(async (resolve, reject) => {
        handleApiReq(
            'get',
            `/crud/checkout-event-data/${this.props.id}`
        ).then(result => {
            if (result.success) {
                handlePushToDataLayer(result.data, true);
            }

            resolve();
        }).catch(error => {
            console.error('error caught return post success data', error);
            resolve();
        });
    });

    handleMessage = (string, stopLoading) => {
        this.setState(stopLoading ? {
            checkoutMessage: string,
            paymentLoading: false
        } : {
            checkoutMessage: string
        });
    };

    handleSuccessfulPOUpload = (original_name, key) => {
        this.setState({
            data: {
                ...this.state.data,
                purchase_order: {
                    original_name,
                    key
                }
            }
        });
    };

    handleUpgrade = () => {
        this.setState({
            loading: true
        }, () => {
            handleApiReq(
                'put',
                '/crud',
                {
                    action: 'UPDATE_QUOTE_LEAD_TIME',
                    quote_id: this.props.id,
                    attach_quote_data: true,
                    attach_config_data: true,
                    val: 'exp',
                    checkout: true
                }
            ).then(() => {
                this.init();
            }).catch(error => {
                this.props.handleLoading(false);
                handleError('ds9088dc', error);
            });
        })
    }

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    render() {
        const {
            data,
            loading,
            stripePromise,
            stripeCS,
            selectedPaymentOption,
            billingDetails,
            checkoutMessage,
            purchaseOrderInputWarnings,
            pONumberWarning,
            paymentLoading,
            applyLink,
            pOLoading
        } = this.state;

        const priceBreakdown = data ? data.price_breakdown : null;

        return (
            <div id="Checkout">
                <div id="CheckoutContentWrap">
                    <div id="CheckoutContent">
                        <div className="CheckoutHalf">
                            <div>
                                {loading &&
                                    <React.Fragment>
                                        <Skeleton
                                            variant="text"
                                            sx={{ fontSize: 16 }}
                                            width={100}
                                            className="QuoteSkeletonLinkBack"
                                        />

                                        <Skeleton
                                            height={58 * 4}
                                            width="100%"
                                            variant="rounded"
                                            sx={{
                                                borderRadius: 1
                                            }}
                                        />

                                        <Skeleton
                                            height={58 * 6}
                                            width="100%"
                                            variant="rounded"
                                            sx={{
                                                borderRadius: 1,
                                                marginTop: '25px'
                                            }}
                                        />
                                    </React.Fragment>
                                }

                                {!loading &&
                                    <React.Fragment>
                                        <div
                                            id="CheckoutSummaryTop"
                                            className="CheckoutSummarySection"
                                        >
                                            <Link
                                                to={`/quote/${this.props.id}`}
                                                id="CheckoutLinkToQuote"
                                                className="MainBlue Bold16"
                                            >
                                                <KeyboardBackspaceIcon
                                                    className="LinkBackIcon"
                                                />
                                                <p>Edit Quote</p>
                                            </Link>

                                            <div className="CheckoutSummaryElem">
                                                <h3 className="Bold20 TonesBlack">Order Summary</h3>

                                                <p className="Bold16">{data.reference_number}</p>
                                            </div>

                                            <div className="CheckoutSummaryElem">
                                                <p className="Reg16">Parts</p>
                                                <p className="Bold16">{data && data.parts_pieces ? data.parts_pieces : '-'}</p>
                                            </div>

                                            <div className="CheckoutSummaryElem">
                                                <p className="Reg16">Service <span><MoreInfoI
                                                    returnTooltip={() => (
                                                        <React.Fragment>
                                                            <p className="TonesBlack">What are production lead times?</p>
                                                            <p>The production lead time is the time it takes to manufacture your parts and apply finishes.</p>
                                                            <br></br>
                                                            <p className="TonesBlack">What are working days?</p>
                                                            <p>Lead times are in working days: Monday to Friday, excluding weekends and public holidays.</p>
                                                            <br></br>
                                                            <p className="TonesBlack">Is there a cut off time?</p>
                                                            <p>The cut off time for orders is 2 PM on a working day. Orders placed after 2 PM start from the next working day.</p>
                                                        </React.Fragment>
                                                    )}
                                                /></span></p>
                                                <p className="Bold16">{data && data.clt ? data.clt : data.service ? data.service : '-'}</p>
                                            </div>

                                            <div className="CheckoutSummaryElem">
                                                <p className="Reg16">Est Delivery By <span><MoreInfoI
                                                    returnTooltip={() => (
                                                        <React.Fragment>
                                                            <p>Your order is estimated to:</p>
                                                            <br></br>
                                                            <p>Ship by: <span className="TonesBlack">{data && data.production_estimate ? data.production_estimate : '-'}</span></p>
                                                            <p>Your order will leave our factory on or before this date.</p>
                                                            <br></br>
                                                            <p>Delivered by: <span className="TonesBlack">{data && data.delivery_estimate ? data.delivery_estimate : '-'}</span></p>
                                                            <p>{data && data.delivery_estimate ? 'Your estimated delivery date is based on the shipping date and the estimated delivery time.' : 'Please enter your shipping address to get a delivered by date.'}</p>
                                                            <br></br>
                                                            <p>You can edit the delivery date by changing production lead times, material finishes and shipping options.</p>
                                                        </React.Fragment>
                                                    )}
                                                /></span></p>
                                                <p className="Bold16">{data && data.delivery_estimate ? data.delivery_estimate : '-'}</p>
                                            </div>
                                        </div>

                                        <div
                                            id="CheckoutSummaryMiddle"
                                            className="CheckoutSummarySection"
                                        >
                                            <div className="CheckoutSummaryElem">
                                                <p className="Reg16">Items sub-total</p>
                                                <p className="Bold16">{priceBreakdown && priceBreakdown.subtotal ? priceBreakdown.subtotal : '-'}</p>
                                            </div>

                                            <div className="CheckoutSummaryElem">
                                                <p className="Reg16">{this.props.windowBreakpoint?.w > 1200 || isNaN(this.props.windowBreakpoint?.w) ? 'Minimum' : 'Min'} order value surcharge <span><MoreInfoI
                                                    returnTooltip={() => (
                                                        <React.Fragment>
                                                            <p>Each order takes time to prepare, manufacture, quality check and package by the 3D People team.</p>
                                                            <br></br>
                                                            <p>Regardless of the size of your order, there is a minimum time spent working on it.</p>
                                                            <br></br>
                                                            <p>The minimum order cost of {!!(priceBreakdown && priceBreakdown.moc_rate) ? priceBreakdown.moc_rate : '£30'} applies to all orders and does not include fees for shipping.</p>
                                                        </React.Fragment>
                                                    )}
                                                /></span></p>
                                                <p className="Bold16">{priceBreakdown && priceBreakdown.moc_total ? priceBreakdown.moc_total : '-'}</p>
                                            </div>

                                            {/* colour setup fees */}
                                            {!!(priceBreakdown && priceBreakdown?.colour_setup_splits.length) && priceBreakdown.colour_setup_splits.sort((a, b) => a.setup_cost - b.setup_cost).map((split, index) =>
                                                <div
                                                    key={'CheckoutSummaryElemColour' + index + '_split'}
                                                    className="CheckoutSummaryElem CheckoutSummaryElemColour"
                                                >
                                                    {!split.sales_team &&
                                                        <div
                                                            className="CheckoutSummaryElemColourPreview"
                                                            style={{
                                                                backgroundColor: split.hex,
                                                                border: split.hex.startsWith('#FFF') ? 'var(--border-main)' : 'none'
                                                            }}
                                                        />
                                                    }
                                                    <div className="CheckoutSummaryElemColourLabel">
                                                        <Tooltip
                                                            title={split.label}
                                                            arrow
                                                        >
                                                            <p className="Reg16">{split.label}</p>
                                                        </Tooltip>
                                                        <MoreInfoI
                                                            returnTooltip={() => (
                                                                <React.Fragment>
                                                                    <p>A fee is applied for each
                                                                        colour dye used in your
                                                                        order to cover setup
                                                                        costs.</p>
                                                                    <br></br>
                                                                    <p>The fees are clearly
                                                                        displayed as you select
                                                                        finishes and itemised in
                                                                        your quote summary.</p>
                                                                </React.Fragment>
                                                            )}
                                                            styleOverwrite={{
                                                                position: 'relative',
                                                                top: -3
                                                            }}
                                                        />
                                                    </div>
                                                    <p className="Bold16">{split.setup_cost}</p>
                                                </div>
                                            )}

                                            <div className="CheckoutSummaryElem">
                                                <p className="Reg16">{data && data.delivery ? data.delivery : '-'}</p>
                                                <p className="Bold16">{priceBreakdown && priceBreakdown.shipping ? priceBreakdown.shipping : '-'}</p>
                                            </div>

                                            <div className="CheckoutSummaryElem">
                                                <p className="Reg16">Order sub-total</p>
                                                <p className="Bold16">{priceBreakdown && priceBreakdown.order_subtotal ? priceBreakdown.order_subtotal : '-'}</p>
                                            </div>

                                            <div className="CheckoutSummaryElem">
                                                <p className="Reg16">{priceBreakdown && priceBreakdown.vat_rate ? priceBreakdown.vat_rate : '-'}</p>
                                                <p className="Bold16">{priceBreakdown && priceBreakdown.vat_total ? priceBreakdown.vat_total : '-'}</p>
                                            </div>

                                            <div className="CheckoutSummaryElem">
                                                <p className="Reg16">Total</p>
                                                <p className="Bold20 MainBlue">{priceBreakdown && priceBreakdown.total_incl_vat ? priceBreakdown.total_incl_vat : '-'}</p>
                                            </div>
                                        </div>

                                        {this.state.upgrade_data &&
                                            <React.Fragment>
                                                <p className={clsx('Bold16', 'CheckoutUpgradeP')}>Need it before {this.state.upgrade_data.curr_date}?</p>

                                                <div className='CheckoutUpgradeWrap'>
                                                    <p className='Reg16'>Upgrade your quote to express for {this.state.upgrade_data.difference} and receive your order by: <span className='Bold16'>{this.state.upgrade_data.upgrade_date}</span>.</p>

                                                    <button
                                                        onClick={this.handleUpgrade}
                                                        className={clsx('CheckoutUpgradeButton', 'Bold16')}
                                                    >
                                                        {this.props.windowBreakpoint?.w <= 480 ? '⚡️ Express Service ⚡️' : '⚡️ Upgrade to Express Service ⚡️'}
                                                    </button>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }
                            </div>
                        </div>

                        <div className="CheckoutHalf">
                            <div>
                                {loading &&
                                    <Skeleton
                                        height={770}
                                        width="100%"
                                        variant="rounded"
                                        className="CheckoutSkeletonForm"
                                        sx={{
                                            borderRadius: 1,
                                            marginBottom: '108px'
                                        }}
                                    />
                                }

                                {!loading &&
                                    <div id="CheckoutPaymentOptions">
                                        <div id="CheckoutOptionSelect">
                                            <div
                                                onClick={pOLoading || paymentLoading ? null : () => this.handlePaymentOptionSelect('stripe')}
                                                className={clsx('CheckoutOptionTab', selectedPaymentOption === 'stripe' ? 'CheckoutOptionSelected' : '', pOLoading || paymentLoading ? 'CheckoutOptionDisabled' : '')}
                                                style={{
                                                    borderBottomRightRadius: selectedPaymentOption !== 'stripe' ? '3px' : 0
                                                }}
                                            >
                                                <p className="Bold16">{this.props.windowBreakpoint?.w <= 480 ? 'Pay by card' : 'Pay by card'}</p>
                                                <p className="Reg16">{this.props.windowBreakpoint?.w <= 480 ? 'Make payment today' : 'Make payment today'}</p>
                                            </div>

                                            <div
                                                onClick={pOLoading || paymentLoading ? null : () => this.handlePaymentOptionSelect('purchase_order')}
                                                className={clsx('CheckoutOptionTab', selectedPaymentOption === 'purchase_order' ? 'CheckoutOptionSelected' : '', pOLoading || paymentLoading ? 'CheckoutOptionDisabled' : '')}
                                            >
                                                <p className="Bold16">{this.props.windowBreakpoint?.w <= 480 ? 'Submit a PO' : 'Submit a Purchase Order'}</p>
                                                <p className="Reg16">{this.props.windowBreakpoint?.w <= 480 ? 'Pay later with NET30' : 'Pay later with NET30 terms'}</p>
                                            </div>
                                        </div>

                                        {!(selectedPaymentOption === 'purchase_order' && (!this.props.loggedIn || !this.props.loggedIn['custom:trade_account'] || this.props.loggedIn['custom:trade_account'] === '0')) &&
                                            <div id="CheckoutPOInputWrap">
                                                <p className="Bold16 TonesBlack">Your Order Reference / PO Number <span className="Reg16">{selectedPaymentOption === 'purchase_order' ? '' : '(optional)'}</span></p>
                                                <TextInput
                                                    label={'Add your company’s internal PO number or a custom reference to your order.'}
                                                    labelStyleOverwrite={{ marginBottom: 8 }}
                                                    required={selectedPaymentOption === 'purchase_order'}
                                                    writtenWarning={pONumberWarning}
                                                    warning={!!pONumberWarning}
                                                    disabled={paymentLoading || pOLoading}
                                                    refToUse={this.orderRefRef}
                                                />
                                            </div>
                                        }

                                        <div id="CheckoutPaymentMethodWrap">
                                            {
                                                selectedPaymentOption === 'stripe' ?
                                                    <React.Fragment>
                                                        {stripeCS && !!stripePromise &&
                                                            <Elements
                                                                options={{
                                                                    clientSecret: stripeCS,
                                                                    appearance: {
                                                                        theme: 'stripe',
                                                                        variables: {
                                                                            colorPrimary: '#005FFE',
                                                                            colorBackground: '#FFFFFF',
                                                                            colorText: '#666666',
                                                                            colorDanger: '#CC0018',
                                                                            colorDangerText: '#CC0018',
                                                                            fontFamily: "'Montserrat', sans-serif",
                                                                            borderRadius: '3px',
                                                                            outline: 'none'
                                                                        },
                                                                        rules: {
                                                                            '.Error': {
                                                                                fontSize: '12px',
                                                                                fontWeight: 400
                                                                            },
                                                                            '.Label': {
                                                                                fontSize: '14px',
                                                                                fontWeight: 400,
                                                                                color: '#666666'
                                                                            },
                                                                            '.Input': {
                                                                                borderWidth: '1px'
                                                                            }
                                                                        }
                                                                    }
                                                                }}
                                                                stripe={stripePromise}
                                                            >
                                                                <CheckoutForm
                                                                    quoteId={this.props.id}
                                                                    billingDetails={billingDetails}
                                                                    agreeRef={this.agreeRef}
                                                                    handleMessage={this.handleMessage}
                                                                    message={checkoutMessage}
                                                                    isLoading={paymentLoading}
                                                                    handleNavToConf={() => this.props.history.push(`/confirmation/${this.props.id}`)}
                                                                    handleSubmitPreChecks={this.handleSubmitPreChecks}
                                                                    handlePostSuccessfulPayment={this.handlePostSuccessfulPayment}
                                                                    setIsLoading={bool => this.setState({ paymentLoading: bool })}
                                                                />
                                                            </Elements>
                                                        }
                                                    </React.Fragment>
                                                :
                                                    <PurchaseOrder
                                                        loggedIn={this.props.loggedIn}
                                                        quoteId={this.props.id}
                                                        handleNewSnackbar={this.props.handleNewSnackbar}
                                                        quoteData={data}
                                                        agreeRef={this.agreeRef}
                                                        message={checkoutMessage}
                                                        handleMessage={this.handleMessage}
                                                        handleSubmitPreChecks={this.handleSubmitPreChecks}
                                                        handlePostSuccessfulPayment={this.handlePostSuccessfulPayment}
                                                        inputWarnings={purchaseOrderInputWarnings}
                                                        handleLoading={this.props.handleLoading}
                                                        handleNavToConf={() => this.props.history.push(`/confirmation/${this.props.id}`)}
                                                        isLoading={paymentLoading}
                                                        setIsLoading={bool => this.setState({ paymentLoading: bool })}
                                                        applyLink={applyLink}
                                                        pOLoading={pOLoading}
                                                        setPOLoading={bool => this.setState({ pOLoading: bool })}
                                                        handleSuccessfulPOUpload={this.handleSuccessfulPOUpload}
                                                    />
                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}

export default withRouter(Checkout);