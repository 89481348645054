import * as React from "react"
export default function CarouselArrowLeft(props) {
    const {
        size,
        rootStyle,
        className,
        id,
        onClick
    } = props;

    let width = size ? size : 15;
    let height = size ? size : 22;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width + 'px'}
            id={id}
            className={className}
            height={height + 'px'}
            fill="none"
            style={rootStyle}
            onClick={onClick}
        >
            <path
                fill="#fff"
                stroke="#666"
                d="m4.636 10.003 7.444 7.088c.13.118.233.263.304.426a1.3 1.3 0 0 1 0 1.039 1.253 1.253 0 0 1-.304.426 1.48 1.48 0 0 1-.995.389 1.48 1.48 0 0 1-.996-.389l-8.434-8.03a1.254 1.254 0 0 1-.296-.412 1.3 1.3 0 0 1 .255-1.434l8.468-8.089c.276-.251.63-.39.996-.39s.72.139.995.39c.13.118.233.264.305.427a1.3 1.3 0 0 1-.305 1.464l-7.437 7.095Z"
            />
        </svg>
    )
}
