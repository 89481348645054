import React from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./LoadingSVG.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function LoadingSVG(props) {

    const {
        size
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // if a size has been specified use that instead of default
    const sizeToUse = size ? size : 70;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
    
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="-50 -50 1140 1140" height={`${sizeToUse}px`} width={`${sizeToUse}px`}>
            <circle cx="535.14" fill="grey" cy="535.14" r="123.49"/>
            <path className="loadingSVGPath" fill="grey" stroke="#FD552D" strokeWidth="20" d="M1069.49 672.59c.52-4.58.79-9.24.79-13.96V411.64c0-68.2-55.29-123.49-123.49-123.49H926.2c-79.57 0-144.08-64.5-144.08-144.08V123.49c0-1.07-.01-2.13-.04-3.19C780.39 53.58 725.77 0 658.63 0H411.64c-68.2 0-123.49 55.29-123.49 123.49v20.59c0 79.57-64.5 144.08-144.08 144.08h-20.58C55.29 288.16 0 343.45 0 411.65v246.99c0 68.2 55.29 123.49 123.49 123.49 68.2 0 123.49-55.29 123.49-123.49V391.07c0-79.57 64.5-144.08 144.08-144.08h288.15c79.57 0 144.08 64.5 144.08 144.08V679.23c0 79.57-64.5 144.08-144.08 144.08H411.64c-68.2 0-123.49 55.29-123.49 123.49 0 68.2 55.29 123.49 123.49 123.49h246.99c68.2 0 123.49-55.29 123.49-123.49v-20.6c0-79.57 64.5-144.08 144.08-144.08h20.59c59.68 0 109.47-42.33 120.98-98.61.47-2.31.87-4.65 1.22-7.01.18-1.29.35-2.6.5-3.91z"/>
        </svg>
    );
};  