import React from 'react';

import SelectInput from '../SelectInput/SelectInput';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function CurrencySelector(props) {
    const {
        options,
        value,
        handleOnChange,
        rootStyleClass,
        rootStyleOverwrite,
        selectStyleClass,
        iconStyleClass,
        menuItemStyleOverwrite,
        warning
    } = props;

    if (!options) return null;

    return (
        <SelectInput
            name="currency"
            label={null}
            key={value}
            options={options.map(option => option.select_option_label)}
            rootStyleClass={rootStyleClass}
            selectStyleClass={selectStyleClass}
            menuItemStyleOverwrite={menuItemStyleOverwrite}
            defaultValue={value}
            onChangeOverwrite={handleOnChange}
            iconStyleClass={iconStyleClass}
            rootStyleOverwrite={rootStyleOverwrite}
            warning={!!warning}
            writtenWarning={warning}
        />
    );
};