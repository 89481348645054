import React, {
    useState,
    useEffect
} from 'react';

import moment from 'moment-timezone';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function TimeRemaining() {

    const [timeRemaining, setTimeRemaining] = useState('?h ?m ?s');

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // calculate the remaining time left for valid lead times and set in state
    const makeTimer = endTime => {
        let timeToDiff = moment();

        let timeLeft = endTime - timeToDiff.unix();

        let days = Math.floor(timeLeft / 86400);
        let hours = Math.floor((timeLeft - days * 86400) / 3600);
        let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
        let seconds = Math.floor(timeLeft - days * 86400 - hours * 3600 - minutes * 60);

        let valueToSet = `${days >= 1 ? `${days}d` : ''} ${hours >= 1 ? `${hours}h` : ''} ${minutes}m ${seconds}s`;

        if (valueToSet !== timeRemaining) {
            setTimeRemaining(valueToSet);
        }
    };

    // calculate when the next cut off is regarding valid lead times
    const determineEndTime = () => {
        let ld = moment().tz('Europe/London');

        let lday = ld.weekday();
        let lhour = ld.hours();

        // if it is after 2pm then the lead times are no longer valid
        let isAfter2 = lhour >= 14;

        // if it is the weekend the next valid cut off will be on monday
        let isWeekend = (lday === 0 || lday === 6 || (lday === 5 && isAfter2));

        if (isWeekend) {
            ld.day(1 + 7);
        } else if (isAfter2) {
            ld.add(1, 'days');
        }

        ld.set({hour: 14, minute: 0, second: 0, millisecond: 0});

        return (ld.unix());
    };


    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        const interval = setInterval(() => {
            makeTimer(determineEndTime());
        }, 1000);

        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return <span>{timeRemaining}</span>
};