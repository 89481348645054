import React, {
    useEffect,
    useRef
} from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./QuotePartOverlay.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function QuotePartOverlay(props) {
    let {
        anchorEl,
        handleShowBorders,
        handleOverlayClose,
        quoteOverlayWarningModalOpen,
        partViewerVisible,
        manualOverlayTriggerValue
    } = props;

    let mainRef = useRef();
    let topRef = useRef();
    let rightRef = useRef();
    let rightFixedRef = useRef();
    let bottomRef = useRef();
    let leftRef = useRef();

    let scrollValueOverwrite = useRef();

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --
    // handles the appearance of the overlay
    const handleOverlay = (init) => {
        if (refsExist()) {
            // if we are initializing the overlay then we want to wait for the positioning to run then reveal the elem
            if (typeof init === 'boolean' && init) {
                setTimeout(() => {
                    changeStyles();

                    setTimeout(() => {
                        mainRef.current.style.opacity = 1;
                    }, 1);
                }, 1);
            } else {
                // if the refs exist, then we want to readjust their position to surround the part elem on the page
                changeStyles();
            }
        }
    };

    // checks to see if the refs of all the elems exist, returns true / false
    const refsExist = () => {
        return !(
            !mainRef ||
            !mainRef.current ||
            !anchorEl ||
            !topRef ||
            !topRef.current ||
            !rightRef ||
            !rightRef.current ||
            !bottomRef ||
            !bottomRef.current ||
            !leftRef ||
            !leftRef.current
        );
    };

    // changes the positioning of the overlay elems
    const changeStyles = () => {
        let overwrite = scrollValueOverwrite && scrollValueOverwrite.current ? scrollValueOverwrite.current : false;

        let scrollValue = overwrite ? overwrite : window.scrollY;

        let height = document.body.scrollHeight - 263;
        mainRef.current.style.height = height;

        let boundingRect = anchorEl.getBoundingClientRect();

        let widthToUse = overwrite ? window.innerWidth : document.body.scrollWidth;

        topRef.current.style.height = boundingRect.top + scrollValue + 'px';
        topRef.current.style.width = widthToUse + 'px';
        topRef.current.style.borderBottomRightRadius = '0px';

        rightRef.current.style.top = boundingRect.top + scrollValue + 'px';
        rightRef.current.style.right = 'unset';
        rightRef.current.style.left = boundingRect.right + 'px';
        rightRef.current.style.height = boundingRect.height + 'px';
        rightRef.current.style.width = widthToUse + 100 + 'px';
        rightRef.current.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';

        bottomRef.current.style.top = boundingRect.bottom + scrollValue + 'px';
        bottomRef.current.style.left = 0;
        bottomRef.current.style.right = 'unset';
        bottomRef.current.style.height = (height - (boundingRect.bottom + scrollValue)) + 'px';
        bottomRef.current.style.width = widthToUse + 'px';
        bottomRef.current.style.borderTopRightRadius = '0px';

        leftRef.current.style.top = boundingRect.top + scrollValue + 'px';
        leftRef.current.style.height = boundingRect.height + 'px';
        leftRef.current.style.width = boundingRect.left + 'px';
    };

    // handles the closing of the overlay elem
    const handleClose = (e, transition) => {
        // hide the elem, opacity transitions
        if (!transition) {
            mainRef.current.style.opacity = 0;
            mainRef.current.pointerEvents = 'none';
        }

        if (rightFixedRef && rightFixedRef.current) rightFixedRef.current.style.opacity = 0;

        // return the borders of the parts elems to normal as we need to wait for the opacity transition to finish before we hide the element
        handleShowBorders(() => {
            handleOverlayClose();
        });
    };

    // return styling for position and top for the wrapping elem in the right overlay elem, when the body becomes locked then set the position to fixed and calculate the appropriate height
    const setFixedRefStyles = () => {
        if (rightFixedRef && rightFixedRef.current) {
            rightFixedRef.current.style.position = !!(scrollValueOverwrite && typeof scrollValueOverwrite.current === 'number') ? 'absolute' : 'sticky';
            rightFixedRef.current.style.top = !!(scrollValueOverwrite && typeof scrollValueOverwrite.current === 'number') ? scrollValueOverwrite.current + 'px' : 0;
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        // on mount initialize the overlay
        handleOverlay(true);

        // add resize event listener to change the styles of the overlay elems
        window.addEventListener('resize', handleOverlay);

        // on unmount remove the event listener
        return () => window.removeEventListener('resize', handleOverlay);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (typeof quoteOverlayWarningModalOpen === 'number' || typeof partViewerVisible === 'number') {
            scrollValueOverwrite.current = quoteOverlayWarningModalOpen || partViewerVisible;
            handleOverlay();
        } else {
            scrollValueOverwrite.current = null;
        }

        setFixedRefStyles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quoteOverlayWarningModalOpen, partViewerVisible]);

    useEffect(() => {
        if (manualOverlayTriggerValue) {
            handleOverlay();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [manualOverlayTriggerValue]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            ref={mainRef}
            id="QuotePartOverlay"
        >
            <div
                ref={topRef}
                onClick={handleClose}
                id="QuotePartOverlayTop"
            >
            </div>

            <div
                ref={rightRef}
                onClick={handleClose}
                id="QuotePartOverlayRight"
            ></div>

            <div
                ref={bottomRef}
                onClick={handleClose}
                id="QuotePartOverlayBottom"
            >
            </div>

            <div
                ref={leftRef}
                onClick={handleClose}
                id="QuotePartOverlayLeft"
            >
            </div>
        </div>
    );
};