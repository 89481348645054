import React, {
    useEffect,
    useState,
    useRef
} from 'react';

import {
    handleAlienFetchUserInfo,
    handleAlienUpdateTradeAccount,
    handleAlienUpdateUserNote
} from '../../functions/user_functions';

import './AlienUserComponent.css';

export default function AlienUserComponent({ handleNewAlienMessage }) {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(false);

    const noteRef = useRef();

    const fetchData = async () => {
        let result = await handleAlienFetchUserInfo();

        if (result.success) {
            setData(result.data);
        } else {
            console.log('something went wrong in AlienUserComponent:', result);
        }

        setLoading(false);
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (data) {
            if (data.default_note) {
                noteRef.current.value = data.default_note;
            }
        }
    }, [data])

    return (
        <div
            id="AlienUserComponentRoot"
            className={loading ? 'AlienUserComponentRootLoading' : ''}
        >
            <p className={loading ? 'loading-dots' : ''}>{loading ? '' : 'update user settings'}</p>

            <hr />

            <div>
                <p>{data ? data.is_trade_account ? 'user has a trade account' : 'user does not have a trade account' : '_'}</p>
                <button
                    onClick={data ? async () => {
                        setLoading(true);
                        let result = await handleAlienUpdateTradeAccount(!data.is_trade_account);
                        setLoading(false);

                        if (result.success) {
                            let dataClone = { ...data };
                            dataClone.is_trade_account = result.data;

                            setData(dataClone);
                        }

                        handleNewAlienMessage(result.message);
                    } : null}
                >{data ? data.is_trade_account ? 'deactivate' : 'activate' : '_'}</button>
            </div>

            <hr />

            <div>
                <p>user note</p>
                <textarea
                    id="AlienUserComponentDefaultNote"
                    className='HideScrollbar'
                    ref={noteRef}
                    resize='none'
                ></textarea>
                <button
                    onClick={!!(data && noteRef?.current) ? async () => {
                        setLoading(true);
                        let result = await handleAlienUpdateUserNote(noteRef.current.value || '');
                        setLoading(false);

                        if (result.success) {
                            let dataClone = { ...data };
                            dataClone.default_note = result.data;

                            setData(dataClone);
                        }

                        handleNewAlienMessage(result.message);
                    } : null}
                >update</button>
            </div>
        </div>
    );
}
