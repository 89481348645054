import * as React from "react"
export default function CarouselArrowRight(props) {
    const {
        size,
        rootStyle,
        className,
        id,
        onClick
    } = props;

    let width = size ? size : 15;
    let height = size ? size : 22;

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width + 'px'}
            id={id}
            className={className}
            height={height + 'px'}
            fill="none"
            style={rootStyle}
            onClick={onClick}
        >
            <path
                fill="#fff"
                stroke="#666"
                d="M8.364 9.997.92 2.909a1.253 1.253 0 0 1-.304-.427 1.3 1.3 0 0 1 0-1.038c.07-.163.174-.308.304-.426.276-.25.63-.389.995-.389.366 0 .72.138.996.389l8.434 8.03c.125.114.226.255.296.412a1.298 1.298 0 0 1-.255 1.434l-8.468 8.089c-.276.251-.63.39-.996.39s-.72-.139-.995-.39a1.253 1.253 0 0 1-.305-.427 1.3 1.3 0 0 1 .305-1.464l7.437-7.095Z"
            />
        </svg>
    )
}
