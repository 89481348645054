import React from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    handleError
} from '../../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

class ErrorBoundary extends React.Component {
    // catches an error thrown in wrapped component
    componentDidCatch(error, errorInfo) {
        if (process.env.REACT_APP_BUILD_ENV === 'prod') {
            handleError(
                'ERROR BOUNDARY: ' + this.props.componentWrapped,
                {
                    error,
                    errorInfo
                }
            );
        }
    };

    render() {
        return this.props.children;
    };
}

export default ErrorBoundary;