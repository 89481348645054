import React from 'react';

import './MoreInfoI.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    Tooltip
} from '@mui/material';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function MoreInfoI(props) {
    const {
        tooltip,
        returnTooltip,
        styleOverwrite,
        delay,
        small
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <Tooltip
            arrow
            leaveDelay={delay}
            enterTouchDelay={0}
            leaveTouchDelay={3500}
            title={!!returnTooltip ? returnTooltip() : tooltip}
        >
            <ErrorOutlineIcon
                style={styleOverwrite}
                className={`MoreInfoI ${small ? 'MoreInfoISmaller' : null}`}
            />
        </Tooltip>
    )
};