import React, {
    useContext
} from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import VerifiedUserOutlinedIcon from '@mui/icons-material/VerifiedUserOutlined';

import General from "../../../context/general";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./PrivacyPolicy.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function PrivacyPolicy() {
    const {
        privacyPolicy,
        cookiesPolicy
    } = useContext(General);

    return (

        <div id="PrivacyPolicy">
            <div id="PrivacyPolicyWrap">
                <div>
                    <VerifiedUserOutlinedIcon id="PrivacyIcon"/>
                    <h4 className="Reg16">We protect your files and IP with our <a className="Bold16" target="_blank" rel="noreferrer" href={privacyPolicy}>{`Privacy Guarantee`}</a></h4>
                </div>

                <h6 className="Reg12">By using our site, you are agreeing to use our cookies. <a target="_blank" rel="noreferrer" href={cookiesPolicy}>more info</a></h6>
            </div>
        </div>

    );
};