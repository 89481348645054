import React, { useEffect, useState, useMemo, useCallback } from 'react';
import Cookies from "universal-cookie"

import CookieNotice from "../Misc/CookieNotice/CookieNotice";

export default function ConsentForm() {

    const [decisionMade, setDecisionMade] = useState(false) // start with true to avoid flashing the cookie notice

    const cookies = useMemo(() => new Cookies(), []);

    function gtag(event) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(event);
    }

    const sendConsent = useCallback((consent) => {
        gtag({event: 'consent_update', consent })
    }, []);

    // handle the user updating their cookie preferences
    // which is either analytics or marketing
    // value is either 'granted' or 'denied'
    // analytics affects 'analytics_storage'
    // marketing affects 'ad_storage', 'ad_user_data', 'ad_personalization'
    const handleUpdate = (which, value) => {
        // get the latest cookie val
        let consent = cookies.get("_MACKEREL_CONSENT_COOKIE");

        // if no cookie has been set then we can use the default values
        if (!consent) {
            consent = {
                'analytics_storage': 'granted',
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                '_accepted': false
            }
        }

        // update the cookie with the new value
        if (which === 'analytics') {
            consent['analytics_storage'] = value ? 'granted' : 'denied';
        } else if (which === 'marketing') {
            consent['ad_storage'] = value ? 'granted' : 'denied';
            consent['ad_user_data'] = value ? 'granted' : 'denied';
            consent['ad_personalization'] = value ? 'granted' : 'denied';
        }

        cookies.set("_MACKEREL_CONSENT_COOKIE", consent, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            path: "/"
        })
    }

    // handle the user accepting the cookie notice
    // if they have changed their preferences then the cookie should already be present
    // if not then they have accepted all cookies
    const handleAccept = () => {
        // get the cookie
        let consent = cookies.get("_MACKEREL_CONSENT_COOKIE");

        if (!consent) {
            consent = {
                'analytics_storage': 'granted',
                'ad_storage': 'granted',
                'ad_user_data': 'granted',
                'ad_personalization': 'granted',
                '_accepted': true
            }
        } else {
            consent['_accepted'] = true;
        };

        cookies.set("_MACKEREL_CONSENT_COOKIE", consent, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            path: "/"
        })

        updateConsentWithCookie();
    }

    // reset the cookie to the default values
    const handleReset = () => {
        console.log('____handleReset');

        let consent = {
            'analytics_storage': 'granted',
            'ad_storage': 'granted',
            'ad_user_data': 'granted',
            'ad_personalization': 'granted',
            '_accepted': false
        }

        cookies.set("_MACKEREL_CONSENT_COOKIE", consent, {
            expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
            path: "/"
        })
    };

    const updateConsentWithCookie = () => {
        let consent = cookies.get("_MACKEREL_CONSENT_COOKIE");

        setDecisionMade(true);

        // update the dataLayer with the new consent
        sendConsent(consent);
    }

    // when the component mounts we need to check if the user has already made a decision
    // if they have we will update the preferences with the current values
    // else we will display the cookie notice
    useEffect(() => {
        if (cookies.get("_MACKEREL_CONSENT_COOKIE") !== undefined) {

            // check if they have accepted
            let consent = cookies.get("_MACKEREL_CONSENT_COOKIE");

            if (consent._accepted) {
                updateConsentWithCookie();
            };
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        decisionMade ? (
            <></>
        ) : (
            <CookieNotice
                accept={handleAccept}
                update={handleUpdate}
                reset={handleReset}
            />
        )
    )
}