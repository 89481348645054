import React from 'react';
import ReactDOM from 'react-dom';

import clsx from 'clsx';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./DropDownList.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function DropDownList(props) {
    const {
        anchorEl,
        keyToUse,
        open,
        bordered,
        handleSelection,
        options,
        returnElem,
        isOptionHidden,
        parentIsBorderBox,
        optionStyleOverwrite,
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // if there is no anchor element or options then return nothing
    if (!anchorEl || !open || !options) return null;

    let top;
    let left;
    let optionsPadding;
    let errorCaught = false;
    try {
        // find the anchor element on the dom
        const domNode = ReactDOM.findDOMNode(anchorEl);
        const styles = domNode ? window.getComputedStyle(domNode) : null;
        const anchorElBoundingRect = anchorEl.getBoundingClientRect();
        top = anchorElBoundingRect.height;
        if (parentIsBorderBox) top = top - styles[`border-top-width`].match(/\d+/)[0] - styles[`border-bottom-width`].match(/\d+/)[0]

        // match the border from the anchor element
        let borderOffset = bordered ? styles[`border-${bordered}-width`].match(/\d+/)[0] : null;
        left = borderOffset ? -borderOffset : 0;

        optionsPadding = styles['padding-left'];
    } catch (error) {
        errorCaught = true;
    }

    if (errorCaught) return null;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            key={keyToUse}
            tabIndex="0"
            id="DropDownList"
            style={{
                top,
                left
            }}
        >
            {options.map(option => {
                const {
                    key
                } = option;

                let childElem = returnElem && returnElem(option);

                let actionDisabled = childElem.props.disabled;
                let thisLoading = childElem.props.thisloading;
                let loading = childElem.props.loading;

                return !isOptionHidden(option) ? (
                    <div
                        key={key}
                        onClick={actionDisabled || loading ? null : e => {
                            handleSelection(e, option);
                            e.stopPropagation();
                        }}
                        style={{
                            minHeight: top,
                            padding: `0px ${optionsPadding}`,
                            ...optionStyleOverwrite
                        }}
                        className={clsx('DropDownOption', actionDisabled ? 'DropDownOptionDisabled' : null, loading ? 'DefaultCursor' : null, thisLoading ? 'DropDownOptionLoading' : null)}
                    >
                        {childElem}
                    </div>
                ) : null;
            })}
        </div>
    );

};