import React, {
    createRef,
    Component
} from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import dateFormat from 'dateformat';
import { withRouter } from 'react-router-dom';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    handleApiReq,
    callApiAndReturnCombinedResultsData,
    handleError,
    returnCurrencySignAndOrValue
} from '../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import LoadingSVG from '../Misc/LoadingSVG/LoadingSVG';
import AddressHandler from '../Misc/AddressHandler/AddressHandler';
import MoreInfoI from '../Misc/MoreInfoI/MoreInfoI';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    Paper,
    CircularProgress,
    Skeleton,
    Tooltip
} from '@mui/material';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./Confirmation.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

class Confirmation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            date: null,
            time: null,
            selectedLeadTimeDates: null,
            selectedLeadTimeBrief: null,
            priceObject: null,
            billing: null,
            shipping: null,
            quoteId: null,
            parts: null,
            thumbnailErrorUrl: null,
            definitions: null,
            dataLoaded: false,
            showWantToRegisterModal: false,
            showAuthenticator: false,
            authLoading: false,
            bSLVal: null
        };

        this.lorryRef = createRef();
        this.estRef = createRef();
        this.partRefs = [];
    };

    async componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0
        });

        // set loading to true as we will be fetching options from the config
        this.props.handleLoading(true);

        // remove qid data
        localStorage.removeItem('qid');

        handleApiReq(
            'get',
            `/crud/checkout-data/${this.props.id}`
        ).then(resultX => {
            const {
                reference_number,
                paid,
                paid_at,
                hiding,
            } = resultX.data;

            // something went wrong fetching the checkout data, display an error modal
            if (!resultX.success || ((!reference_number || !paid || !paid_at) && !hiding)) {
                handleError('37669PCO', 'resultX failed in confirmation mount', false, "Don’t Worry! \n\nYour order and payment have been received. \n\nThere has been a small issue with processing your order data, one of our team will push the order through manually.");
            } else {
                // if hiding, this order has past the time required to connect it to an account
                if (hiding) {
                    this.setState({
                        hiding
                    }, () => this.props.handleLoading(false));
                } else {
                    let paidAtDate = new Date(paid_at);

                    this.setState({
                        quote: resultX.data,
                        billing: resultX.data.billing,
                        shipping: resultX.data.shipping,
                        priceObject: resultX.data.totals,
                        service: resultX.data.clt || resultX.data.service_string,
                        paymentMethod: resultX.data.payment_method,
                        date: dateFormat(paidAtDate, 'dd.mm.yyyy'),
                        time: dateFormat(paidAtDate, 'HH:MM'),
                        parts: resultX.data.parts_data
                    }, async () => {
                        // fetch options and mock data payload from the config
                        callApiAndReturnCombinedResultsData([
                            {method: 'get', path: '/fetch-config/quote-options'},
                            {method: 'get', path: '/fetch-config/definitions'},
                            {method: 'get', path: '/fetch-config/address-options'},
                            {method: 'get', path: '/fetch-config/general-options'},
                        ]).then(result => {
                            this.props.handleAlienData({
                                quote_id: this.props.id,
                                que: this.state.quote.email,
                                ahZzZ: this.state.quote.admin_hidden || this.state.quote.user_hidden
                            });

                            this.setState({
                                invoiceUrl: result.invoice_url,
                                invoiceFetching: false,
                                addressElemInfo: result.address_inputs,
                                productionEstimate: this.state.priceObject.lead_time,
                                deliveryEstimate: this.state.priceObject.est_delivery,
                                industryOptions: result.industry_options,
                                quoteId: this.state.quote.reference_number,
                                thumbnailErrorUrl: result.thumbnail_error_url,
                                definitions: result.definitions,
                                dataLoaded: true
                            }, () => {
                                this.props.handleLoading(false);

                                // check to see if a user and if not open a prompt for the user
                                this.handleUserRegisterPrompt(!!this.state.quote.is_user);

                                this.animateLorry();
                            });
                        }).catch(error => {
                            handleError('46110xMQ', error);
                        });
                    });
                }
            }
        }).catch(error => {
            if (error?.response?.statusCode === 403) {
                if (error.response && error.response.data && error.response.data.challenge && error.response.data.challenge_type) {
                    this.props.handleLoading(false);
                    this.props.history.push(`/challenge/c/${error.response.data.challenge_type}/${error.response.data.challenge}`);
                } else {
                    // redirect to upload page
                    this.props.history.push('/upload/');
                }
            } else {
                handleError('36156Adz', error, false, "Don’t Worry! \n\nYour order and payment have been received. \n\nThere has been a small issue with processing your order data, one of our team will push the order through manually.");
            }
        });
    };

    getSnapshotBeforeUpdate(prevProps) {
        // determine if the user has resized the window from below the 1024 breakpoint back to it.
        // we will need to re-animate the lorry if this is the case, as the dom elem has been re-appended
        if (this.props.windowBreakpoint?.w && prevProps.windowBreakpoint?.w) {
            if ((this.props.windowBreakpoint.w >= 1024 || isNaN(this.props.windowBreakpoint.w)) && prevProps.windowBreakpoint.w <= 768) {
                return 'animate_lorry';
            }
        }

        return null;
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot === 'animate_lorry') this.animateLorry();
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // returns title elem with blue border-left
    returnConfirmationTitle = str => {
        return (
            <div className="ConfirmationTitle">
                <h3 className="Bold14">{str}</h3>
            </div>
        );
    };

    // if the thumbnail image fails to load then replace the url with the error url
    handleImgError = index => {
        if (this.partRefs[index].img) {
            this.partRefs[index].img.src = this.state.thumbnailErrorUrl;
        }
    };

    // the thumbnail image has loaded, fade in the image and fade out the loading overlay
    handleImgLoad = index => {
        if (this.partRefs[index].img && this.partRefs[index].loading) {
            this.partRefs[index].img.style.opacity = 1;
            this.partRefs[index].loading.style.opacity = 0;
        }
    };

    // returns the part elem in the quote summary
    returnConfirmationPart = (part, index) => {
        const {
            thumbnail,
            filename,
            price,
            material_id,
            finish_id,
            colour_id,
            colour_val
        } = part;

        this.partRefs[index] = {
            img: null,
            loading: null
        };

        return (
            <div
                key={index + '_part'}
                className="ConfirmationPart"
            >
                <div>
                    <div className="ConfirmationPartThumbnailWrap">
                        <img
                            ref={el => this.partRefs[index].img = el}
                            src={thumbnail ? thumbnail : this.state.thumbnailErrorUrl}
                            onError={() => this.handleImgError(index)}
                            onLoad={() => this.handleImgLoad(index)}
                            alt={filename ? filename : '3d-part'}
                            className="ConfirmationPartThumbnail"
                        ></img>

                        <div
                            ref={el => this.partRefs[index].loading = el}
                            className="ConfirmationPartThumbnailLoadingWrap"
                        >
                            <LoadingSVG
                                size={30}
                            />
                        </div>
                    </div>

                </div>

                <div>
                    <p className="Bold14">{filename}</p>
                    <p className="Reg12">Quantity: {price.qty ? price.qty : '-'}</p>
                </div>

                <div>
                    <p className="Bold14">{material_id ? this.state.definitions.materials[material_id].label : '-'}</p>
                    <p className="Reg12">{material_id ? `${this.state.definitions.materials[material_id].tech_label_long} (${this.state.definitions.materials[material_id].tech_label_short})` : '-'}</p>
                </div>

                <div className="Colour">
                    <div className="ColourLeft"
                        style={{
                            backgroundColor: colour_id && colour_val ? this.state.definitions.colour_values[colour_val].hex : 'transparent',
                            border: colour_id && colour_val && this.state.definitions.colour_values[colour_val].hex.startsWith('#FFF') ? 'var(--border-main)' : 'none'
                        }}
                    />

                    <div className="ColourRight">
                        <Tooltip
                            arrow
                            title={colour_id && colour_val ? `${this.state.definitions.colour_finishes[colour_id].label}${this.state.definitions.colour_finishes[colour_id].multi_colour && this.state.definitions.colour_values[colour_val] ? ` (${this.state.definitions.colour_values[colour_val].label})` : ''}` : ''}
                        >
                            <p className="Bold14">{colour_id && colour_val ? `${this.state.definitions.colour_finishes[colour_id].label}${this.state.definitions.colour_finishes[colour_id].multi_colour && this.state.definitions.colour_values[colour_val] ? ` (${this.state.definitions.colour_values[colour_val].label})` : ''}` : '-'}</p>
                        </Tooltip>
                        <p className="Reg12">{finish_id ? this.state.definitions.surface_finishes[finish_id].label : '-'}</p>
                    </div>
                </div>

                <div className="TotalPrice">
                    <p className="Bold14">{!isNaN(price.total) && this.state.quote?.currency ? returnCurrencySignAndOrValue(this.state.quote.currency, price.total, null, true) : '-'}</p>
                </div>
            </div>
        );
    };

    // user has clicked the view invoice button, request from the backend
    handleFetchInvoice = () => {
        this.setState({
            invoiceFetching: true
        }, async () => {
            // if the invoice hasn't been generated yet, then the user will be prompted to try again, giving it time to generate
            handleApiReq(
                'get',
                `/crud/invoice/${this.props.id}`
            ).then(result => {
                if (result.success && result.invoice_url) {
                    this.setState({
                        invoiceUrl: result.invoice_url,
                        invoiceFetching: false
                    }, () => window.open(result.invoice_url));
                } else {
                    this.setState({
                        invoiceFetching: false
                    }, () => {
                        handleError('31983yWg', 'Request for invoice took too long', false, "Request for invoice took too long\n\nPlease try again, or contact us if the problem persists.");
                    });
                }
            }).catch(error => {
                this.setState({
                    invoiceFetching: false
                }, () => {
                    handleError('36756faH', error, false, "Request for invoice took too long.\n\nPlease try again, or contact us if the problem persists.");
                });
            });
        });
    };

    // handle the prompt asking if the user wants to register
    handleUserRegisterPrompt = isUser => {
        if (!isUser) setTimeout(() => this.props.toggleActiveModal('want_to_register', {
            industryOptions: this.state.industryOptions
        }), 2000);
    };

    // toggleAuthLoading
    handleAuthLoading = bool => {
        this.setState({ authLoading: bool });
    };

    animateLorry = () => {
        // animate the lil lorry
        if (this.lorryRef?.current) {
            this.lorryRef.current.classList.add('AnimateLorry');
            this.lorryRef.current.style.opacity = 1;
        }
        if (this.estRef?.current) {
            setTimeout(() => {
                if (this.estRef?.current) {
                    this.estRef.current.style.opacity = 1;
                    this.estRef.current.style.transform = 'translateX(0px)';
                }
            }, 400);
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    render() {

        if (this.state.hiding) {
            return (
                <div id="ConfirmationHiding">
                    <Paper
                        elevation={6}
                    >
                        <div className="Reg16">
                            <h2 className="Bold30">Thank You!</h2>
                            <p>Your order has been received</p>

                            {this.props.loggedIn &&
                                <p>The information for this order has been hidden, as no connection to an account was made in the required time.</p>
                            }

                            <p>Please check the email address used to order for further information.</p>
                        </div>
                    </Paper>
                </div>
            );
        }

        const {
            date,
            time,
            paymentMethod,
            priceObject,
            billing,
            shipping,
            addressElemInfo,
            quoteId,
            parts,
            invoiceUrl,
            invoiceFetching,
            deliveryEstimate,
            productionEstimate,
            service
        } = this.state.dataLoaded ? this.state : {};

        return (
            <React.Fragment>
                <div className="Page">
                    <div className="PageContentWrap">
                        {this.props.windowBreakpoint?.w <= 768 &&
                            <div id="ConfirmationHeader">
                                <div id="ConfirmationHeaderTop">
                                    <h1 className="Bold30">Order Confirmation & Receipt</h1>

                                    <h3 className="Reg16">Your order has been received and a receipt will be emailed to you</h3>
                                </div>

                                <div id="ConfirmationHeaderBottom">
                                    <h4 className="Reg12">ORDER ID:</h4>
                                    <p className="Bold16">{quoteId ? quoteId : '-'}</p>
                                </div>
                            </div>
                        }

                        <div className="PageContent">
                            {(this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) &&
                                <div id="ConfirmationHeader">
                                    <div id="ConfirmationHeaderTop">
                                        <h2 className="Bold30">Order Confirmation & Receipt</h2>

                                        <p className="Reg16">Your order has been received and a receipt will be emailed to you</p>
                                    </div>

                                    <div id="ConfirmationHeaderBottom">
                                        <div>
                                            <div>
                                                <p className="Reg12">ORDER ID:</p>

                                                <p className="Bold16">{quoteId ? quoteId : '-'}</p>
                                            </div>
                                        </div>

                                        <div>
                                            <div>
                                                <p className="Reg12">DATE:</p>

                                                <p className="Bold16">{date ? date : '-'}</p>
                                            </div>
                                        </div>

                                        <div>
                                            <div>
                                                <p className="Reg12">TIME:</p>

                                                <p className="Bold16">{time ? time : '-'}</p>
                                            </div>
                                        </div>

                                        <div>
                                            <div>
                                                <p className="Reg12">PAYMENT METHOD:</p>

                                                <p className="Bold16">{paymentMethod ? paymentMethod : '-'}</p>
                                            </div>
                                        </div>
                                    </div>

                                    {!deliveryEstimate &&
                                        <Skeleton
                                            variant="rounded"
                                            width="100%"
                                            height={80}
                                            sx={{ borderRadius: 1, marginBottom: '40px' }}
                                        />
                                    }

                                    {!!deliveryEstimate &&
                                        <div id="ConfirmationEstimatedDeliveryWrap">
                                            <div>
                                                <LocalShippingOutlinedIcon ref={this.lorryRef} id="ConfirmationEstimatedDeliveryIcon"/>

                                                <div id="EstimatedLeadTime" ref={this.estRef}>
                                                    <p className="Reg12">Estimated delivery by:</p>

                                                    <p id="EstimatedDates" className="Bold14 TonesBlack">{deliveryEstimate}</p>
                                                </div>
                                            </div>

                                            {
                                                invoiceUrl ?
                                                    <a href={invoiceUrl} target="_blank" rel="noreferrer" download className="Reg14">Download Invoice</a>
                                                    : <div id="FetchInvoiceWrap">
                                                        {invoiceFetching && <CircularProgress id="FetchInvoiceCircular" size={15} />}
                                                        <p id="FetchInvoice" onClick={this.handleFetchInvoice} className="Reg14">Download Invoice</p>
                                                    </div>
                                            }
                                        </div>
                                    }
                                </div>
                            }

                            <div id="ConfirmationMain">
                                <div id="ConfirmationMainLeft">
                                    {(this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) &&
                                        <React.Fragment>
                                            {(!parts || !parts.length) &&
                                                <Skeleton
                                                    variant="rounded"
                                                    width="100%"
                                                    height={250}
                                                    sx={{ borderRadius: 1 }}
                                                />
                                            }

                                            {parts && parts.length &&
                                                <div id="ConfirmationOrderSummary">
                                                    <div id="ConfirmationOrderSummaryHeader" className="Bold14">
                                                        <p>Image</p>
                                                        <p>Filename / Quantity</p>
                                                        <p>Material / Technology</p>
                                                        <p>Color / Finish</p>
                                                        <p>Price</p>
                                                    </div>

                                                    {parts.sort((a, b) => a.created_at - b.created_at).map((part, index) => this.returnConfirmationPart(part, index))}
                                                </div>
                                            }

                                            {!priceObject &&
                                                <Skeleton
                                                    variant="rounded"
                                                    width="100%"
                                                    height={381}
                                                    sx={{ borderRadius: 1, marginTop: '40px', marginBottom: '50px' }}
                                                />
                                            }

                                            {!!priceObject &&
                                                <div id="ConfirmationPriceBreakdownWrap">
                                                    <div>
                                                        <p className="Reg14">Items sub-total</p>
                                                        <p className="Bold14">{priceObject && typeof priceObject.subtotal === 'number' ? returnCurrencySignAndOrValue(this.state.quote?.currency, priceObject.subtotal) : '-'}</p>
                                                    </div>

                                                    <div>
                                                        <p className="Reg14">Minimum order value surcharge <span><MoreInfoI
                                                            returnTooltip={() => (
                                                                <React.Fragment>
                                                                    <p>Each order takes time to prepare, manufacture, quality check and package by the 3D People team.</p>
                                                                    <br></br>
                                                                    <p>Regardless of the size of your order, there is a minimum time spent working on it.</p>
                                                                    <br></br>
                                                                    <p>The minimum order cost of {returnCurrencySignAndOrValue(this.state.quote?.currency, !!(priceObject && priceObject.moc_rate && typeof priceObject.moc_rate === 'number') ? priceObject.moc_rate : 30)} applies to all orders and does not include fees for shipping.</p>
                                                                </React.Fragment>
                                                            )}
                                                        /></span></p>
                                                        <p className="Bold14">{priceObject && typeof priceObject.minimum_order_cost === 'number' ? returnCurrencySignAndOrValue(this.state.quote?.currency, priceObject.minimum_order_cost, null, true) : '-'}</p>
                                                    </div>

                                                    {!!priceObject.discount_value &&
                                                        <div>
                                                            <p className="Reg14">Coupon {priceObject.discount_name.toUpperCase()} - {priceObject.discount_method}</p>
                                                            <p className="Bold14">{returnCurrencySignAndOrValue(this.state.quote?.currency, priceObject.discount_value, null, true)}</p>
                                                        </div>
                                                    }

                                                    {!!(priceObject && priceObject?.colour_setup_splits.length) && priceObject.colour_setup_splits.sort((a, b) => a.setup_cost - b.setup_cost).map((split, index) =>
                                                        <div
                                                            key={'ConfirmationBreakdownElemColourSetup' + index + '_split'}
                                                            className="ConfirmationBreakdownElemColourSetup"
                                                        >
                                                            {!split.sales_team &&
                                                                <div
                                                                    className="ConfirmationBreakdownElemColourPreview"
                                                                    style={{
                                                                        backgroundColor: split.hex,
                                                                        border: split.hex.startsWith('#FFF') ? 'var(--border-main)' : 'none'
                                                                    }}
                                                                />
                                                            }
                                                            <div className="ConfirmationBreakdownElemColourLabel">
                                                                <Tooltip title={split.label} arrow>
                                                                    <p className="Reg14">{split.label}</p>
                                                                </Tooltip>
                                                                <MoreInfoI
                                                                    returnTooltip={() => (
                                                                        <React.Fragment>
                                                                            <p>A fee is applied for each
                                                                                colour dye used in your
                                                                                order to cover setup
                                                                                costs.</p>
                                                                            <br></br>
                                                                            <p>The fees are clearly
                                                                                displayed as you select
                                                                                finishes and itemised in
                                                                                your quote summary.</p>
                                                                        </React.Fragment>
                                                                    )}
                                                                    styleOverwrite={{
                                                                        position: 'relative',
                                                                        top: -3
                                                                    }}
                                                                />
                                                            </div>
                                                            <p className="Bold14">{split.setup_cost}</p>
                                                        </div>
                                                    )}

                                                    <div>
                                                        <p className="Reg14">{priceObject && priceObject.delivery_method ? priceObject.delivery_method : '-'}</p>
                                                        <p className="Bold14">{priceObject && typeof priceObject.delivery_value === 'number' ? returnCurrencySignAndOrValue(this.state.quote?.currency, priceObject.delivery_value, false, true) : '-'}</p>
                                                    </div>

                                                    <div>
                                                        <p className="Reg14">Order sub-total</p>
                                                        <p className="Bold14">{priceObject && typeof priceObject.total_excl_vat === 'number' ? returnCurrencySignAndOrValue(this.state.quote?.currency, priceObject.total_excl_vat, false, true) : '-'}</p>
                                                    </div>

                                                    <div>
                                                        <p className="Reg14">{priceObject && typeof priceObject.tax_rate === 'number' ? `VAT ${priceObject.tax_rate * 100}%` : '-'}</p>
                                                        <p className="Bold14">{priceObject && typeof priceObject.vat === 'number' ? returnCurrencySignAndOrValue(this.state.quote?.currency, priceObject.vat, false, true) : '-'}</p>
                                                    </div>

                                                    <div>
                                                        <div>
                                                            <p className="Reg14">Total</p>
                                                        </div>

                                                        <div>
                                                            <p className="Bold16">{priceObject && priceObject.total_incl_vat ? returnCurrencySignAndOrValue(this.state.quote?.currency, priceObject.total_incl_vat, false, true) : '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </React.Fragment>
                                    }

                                    {this.props.windowBreakpoint?.w <= 768 &&
                                        <React.Fragment>
                                            {(!deliveryEstimate || !parts || !parts.length || !priceObject) &&
                                                <Skeleton
                                                    variant="rounded"
                                                    width="100%"
                                                    height={250}
                                                    sx={{ borderRadius: 1, marginTop: '10px', marginBottom: '26px' }}
                                                />
                                            }

                                            {!!(deliveryEstimate && parts && parts.length && !!priceObject) &&
                                                <div className="ConfirmationSummarySection">
                                                    <div>
                                                        <h3 className="Bold20">Order Summary</h3>

                                                        {
                                                            invoiceUrl ?
                                                                <a id="FetchInvoice" href={invoiceUrl} target="_blank" rel="noreferrer" download className="Bold14">Download Invoice</a>
                                                                : <div id="FetchInvoiceWrap">
                                                                    {invoiceFetching && <CircularProgress id="FetchInvoiceCircular" size={12} />}
                                                                    <p id="FetchInvoice" onClick={this.handleFetchInvoice} className="Bold14">Download Invoice</p>
                                                                </div>
                                                        }
                                                    </div>

                                                    <div>
                                                        <p className="Reg16">Parts</p>
                                                        <p className="Bold16">{priceObject.parts_pieces || '-'}</p>
                                                    </div>

                                                    <div>
                                                        <p className="Reg16">Service <span><MoreInfoI
                                                            returnTooltip={() => (
                                                                <React.Fragment>
                                                                    <p className="TonesBlack">What are production lead times?</p>
                                                                    <p>The production lead time is the time it takes to manufacture your parts and apply finishes.</p>
                                                                    <br></br>
                                                                    <p className="TonesBlack">What are working days?</p>
                                                                    <p>Lead times are in working days: Monday to Friday, excluding weekends and public holidays.</p>
                                                                    <br></br>
                                                                    <p className="TonesBlack">Is there a cut off time?</p>
                                                                    <p>The cut off time for orders is 2 PM on a working day. Orders placed after 2 PM start from the next working day.</p>
                                                                </React.Fragment>
                                                            )}
                                                        /></span></p>

                                                        <p className="Bold16">{service}</p>
                                                    </div>

                                                    <div>
                                                        <p className="Reg16">Est Delivery By <span><MoreInfoI
                                                            returnTooltip={() => (
                                                                <React.Fragment>
                                                                    <p>Your order is estimated to:</p>
                                                                    <br></br>
                                                                    <p>Ship by: <span className="TonesBlack">{productionEstimate}</span></p>
                                                                    <p>Your order will leave our factory on or before this date.</p>
                                                                    <br></br>
                                                                    <p>Delivered by: <span className="TonesBlack">{deliveryEstimate}</span></p>
                                                                    <p>Your estimated delivery date is based on the shipping date and the estimated delivery time.</p>
                                                                    <br></br>
                                                                    <p>You can edit the delivery date by changing production lead times, material finishes and shipping options.</p>
                                                                </React.Fragment>
                                                            )}
                                                        /></span></p>
                                                        <p className="Bold16">{deliveryEstimate}</p>
                                                    </div>
                                                </div>
                                            }

                                            {!priceObject &&
                                                <Skeleton
                                                    variant="rounded"
                                                    width="100%"
                                                    height={250}
                                                    sx={{ borderRadius: 1 }}
                                                />
                                            }

                                            {!!priceObject &&
                                                <div className="ConfirmationSummarySection">
                                                    <div>
                                                        <p className="Reg16">Items Subtotal</p>
                                                        <p className="Bold16">{priceObject && typeof priceObject.subtotal === 'number' ? returnCurrencySignAndOrValue(this.state.quote?.currency, priceObject.subtotal, false, true) : '-'}</p>
                                                    </div>

                                                    <div>
                                                        <p className="Reg16">{this.props.windowBreakpoint?.w <= 480 ? 'Min' : 'Minimum'} order value surcharge <span><MoreInfoI
                                                            returnTooltip={() => (
                                                                <React.Fragment>
                                                                    <p>Each order takes time to prepare, manufacture, quality check and package by the 3D People team.</p>
                                                                    <br></br>
                                                                    <p>Regardless of the size of your order, there is a minimum time spent working on it.</p>
                                                                    <br></br>
                                                                    <p>The minimum order cost of {returnCurrencySignAndOrValue(this.state.quote?.currency, !!(priceObject && priceObject.moc_rate && typeof priceObject.moc_rate === 'number') ? priceObject.moc_rate : 30)} applies to all orders and does not include fees for shipping.</p>
                                                                </React.Fragment>
                                                            )}
                                                        /></span></p>
                                                        <p className="Bold16">{typeof priceObject.minimum_order_cost === 'number' ? returnCurrencySignAndOrValue(this.state.quote?.currency, priceObject.minimum_order_cost, null, true) : '-'}</p>
                                                    </div>

                                                    {!!(priceObject && priceObject?.colour_setup_splits.length) && priceObject.colour_setup_splits.sort((a, b) => a.setup_cost - b.setup_cost).map((split, index) =>
                                                        <div
                                                            key={'ConfirmationBreakdownElemColourSetup' + index + '_split'}
                                                            className="ConfirmationBreakdownElemColourSetup"
                                                        >
                                                            <div
                                                                className="ConfirmationBreakdownElemColourPreview"
                                                                style={{
                                                                    backgroundColor: split.hex,
                                                                    border: split.hex.startsWith('#FFF') ? 'var(--border-main)' : 'none'
                                                                }}
                                                            />
                                                            <div className="ConfirmationBreakdownElemColourLabel">
                                                                <Tooltip
                                                                    arrow
                                                                    title={split.label}
                                                                >
                                                                    <p className="Reg16">{split.label}</p>
                                                                </Tooltip>
                                                                <MoreInfoI
                                                                    returnTooltip={() => (
                                                                        <React.Fragment>
                                                                            <p>A fee is applied for each
                                                                                colour dye used in your
                                                                                order to cover setup
                                                                                costs.</p>
                                                                            <br></br>
                                                                            <p>The fees are clearly
                                                                                displayed as you select
                                                                                finishes and itemised in
                                                                                your quote summary.</p>
                                                                        </React.Fragment>
                                                                    )}
                                                                    styleOverwrite={{
                                                                        position: 'relative',
                                                                        top: -3
                                                                    }}
                                                                />
                                                            </div>
                                                            <p className="Bold16">{split.setup_cost}</p>
                                                        </div>
                                                    )}

                                                    <div>
                                                        <p className="Reg16">{priceObject.delivery_method? priceObject.delivery_method : '-'}</p>
                                                        <p className="Bold16">{typeof priceObject.delivery_value === 'number' ? returnCurrencySignAndOrValue(this.state.quote?.currency, priceObject.delivery_value, false, true) : '-'}</p>
                                                    </div>

                                                    {/* if vat */}
                                                    <div>
                                                        <p className="Reg16">{typeof priceObject.tax_rate === 'number' ? `VAT ${priceObject.tax_rate * 100}%` : '-'}</p>
                                                        <p className="Bold16">{typeof priceObject.vat === 'number' ? returnCurrencySignAndOrValue(this.state.quote?.currency, priceObject.vat, false, true) : '-'}</p>
                                                    </div>

                                                    <div>
                                                        <p className="Reg16">Total</p>
                                                        <p className="Bold20 MainBlue">{priceObject.total_incl_vat ? returnCurrencySignAndOrValue(this.state.quote?.currency, priceObject.total_incl_vat, false, true) : '-'}</p>
                                                    </div>
                                                </div>
                                            }
                                        </React.Fragment>
                                    }

                                    {(this.props.windowBreakpoint?.w > 1024 || isNaN(this.props.windowBreakpoint?.w)) &&
                                        <p className="Reg12">If you have any further questions, don't hesitate to contact us at <a href="mailto:print@3dpeople.uk">print@3dpeople.uk</a> or call us on <a href="tel:+442030512298">+44 203 051 2298</a>. We are here to help!</p>
                                    }
                                </div>

                                <div id="ConfirmationMainRight">
                                    <div id="UserInformationWrap">
                                        {!billing &&
                                            <Skeleton
                                                variant="rounded"
                                                height={250}
                                                className="UserAddressSkeleton"
                                                sx={{ borderRadius: 1 }}
                                            />
                                        }

                                        {!!billing &&
                                            <AddressHandler
                                                mode="confirmation"
                                                which="billing"
                                                address={billing}
                                                addressElemInfo={addressElemInfo}
                                            />
                                        }

                                        {!shipping &&
                                            <Skeleton
                                                variant="rounded"
                                                height={250}
                                                className="UserAddressSkeleton"
                                                sx={{ borderRadius: 1, marginTop: '40px' }}
                                            />
                                        }

                                        {!!shipping &&
                                            <AddressHandler
                                                mode="confirmation"
                                                which="shipping"
                                                address={shipping}
                                                addressElemInfo={addressElemInfo}
                                            />
                                        }
                                    </div>
                                </div>

                                {this.props.windowBreakpoint?.w <= 1024 &&
                                    <p className="Reg12">If you have any further questions, don't hesitate to contact us at <a href="mailto:print@3dpeople.uk">print@3dpeople.uk</a> or call us on <a href="tel:+442030512298">+44 203 051 2298</a>. We are here to help!</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    };
}

export default withRouter(Confirmation);