import React from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    Paper,
    ClickAwayListener
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import './DeleteUserModal.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function DeleteUserModal(props) {
    const {
        handleClose,
        handleUserDelete
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            id="UserDeleteAreYouSureWrap"
            className="FullHeightWidth"
        >
            <ClickAwayListener onClickAway={handleClose}>
                <Paper
                    elevation={6}
                    id="UserDeleteAreYouSureModal"
                >
                    <CloseIcon
                        id="UserDeleteAreYouSureModalClose"
                        onClick={handleClose}
                    />

                    <p className="Reg14">Are you sure?<br></br><br></br>Once deleted, all your data will be lost and unrecoverable.</p>

                    <div id="UserDeleteAreYouSureModalButtonWrap">
                        <button
                            onClick={handleUserDelete}
                            className="Bold16 UserRedButton"
                        >
                            Delete account
                        </button>
                    </div>
                </Paper>
            </ClickAwayListener>
        </div>
    );
};