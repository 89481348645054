import React, {
    useContext
} from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./FooterImgLinks.css";

import General from '../../context/general';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function FooterImgLinks(props) {
    const {
        marginTop = 50,
        paddingTop = 30
    } = props;

    const {
        footerImgLinks
    } = useContext(General);

    if (!footerImgLinks) {
        return null;
    }

    return (
        <div
            id="FooterImgLinks"
            style={{
                marginTop: marginTop,
                paddingTop: paddingTop
            }}
        >
            <div id="FooterImgLinksSectionWrap" className="PageContentWidth">
                {footerImgLinks.map((footerImgLink, index) => {
                    return (
                        <a className="FooterImgLinksSection" key={'footer_img_link_' + index} rel="noreferrer" target="_blank" href={footerImgLink.link}>
                            <div
                                title={footerImgLink.alt}
                                style={{
                                    backgroundImage: `url(${footerImgLink.img})`
                                }}
                            ></div>
                            <p className="Reg12">Learn More &#160; &#8594;</p>
                        </a>
                    );
                })}
            </div>
        </div>
    );
};