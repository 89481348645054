import React, {
    useState,
    useRef,
    useEffect
} from 'react';

import clsx from 'clsx';

import QuantityInput from '../QuantityInput/QuantityInput';

import CloseIcon from '@mui/icons-material/Close';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

import {
    Checkbox
} from '@mui/material';

import './ApplyOptionsToAllModal.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function ApplyOptionsToAllModal(props) {
    const {
        handleClose,
        handleUpdate,
        windowBreakpoint,
        partData,
        definitions,
        maxPartQuantity
    } = props;

    const applyQuantityRef = useRef();

    const [techMatSelected, setTechMatSelected] = useState(true);
    const [quantitySelected, setQuantitySelected] = useState(false);
    const [quantityValue, setQuantityValue] = useState(null);

    const returnWarningModalMessage = techMat => {
        if (!definitions) return '';

        let material = (techMat && partData.material_id) || null;
        let finish = (!techMat && partData.finish_id) || null;
        let colour = (!techMat && partData.colour_id) || null;
        let colour_val = (!techMat && partData.colour_val) || null;

        let technologyLabel = material && definitions.materials[material]?.tech_label_long ? definitions.materials[material].tech_label_long : '';
        let technologyShortLabel = material && definitions.materials[material]?.tech_label_short ? definitions.materials[material].tech_label_short : '';
        let materialLabel = material && definitions.materials[material]?.label ? definitions.materials[material].label : '';
        let surfaceFinishLabel = finish && definitions.surface_finishes[finish]?.label ? definitions.surface_finishes[finish].label : '';
        let colourFinishLabel = colour && definitions.colour_finishes[colour]?.label ? definitions.colour_finishes[colour].label : '';
        let colourLabel = colour && definitions.colour_finishes[colour]?.multi_colour && colour_val && definitions.colour_values[colour_val]?.label ? definitions.colour_values[colour_val].label : '';

        return techMat ? `${technologyLabel} (${technologyShortLabel}): ${materialLabel}`
            : `${surfaceFinishLabel} + ${colourFinishLabel}${colourLabel ? ` (${colourLabel})` : ''}`;
    };

    // close the warning modal when user clicks away from elem
    const handleClickAwayWarning = e => {
        if (e.target.id === 'QuoteOverlayWarningModalWrap') handleClose();
    };

    const handleApplyToAllQuantityClick = increment => {
        // if the user hasn't enabled quantity update then do so
        if (!quantitySelected) setQuantitySelected(true);

        // if there is a value and it's a number then parse, else set to 1
        let valueToIncrement = !isNaN(quantityValue) ? parseInt(quantityValue) : 1;

        setQuantityValue(valueToIncrement + (increment ? 1 : -1));
    };

    const handleApplyToAllInputOnChange = value => {
        // if the user hasn't enabled quantity update then do so
        if (!quantitySelected) setQuantitySelected(true);

        // if there is a val and it is a number then parse, else set to empty string
        let valueToUse = value && !isNaN(value) ? parseInt(value) : '';

        setQuantityValue(valueToUse);
    };

    const handleCheckApplyToAllInputValue = () => {
        setQuantityValue(
            quantityValue > maxPartQuantity ? maxPartQuantity
                : isNaN(quantityValue) || quantityValue < 1 ? 1
                : quantityValue
        );
    };

    const handleConfirm = () => {
        let options = {
            material_id: techMatSelected && partData.material_id ? partData.material_id : null,
            finish_id: techMatSelected && partData.finish_id ? partData.finish_id  : null,
            colour_id: techMatSelected && partData.colour_id ? partData.colour_id : null,
            colour_val: techMatSelected && partData.colour_val ? partData.colour_val : null,
            quantity: quantitySelected && typeof quantityValue === 'number' ? quantityValue : null
        };

        // update the parts with the selected options
        handleUpdate(options);

        // close the quote overlay warning modal after the update has been invoked
        handleClose();
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        if (partData && partData?.price?.qty && applyQuantityRef?.current) {
            setQuantityValue(partData.price.qty);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (applyQuantityRef?.current) {
            applyQuantityRef.current.value = quantityValue;
        }
    }, [quantityValue]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            id="QuoteOverlayWarningModalWrap"
            className="FullHeightWidth"
            onClick={handleClickAwayWarning}
        >
            <div>
                <button
                    id="QuoteOverlayWarningModalCloseButton"
                    onClick={handleClose}
                >
                    <CloseIcon id="QuoteOverlayWarningModalCloseButtonIcon"/>
                </button>

                <WarningAmberIcon id="QuoteOverlayWarningModalIcon" />

                <p className="Bold16">Apply to all items in your quote</p>

                <div id="QuoteOverlayWarningModalOptionsWrap">
                    <div
                        onClick={e => e.target.classList.contains('QuoteOverlayWarningModalOption') ? setTechMatSelected(!techMatSelected) : null}
                        className={clsx('QuoteOverlayWarningModalOption', techMatSelected ? 'QuoteOverlayWarningModalOptionSelected' : null)}
                    >
                        <Checkbox
                            size="medium"
                            checked={techMatSelected}
                            onChange={e => setTechMatSelected(e.target.checked)}
                            disableRipple={true}
                            classes={{
                                root: clsx("QuoteOverlayWarningModalCheckbox")
                            }}
                        />

                        <div id="QuoteOverlayWarningModalTechMatWrap">
                            <p className="Bold14"><span className="Reg16">Material:</span> {returnWarningModalMessage(true)}</p>
                            <p className="Bold14"><span className="Reg16">Finish:</span> {returnWarningModalMessage()}</p>
                        </div>
                    </div>

                    <div
                        onClick={
                            !maxPartQuantity ? null
                            : e => e.target.classList.contains('QuoteOverlayWarningModalOption') ? setQuantitySelected(!quantitySelected)
                            : null
                        }
                        className={clsx(
                            'QuoteOverlayWarningModalOption',
                            !maxPartQuantity ? 'QuoteOverlayWarningModalOptionDisabled' : null,
                            quantitySelected ? 'QuoteOverlayWarningModalOptionSelected' : null)
                        }
                    >
                        <Checkbox
                            size="medium"
                            checked={quantitySelected}
                            onChange={e => setQuantitySelected(e.target.checked)}
                            disableRipple={true}
                            classes={{
                                root: clsx("QuoteOverlayWarningModalCheckbox")
                            }}
                        />

                        <div id="QuoteOverlayWarningModalQuantityWrap">
                            <p className="Reg16">{windowBreakpoint?.w > 480 || isNaN(windowBreakpoint?.w) ? 'Apply quantity of' : 'Quantity'}</p>

                            <QuantityInput
                                rootStyle={{
                                    height: '40px',
                                    flex: 'unset',
                                    margin: '0px 14px',
                                    pointerEvents: !maxPartQuantity ? 'none' : 'all'
                                }}
                                mainStyle={{ height: '100%' }}
                                refToUse={applyQuantityRef}
                                disabled={!maxPartQuantity}
                                addDisabled={!maxPartQuantity || quantityValue >= maxPartQuantity}
                                handleAddOnClick={!maxPartQuantity ? null : () => handleApplyToAllQuantityClick(true)}
                                subtractDisabled={quantityValue <= 1 || !maxPartQuantity}
                                handleSubtractOnClick={!maxPartQuantity ? null : () => handleApplyToAllQuantityClick(false)}
                                handleOnChange={!maxPartQuantity ? null : e => handleApplyToAllInputOnChange(!!e.target.value ? e.target.value : null)}
                                handleOnInput={!maxPartQuantity ? null : e => e.target.value = e.target.value.replace(/[^\d]/g, '')}
                                handleOnBlur={handleCheckApplyToAllInputValue}
                            />

                            {(windowBreakpoint?.w > 480 || isNaN(windowBreakpoint?.w)) && <p>to all items</p>}
                        </div>
                    </div>
                </div>

                <p className="Reg16">{'(Change the material and finish and keep your current quantity selections)'}</p>

                <div>
                    <button
                        disabled={!(techMatSelected || quantitySelected)}
                        onClick={handleConfirm}
                        className={clsx('Bold16', !(techMatSelected || quantitySelected) ? 'QuoteOverlayWarningModalButtonDisabled' : null)}
                    >{windowBreakpoint?.w <= 480 ? 'Apply' : 'Yes, Apply to All' }</button>

                    <button className="Bold16" onClick={handleClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};