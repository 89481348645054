import React, {
    Component,
    createRef
} from 'react';

import {
    withRouter
} from 'react-router-dom';

import {
    isCancelError,
    put
} from 'aws-amplify/api';

import axios from 'axios';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import clsx from 'clsx';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import QuotePart from '../Misc/QuotePart/QuotePart';
import QuotePartSkeleton from '../Misc/QuotePartSkeleton/QuotePartSkeleton';
import DropDownList from '../Misc/DropDownList/DropDownList';
import QuotePartOverlay from '../Misc/QuotePartOverlay/QuotePartOverlay';
import ErrorBoundary from '../Misc/ErrorBoundary/ErrorBoundary';
import TimeRemaining from '../Misc/TimeRemaining/TimeRemaining';
import MoreInfoI from '../Misc/MoreInfoI/MoreInfoI';
import AddressHandler from '../Misc/AddressHandler/AddressHandler';
import OptionsList from '../Misc/OptionsList/OptionsList';
import UploadIcon from '../Icons/UploadIcon';
import FooterImgLinks from "../FooterImgLinks/FooterImgLinks";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import StarIcon from '@mui/icons-material/Star';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import GroupsIcon from '@mui/icons-material/Groups';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    handleApiReq,
    resolveBodyPromise,
    handleClickAway,
    callApiAndReturnCombinedResultsData,
    sortPartsByFileKey,
    handlePushToDataLayer,
    handleError,
    calculateTotalPrice,
    isPriceLoading,
    returnListTitleLabel,
    calculateProductionDifference,
    determineIfRequiredSatisfiedReturnValues,
    determineIfEmailIsSatisfied,
    returnShippingAndDeliveryEstimate,
    returnCurrencySignAndOrValue,
    getSetCurrencyLSValue,
    returnRFQTooltip,
    isNumber,
    determineIfThereIsWarnings,
    determineMaximumLeadTime
} from '../../functions/utils';

import {
    returnApi,
    returnReqInit,
    handleUserLockQuote,
    handleUserDownloadPDF,
    setFavourite,
    cloneQuoteOrder,
    setNickname
} from '../../functions/user_functions';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    Tooltip,
    Skeleton
} from '@mui/material';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./Quote.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

const FORCED_DEFINITIONS_INDICES = ['materials', 'surface_finishes', 'colour_finishes'];

class Quote extends Component {
    constructor(props) {
        super(props);

        this.contentRef = createRef();
        this.toolbarActions = createRef();
        this.nicknameInputRef = createRef();
        this.addressWrapRef = createRef();
        this.addressesInfoRefs = createRef();
        this.addressesInfoRefs.current = {
            billing: {},
            shipping: {}
        };
        this.billingEmailRef = createRef();

        this.state = {
            leadTimeListOptions: null,
            timeRemainingIntervalId: null,
            listTitleBrief: null,
            listTitlePrice: null,
            listTitleLabel: null,
            listTitleDescription: null,
            selectedLeadTimeValue: null,
            thumbnailErrorUrl: null,
            uploading: [],
            quote: null,
            parts: [],
            skeletons: [],
            quotePartOverlayOpen: false,
            quotePartOverlayIndex: null,
            hideQuotePartBorders: false,
            quantityChangeTimeoutArray: [],
            quantityInputOnChangeTimeoutArray: [],
            movingToNextStage: false,
            partsToRecursivelyCheckTimeoutLength: null,
            partsToRecursivelyCheckTimeout: null,
            partsToRecursivelyCheck: [],
            totalPriceLoading: true,
            totalPriceWaiting: false,
            totalPriceInitialFetch: false,
            totalPriceIncrementor: 0,
            totalPriceIncrementorData: null,
            showNoPartsDiv: false,
            cloningActive: false,
            canCloneAgain: null,
            recursiveReqPromise: null,
            partsOptionsTempOverwrites: {},
            manualOverlayTriggerValue: 0,
            toolbarActionsOpen: false,
            toolbarActionLoading: null,
            nicknameWarning: null,
            nicknameToolbarVisible: false,
            toolbarInputEditVisible: false,
            billingAddressOpen: false,
            billingAddressWarning: null,
            shippingAddressOpen: false,
            shippingAddressWarning: null,
            user: null,
            addressElemInfo: null,
            addressToMutate: null,
            countriesOptions: null,
            shippingOptions: [],
            proceedToCheckoutWarning: null,
            paymentLoading: false,
            estimatedDates: null,
            billingEmailWarning: false,
            requestForQuoteTriggers: null,
            requestForQuoteReasons: [],
            requestForQuoteActive: false,
            maxPartQuantity: null,
            definitions: null,
            optionsTree: null
        };
    };

    async componentDidMount() {
        window.scrollTo({
            top: 0,
            left: 0
        });

        // set loading to true as we will be fetching options from the config
        this.props.handleLoading(true);

        // remove qid data
        localStorage.removeItem('qid');

        // check to see if quote exists
        handleApiReq(
            'get',
            `/crud/quote/${this.props.id}`
        ).then(resultX => {
            const {
                reference_number,
                paid,
                billing,
                shipping,
                dismissible_note,
                under_review
            } = resultX.data.quote_data;

            if (!reference_number) {
                // redirect to upload page
                this.props.history.push('/upload/');
            } else if (paid) {
                // if the user has page redirect them to the confirmation page
                this.props.history.push(`/confirmation/${this.props.id}`);
            } else {
                this.setState({
                    quote: resultX.data.quote_data,
                    shippingOptions: resultX.data.shipping_options,
                    selectedShippingOption: resultX.data.quote_data.selected_shipping_option,
                    addressToMutate: {
                        billing,
                        shipping
                    }
                }, async () => {
                    // fetch options and from the config
                    callApiAndReturnCombinedResultsData([
                        {method: 'get', path: '/fetch-config/quote-options'},
                        {method: 'get', path: '/fetch-config/upload-options'},
                        {method: 'get', path: '/fetch-config/definitions'},
                        {method: 'get', path: '/fetch-config/options-tree'},
                        {method: 'get', path: '/fetch-config/holidays'},
                        {method: 'get', path: '/fetch-config/stripe-options'},
                        {method: 'get', path: '/fetch-config/address-options'},
                        {method: 'get', path: '/fetch-config/countries-options'},
                        {method: 'get', path: `/crud/parts/${this.props.id}`}
                    ]).then(async result => {
                        this.props.handleAlienData({
                            quote_id: this.props.id,
                            locked: this.state.quote.locked,
                            css: this.state.quote.css,
                            aas: this.state.quote.aas,
                            sso: this.state.shippingOptions.filter(item => item.service_code === this.state.selectedShippingOption)[0],
                            dn: this.state.quote.dismissible_note,
                            clt: this.state.quote.clt,
                            c: this.state.quote.currency,
                            ur: this.state.quote.under_review,
                            que: this.state.quote.email,
                            ahZzZ: this.state.quote.admin_hidden || this.state.quote.user_hidden
                        });

                        let defaultLeadTimeOption = result.lead_time_list_options.filter(x => x.value.type === this.state.quote.selected_lead_time)[0];

                        // get expected parts data
                        let expectedParts = localStorage.getItem('expected_parts');
                        if (expectedParts) expectedParts = JSON.parse(expectedParts);

                        // determine and set appropriate skeletons, parts that don't exist in req payload
                        let skeletonsToSet;
                        if (expectedParts && expectedParts.length && (result.parts_data.length < expectedParts.length || true)) {
                            skeletonsToSet = expectedParts.filter(item => item.upload_pk === this.props.id && result.parts_data.findIndex(itemX => itemX.SK === item.upload_sk) === -1).map((item, index) => {
                                return {
                                    PK: this.props.id,
                                    SK: item.upload_sk,
                                    is_skeleton: true,
                                    created_at: item.created_at,
                                    status: 'INIT',
                                    message: item.upload_message,
                                    index,
                                    id: index
                                }
                            });

                            // remove parts from expected parts which were returned from req
                            let partsToRemove = expectedParts.filter(item => item.upload_pk === this.props.id && result.parts_data.findIndex(itemX => itemX.SK === item.upload_sk) !== -1);
                            this.handleRemoveFromExpected(partsToRemove.map(item => item.upload_sk));
                        }

                        // initial parts set
                        let partsDataToSet = result.parts_data.map(part => {
                            return { ...part, ...{ part_loading: 'WAITING' } };
                        });

                        sortPartsByFileKey(partsDataToSet);

                        // compute triggers with the currency value
                        let cVal = this.state.quote?.currency?.value ? this.state.quote.currency.value : 1;
                        const rFQTriggers = result.request_for_quote_triggers;
                        for (const [k, v] of Object.entries(rFQTriggers)) {
                            if (k.includes('price')) {
                                rFQTriggers[k] = v * cVal;
                            }
                        }

                        this.setState({
                            leadTimeListOptions: result.lead_time_list_options.filter(option => this.state.quote.locked ? this.state.quote.selected_lead_time === option.value.type : option.visible),
                            toolbarActionsOptions: result.toolbar_actions_options.filter(option => option.visible),
                            partSettingsButtonOptions: result.part_settings_button_options,
                            selectedLeadTimeValue: defaultLeadTimeOption.value,
                            thumbnailErrorUrl: result.thumbnail_error_url,
                            partsToRecursivelyCheckTimeoutLength: result.recursive_parts_check_timeout_length,
                            parts: partsDataToSet.sort((a, b) => a.created_at - b.created_at),
                            showNoPartsDiv: !partsDataToSet.length,
                            fileLimit: result.file_limit,
                            startFromIndex: result.total_parts_deleted_non_deleted,
                            skeletons: skeletonsToSet ? skeletonsToSet : [],
                            holidays: result.holidays,
                            initLoaded: true,
                            addressElemInfo: result.address_inputs,
                            countriesOptions: result.countries_options,
                            requestForQuoteTriggers: this.props.isAdmin ? {} : rFQTriggers,
                            maxPartQuantity: result.max_part_quantity,
                            definitions: result.definitions,
                            optionsTree: result.options_tree
                        }, () => {
                            getSetCurrencyLSValue({
                                ...this.state.quote.currency,
                                disabled: this.state.quote.locked || this.state.quote.expired
                            });

                            this.props.handleLoading(false);

                            if (!this.props.isAdmin && dismissible_note && under_review) {
                                this.props.toggleActiveModal('dismissible_note', {
                                    quoteId: this.props.id,
                                    dismissibleNote: dismissible_note,
                                    handleCloseCallback: () => {
                                        this.props.toggleActiveModal('under_review', {
                                            quoteId: this.props.id,
                                            refNo: this.state.quote?.reference_number,
                                            loggedIn: this.props.loggedIn,
                                            handleCopy: this.handleUserClone
                                        });
                                    }
                                });
                            } else {
                                if (dismissible_note && !this.props.isAdmin) {
                                    this.props.toggleActiveModal('dismissible_note', {
                                        quoteId: this.props.id,
                                        dismissibleNote: dismissible_note
                                    });
                                }

                                if (under_review && !this.props.isAdmin) {
                                    this.props.toggleActiveModal('under_review', {
                                        quoteId: this.props.id,
                                        refNo: this.state.quote?.reference_number,
                                        loggedIn: this.props.loggedIn,
                                        handleCopy: this.handleUserClone
                                    });
                                }
                            }

                            this.initiateRecursivePartsCheck();
                        });
                    }).catch(error => {
                        handleError('15400Yun', error);
                    });
                });
            }
        }).catch(error => {
            if (error.response && error.response.data && error.response.data.challenge && error.response.data.challenge_type) {
                this.props.handleLoading(false);
                this.props.history.push(`/challenge/q/${error.response.data.challenge_type}/${error.response.data.challenge}`);
            } else {
                handleError('56816nUB', error || 'something went wrong', true);
                // redirect to upload page
                this.props.history.push(`/upload`);
            }
        });
    };

    componentWillUnmount() {
        // clear the interval indicating remaining time for lead times
        if (this.state.timeRemainingIntervalId) clearInterval(this.state.timeRemainingIntervalId);

        // if there is a timeout checking the parts recursively then clear from the window
        if (this.state.partsToRecursivelyCheckTimeout) clearTimeout(this.state.partsToRecursivelyCheckTimeout);

        // if we are mid request that leads to recursion, cancel it
        if (this.state.recursiveReqPromise) this.state.recursiveReqPromise.cancel('cancelled by unmount');

        // if there are any timeouts for the quantity change via input then clear them
        if (this.state.quantityChangeTimeoutArray.length) {
            for (const elem of this.state.quantityChangeTimeoutArray) {
                clearTimeout(elem.timeoutId);
            }
        }
    };

    // determine if we need to update the total price because of a change to part setting before the next render is called
    getSnapshotBeforeUpdate(prevProps, prevState) {
        if (this.state.parts.length && this.state.totalPriceWaiting && !this.state.parts.some(x => x.part_loading) && prevState.parts.some(x => x.part_loading)) {
            return {
                type: 'determineTotalPrice',
                location: 1,
                data: this.state.totalPriceIncrementorData
            }
        } else if (this.state.parts.length && !this.state.parts.some(x => x.part_loading) && prevState.parts.some(x => x.part_loading)) {
            return {
                type: 'determineTotalPrice',
                location: 2,
                data: this.state.totalPriceIncrementorData
            }
        } else if ((this.state.parts.length && !this.state.parts.some(x => x.part_loading)) && !this.state.totalPriceInitialFetch) {
            return {
                type: 'determineTotalPrice',
                location: 3,
                data: this.state.totalPriceIncrementorData
            }
        } else if (this.state.parts.length && this.state.totalPriceWaiting && this.state.totalPriceIncrementor !== prevState.totalPriceIncrementor && !this.state.parts.some(x => x.part_loading)) {
            // TODO: determine cleaner way of handling this, seems to be required for when the user address changes, and the vat value of the address is checked
            return {
                type: 'determineTotalPrice',
                location: 4,
                data: this.state.totalPriceIncrementorData
            }
        } else if (this.state.parts.length && !this.state.parts.some(x => !x.price || !x.price.success) && prevState.parts.some(x => !x.price || !x.price.success)) {
            return {
                type: 'determineTotalPrice',
                location: 5,
                data: this.state.totalPriceIncrementorData
            }
        } else if (this.state.parts.length && this.state.parts.length !== prevState.parts.length) {
            return {
                type: 'determineTotalPrice',
                location: 6,
                data: this.state.totalPriceIncrementorData
            }
        } else if (this.props.checkUpdateUserSubResolved && !prevProps.checkUpdateUserSubResolved) {
            return {
                type: 'fetchUserAddress',
                location: 7
            }
        } else if (prevState.parts.length && !this.state.parts.length) {
            return {
                type: 'determineTotalPrice',
                location: 8,
                data: this.state.totalPriceIncrementorData
            }
        } else if (prevState.quote && !prevState.quote.locked && this.state.quote && this.state.quote.locked) {
            return {
                type: 'quoteHasLocked',
                location: 10
            }
        } else {
            return null;
        }
    };

    componentDidUpdate(pevProps, prevState, snapshot) {
        if (snapshot) {
            // if a price changing event was detected fetch new total price (use to be fetch, now it's done on frontend)
            if (snapshot.type === 'determineTotalPrice') {
                this.determineTotalPrice(snapshot.location);
            } else if (snapshot.type === 'fetchUserAddress') {
                this.fetchAddress();
            } else if (snapshot.type === 'quoteHasLocked') {
                this.handleCollapsableClose();
            }
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // handle the toggle on the toolbar actions elem
    handleActionsOpenClose = () => {
        this.setState({
            // toolbarActionsOpen: bypass !== undefined ? bypass : !this.state.toolbarActionsOpen ? true : e.currentTarget === e.target ? false : this.state.toolbarActionsOpen
            toolbarActionsOpen: !this.state.toolbarActionsOpen
        });
    };

    // determine if an action option should be visible
    determineIsActionHidden = option => {
        const {
            value
        } = option;

        return !!(value === 'lock_quote' && this.state.quote && this.state.quote.locked);
    };

    // return the appropriate message per action
    returnIsDisabled = (which, action) => {
        if (which === 'prices_loading') {
            return (
                action === 'lock_quote' ? 'Cannot lock whilst prices are loading'
                : action === 'download_pdf' ? 'Cannot download quote PDF whilst prices are loading'
                : action === 'share_secure_link' ? 'Cannot generate secure share link whilst prices are loading'
                : action === 'clone_quote' || action === 'favourite_quote' ? false
                : '__'
            );
        } else if (which === 'warnings') {
            return (
                action === 'lock_quote' ? 'Please clear warnings before locking your quote'
                : action === 'download_pdf' ? 'Please clear warnings before downloading quote PDF'
                : action === 'share_secure_link' ? 'Please clear warnings before generating secure share link'
                : action === 'clone_quote' || action === 'favourite_quote' ? false
                : '__'
            );
        } else if (which === 'expired') {
            return (
                action === 'lock_quote' ? 'Cannot lock an expired quote'
                : action === 'download_pdf' ? 'Cannot download quote PDF for an expired quote'
                : action === 'share_secure_link' ? 'Cannot generate a secure share link for an expired quote'
                : action === 'clone_quote' || action === 'favourite_quote' ? false
                : action === 'request_for_quote' ? 'Cannot RFQ for an expired quote'
                : '__'
            );
        } else {
            return '__';
        }
    };

    // Admin locked quotes cannot be copied

    // return the action option elem
    returnToolbarActionElem = option => {
        const {
            label,
            value,
            description,
            requires_locked,
            requires_account
        } = option;

        const isThereWarnings = determineIfThereIsWarnings(this.state.parts);
        let filteredParts = this.state.parts.filter(part => part.deleting !== 'finished');

        let disabled =
            !this.props.loggedIn && requires_account ? 'Please login to use this feature'
            : this.state.quote && this.state.quote.expired ? this.returnIsDisabled('expired', value)
            : value === 'lock_quote' && this.state.requestForQuoteActive ? 'Please submit a Request for Quote'
            : value === 'request_for_quote' && this.state?.quote?.locked ? 'Quote is locked'
            : !!(requires_locked && (this.state.quote && !this.state.quote.locked)) ? 'Your quote must be locked first'
            : !filteredParts.length ? 'No parts detected'
            : isThereWarnings && value !== 'request_for_quote' ? this.returnIsDisabled('warnings', value)
            : filteredParts.some(part => !part.price || !part.price.success) && value !== 'request_for_quote' ? this.returnIsDisabled('prices_loading', value)
            : false;

        let thisLoading = !!(this.state.toolbarActionLoading && this.state.toolbarActionLoading === value);
        let labelToUse = value === 'favourite_quote' && this.state.quote && this.state.quote.favourite ? 'Remove from Favourites'
            : label;
        let descriptionToUse = value === 'favourite_quote' && this.state.quote && this.state.quote.favourite ? 'Remove this quote from your favourites'
            : description;

        return (
            <div className="QuoteActionWrap" disabled={+!!(disabled)} loading={this.state.toolbarActionLoading} thisloading={+thisLoading}>
                <p className={clsx('Bold12', thisLoading ? 'MainBlue' : null)} highlightonhover="true">{labelToUse}</p>

                <p className={clsx('Reg12', thisLoading ? 'QuoteActionLoadingP' : null)}>{thisLoading ? 'loading' : disabled ? disabled : descriptionToUse}</p>
            </div>
        );
    };

    // handle the user using a toolbar option
    handleToolbarAction = (e, option) => {
        const {
            value,
            requires_locked,
            requires_account
        } = option;

        if (!!(requires_account && !this.props.loggedIn)) return;
        if (!!(requires_locked && (this.state.quote && !this.state.quote.locked))) return;
        if (this.state.toolbarActionLoading) return;

        this.setState({
            toolbarActionLoading: value
        }, async () => {
            if (value === 'lock_quote') {
                await this.handleUserLockClick();
            } else if (value === 'download_pdf') {
                await this.handleUserDownloadPDFClick();
            } else if (value === 'favourite_quote') {
                await this.handleUserFavourite();
            } else if (value === 'clone_quote') {
                await this.handleUserClone(false);
            } else if (value === 'share_secure_link') {
                await this.handleUserShareSecureLink();
            } else if (value === 'request_for_quote') {
                this.showRequestForQuoteModal();
            }

            this.setState({
                toolbarActionLoading: null,
                toolbarActionsOpen: false
            });
        });
    };

    // UNUSED?
    // handle the blur function of the lead time select elem
    // handleListClickAway = (e, desiredBoolean) => {
    //     e.stopPropagation();
    //
    //     if (handleClickAway(e)) {
    //         this.setState({
    //             leadTimeListOpen: desiredBoolean
    //         });
    //     }
    // };
    //
    // // handle the mouse move event on the lead time select elem
    // handleListMouseMove = e => {
    //     if (e.target.id === 'SelectLeadTimeSelect' && e.currentTarget.id === e.target.id) {
    //         if (!e.currentTarget.classList.contains('SelectLeadTimeSelectHover')) {
    //             e.currentTarget.classList.add('SelectLeadTimeSelectHover');
    //         }
    //     } else {
    //         if (e.currentTarget.classList.contains('SelectLeadTimeSelectHover')) {
    //             e.currentTarget.classList.remove('SelectLeadTimeSelectHover');
    //         }
    //     }
    // };
    //
    // // handle the mouse leave event on the lead time select elem
    // handleListMouseLeave = e => {
    //     if (e.target.classList.contains('SelectLeadTimeSelectHover')) {
    //         e.target.classList.remove('SelectLeadTimeSelectHover');
    //     }
    // };

    // handle the user lead time selection
    handleLeadTimeSelection = (e, option) => {
        e.stopPropagation();

        this.setState({
            selectedLeadTimeOption: option,
            selectedLeadTimeValue: option.value,
            totalPriceLoading: true
        }, async () => {
            this.props.handleLoading(true)

            handleApiReq(
                'put',
                '/crud',
                {
                    action: 'UPDATE_QUOTE_LEAD_TIME',
                    quote_id: this.props.id,
                    attach_quote_data: true,
                    attach_config_data: true,
                    val: option.value.type
                }
            ).then(() => {
                this.refreshQuoteData().then(() => {
                    this.props.handleLoading(false);
                    this.initiateRecursivePartsCheck(true);
                }).catch(error => {
                    this.props.handleLoading(false);
                    handleError('52610Fbm', error);
                });
            }).catch(error => {
                this.props.handleLoading(false);
                handleError('10159MuS', error);
            });
        });
    };

    // handle the user opening up the part settings
    handlePartSettingsClick = (anchorEl, positionIndex) => {
        this.setState({
            quotePartOverlayOpen: anchorEl,
            quotePartOverlayIndex: positionIndex,
            manualOverlayTriggerValue: this.state.manualOverlayTriggerValue + 1
        });
    };

    // handle manually refreshing the quote part overlay
    handleQuotePartOverlayRefresh = () => this.setState({ manualOverlayTriggerValue: this.state.manualOverlayTriggerValue + 1 });

    // handle showing the borders on the part elems, this is to sync up with the opacity
    handleShowBorders = cb => {
        this.setState({
            hideQuotePartBorders: false
        }, cb);
    };

    // close the part settings  overlay
    handleOverlayClose = () => {
        this.setState({
            quotePartOverlayOpen: false,
            quotePartOverlayIndex: null,
            quotePartUsingCollapsable: false
        });
    };

    // handle collapsable close event
    handleCollapsableClose = () => {
        this.setState({
            quotePartUsingCollapsable: false,
            quotePartOverlayIndex: null
        });
    };

    // handles the user clicking on a part option, either material, surface finish and colour finish
    // 'SK' can be used to determine index of the part in state.parts by passing it to the function
    // 'which' indicates what type of value is being selected
    // 'value' is an int that points a definition and is used to navigate the pricing tree
    // 'colour_value' is an int that points to a colour definition, should only be present if 'which' is 'colour_finishes'
    // 'defaults' will only be present if the type of value selected is mater, if defaults is present then all values of the part will need to be changed
    // using the given arguments, we are going to change the 'material_id', 'finish_id', 'colour_id' and 'colour_val'
    handleApplyClick = (SK, which, value, colour_value, defaults, currentSpec, callback, callbefore) => {
        let index = this.returnIndex(SK);
        if (index === -1) return;

        let partsClone = [...this.state.parts];

        if (which === 'materials' && !!defaults) {
            const {
                spec,
                colour_val
            } = defaults;

            let matToSet = spec[0];
            let currOptionsBranch = this.state.optionsTree[matToSet];

            // if the current surface finish and colour finish are not in the new material options then set them to the defaults
            // surface finish check
            let currSurfaceFinishValid = false;
            try {
                currSurfaceFinishValid = currOptionsBranch[partsClone[index].finish_id] && currOptionsBranch[partsClone[index].finish_id].visible;
            } catch (err) {
                console.log('current surface finish invalid');
            }
            let surfaceFinishToSet = currSurfaceFinishValid ? partsClone[index].finish_id : spec[1];

            // colour finish check
            let currColourFinishValid = false;
            try {
                currColourFinishValid = currOptionsBranch[surfaceFinishToSet][partsClone[index].colour_id] && currOptionsBranch[surfaceFinishToSet][partsClone[index].colour_id].visible;
            } catch (err) {
                console.log('current colour finish invalid');
            }

            // values should cascade from this point, if the colour value is valid then we use the current colour value, else we use the default colour of surfaceFinishToSet
            // then if colour val is valid then we use the current colour val, else we use the first colour value of the colour finish

            // instead of spec[2] we should use the default colour of the surface finish
            let colourIdToSet;
            try {
                colourIdToSet = currColourFinishValid ? partsClone[index].colour_id : currOptionsBranch[surfaceFinishToSet].default_colour;
            } catch (err) {
                console.log('error caught @ setting colour id: setting vals to defaults')
                surfaceFinishToSet = spec[1];
                colourIdToSet = spec[2];
            }

            let currColourValueValid = false;
            try {
                currColourValueValid = currColourFinishValid ? currOptionsBranch[surfaceFinishToSet][colourIdToSet].colour_value.includes(partsClone[index].colour_val) : false;
            } catch (err) {
                console.log('current colour value invalid');
            }

            let colourValToSet;
            try {
                // instead of using colour_val we should use the first colour value of the colour finish
                colourValToSet = currColourValueValid ? partsClone[index].colour_val : currOptionsBranch[surfaceFinishToSet][colourIdToSet].colour_value[0];
            } catch (error) {
                console.log('error caught @ setting colour val: setting vals to defaults');
                surfaceFinishToSet = spec[1];
                colourIdToSet = spec[2];
                colourValToSet = colour_val;
            }

            partsClone[index].material_id = matToSet;
            partsClone[index].finish_id = surfaceFinishToSet;
            partsClone[index].colour_id = colourIdToSet;
            partsClone[index].colour_val = colourValToSet;
        } else if (which === 'surface_finishes') {
            partsClone[index].finish_id = value;

            // we still need to set a default colour here
            // so what we should do is refer to the tree and get the first colour value if there is only one
            // TODO: maybe there is a better way of achieving this?
            let currMat = currentSpec[0];
            try {
                let currOptionsBranch = this.state.optionsTree[currMat][value];
                if (currOptionsBranch) {
                    let colourIdToSet = currOptionsBranch.default_colour;
                    let colourValToSet = currOptionsBranch[colourIdToSet].colour_value[0];

                    // check here to see if the current selected colour and colour_value is in the colour options for the newly selected finish
                    // if so then don't change the current options for colour and colour_value
                    let currColourFinishValid = false;
                    try {
                        currColourFinishValid = currOptionsBranch[partsClone[index].colour_id] && currOptionsBranch[partsClone[index].colour_id].visible;
                    } catch (err) {
                        console.log('current colour finish invalid')
                    }

                    let currColourValueValid = false;
                    try {
                        currColourValueValid = currOptionsBranch[partsClone[index].colour_id].colour_value.includes(partsClone[index].colour_val);
                    } catch (err) {
                        console.log('current colour value invalid')
                    }

                    if (!(currColourFinishValid && currColourValueValid) && colourIdToSet && colourValToSet) {
                        partsClone[index].colour_id = colourIdToSet;
                        partsClone[index].colour_val = colourValToSet;
                    }
                }
            } catch (error) {
                console.error('error caught @ trying to set default colour', error);
            }
        } else if (which === 'colour_finishes') {
            partsClone[index].colour_id = value;
            partsClone[index].colour_val = colour_value;
        }

        const {
            material_id,
            finish_id,
            colour_id,
            colour_val
        } = partsClone[index];

        this.setState({
            parts: partsClone
        }, () => {
            if (typeof callbefore === 'function') {
                callbefore();
            }

            this.handleUpdateOptions([{
                PK: partsClone[index].PK,
                SK: partsClone[index].SK
            }], {
                material_id,
                finish_id,
                colour_id,
                colour_val
            }, callback);
        });
    };

    // handle user clicking on bulk prices option
    handleBulkPricesClick = (SK, newQuantity) => {
        if (SK) {
            this.handleQuantityInputChangeTimeout(SK, newQuantity);
        }

        this.props.toggleActiveModal(null);
    };

    // handle update parts options either by option click or apply to all button
    handleUpdateOptions = (parts, options, callback) => {
        let partsClone = [...this.state.parts];
        let partsOptionsTempOverwritesClone = {...this.state.partsOptionsTempOverwrites};

        for (const part of parts) {
            let partIndex = partsClone.map(x => x.SK).indexOf(part.SK);

            if (partIndex !== -1) {
                partsClone[partIndex].part_loading = 'UPDATING PART OPTIONS';

                let {
                    SK,
                    material_id,
                    finish_id,
                    colour_id,
                    colour_val,
                    price
                } = partsClone[partIndex];

                // create temp overwrites obj for part
                partsOptionsTempOverwritesClone[SK] = {
                    material_id,
                    finish_id,
                    colour_id,
                    colour_val,
                    quantity: price ? price.qty : null
                };
            }
        }

        this.setState({
            parts: [...partsClone],
            partsOptionsTempOverwrites: {...partsOptionsTempOverwritesClone},
            totalPriceLoading: true
        }, async () => {
            handleApiReq(
                'put',
                '/crud',
                {
                    action: 'UPDATE_PARTS_OPTIONS',
                    quote_id: this.props.id,
                    attach_quote_data: true,
                    attach_config_data: true,
                    parts,
                    options
                }
            ).then(result => {
                let partsCloneX = [...this.state.parts];

                for (const part of result.data) {
                    let partResultIndex = partsCloneX.map(x => x.SK).indexOf(part.SK);

                    if (partResultIndex !== -1) {
                        if (part.success) {
                            partsCloneX[partResultIndex] = part;
                        }
                    }
                }

                this.setState({
                    parts: partsCloneX
                }, () => this.initiateRecursivePartsCheck(true));

                if (typeof callback === 'function') {
                    callback();
                }
            }).catch(error => {
                handleError('63719PiX', error);
            });
        });
    };

    // handle the user clicking on the quantity arrows
    handleQuantityClick = (SK, quantity, increment) => {
        // min = 1, max = maxPartQuantity
        let valueToSet =
            !!(quantity === 1 && !increment) || !!(quantity === this.state.maxPartQuantity && increment) ? quantity
            : increment ? quantity + 1 : quantity - 1;

        if (valueToSet && quantity !== valueToSet) {
            this.handleQuantityInputChangeTimeout(SK, valueToSet);
        }
    };

    // handle separate timeout on the input, as to not set the value whilst the user is using the input
    handleQuantityInputOnChange = (SK, quantity) => {
        let quantityInputOnChangeTimeouts = [...this.state.quantityInputOnChangeTimeoutArray];

        let timeoutIndex = quantityInputOnChangeTimeouts.map(elem => elem.SK).indexOf(SK);

        let quantityToUse = quantity.length ? quantity : '1';

        // initiate the timeout
        let timeoutToSet = setTimeout(() => this.handleQuantityInputChangeTimeout(SK, quantityToUse, true), 600);

        if (timeoutIndex === -1) {
            // if there is no previous timeout, push the new obj to the timeout array
            quantityInputOnChangeTimeouts.push({
                SK,
                timeoutId: timeoutToSet,
                value: quantityToUse
            });
        } else {
            // if there is a previous timeout, then clear it
            clearTimeout(quantityInputOnChangeTimeouts[timeoutIndex].timeoutId);

            // update the current values to match the new ones
            quantityInputOnChangeTimeouts[timeoutIndex].timeoutId = timeoutToSet;
            quantityInputOnChangeTimeouts[timeoutIndex].value = quantityToUse;
        }

        this.setState({
            quantityInputOnChangeTimeoutArray: quantityInputOnChangeTimeouts
        });
    };

    // user has used the input handle the timeout to detect the change after a period of 400ms
    // this is to prevent the ux from sending requests to the backend all the time, the request will only be sent 400ms after the user has finished typing
    // each key press will cancel the waiting timeout and create a new one
    handleQuantityInputChangeTimeout = (SK, value, removeInput) => {
        let timeoutsClone = [...this.state.quantityChangeTimeoutArray];

        let timeoutIndex = timeoutsClone.map(elem => elem.SK).indexOf(SK);

        // initiate the timeout
        let timeoutToSet = setTimeout(() => this.handleQuantityInputChange(SK), 400);

        if (timeoutIndex === -1) {
            // if there is no previous timeout, push the new obj to the timeout array
            timeoutsClone.push({
                SK,
                timeoutId: timeoutToSet,
                value
            });
        } else {
            // if there is a previous timeout, then clear it
            clearTimeout(timeoutsClone[timeoutIndex].timeoutId);

            // update the current values to match the new ones
            timeoutsClone[timeoutIndex].timeoutId = timeoutToSet;
            timeoutsClone[timeoutIndex].value = value;
        }

        let quantityInputOnChangeTimeoutsClone = [...this.state.quantityInputOnChangeTimeoutArray];
        if (removeInput) {
            quantityInputOnChangeTimeoutsClone = quantityInputOnChangeTimeoutsClone.filter(item => item.SK !== SK);
        }

        this.setState({
            quantityChangeTimeoutArray: timeoutsClone,
            quantityInputOnChangeTimeoutArray: quantityInputOnChangeTimeoutsClone
        });
    };

    // handles and sets the value from the input, triggered when the timeout from handleQuantityInputChangeTimeout finishes
    handleQuantityInputChange = SK => {
        let index = this.returnIndex(SK);
        if (index === -1) return;

        let timeoutsClone = [...this.state.quantityChangeTimeoutArray];
        let timeoutIndex = timeoutsClone.map(elem => elem.SK).indexOf(SK);

        // if there is an error caught in handling the value from the input, then we will reset the quantity value
        let errorCaught = false;

        if (timeoutIndex === -1) errorCaught = true;

        let partsClone = [...this.state.parts];

        let valueToSet = null;

        if (!errorCaught) {
            let value;

            try {
                // replace any character that is not an number
                value = typeof timeoutsClone[timeoutIndex].value === 'string' ? timeoutsClone[timeoutIndex].value.replace(/[^\d]/g, '') : timeoutsClone[timeoutIndex].value;
                if (typeof value === 'string' && (!value.length || isNaN(value))) value = 1;

                value = parseInt(value);
            } catch (error) {
                errorCaught = true;
            }

            valueToSet =
                errorCaught ? null
                : value > this.state.maxPartQuantity ? this.state.maxPartQuantity
                : value < 1 ? 1
                : value;
        }

        if (valueToSet && valueToSet !== partsClone[index].price.qty) {
            partsClone[index].price.qty = valueToSet;
            partsClone[index].quantityLoading = valueToSet;
            this.handleUpdateQuantity(partsClone[index], valueToSet);
        }

        this.setState({
            parts: partsClone,
            quantityChangeTimeoutArray: timeoutsClone.filter(elem => elem.SK !== SK)
        });
    };

    handleUpdateQuantity = (part, quantity) => {
        let partsClone = this.state.parts;
        let partsOptionsTempOverwritesClone = {...this.state.partsOptionsTempOverwrites};
        let partIndex = partsClone.map(part => part.SK).indexOf(part.SK);

        if (partIndex !== -1) {
            partsClone[partIndex].part_loading = 'UPDATING PART QUANTITY';

            let leadTimeListOptionsClone = this.state.leadTimeListOptions;

            const {
                SK,
                material_id,
                finish_id,
                colour_id,
                colour_val,
                status
            } = partsClone[partIndex];

            // create temp overwrites obj for part if part is still loading
            if (!(material_id && finish_id && colour_id && (status === 'FAILED' || status === 'SUCCESSFUL'))) {
                partsOptionsTempOverwritesClone[SK] = {
                    material_id,
                    finish_id,
                    colour_id,
                    colour_val,
                    quantity
                };
            }

            for (let leadTime of leadTimeListOptionsClone) {
                leadTime.price = '-';
                leadTime.price_object = '-';
                leadTime.dates = '-';
                leadTime.maxModifier = null;
            }

            this.setState({
                parts: [...partsClone],
                leadTimeListOptions: [...leadTimeListOptionsClone],
                partsOptionsTempOverwrites: {...partsOptionsTempOverwritesClone},
                totalPriceLoading: true
            }, async () => {
                handleApiReq(
                    'put',
                    '/crud',
                    {
                        action: 'UPDATE_PART_QUANTITY',
                        quote_id: part.PK,
                        attach_quote_data: true,
                        attach_config_data: true,
                        part_id: part.SK,
                        quantity
                    }
                ).then(result => {
                    let partsCloneX = [...this.state.parts];
                    let partResultIndex = partsCloneX.map(x => x.SK).indexOf(result.data.SK);

                    if (partResultIndex !== -1) {
                        partsCloneX[partResultIndex] = result.data;
                        partsCloneX[partResultIndex].part_loading = false;
                        partsCloneX[partResultIndex].quantityLoading = false;

                        this.setState({
                            parts: partsCloneX
                        }, () => this.incrementTotalPrice('handleUpdateQuantity', part));
                    }
                }).catch(error => {
                    handleError('65126dUd', error);
                });
            });
        }
    };

    // if the user has added more parts handle the end of the upload here
    handleUploadEnd = skeletonData => {
        this.props.handleLoading(true);

        this.handleAddToExpected(skeletonData);

        let skeletonsToSet = skeletonData.map((item, index) => {
            return {
                PK: item.upload_pk,
                SK: item.upload_sk,
                is_skeleton: true,
                created_at: item.created_at,
                status: 'INIT',
                message: item.upload_message,
                converting: item.converting,
                index,
                id: index
            }
        });

        let skeletons = [...this.state.skeletons, ...skeletonsToSet];

        this.handleToggleDropZone(false);

        this.setState({
            skeletons
        }, async () => {
            handleApiReq(
                'post',
                '/crud',
                {
                    action: 'INSERT_MORE_PARTS',
                    quote_id: this.props.id,
                }
            ).then(() => {
                this.refreshQuoteData().then(() => {
                    this.props.handleLoading(false);

                    this.initiateRecursivePartsCheck(true);
                }).catch(error => {
                    handleError('42101bcr', error);
                });
            }).catch(error => {
                handleError('33449tQr', error);
            });
        });
    };

    // handle call to refresh quote data
    refreshQuoteData = () => {
        return new Promise((resolve, reject) => {
            this.setState({
                showNoPartsDiv: false
            }, async () => {
                callApiAndReturnCombinedResultsData([
                    {method: 'get', path: `/crud/quote/${this.props.id}`},
                    {method: 'get', path: `/crud/parts/${this.props.id}`}
                ]).then(result => {
                    let skeletonsClone = [...this.state.skeletons];
                    let skeletonsToRemove = [];
                    let expectedToRemove = [];

                    if (typeof this.state.canCloneAgain === 'number' && result.total_parts_deleted_non_deleted >= this.state.canCloneAgain) {
                        this.setState({
                            canCloneAgain: null,
                            cloningActive: false
                        });
                    }

                    // set all parts to loading
                    let partsDataToSet = result.parts_data.map(part => {
                        return { ...part, ...{ part_loading: 'WAITING' } }
                    });

                    for (const skeleton of skeletonsClone) {
                        if (partsDataToSet.findIndex(part => part.SK === skeleton.SK) !== -1) skeletonsToRemove.push(skeleton.SK);
                    }

                    // remove incoming parts from expected parts if need be
                    let expectedParts = localStorage.getItem('expected_parts');
                    if (expectedParts) {
                        expectedParts = JSON.parse(expectedParts);

                        for (const part of expectedParts) {
                            if (partsDataToSet.findIndex(x => x.SK === part.upload_sk) !== -1) expectedToRemove.push(part.upload_sk);
                        }

                        this.handleRemoveFromExpected(expectedToRemove);
                    }

                    if (partsDataToSet.findIndex(part => part.u))

                    skeletonsClone = skeletonsClone.filter(skeleton => !skeletonsToRemove.includes(skeleton.SK));

                    sortPartsByFileKey(partsDataToSet);

                    let selectedLeadTimeOption = this.state.leadTimeListOptions.filter(x => x.value.type === result.quote_data.selected_lead_time)[0];

                    let valueToSet = selectedLeadTimeOption.value ? selectedLeadTimeOption.value : this.state.selectedLeadTimeValue;

                    this.setState({
                        quote: result.quote_data,
                        parts: partsDataToSet.sort((a, b) => a.created_at - b.created_at),
                        skeletons: skeletonsClone,
                        selectedLeadTimeValue: valueToSet,
                        leadTimeListOptions: this.state.leadTimeListOptions.filter(option => result.quote_data.locked ? this.state.quote.selected_lead_time === option.value.type : option.visible),
                        startFromIndex: result.total_parts_deleted_non_deleted,
                        showNoPartsDiv: !partsDataToSet.length,
                        dismissibleNote: result.quote_data.dismissible_note
                    }, () => {
                        this.props.handleAlienData({
                            quote_id: this.props.id,
                            locked: this.state.quote.locked,
                            css: this.state.quote.css,
                            aas: this.state.quote.aas,
                            sso: this.state.shippingOptions.filter(item => item.service_code === this.state.selectedShippingOption)[0],
                            dn: this.state.quote.dismissible_note,
                            clt: this.state.quote.clt,
                            c: this.state.quote.currency,
                            ur: this.state.quote.under_review,
                            ahZzZ: this.state.quote.admin_hidden || this.state.quote.user_hidden
                        });

                        resolve();
                    });
                }).catch(error => {
                    reject(error);
                });
            });
        });
    };

    // start the recursive parts check, parts have been added or parts have been updated, we need to wait for them to finish loading
    initiateRecursivePartsCheck = incrementingTotalPrice => {
        let partsClone = [...this.state.parts];
        let skeletonsClone = [...this.state.skeletons];
        let partsOptionsTempOverwritesClone = {...this.state.partsOptionsTempOverwrites};

        let partsToCheck = [];

        // loop over both parts and skeletons and determine what needs checking
        for (let item of [...partsClone, ...skeletonsClone]) {
            const {
                SK,
                status,
                material_id,
                finish_id,
                colour_id,
                part_timeout,
                file_key,
                is_skeleton
            } = item;

            if (is_skeleton) {
                partsToCheck.push(item);
            } else {
                let loading = true;

                // if this is the initial check, before incrementing the price (aka: changing an option) then report that the part has failed analysis
                if (!incrementingTotalPrice && status === 'FAILED') {
                    handlePushToDataLayer({
                        event: 'part_failed_analysis',
                        type: part_timeout ? 'analysis timeout' : 'failed',
                        file_key
                    });
                }

                // if the part doesn't have a material or finish id then it hasn't been processed
                // if the status is not a final status then the backend has not finished processing it yet
                if ((material_id && finish_id && colour_id && status === 'SUCCESSFUL') || status === 'FAILED') {
                    loading = false;
                }

                if (loading) {
                    item.part_loading = 'WAITING';
                    partsToCheck.push(item);
                } else {
                    item.part_loading = false;
                    if (partsOptionsTempOverwritesClone[SK]) {
                        // delete from overwrites
                        delete partsOptionsTempOverwritesClone[SK];
                    }
                }
            }
        }

        if (incrementingTotalPrice) this.incrementTotalPrice('initiateRecursivePartsCheck', null);

        this.setState({
            parts: [...partsClone],
            skeletons: [...skeletonsClone],
            partsToRecursivelyCheck: partsToCheck,
        }, () => {
            this.setState({
                partsOptionsTempOverwrites: {...partsOptionsTempOverwritesClone}
            }, () => {
                if (this.state.partsToRecursivelyCheck.length) {
                    if (this.state.partsToRecursivelyCheckTimeout) {
                        window.clearTimeout(this.state.partsToRecursivelyCheckTimeout);
                    }

                    let timeoutToSet = setTimeout(this.handleRecursivePartsCheckTimeout, this.state.partsToRecursivelyCheckTimeoutLength);

                    this.setState({
                        partsToRecursivelyCheckTimeout: timeoutToSet
                    });
                }
            });
        });
    };

    // handle the req to the server for the recursive parts check
    handleRecursivePartsCheckTimeout = async () => {
        // create a reference to the promise so we can cancel it if need be (on unmount)
        let params = {
            apiName: await returnApi(),
            path: '/crud/check-waiting-parts',
            options: {
                 ...returnReqInit({
                     action: 'CHECK_WAITING_PARTS',
                     quote_id: this.props.id,
                     attach_quote_data: true,
                     attach_parts_data: false,
                     attach_part_data: false,
                     attach_config_data: true,
                     parts_to_check: this.state.partsToRecursivelyCheck.map(part => {
                         return {
                             keys: {
                                 PK: part.PK,
                                 SK: part.SK,
                             },
                             is_skeleton: part.is_skeleton
                         }
                     })
                 })
            }
        }

        let recursiveReqPromiseToSet = put(params);
        // add reference to the state
        this.setState({
            recursiveReqPromise: recursiveReqPromiseToSet
        }, async () => {
            try {
                let promiseResult = await recursiveReqPromiseToSet.response;
                let result = await resolveBodyPromise(promiseResult.body, 'json');

                let partsClone = [...this.state.parts];
                let skeletonsClone = [...this.state.skeletons];
                let partsOptionsTempOverwritesClone = {...this.state.partsOptionsTempOverwrites};

                let partsToCheck = [];

                // can clone again is enabled when the file has uploaded to S3, we can assume that when canCloneAgain equals total_part_count that the file has been uploaded successfully
                if (typeof this.state.canCloneAgain === 'number' && result.total_part_count >= this.state.canCloneAgain) {
                    this.setState({
                        canCloneAgain: null,
                        cloningActive: false
                    });
                }

                for (let part of result.data) {
                    let {
                        PK,
                        SK,
                        status,
                        material_id,
                        finish_id,
                        colour_id,
                        thumbnail,
                        price
                    } = part;

                    // get the index of part if exists as part
                    let partIndex = partsClone.map(x => `${x.PK}/${x.SK}`).indexOf(`${PK}/${SK}`);
                    // get the index of part if exists as skeleton
                    let skeletonIndex = skeletonsClone.map(x => `${x.PK}/${x.SK}`).indexOf(`${PK}/${SK}`);

                    if (partIndex !== -1) {
                        // will we remove from temp overwrites
                        let deleteFromTempOverwrites = false;
                        // if key value pair exists for part
                        if (partsOptionsTempOverwritesClone[SK]) {
                            let currPart = {
                                material_id,
                                finish_id,
                                colour_id,
                                quantity: price ? price.qty : null
                            };

                            // compare JSON strings from returned data and temp overwrite data
                            // if matches then we can assume that the part has been updated
                            if (JSON.stringify(currPart) === JSON.stringify(partsOptionsTempOverwritesClone[SK])) {
                                deleteFromTempOverwrites = true;
                            }
                        }

                        let loading = true;

                        if ((material_id && finish_id && colour_id && status === 'SUCCESSFUL') || status === 'FAILED') {
                            loading = false;
                        }

                        if (loading) {
                            if (loading) part.part_loading = 'WAITING';

                            partsToCheck.push(part);
                        } else {
                            // if part is done loading then remove from temp overwrites, as catchall
                            if (partsOptionsTempOverwritesClone[SK]) deleteFromTempOverwrites = true;

                            part.part_loading = false;
                        }

                        partsClone[partIndex] = part;

                        if (deleteFromTempOverwrites) {
                            // delete from temp overwrites
                            delete partsOptionsTempOverwritesClone[SK];
                        }
                    } else if (skeletonIndex !== -1) {
                        let needsToBecomeAPart = status !== 'INIT';

                        // skeleton can become a part
                        // it could still be loading
                        // remove from skeletons and add to parts
                        if (needsToBecomeAPart) {
                            let loading = true;
                            if (material_id && finish_id && colour_id && (status === 'FAILED' || status === 'SUCCESSFUL')) {
                                loading = false;
                            }

                            if (loading || !thumbnail) {
                                if (loading) part.part_loading = 'WAITING';
                                partsToCheck.push(part);
                            } else {
                                part.part_loading = false;
                            }

                            // remove the part from expected parts as it's data will be present now
                            this.handleRemoveFromExpected(SK);

                            skeletonsClone.splice(skeletonIndex, 1);
                            partsClone.push(part);
                        }
                    }
                }

                let timeoutToSet = null;

                // always add skeletons to parts to check
                partsToCheck = [...partsToCheck, ...skeletonsClone];

                if (partsToCheck.length) {
                    timeoutToSet = setTimeout(this.handleRecursivePartsCheckTimeout, this.state.partsToRecursivelyCheckTimeoutLength);
                }

                this.setState({
                    partsToRecursivelyCheckTimeout: timeoutToSet,
                    partsToRecursivelyCheck: partsToCheck,
                    parts: partsClone,
                    skeletons: skeletonsClone,
                    recursiveReqPromise: null
                }, () => {
                    this.setState({
                        partsOptionsTempOverwrites: partsOptionsTempOverwritesClone
                    });
                });
            } catch (error) {
                if (!isCancelError(error)) {
                    handleError('18632WCK', error);
                }
            }
        });
    };

    // handle adding more parts to expected parts, this is to handle the user refreshing the page directly after, catching parts that have not been inserted into the database
    handleAddToExpected = item => {
        let expectedParts = localStorage.getItem('expected_parts');
        if (expectedParts) {
            expectedParts = JSON.parse(expectedParts);
        } else {
            // set expected parts to empty array if it doesn't exist
            expectedParts = [];
            // set to local storage
            localStorage.setItem('expected_parts', JSON.stringify(expectedParts));
        }

        let uniqueToSet = [...item.filter(x => expectedParts.findIndex(y => y.upload_sk === x.upload_sk) === -1)];
        if (expectedParts) {
            uniqueToSet = [...expectedParts, ...uniqueToSet];
        }

        localStorage.setItem('expected_parts', JSON.stringify(uniqueToSet));
    };

    // remove one or more parts from expected parts, so that when refreshed, the parts don't show up in list
    handleRemoveFromExpected = item => {
        let expectedParts = localStorage.getItem('expected_parts');
        if (expectedParts) expectedParts = JSON.parse(expectedParts);

        // detect if argument is an array or not
        if (Array.isArray(item)) {
            expectedParts = expectedParts.filter(part => item.indexOf(part.upload_sk) === -1);

            localStorage.setItem('expected_parts', JSON.stringify(expectedParts));
        } else {
            let index = expectedParts.findIndex(part => part.upload_sk === item);

            if (index !== -1) {
                expectedParts.splice(index, 1);

                localStorage.setItem('expected_parts', JSON.stringify(expectedParts));
            }
        }
    };

    // handle user clicking on the delete part button
    handleDeletePart = keys => {
        let partsClone = [...this.state.parts];
        let partIndex = partsClone.map(x => x.SK).indexOf(keys.SK);

        if (partIndex !== -1) {
            partsClone[partIndex].part_loading = 'DELETING PART';
            partsClone[partIndex].deleting = true;

            this.handleOverlayClose();

            // this timeout allows the delete animation to finish
            // when the timeout is resolved, remove the part from the ui
            setTimeout(() => {
                partsClone[partIndex].deleting = 'finished';
            }, 320);

            // if the part is in the recursive check array then remove to stop it checking on parts that don't exist
            let partsToRecursivelyCheckClone = [...this.state.partsToRecursivelyCheck];
            let recursiveCheckIndex = partsToRecursivelyCheckClone.map(x => x.SK).indexOf(keys.SK);
            if (recursiveCheckIndex !== -1) partsToRecursivelyCheckClone.splice(recursiveCheckIndex, 1);

            this.setState({
                parts: partsClone,
                partsToRecursivelyCheck: partsToRecursivelyCheckClone,
                totalPriceLoading: true
            }, async () => {
                handleApiReq(
                    'put',
                    '/crud',
                    {
                        action: 'DELETE_PART',
                        quote_id: keys.PK,
                        part_id: keys.SK
                    }
                ).then(() => {
                    let partsCloneX = [...this.state.parts];
                    partsCloneX = partsCloneX.filter(x => x.SK !== keys.SK);

                    this.setState({
                        parts: partsCloneX
                    }, () => {
                        this.incrementTotalPrice('handleDeletePart', keys);

                        if (!this.state.parts.length) {
                            this.setState({
                                showNoPartsDiv: true
                            });
                        }
                    });
                }).catch(error => {
                    handleError('55236wgi', error);
                });
            });
        }
    };

    // handle user clicking on the clone part button
    handleClonePart = (keys, filename) => {
        this.setState({
            cloningActive: true,
            totalPriceLoading: true
        }, async () => {
            handleApiReq(
                'post',
                '/crud',
                {
                    action: 'CLONE_PART',
                    quote_id: keys.PK,
                    attach_quote_data: true,
                    attach_part_data: true,
                    part_id: keys.SK
                }
            ).then(result => {
                if (result.success) {
                    let skeletonsClone = [...this.state.skeletons, {
                        PK: result.data.upload_pk,
                        SK: result.data.upload_sk,
                        is_skeleton: true,
                        created_at: result.data.created_at,
                        status: 'INIT',
                        message: result.data.upload_message,
                        index: this.state.skeletons.length,
                        id: this.state.skeletons.length
                    }];

                    this.setState({
                        skeletons: skeletonsClone,
                        canCloneAgain: result.data.expected_total_part_count
                    }, () => {
                        this.refreshQuoteData(result.data.expected_total_part_count).then(() => {
                            this.initiateRecursivePartsCheck(true);
                        }).catch(error => {
                            handleError('42101bcr', error);
                        });
                    });
                }
            }).catch(error => {
                this.setState({
                    cloningActive: false
                }, () => {
                    handleError('55236wgi', error);
                });
            });
        });
    };

    // calculate the total price, as settings on a part or parts have changed
    determineTotalPrice = () => {
        let leadTimeListOptionsClone = this.state.leadTimeListOptions;

        for (let leadTime of leadTimeListOptionsClone) {
            leadTime.price = '-';
            leadTime.price_object = '-';
            leadTime.dates = '-';
            leadTime.maxModifier = null;
        }

        this.setState({
            leadTimeListOptions: [...leadTimeListOptionsClone],
            totalPriceLoading: true,
            totalPriceInitialFetch: true
        }, async () => {
            try {
                let leadTimeListOptionsCloneX = [...this.state.leadTimeListOptions];

                let greatestFinishLeadTime = determineMaximumLeadTime(this.state.parts.filter(part => part.warnings.remove_from_total === false), this.state.optionsTree);

                let calculatedTotalPrice = calculateTotalPrice(this.state.quote, this.state.parts, this.state.shippingOptions, this.state.definitions);

                // determine if request for quote is active here
                let reqForQuoteReasons = [];
                if (this.state.requestForQuoteTriggers && !this.state?.quote?.locked && this.state?.parts.length) {
                    let partQuantityExceedsLimit = this.state.parts.some(part => part?.price?.qty && part?.price?.qty >= this.state.requestForQuoteTriggers.quantity_part);
                    if (partQuantityExceedsLimit) {
                        reqForQuoteReasons.push('quantity_part');
                    }

                    let partValueExceedsLimit = this.state.parts.some(part => part?.price?.total && part?.price?.total >= this.state.requestForQuoteTriggers.price_part);
                    if (partValueExceedsLimit) {
                        reqForQuoteReasons.push('price_part');
                    }

                    let quoteQuantityExceedsLimit = this.state.parts.reduce((a, part) => a + part?.price?.qty || 0, 0) >= this.state.requestForQuoteTriggers.quantity_quote;
                    if (quoteQuantityExceedsLimit) {
                        reqForQuoteReasons.push('quantity_quote');
                    }

                    let quotePriceExceedsLimit = this.state.parts.reduce((val, part) => val + part?.price?.total || 0, 0) >= this.state.requestForQuoteTriggers.price_quote;
                    if (quotePriceExceedsLimit) {
                        reqForQuoteReasons.push('price_quote');
                    }

                    // check for failed parts
                    let hasFailedParts = this.state.parts.some(part => part.status === 'FAILED' || part?.warnings?.remove_from_total);
                    if (hasFailedParts) {
                        reqForQuoteReasons.push('failed_parts');
                    }
                }

                for (let option of leadTimeListOptionsCloneX) {
                    let days = option.value.lead_times.map(val => val + (typeof greatestFinishLeadTime === 'number' ? greatestFinishLeadTime : 0));
                    let maxDay = Math.max(...days);
                    let shipsBy = returnShippingAndDeliveryEstimate(this.state.holidays, Date.now(), this.state.quote.clt ? this.state.quote.clt : maxDay, null);

                    option.ov_clt = this.state.quote.clt;
                    option.ships_by = shipsBy.production_estimate;

                    // difference between lead time production prices
                    option.difference = null;
                    if (this.state.parts && this.state.parts.length && this.state.leadTimeListOptions && this.state.leadTimeListOptions.length && this.state.selectedLeadTimeValue && this.state.selectedLeadTimeValue.type && option.value && option.value.type) {
                        option.difference =
                            option.value.type === this.state.selectedLeadTimeValue.type ? null
                            : calculateProductionDifference(calculatedTotalPrice.total_line_items, option, this.state.parts, this.state.quote.currency, this.state.requestForQuoteTriggers ? this.state.requestForQuoteTriggers.price_part : null);

                        option.difference =
                            !option.difference ? null
                            : !!reqForQuoteReasons.length ? null
                            : this.state.requestForQuoteTriggers && calculatedTotalPrice.total_line_items + (option.difference?.raw || 0) >= this.state.requestForQuoteTriggers.price_quote ? null
                            : option.difference.rfqTrigger ? null
                            : option.difference.label;
                    }

                    option.maxModifier = greatestFinishLeadTime;

                    option.price = calculatedTotalPrice.total_incl_vat.toFixed(2);
                    option.price_object = calculatedTotalPrice;
                }

                // calculate estimated dates here
                this.setEstimatedDates();

                this.setState({
                    leadTimeListOptions: [...leadTimeListOptionsCloneX],
                    totalPriceLoading: false,
                    priceBreakDownRequest: null,
                    requestForQuoteActive: !!reqForQuoteReasons.length,
                    requestForQuoteReasons: reqForQuoteReasons
                });
            } catch (error) {
                console.log('error caught @ determineTotalPrice', error);
            }
        });
    };

    // trigger a change in state that ultimately recalculates the new total price
    //see getSnapshotBeforeUpdate
    incrementTotalPrice = (functionCalled, refData) => {
        this.setState({
            totalPriceWaiting: true,
            totalPriceIncrementor: this.state.totalPriceIncrementor + 1,
            totalPriceIncrementorData: {
                functionCalled,
                refData
            }
        });
    };

    // handle the user locking the quote from the actions menu
    handleUserLockClick = () => {
        return new Promise(async (resolve, reject) => {
            try {
                this.props.handleLoading(true);
                let result = await handleUserLockQuote({ uuid: this.props.id });

                if (result.success) {
                    await this.refreshQuoteData().then(() => {
                        this.props.handleNewSnackbar('quote successfully locked');
                        this.props.handleLoading(false);
                        this.initiateRecursivePartsCheck(true);

                        // user has locked their quote > remove the ability to change the currency
                        if (!!getSetCurrencyLSValue()) {
                            getSetCurrencyLSValue({
                                ...getSetCurrencyLSValue(),
                                disabled: true
                            });
                        }

                        resolve();
                    });
                } else {
                    this.props.handleLoading(false);
                    this.props.handleNewSnackbar('something went wrong', 'error');
                    resolve();
                }
            } catch (error) {
                this.props.handleLoading(false);
                console.log('error caught @ handleUserLockClick', error);
                this.props.handleNewSnackbar('something went wrong', 'error');
                resolve();
            }
        });
    };

    // handle the user gen / downloading the quote from the actions menu
    handleUserDownloadPDFClick = () => {
        return new Promise(async (resolve, reject) => {
            try {
                this.props.handleLoading(true);
                let result = await handleUserDownloadPDF({ uuid: this.props.id });
                this.props.handleLoading(false);

                if (result.success && result.data) {
                    this.props.handleNewSnackbar('PDF successfully generated');
                    resolve();

                    await axios({
                        url: result.data.url,
                        method: 'GET',
                        responseType: 'blob',
                    }).then(resultX => {
                        const urlX = window.URL.createObjectURL(new Blob([resultX.data]));
                        const link = document.createElement('a');
                        link.href = urlX;
                        link.setAttribute('download', result.data.file_name || `xero_quote_${this.state.quote.reference_number}.pdf`);
                        link.target = '_blank';
                        document.body.appendChild(link);

                        link.click();
                        link.remove();
                    });
                } else {
                    this.props.handleNewSnackbar('something went wrong', 'error');
                    resolve();
                }
            } catch (error) {
                this.props.handleLoading(false);
                console.log('error caught @ handleUserDownloadPDFClick', error);
                this.props.handleNewSnackbar('something went wrong', 'error');
                resolve();
            }
        });
    };

    // handle the user favouring the quote from the actions menu
    handleUserFavourite = () => {
        return new Promise(async (resolve, reject) => {
            try {
                this.props.handleLoading(true);
                let result = await setFavourite({ uuid: this.props.id, favourite: this.state.quote && this.state.quote.favourite })

                if (result.success) {
                    await this.refreshQuoteData().then(() => {
                        this.props.handleNewSnackbar(`quote ${this.state.quote && !this.state.quote.favourite ? 'un' : ''}favourited`);
                        this.props.handleLoading(false);
                        this.initiateRecursivePartsCheck(true);
                        resolve();
                    });
                } else {
                    this.props.handleLoading(false);
                    this.props.handleNewSnackbar('something went wrong', 'error');
                    resolve();
                }
            } catch (error) {
                this.props.handleLoading(false);
                console.log('error caught @ handleUserFavourite', error);
                this.props.handleNewSnackbar('something went wrong', 'error');
                resolve();
            }
        });
    };

    // handle the user cloning from the actions menu
    handleUserClone = () => {
        return new Promise(async (resolve, reject) => {
            try {
                this.props.handleLoading(true);
                let result = await cloneQuoteOrder({ uuid: this.props.id });

                if (result.success) {
                    this.props.handleNewSnackbar(`quote successfully copied`);
                    this.props.handleLoading(false);
                    resolve();

                    // open item in new tab
                    localStorage.setItem('expected_parts', JSON.stringify(result.data.skeleton_data));
                    window.open(`${window.location.origin}/quote/${result.data.clone_uuid}`);
                } else {
                    this.props.handleLoading(false);
                    this.props.handleNewSnackbar('something went wrong', 'error');
                    resolve();
                }
            } catch (error) {
                this.props.handleLoading(false);
                console.log('error caught @ handleUserClone', error);
                this.props.handleNewSnackbar('something went wrong', 'error');
                resolve();
            }
        });
    };

    // handle the keydown on nickname input
    handleNicknameKeyDown = e => {
        // clear warning when value is changed
        if (this.state.nicknameWarning) {
            this.setState({
                nicknameWarning: null
            });
        }

        // if enter was pressed, attempt to save nickname
        if (e.code === 'Enter') {
            if (!this.nicknameInputRef || !this.nicknameInputRef.current) return;
            if (!this.props.loggedIn) return;

            // trim nickname
            let nickname = this.nicknameInputRef.current.value.trim();

            // max char length of 30
            if (nickname.length > 30) {
                this.setState({
                    nicknameWarning: "Can't be longer than 30 characters"
                });
                return;
            }

            this.handleUserNicknamingQuote(nickname);
        }
    };

    handleNicknameBlur = () => {
        // clear warning when value is changed
        if (this.state.nicknameWarning) {
            this.setState({
                nicknameWarning: null
            });
        }

        // attempt to save nickname
        if (!this.nicknameInputRef || !this.nicknameInputRef.current) return;
        if (!this.props.loggedIn) return;

        // trim nickname
        let nickname = this.nicknameInputRef.current.value.trim();

        if ((this.state.quote && this.state.quote.nickname && nickname === this.state.quote.nickname) || (!this.state.quote.nickname && !nickname.length)) {
            this.setState({
                nicknameWarning: null,
                nicknameToolbarVisible: false,
                toolbarInputEditVisible: false
            });
            return;
        }

        // max char length of 30
        if (nickname.length > 30) {
            this.setState({
                nicknameWarning: "Can't be longer than 30 characters"
            });
            return;
        }

        this.handleUserNicknamingQuote(nickname);
    };

    handleUserNicknamingQuote = nickname => {
        return new Promise(async (resolve, reject) => {
            try {
                this.props.handleLoading(true);

                let result = await setNickname({ uuid: this.props.id, nickname });

                if (result.success) {
                    await this.refreshQuoteData().then(() => {
                        this.props.handleNewSnackbar(`quote successfully nicknamed`);
                        this.props.handleLoading(false);
                        this.initiateRecursivePartsCheck(true);

                        if (this.nicknameInputRef && this.nicknameInputRef.current) {
                            this.nicknameInputRef.current.value = '';
                        }

                        this.setState({
                            nicknameToolbarVisible: false,
                            toolbarInputEditVisible: false
                        }, resolve);
                    });
                } else {
                    this.props.handleLoading(false);
                    this.props.handleNewSnackbar('something went wrong', 'error');
                    resolve();
                }
            } catch (error) {
                this.props.handleLoading(false);
                console.log('error caught @ handleUserNicknamingQuote', error);
                this.props.handleNewSnackbar('something went wrong', 'error');
                resolve();
            }
        });
    };

    handleUserShareSecureLink = () => {
        return new Promise(async (resolve, reject) => {
            this.props.handleNewSnackbar(`still waiting on this function`, 'warning');
            resolve();
        });
    };

    returnIndex = SK => this.state.parts.map(part => part.SK).indexOf(SK);

    fetchAddress = () => {
        this.props.handleLoading(true);

        this.setState({
            billingAddressOpen: false,
            shippingAddressOpen: false,
            billingEmailWarning: false,
            totalPriceLoading: true
        }, async () => {
            try {
                let quoteResult = await handleApiReq(
                    'get',
                    `/crud/quote/${this.props.id}`
                );

                this.props.handleLoading(false);

                if (quoteResult.success) {
                    this.setState({
                        shippingOptions: quoteResult.data.shipping_options,
                        selectedShippingOption: quoteResult.data.quote_data.selected_shipping_option,
                        addressToMutate: {
                            billing: quoteResult.data.quote_data.billing,
                            shipping: quoteResult.data.quote_data.shipping
                        }
                    }, () => {
                        this.props.handleAlienData({
                            quote_id: this.props.id,
                            locked: this.state.quote.locked,
                            css: this.state.quote.css,
                            aas: this.state.quote.aas,
                            sso: this.state.shippingOptions.filter(item => item.service_code === this.state.selectedShippingOption)[0],
                            dn: this.state.quote.dismissible_note,
                            clt: this.state.quote.clt,
                            c: this.state.quote.currency,
                            ur: this.state.quote.under_review,
                            ahZzZ: this.state.quote.admin_hidden || this.state.quote.user_hidden
                        });
                    });
                } else {
                    this.props.handleNewSnackbar('failed to load default address', 'error');
                }
            } catch (error) {
                this.props.handleLoading(false);
                this.props.handleNewSnackbar('failed to load default address', 'error');
            }

            this.initiateRecursivePartsCheck(true);
        });
    };

    handleBillingToggle = bool => {
        this.setState({
            billingAddressOpen: bool,
            billingAddressWarning: false,
            shippingAddressOpen: false,
            billingEmailWarning: false
        });
    };

    handleShippingToggle = bool => {
        this.setState({
            shippingAddressOpen: bool,
            shippingAddressWarning: false,
            billingAddressOpen: false
        });
    };

    handleUpdateAddress = (which, applyToOther) => {
        if (!this.addressesInfoRefs || !this.addressesInfoRefs.current || !this.addressesInfoRefs.current[which]) return;

        let isSatisfied = determineIfRequiredSatisfiedReturnValues([this.addressesInfoRefs.current[which]], this.state.addressElemInfo, true);
        let isEmailSatisfied = which === 'billing' && !this.props.loggedIn ? determineIfEmailIsSatisfied(this.billingEmailRef) : { satisfied: true };

        let addressToMutateClone = { ...this.state.addressToMutate  };

        if (!isSatisfied.satisfied || !isEmailSatisfied.satisfied) {
            this.setState({
                [`${which}AddressWarning`]: {
                    main: 'Please fill out all required fields',
                    elems: isSatisfied.elemWarnings
                },
                billingEmailWarning: isEmailSatisfied.warning
            });
            return;
        }

        this.props.handleLoading(true);

        this.setState({
            totalPriceLoading: true
        }, async () => {
            try {
                // update quote address
                let updateResult = await handleApiReq(
                    'put',
                    '/crud',
                    {
                        action: 'UPDATE_QUOTE_ADDRESS',
                        quote_id: this.props.id,
                        attach_config_data: true,
                        attach_quote_data: true,
                        billing_email_val: this.billingEmailRef && this.billingEmailRef.current ? this.billingEmailRef.current.value : null,
                        address: applyToOther ? {
                            billing: isSatisfied.values,
                            shipping: isSatisfied.values
                        } : {
                            ...addressToMutateClone,
                            [which]: isSatisfied.values
                        }
                    }
                );

                this.setState({
                    addressToMutate: {
                        billing: updateResult.data.quote_data.billing,
                        shipping: updateResult.data.quote_data.shipping
                    },
                    quote: updateResult.data.quote_data,
                    shippingAddressOpen: false,
                    billingAddressOpen: false,
                    billingEmailWarning: false,
                    shippingAddressWarning: false,
                    proceedToCheckoutWarning: null,
                    billingAddressWarning: false,
                    shippingOptions: updateResult.data.shipping_options,
                    selectedShippingOption: updateResult.data.selected_shipping_option
                }, () => {
                    this.props.handleAlienData({
                        quote_id: this.props.id,
                        locked: this.state.quote.locked,
                        css: this.state.quote.css,
                        aas: this.state.quote.aas,
                        sso: this.state.shippingOptions.filter(item => item.service_code === this.state.selectedShippingOption)[0],
                        dn: this.state.quote.dismissible_note,
                        clt: this.state.quote.clt,
                        c: this.state.quote.currency,
                        ur: this.state.quote.under_review,
                        ahZzZ: this.state.quote.admin_hidden || this.state.quote.user_hidden
                    });

                    this.refreshQuoteData().then(() => {
                        this.props.handleLoading(false);
                        this.initiateRecursivePartsCheck(true);
                    }).catch(error => {
                        this.props.handleLoading(false);
                        handleError('34452vlz', error);
                    });
                });
            } catch (error) {
                this.props.handleLoading(false);
                console.log('error caught @ handleUpdateAddress', error);
                this.props.handleNewSnackbar('something went wrong', 'error');
            }
        });
    };

    handleShippingOptionSelection = (e, option) => {
        e.stopPropagation();

        this.setState({
            selectedShippingOption: option.service_code,
            totalPriceLoading: true
        }, async () => {
            this.props.handleLoading(true);

            handleApiReq(
                'put',
                '/crud',
                {
                    action: 'UPDATE_QUOTE_SHIPPING_OPTION',
                    quote_id: this.props.id,
                    attach_config_data: true,
                    attach_quote_data: true,
                    val: option.service_code
                }
            ).then(result => {
                if (result.success) {
                    this.refreshQuoteData().then(() => {
                        this.props.handleAlienData({
                            quote_id: this.props.id,
                            locked: this.state.quote.locked,
                            css: this.state.quote.css,
                            aas: this.state.quote.aas,
                            sso: this.state.shippingOptions.filter(item => item.service_code === this.state.selectedShippingOption)[0],
                            dn: this.state.quote.dismissible_note,
                            clt: this.state.quote.clt,
                            c: this.state.quote.currency,
                            ur: this.state.quote.under_review,
                            ahZzZ: this.state.quote.admin_hidden || this.state.quote.user_hidden
                        });

                        this.props.handleLoading(false);
                        this.initiateRecursivePartsCheck(true);
                    }).catch(error => {
                        this.props.handleLoading(false);
                        handleError('7529rsy', error);
                    });
                } else {
                    this.props.handleLoading(false);
                    this.props.handleNewSnackbar('something went wrong', 'error');
                }
            }).catch(error => {
                this.props.handleLoading(false);
                handleError('52220HHg', error);
                this.props.handleNewSnackbar('something went wrong', 'error');
            });
        });
    };

    handleInitialPaymentChecks = callback => {
        try {
            const {
                billing,
                shipping,
            } = this.state.addressToMutate;

            let missingBillingAddress = !billing || !Object.keys(billing).some(key => !!billing[key]);
            let missingShippingAddress = !shipping || !Object.keys(shipping).some(key => !!shipping[key]);

            if (missingBillingAddress || missingShippingAddress) {
                this.setState({
                    proceedToCheckoutWarning: {
                        address: 'Please fill out all required fields'
                    }
                });

                if (this.addressWrapRef && this.addressWrapRef.current) {
                    window.scrollTo({
                        top: this.addressWrapRef.current.offsetTop - (this.props.windowBreakpoint?.w <= 768 ? 20 : 150),
                        left: 0,
                        behavior: "smooth",
                    });
                }

                return;
            }

            handlePushToDataLayer({
                event: 'continue_to_checkout_click'
            });

            this.setState({
                proceedToCheckoutWarning: null
            }, callback);
        } catch (error) {
            console.log('error caught @ handleInitialPaymentChecks', error);
            this.props.handleNewSnackbar('something went wrong', 'error');
        }
    };

    setEstimatedDates = () => {
        try {
            if (!this.state.selectedLeadTimeValue) return;

            let greatestFinishLeadTime = determineMaximumLeadTime(this.state.parts.filter(part => part.warnings.remove_from_total === false), this.state.optionsTree);
            let days = this.state.selectedLeadTimeValue && this.state.selectedLeadTimeValue.lead_times.map(val => val + (typeof greatestFinishLeadTime === 'number' ? greatestFinishLeadTime : 0));
            let greatestProductionTime = Math.max(...days);

            let leastDeliveryTime = null;
            let shippingOptionIndex = this.state.shippingOptions.findIndex(option => option.service_code === this.state.selectedShippingOption);
            if (shippingOptionIndex !== -1) {
                let shippingOption = shippingOptionIndex !== -1 ? this.state.shippingOptions[shippingOptionIndex] : null;
                leastDeliveryTime = shippingOption.stripe_json.shipping_rate_data.delivery_estimate.minimum.value;
            }

            let dateValues = returnShippingAndDeliveryEstimate(this.state.holidays, Date.now(), this.state.quote.clt ? this.state.quote.clt : greatestProductionTime, leastDeliveryTime)

            this.setState({
                estimatedDates: dateValues
            });
        } catch (error) {
            console.log('error caught @ set estimatedDates', error);
        }
    };

    handleToggleDropZone = bool => {
        if (this.state.quoteExpired || this.state?.quote?.locked) return;

        const data = bool ? {
            mode: "fullscreen",
            stepReference: null,
            Bol16: "Upload More Files",
            handleUploadEnd: this.handleUploadEnd,
            quoteHash: this.props.id,
            indexFromOverwrite: this.state.startFromIndex,
            noPartsInQuote: this.state.parts.filter(part => !part.deleting).length,
            fileLimit: this.state.fileLimit,
            handleStartIndexFrom: num => {
                if (this.state.quote && this.state.startFromIndex) {
                    this.setState({
                        startFromIndex: num + this.state.startFromIndex
                    });
                }
            }
        } : null;

        this.props.toggleActiveModal(bool ? 'upload_drop_zone' : null, data);
    };

    handleApplyToAllClick = SK => {
        let index = this.returnIndex(SK);
        if (index === -1) return;

        const data = {
            partData: this.state.parts[index],
            definitions: this.state.definitions,
            maxPartQuantity: this.state.maxPartQuantity,
            handleUpdate: (options) => {
                let partsClone = [...this.state.parts];

                if (options.material_id && options.finish_id && options.colour_id && options.colour_val) {
                    for (let part of partsClone) {
                        part.material_id = options.material_id;
                        part.finish_id = options.finish_id;
                        part.colour_id = options.colour_id;
                        part.colour_val = options.colour_val;
                    }
                }

                this.setState({
                    parts: partsClone,
                }, () => {
                    this.handleUpdateOptions(partsClone.map(part => {
                            return {
                                PK: part.PK,
                                SK: part.SK
                            };
                        }),
                        options
                    );
                });
            }
        };

        this.props.toggleActiveModal('apply_options_to_all', data);
    };

    handleRequestForQuoteCallback = () => {
        this.props.handleLoading(true);
        this.refreshQuoteData().then(() => {
            this.props.handleLoading(false);
            this.initiateRecursivePartsCheck(true);
            this.props.toggleActiveModal('under_review', {
                quoteId: this.props.id,
                refNo: this.state.quote?.reference_number,
                loggedIn: this.props.loggedIn,
                handleCopy: this.handleUserClone
            });
        }).catch(error => {
            this.props.handleLoading(false);
            handleError('28114Mni', error);
        });
    };

    showRequestForQuoteModal = () => {
        this.props.toggleActiveModal('request_for_quote', {
            quote: this.state.quote,
            quoteId: this.props.id,
            loggedIn: this.props.loggedIn,
            callback: this.handleRequestForQuoteCallback
        });
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    render() {
        const {
            quote,
            parts,
            thumbnailErrorUrl,
            selectedLeadTimeValue,
            leadTimeListOptions,
            quotePartOverlayOpen,
            quotePartOverlayIndex,
            hideQuotePartBorders,
            quantityChangeTimeoutArray,
            quantityInputOnChangeTimeoutArray,
            totalPriceLoading,
            showNoPartsDiv,
            cloningActive,
            skeletons,
            partsOptionsTempOverwrites,
            manualOverlayTriggerValue,
            toolbarActionsOpen,
            toolbarActionsOptions,
            nicknameWarning,
            nicknameToolbarVisible,
            toolbarInputEditVisible,
            initLoaded,
            shippingOptions,
            billingAddressOpen,
            shippingAddressOpen,
            billingAddressWarning,
            shippingAddressWarning,
            addressToMutate,
            addressElemInfo,
            countriesOptions,
            selectedShippingOption,
            proceedToCheckoutWarning,
            estimatedDates,
            billingEmailWarning,
            requestForQuoteActive,
            maxPartQuantity,
            requestForQuoteTriggers,
            definitions,
            optionsTree
        } = this.state;

        // return page skellies
        if (!initLoaded) return (
            <div className="Page">
                <div id="QuoteSkeletonToolbarWrap">
                    <Skeleton
                        variant="rectangular"
                        height="100%"
                        width="100%"
                    />
                </div>

                <div className="PageContentWrap">
                    <div className="PageContent">
                        <Skeleton
                            variant="rounded"
                            height={500}
                            width="100%"
                            sx={{ borderRadius: 1 }}
                        />

                        <div id="QuoteSkeletonBottom">
                            <Skeleton
                                variant="rounded"
                                height={85}
                                width={this.props.windowBreakpoint?.w <= 1200 ? 355 : "32%"}
                                sx={{ borderRadius: 1 }}
                            />

                            <Skeleton
                                variant="rounded"
                                height={85}
                                width={this.props.windowBreakpoint?.w <= 1200 ? 355 : "32%"}
                                sx={{ borderRadius: 1 }}
                            />

                            <Skeleton
                                variant="rounded"
                                height={85}
                                width={this.props.windowBreakpoint?.w <= 1200 ? 355 : "32%"}
                                sx={{ borderRadius: 1 }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );

        const selectedLeadTime = (selectedLeadTimeValue && leadTimeListOptions && leadTimeListOptions.length) ? leadTimeListOptions.filter(leadTime => leadTime.value === selectedLeadTimeValue)[0] : null;
        let priceObject = selectedLeadTime ? selectedLeadTime.price_object : null;

        const isThereWarnings = determineIfThereIsWarnings(parts);

        const quoteLocked = quote ? !!quote.locked : false;
        const quoteExpired = quote ? !!quote.expired : false;

        const selectedPartsOptionsFinishedLoading = !(parts.length && parts.some(part => (!part.price || !part.price.success) || !part.material_id || !part.finish_id || !part.colour_id || !part.colour_val));

        const checkoutButtonDisabled =
            !parts.length ? true
            : quoteExpired ? true
            : !!totalPriceLoading  || !!isThereWarnings ? true
            : !selectedPartsOptionsFinishedLoading ? true
            : !!skeletons.length;

        const requestForQuoteButtonDisabled =
            quoteExpired || quoteLocked ? true
            : !!skeletons.length;

        // apply the index of the part in parts array as a property of the part (in order to modify it's settings)
        // filter out parts which have been deleted but not removed yet
        const filteredParts = parts.filter(part => part.deleting !== 'finished');

        // combine parts and skeletons and sort by created_at, so that when a skeleton loads into a part it doesn't mess up the order of the list
        const partsAndSkeletons = [...filteredParts, ...skeletons].sort((a, b) => a.created_at - b.created_at);

        let missingBillingAddress = !addressToMutate || !addressToMutate.billing || !Object.keys(addressToMutate.billing).some(key => !!addressToMutate.billing[key]);
        let missingShippingAddress = !addressToMutate || !addressToMutate.shipping || !Object.keys(addressToMutate.shipping).some(key => !!addressToMutate.shipping[key]);

        return (
            <div className="Page">
                {toolbarActionsOpen &&
                    <div
                        id="QuoteFalseBlur"
                        className="FullHeightWidth"
                        onClick={this.handleActionsOpenClose}
                    ></div>
                }

                {!!((this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) && quotePartOverlayOpen && typeof quotePartOverlayIndex === 'number') && !quoteLocked && !quoteExpired &&
                    <ErrorBoundary
                        componentWrapped="QuotePartOverlay"
                    >
                        <QuotePartOverlay
                            anchorEl={quotePartOverlayOpen}
                            handleShowBorders={this.handleShowBorders}
                            handleOverlayClose={this.handleOverlayClose}
                            manualOverlayTriggerValue={manualOverlayTriggerValue}
                        />
                    </ErrorBoundary>
                }

                <div id="QuoteToolbar">
                    <div className="QuoteToolbarElem">
                        <div id="QuoteToolbarFloatingIconsWrap">
                            {quote && quote.favourite &&
                                <Tooltip
                                    arrow
                                    title="This quote has been added to your favourites."
                                >
                                    <StarIcon className="Favourite"/>
                                </Tooltip>
                            }
                        </div>

                        <div id="QuoteToolbarRefWrap">
                            {quote && !quote.nickname &&
                                <h2 id="QuoteToolbarReferenceNumber" className="Bold20">{(quote && quote.reference_number) ? `#${quote.reference_number}` : '__'}</h2>
                            }

                            {quote && quote.nickname &&
                                <React.Fragment>
                                    {<p id="QuoteToolbarReferenceNumber" className="Bold20">{(quote && quote.reference_number) ? `#${quote.reference_number}` : '__'}</p>}

                                    {!nicknameToolbarVisible &&
                                        <h2
                                            onMouseEnter={quote.under_review ? null : (this.props.windowBreakpoint?.w > 1024 || isNaN(this.props.windowBreakpoint?.w)) ? () => this.setState({
                                                toolbarInputEditVisible: true
                                            }) : null}
                                            onMouseLeave={quote.under_review ? null : (this.props.windowBreakpoint?.w > 1024 || isNaN(this.props.windowBreakpoint?.w)) ? () => this.setState({
                                                toolbarInputEditVisible: false
                                            }) : null}
                                            onClick={quote.under_review ? null : (this.props.windowBreakpoint?.w > 1024 || isNaN(this.props.windowBreakpoint?.w)) ? () => {
                                                this.setState({
                                                    nicknameToolbarVisible: true
                                                }, () => {
                                                    if (this.nicknameInputRef && this.nicknameInputRef.current) this.nicknameInputRef.current.focus()
                                                });
                                            } : null}
                                            id="QuoteToolbarNickname"
                                            className={clsx('Bold12', this.props.windowBreakpoint?.w <= 1024 || nicknameToolbarVisible || quote.under_review ? 'NoPointer' : null)}
                                        >
                                            {quote.nickname}
                                            {!!(toolbarInputEditVisible && !nicknameToolbarVisible) && <span id="EditNicknameSpan" className="MainBlue">Edit</span>}
                                        </h2>
                                    }
                                </React.Fragment>
                            }
                        </div>

                        {!!((!!(quote && !quote.nickname) || nicknameToolbarVisible) && (!quote.under_review || this.props.isAdmin) && (this.props.windowBreakpoint?.w > 1024 || isNaN(this.props.windowBreakpoint?.w))) &&
                            <div id="QuoteToolbarWrap">
                                {nicknameWarning && <p id="ToolbarInputWarning" className="Reg10">{nicknameWarning}</p>}

                                <Tooltip
                                    arrow
                                    title={!this.props.loggedIn ? 'Please login to use this feature' : ''}
                                >
                                    <input
                                        id="QuoteToolbarInput"
                                        ref={this.nicknameInputRef}
                                        disabled={!this.props.loggedIn}
                                        className={!this.props.loggedIn ? 'QuoteToolbarInputDisabled' : null}
                                        placeholder="enter nickname"
                                        onKeyDown={this.handleNicknameKeyDown}
                                        maxLength={30}
                                        onBlur={this.handleNicknameBlur}
                                    >
                                    </input>
                                </Tooltip>
                            </div>
                        }
                    </div>

                    {((this.props.windowBreakpoint?.w > 1024 || isNaN(this.props.windowBreakpoint?.w)) && (!quote.under_review || this.props.isAdmin)) &&
                        <Tooltip
                            arrow
                            title={
                                quoteExpired ? (
                                    <React.Fragment>
                                        <p>Your quote has expired.</p>
                                        <br></br>
                                        <p>You can create a copy to duplicate these parts and specifications to a new quote.</p>
                                    </React.Fragment>
                                ) : quoteLocked ? (
                                    <React.Fragment>
                                        <p>Your quote is locked, saving your specifications and price.</p>
                                        <br></br>
                                        <p>Quote Expiry: <span className="TonesBlack">{quote.expiry_date ? quote.expiry_date : '-'}</span></p>
                                        <br></br>
                                        <p>You can still edit the quote by creating a copy.</p>
                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        <p>Your quote is unlocked and vulnerable to price changes. Lock your quote now to secure the price.</p>
                                    </React.Fragment>
                                )
                            }
                        >
                            <div className="QuoteToolbarElem">
                                {
                                    quoteExpired ? <HourglassBottomIcon id="QuoteStatusIcon" className="QuoteExpiredIcon" />
                                    : quoteLocked ? <LockIcon id="QuoteStatusIcon"/>
                                    : <LockOpenOutlinedIcon id="QuoteStatusIcon" />
                                }

                                <div className="QuoteToolbarInfoWrap">
                                    <p className="Bold12">{quoteExpired ? 'Expired' : quoteLocked ? 'Locked' : 'Unlocked'} Quote:</p>

                                    <p className="Bold16">{quoteExpired ? `Expired on ${quote.expiry_date ? quote.expiry_date : '-'}` : !quoteLocked ? 'Lock to secure quote' : `Valid for ${quote.expiry_days_remaining ? quote.expiry_days_remaining + ' day' + (quote.expiry_days_remaining < 2 ? '' : 's') : '-'}`}</p>
                                </div>
                            </div>
                        </Tooltip>
                    }

                    {((this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) && ((!quote.under_review || this.props.isAdmin) && !quoteExpired)) &&
                        <Tooltip
                            arrow
                            title={
                                <React.Fragment>
                                    <p>Your order is estimated to:</p>
                                    <br></br>
                                    <p>Ship by: <span className="TonesBlack">{estimatedDates && estimatedDates.production_estimate ? estimatedDates.production_estimate : '-'}</span></p>
                                    <p>Your order will leave our factory on or before this date.</p>
                                    <br></br>
                                    <p>Delivered by: <span className="TonesBlack">{estimatedDates && estimatedDates.delivery_estimate ? estimatedDates.delivery_estimate : '-'}</span></p>
                                    <p>{estimatedDates && estimatedDates.delivery_estimate ? 'Your estimated delivery date is based on the shipping date and the estimated delivery time.' : 'Please enter your shipping address to get a delivered by date.'}</p>
                                    <br></br>
                                    <p>You can edit the delivery date by changing production lead times, material finishes and shipping options.</p>
                                </React.Fragment>
                            }
                        >
                            <div className="QuoteToolbarElem">
                                <div className="QuoteToolbarInfoWrap">
                                    <p className="Bold12">{estimatedDates && !totalPriceLoading ? estimatedDates.production_estimate && !estimatedDates.delivery_estimate ? 'Estimated to Ship By:' : 'Estimated Delivery By:' : 'Estimated dates loading...'}</p>

                                    <p className="Bold16">{estimatedDates && !totalPriceLoading ? estimatedDates.production_estimate && !estimatedDates.delivery_estimate ? estimatedDates.production_estimate : estimatedDates.delivery_estimate : '__'}</p>
                                </div>
                            </div>
                        </Tooltip>
                    }

                    {(!quote.under_review || this.props.isAdmin) &&
                        <div className="QuoteToolbarElem">
                            <div id="QuoteToolBarEndElemMargin">
                                {(this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) &&
                                    <div className="QuoteToolbarInfoWrap">
                                        <p className="Bold12 EndAlign">TOTAL</p>

                                        <p className="Bold16 MainBlue EndAlign">{
                                            requestForQuoteActive ? '-'
                                            : !quoteExpired && priceObject && priceObject.total_incl_vat && !totalPriceLoading ? returnCurrencySignAndOrValue(quote.currency, priceObject.total_incl_vat) : '-'
                                        }</p>
                                    </div>
                                }

                                {(!quoteExpired && !toolbarActionsOpen) &&
                                    <button
                                        style={{
                                            marginTop: 0,
                                            marginLeft: 20,
                                            height: '65%',
                                            padding: '0px 25px'
                                        }}
                                        onClick={
                                            requestForQuoteActive && !requestForQuoteButtonDisabled ? this.showRequestForQuoteModal
                                            : !checkoutButtonDisabled && !requestForQuoteActive ? () => this.handleInitialPaymentChecks(() => this.props.history.push(`/checkout/${this.props.id}`))
                                            : null
                                        }
                                        className={clsx(
                                            requestForQuoteActive && !requestForQuoteButtonDisabled ? 'CheckoutButtonRequest' : '',
                                            (checkoutButtonDisabled && !requestForQuoteActive) || (requestForQuoteButtonDisabled && requestForQuoteActive)? 'CheckoutButtonLoading' :  '',
                                            'Bold16',
                                            'CheckoutButton',
                                            'ToolbarCheckoutButton'
                                        )}
                                    >
                                        {requestForQuoteActive ? this.props.windowBreakpoint?.w < 768 ? 'Request' : 'Request for Quote' : 'Checkout'}
                                    </button>
                                }
                            </div>

                            {(this.props.windowBreakpoint?.w > 480 || isNaN(this.props.windowBreakpoint?.w)) &&
                                <div
                                    id="QuoteToolbarActions"
                                    tabIndex="0"
                                    onClick={this.handleActionsOpenClose}
                                    ref={this.toolbarActions}
                                    className={
                                        toolbarActionsOpen && toolbarActionsOptions && toolbarActionsOptions.length ?
                                            requestForQuoteActive ? 'QuoteToolbarActionsOpenRFQ' : 'QuoteToolbarActionsOpen'
                                        : null
                                    }
                                >
                                    <p className="Bold12 NoPointer">Actions</p>


                                    <ExpandMoreIcon
                                        id="QuoteActionIcon"
                                        className={clsx(toolbarActionsOpen ? "OpenIcon" : null, 'NoPointer')}
                                    />

                                    {toolbarActionsOpen &&
                                        <DropDownList
                                            keyToUse="quote-toolbar-actions"
                                            anchorEl={this.toolbarActions.current}
                                            open={toolbarActionsOpen && toolbarActionsOptions && toolbarActionsOptions.length}
                                            handleSelection={this.handleToolbarAction}
                                            options={toolbarActionsOptions}
                                            isOptionHidden={option => this.determineIsActionHidden(option)}
                                            parentIsBorderBox={true}
                                            bordered="left"
                                            optionStyleOverwrite={{
                                                padding: 10
                                            }}
                                            returnElem={option => this.returnToolbarActionElem(option)}
                                        />
                                    }
                                </div>
                            }
                        </div>
                    }

                    {(quote.under_review && !this.props.isAdmin) &&
                        <div className="QuoteToolbarElem">
                            <div id="QuoteToolBarEndElemNoMargin">
                                {(this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) &&
                                    <div className="QuoteToolbarInfoWrap QuoteToolbarReverseInfoWrap">
                                        <p className="Bold12">Under Review</p>

                                        <p className="Bold16">We are looking at your quote</p>
                                    </div>
                                }

                                <GroupsIcon id="QuoteUnderReviewIcon" />
                            </div>
                        </div>
                    }
                </div>

                <div className="PageContentWrap">
                    <div
                        className="PageContent"
                        ref={this.contentRef}
                    >
                        <div id="PartsWrap" className={quoteExpired ? 'PartsWrapMinHeight' : null}>
                            <div
                                id="PartsWrapHeader"
                                className={clsx(!!(hideQuotePartBorders && typeof quotePartOverlayIndex === 'number' && quotePartOverlayIndex === 0) ? "PartsWrapHeaderHideBorderBottom" : "", showNoPartsDiv || !(partsAndSkeletons && partsAndSkeletons.length) ? 'QuoteHeaderBorderBottom' : null)}
                            >
                                <div id="PartsHeaderStartWrap">
                                    <h4 className={clsx('Bold12', 'StepReference')}>Manage Your Quote</h4>
                                    <h3 className="Bold16">Edit Part Specifications</h3>
                                </div>

                                {!quoteLocked && !quoteExpired &&
                                    <div id="UploadingPartsHeaderEndWrap">
                                        <button
                                            type="button"
                                            onClick={() => this.handleToggleDropZone(true)}
                                            id="ShowDropZoneButton"
                                            className="Button"
                                        >
                                            <UploadIcon
                                                id="ShowDropZoneIcon"
                                            />

                                            {(this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) &&
                                                <p className="Bold12">Add More Parts</p>
                                            }
                                        </button>
                                    </div>
                                }
                            </div>

                            <div id="Parts">
                                {!!(partsAndSkeletons && !partsAndSkeletons.length) && showNoPartsDiv &&
                                    <div id="NoPartsDiv">
                                        <p className="Bold16">{quoteExpired ? 'This quote expired without any parts.' : 'Click the "Add More Parts" button to upload new parts.'}</p>
                                    </div>
                                }
                                {!!(partsAndSkeletons && partsAndSkeletons.length) && partsAndSkeletons.map((part, index) => {
                                    if (part.is_skeleton) {
                                        return <QuotePartSkeleton
                                            key={'quote_part_skeleton_' + part.id}
                                            index={index}
                                            message={part.message}
                                            isLastPart={index + 1 === partsAndSkeletons.length}
                                            converting={part.converting}
                                            windowBreakpoint={this.props.windowBreakpoint}
                                        />
                                    } else {
                                        const {
                                            PK,
                                            SK,
                                            thumbnail,
                                            filename,
                                            bodies,
                                            material_id,
                                            finish_id,
                                            colour_id,
                                            colour_val,
                                            part_loading,
                                            status,
                                            warnings,
                                            loading_status,
                                            deleting,
                                            qty_discounts,
                                            quantityLoading,
                                            base_price_values,
                                            loading_percentage
                                        } = part;

                                        const {
                                            x_extent,
                                            y_extent,
                                            z_extent
                                        } = part.size;

                                        const {
                                            qty,
                                            qty_discount,
                                            base,
                                            unit,
                                            total,
                                            success
                                        } = part.price;

                                        let tempOverwrites = partsOptionsTempOverwrites[SK];

                                        let active_material_id = tempOverwrites && isNumber(tempOverwrites.material_id) ? tempOverwrites.material_id : material_id;
                                        let active_finish_id = tempOverwrites && isNumber(tempOverwrites.finish_id) ? tempOverwrites.finish_id : finish_id;
                                        let active_colour_id = tempOverwrites && isNumber(tempOverwrites.colour_id) ? tempOverwrites.colour_id : colour_id;
                                        let active_colour_val = tempOverwrites && isNumber(tempOverwrites.colour_val) ? tempOverwrites.colour_val : colour_val;

                                        const hideBorderRight = !!(hideQuotePartBorders && quotePartOverlayIndex === index);
                                        const hideBorderBottom = !!(hideQuotePartBorders && (quotePartOverlayIndex === index || quotePartOverlayIndex - 1 === index));

                                        const materialLabel = (definitions && isNumber(active_material_id) && definitions['materials'][active_material_id]) ? `${definitions['materials'][active_material_id].label} (${ definitions['materials'][active_material_id].tech_label_short})` : null;
                                        const finishLabel = (definitions && isNumber(active_finish_id) && definitions['surface_finishes'][active_finish_id]) ? definitions['surface_finishes'][active_finish_id].label : null;
                                        // checks the definition for the value 'multi_colour' if thats true then the label will be pulled from colour_values instead of colour_finishes
                                        const colourLabel = (definitions && isNumber(active_colour_id) && definitions['colour_finishes'][active_colour_id]) ?
                                            !definitions['colour_finishes'][active_colour_id].multi_colour ?
                                                definitions['colour_finishes'][active_colour_id].label
                                                : definitions['colour_values'][active_colour_val] ?
                                                    definitions['colour_values'][active_colour_val].label
                                                    : null
                                            : null;
                                        const colourHex = colourLabel && definitions && isNumber(active_colour_val) && definitions['colour_values'][active_colour_val] ? definitions['colour_values'][active_colour_val].hex : null;

                                        let quantityChangeTimeoutArrayIndex = quantityChangeTimeoutArray.map(x => x.SK).indexOf(SK);
                                        let quantityToUse = quantityChangeTimeoutArrayIndex !== -1 ? quantityChangeTimeoutArray[quantityChangeTimeoutArrayIndex].value : qty;

                                        let inputTimeoutActive = quantityInputOnChangeTimeoutArray.some(x => x.SK === SK);
                                        let inputTimeoutCurrQty = inputTimeoutActive ? quantityInputOnChangeTimeoutArray.filter(x => x.SK === SK)[0].value : null;

                                        if (inputTimeoutCurrQty) quantityToUse = inputTimeoutCurrQty;
                                        if (isNaN(quantityToUse)) quantityToUse = 1;
                                        if (tempOverwrites && !isNaN(tempOverwrites.quantity)) quantityToUse = tempOverwrites.quantity;

                                        let unitPriceToUse = unit ? returnCurrencySignAndOrValue(quote.currency, unit) : '-';
                                        let basePriceToUse = base ? returnCurrencySignAndOrValue(quote.currency, base) : '-';

                                        let partPriceLoading = isPriceLoading(status, selectedLeadTimeValue && selectedLeadTimeValue.type, active_material_id, active_finish_id, active_colour_id, base_price_values);

                                        let statusFailed = !!warnings.status;

                                        let partWarning = warnings.status ? warnings.status : [
                                            ...warnings.materials.filter(warning => active_material_id === warning.id),
                                            ...warnings.surface_finishes.filter(warning => active_finish_id === warning.id),
                                            ...warnings.colour_finishes.filter(warning => active_colour_id === warning.id),
                                            !success && (status !== 'RUNNING' && status !== 'INIT' && status !== 'CONVERTING') ? {
                                                message: `Pricing generation failure for (${materialLabel})`,
                                                readMore: 'Pricing has failed for the selected options.|Please select another option.'
                                            } : null
                                        ].filter(x => !!x)[0];

                                        let partReqForQuotes = requestForQuoteActive && requestForQuoteTriggers ?
                                            [quantityToUse >= requestForQuoteTriggers.quantity_part, total >= requestForQuoteTriggers.price_part].some(x => x)
                                            : false;

                                        let reducedWarnings = {
                                            materials: warnings.materials ? warnings.materials.map(x => x.id) : [],
                                            surface_finishes: warnings.surface_finishes ? warnings.surface_finishes.map(x => x.id) : [],
                                            colour_finishes: warnings.colour_finishes ? warnings.colour_finishes.map(x => x.id) : [],
                                        };

                                        return <QuotePart
                                            key={`part_${index}`}
                                            keys={{PK, SK}}
                                            isLastPart={index + 1 === partsAndSkeletons.length && !(!quoteLocked && !quoteExpired && partsAndSkeletons.length)}
                                            partIniting={status === 'INIT'}
                                            partLoading={!!(part_loading || !active_material_id || !active_finish_id || !active_colour_id || status === 'RUNNING')}
                                            partPriceLoading={partPriceLoading}
                                            partLoadingStatus={part_loading}
                                            quoteLocked={quoteLocked}
                                            adminLocked={quote && quote.admin_locked}
                                            quoteExpired={quoteExpired}
                                            position_index={index}
                                            thumbnail={
                                                thumbnail ? thumbnail
                                                    : (status === 'FAILED' || status === 'SUCCESSFUL') ? thumbnailErrorUrl : null
                                            }
                                            status={status}
                                            filename={filename}
                                            dimensions={(x_extent && y_extent && z_extent) ? `${x_extent} x ${y_extent} x ${z_extent} mm` : null}
                                            bodies={typeof bodies === 'number' ? bodies > 1 ? `${bodies} Bodies` : `${bodies} Body` : null}
                                            quantity={!isNaN(quantityToUse) ? parseInt(quantityToUse) : 1}
                                            quantityLoading={!!quantityLoading}
                                            inputTimeoutActive={inputTimeoutActive}
                                            unitPrice={unitPriceToUse}
                                            discount={typeof qty_discount === 'number' ? `${Math.round(qty_discount * 100)}%` : '-'}
                                            basePrice={basePriceToUse}
                                            totalPrice={typeof total === 'number' ? returnCurrencySignAndOrValue(quote.currency, total) : '-'}
                                            thumbnailErrorUrl={thumbnailErrorUrl}
                                            handlePartSettingsClick={this.handlePartSettingsClick}
                                            handlePartSettingsClose={this.handleCollapsableClose}
                                            hideBorderBottom={hideBorderBottom}
                                            hideBorderRight={hideBorderRight}
                                            maxPartQuantity={maxPartQuantity}
                                            handleQuantityClick={this.handleQuantityClick}
                                            handleQuantityInputOnChange={this.handleQuantityInputOnChange}
                                            quantityChangeTimeoutArray={quantityChangeTimeoutArray}
                                            togglePartViewer={data => this.props.toggleActiveModal('part_viewer', data)}
                                            handleDeletePart={this.handleDeletePart}
                                            collapseOpen={quotePartOverlayIndex === index}
                                            handlePartOptionClick={this.handleApplyClick}
                                            handleApplyToAllClick={this.handleApplyToAllClick}
                                            partsData={parts}
                                            partData={part}
                                            partWarning={partWarning}
                                            statusFailed={statusFailed}
                                            loadingMessage={loading_status}
                                            deleting={deleting}
                                            handleOpenBulkPricingModal={() => this.props.toggleActiveModal('bulk_prices_modal', {
                                                basePrice: base,
                                                discountData: qty_discounts,
                                                handleBulkPricesClick: val => this.handleBulkPricesClick(SK, val),
                                                currency: quote.currency,
                                                rfqLimits: requestForQuoteTriggers
                                            })}
                                            handleClonePart={this.handleClonePart}
                                            cloningActive={cloningActive}
                                            tempOverwrites={partsOptionsTempOverwrites[SK]}
                                            partLoadingPercentage={loading_percentage || '100%'}
                                            xtra={!!(this.props.isAdmin || quote.admin_locked)}
                                            windowBreakpoint={this.props.windowBreakpoint}
                                            partReqForQuotes={partReqForQuotes}
                                            openReqForQuotesModal={this.showRequestForQuoteModal}
                                            currencyUnitSign={quote?.currency?.unit_sign}
                                            underReview={!this.props.isAdmin && quote.under_review}
                                            removedFromTotal={warnings.remove_from_total}
                                            reducedWarnings={reducedWarnings}
                                            definitions={definitions}
                                            optionsTree={optionsTree}
                                            spec={[active_material_id, active_finish_id, active_colour_id]}
                                            colour_val={active_colour_val}
                                            forcedDefinitionsIndices={FORCED_DEFINITIONS_INDICES}
                                            labels={{
                                                material: materialLabel,
                                                finish: finishLabel,
                                                colour: {
                                                    text: colourLabel,
                                                    hex: colourHex
                                                }
                                            }}
                                            handleQuotePartOverlayRefresh={this.handleQuotePartOverlayRefresh}
                                            tempOverwritesActive={!!(tempOverwrites && (tempOverwrites.material_id || tempOverwrites.finish_id || tempOverwrites.colour_id || tempOverwrites.colour_val))}
                                            basePriceValues={base_price_values}
                                            requestForQuoteTriggers={requestForQuoteTriggers}
                                            handleOpenInfoDisplayImgCarousel={data => {
                                                this.props.toggleActiveModal('img_carousel', {
                                                    ...data,
                                                    handleCloseCallback: this.handleQuotePartOverlayRefresh
                                                })
                                                this.handleQuotePartOverlayRefresh();
                                            }}
                                        />
                                    }
                                })}

                                {!!(!quoteLocked && !quoteExpired && partsAndSkeletons.length) &&
                                    <div
                                        type="button"
                                        onClick={() => this.handleToggleDropZone(true)}
                                        id="ShowDropZoneButtonBottom"
                                    >
                                        <UploadIcon
                                            id="ShowDropZoneIcon"
                                        />

                                        {(this.props.windowBreakpoint?.w > 768 || isNaN(this.props.windowBreakpoint?.w)) && <p className="Bold12">Add More Parts</p>}
                                    </div>
                                }
                            </div>

                            {quoteExpired &&
                                <div id="ExpiredQuoteMemo">
                                    <p className="Bold16">Quote expired.<br></br><span className="Reg16">You can create a copy to duplicate these parts and specifications to a new quote.</span></p>

                                    <Tooltip
                                        arrow
                                        title={!this.props.loggedIn ? 'Please login to use this feature' : ''}
                                    >
                                        <button
                                            id="ExpiredCopyButton"
                                            className={clsx('Bold16', !this.props.loggedIn ? 'ExpiredButtonDisabled' : null)}
                                            onClick={!this.props.loggedIn ? null : () => this.handleUserClone(false)}
                                        >
                                            Create a copy
                                        </button>
                                    </Tooltip>
                                </div>
                            }

                            {!quoteExpired &&
                                <div id="QuoteBottomWrap">
                                    <div ref={this.addressWrapRef} id="QuoteBottomStart">
                                        <div className="QuoteBottomSection">
                                            <AddressHandler
                                                mode="quote"
                                                which="billing"
                                                email={quote && quote.email}
                                                loggedIn={this.props.loggedIn}
                                                address={addressToMutate && addressToMutate.billing ? addressToMutate.billing : null}
                                                handleOpenClose={bool => this.handleBillingToggle(bool)}
                                                noAddressInfo={missingBillingAddress}
                                                copyInitialValue={missingShippingAddress}
                                                inputRefs={!!(billingAddressOpen && this.addressesInfoRefs && this.addressesInfoRefs.current) ? this.addressesInfoRefs.current : null}
                                                open={billingAddressOpen}
                                                handleApplyClick={billingAddressOpen ? this.handleUpdateAddress : null}
                                                addressElemInfo={addressElemInfo}
                                                countriesOptions={countriesOptions}
                                                warnings={billingAddressWarning}
                                                emptyWarning={!!(proceedToCheckoutWarning && proceedToCheckoutWarning.address && missingBillingAddress)}
                                                billingEmailRef={!!(billingAddressOpen && this.billingEmailRef) ? this.billingEmailRef : null}
                                                billingEmailWarning={billingEmailWarning}
                                            />
                                        </div>

                                        <div className="QuoteBottomSection">
                                            <AddressHandler
                                                mode="quote"
                                                which="shipping"
                                                email={quote && quote.email}
                                                loggedIn={this.props.loggedIn}
                                                address={addressToMutate && addressToMutate.shipping ? addressToMutate.shipping : null}
                                                handleOpenClose={bool => this.handleShippingToggle(bool)}
                                                noAddressInfo={missingShippingAddress}
                                                copyInitialValue={missingBillingAddress}
                                                inputRefs={!!(shippingAddressOpen && this.addressesInfoRefs && this.addressesInfoRefs.current) ? this.addressesInfoRefs.current : null}
                                                open={shippingAddressOpen}
                                                handleApplyClick={shippingAddressOpen ? this.handleUpdateAddress : null}
                                                addressElemInfo={addressElemInfo}
                                                countriesOptions={countriesOptions}
                                                warnings={shippingAddressWarning}
                                                emptyWarning={!!(proceedToCheckoutWarning && proceedToCheckoutWarning.address && missingShippingAddress)}
                                            />
                                        </div>
                                    </div>

                                    <div id="QuoteBottomEnd" className="QuoteBottomSection">
                                        <OptionsList
                                            title="Select Production Lead Times"
                                            options={leadTimeListOptions}
                                            rootStyle={{
                                                marginBottom: 26
                                            }}
                                            infoI={{
                                                returnTooltip: () => (
                                                    <React.Fragment>
                                                        <p className="TonesBlack">What are production lead times?</p>
                                                        <p>The production lead time is the time it takes to manufacture your parts and apply finishes.</p>
                                                        <br></br>
                                                        <p className="TonesBlack">What are working days?</p>
                                                        <p>Lead times are in working days: Monday to Friday, excluding weekends and public holidays.</p>
                                                        <br></br>
                                                        <p className="TonesBlack">Is there a cut off time?</p>
                                                        <p>The cut off time for orders is 2 PM on a working day. Orders placed after 2 PM start from the next working day.</p>
                                                    </React.Fragment>
                                                ),
                                                tooltip: null,
                                                delay: null,
                                                styleOverwrite: null
                                            }}
                                            handleSelection={this.handleLeadTimeSelection}
                                            isSelectedOption={({ value }) => !!(value.type && selectedLeadTimeValue.type && value.type === selectedLeadTimeValue.type)}
                                            returnElem={({ difference, label, maxModifier, value, ships_by, ov_clt }, selected) => {
                                                return (
                                                    <div className={clsx(selected ? 'LeadTimeOptionWrapSelected' : null, 'LeadTimeOptionWrap')}>
                                                        <div>
                                                            <p className="Bold12 EstimatedShippingTitle LeadTimeOptionP">
                                                                {ov_clt ? 'Custom Service' : label}
                                                            </p>

                                                            {ov_clt && <p className="Reg12 ListTitleLabelLeadTime">{ov_clt} working days</p>}
                                                            {!ov_clt && returnListTitleLabel('Reg12 ListTitleLabelLeadTime', value, maxModifier, totalPriceLoading, difference)}
                                                            <p className="Reg12">Ships before <span>{ships_by ? ships_by : '-'}</span></p>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        />

                                        <OptionsList
                                            title="Select Shipping"
                                            options={shippingOptions.map(option => ({...option, key: option.service_code}))}
                                            overwrite={
                                                !!(!shippingOptions.length || !shippingOptions.length) ?
                                                    <div className={clsx('ShippingRateOptionWrap', 'ShippingRateNoOption')}>
                                                        <div>
                                                            <p className="Bold12 ShippingOptionTitle">Shipping Options</p>

                                                            <p className="Reg12">Please add your address to see options</p>
                                                        </div>
                                                    </div>
                                                : false
                                            }
                                            isSelectedOption={({ service_code }) => service_code === selectedShippingOption}
                                            handleSelection={this.handleShippingOptionSelection}
                                            rootStyle={{
                                                marginBottom: 26
                                            }}
                                            returnElem={({ stripe_json }, selected) => {
                                                const {
                                                    shipping_rate_data
                                                } = stripe_json;

                                                const {
                                                    fixed_amount,
                                                    display_name,
                                                    delivery_estimate
                                                } = shipping_rate_data;

                                                let value = '-';
                                                let deliveryDays;
                                                let deliveryDaysText = '-';
                                                try {
                                                    value = returnCurrencySignAndOrValue(quote.currency, null, fixed_amount.amount, true, val => val / 100);
                                                    deliveryDays = [...new Set([delivery_estimate.minimum.value, delivery_estimate.maximum.value])];
                                                    if (deliveryDays.length) {
                                                        deliveryDaysText = `${deliveryDays.join('-')} Working Day${deliveryDays.length > 1 ? 's' : ''} - ${value}`;
                                                    }
                                                } catch (error) {
                                                    console.log('caught error @ delivery value / parsing delivery days', error);
                                                }

                                                return (
                                                    <div className={clsx(selected ? 'ShippingRateOptionWrapSelected' : null, 'ShippingRateOptionWrap')}>
                                                        <div>
                                                            <p className="Bold12 ShippingOptionTitle">{display_name}</p>

                                                            <p className="Reg12">{deliveryDaysText}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        />

                                        {!quoteExpired &&
                                            <div id="PriceBreakdownWrap">
                                                <div>
                                                    {(!parts.length || totalPriceLoading || (!selectedPartsOptionsFinishedLoading && !requestForQuoteActive)) &&
                                                        <Skeleton
                                                            variant="rounded"
                                                            height={388}
                                                            width="100%"
                                                            sx={{ borderRadius: 1 }}
                                                        />
                                                    }

                                                    {(!!parts.length && !totalPriceLoading && (selectedPartsOptionsFinishedLoading || requestForQuoteActive)) &&
                                                        <div id="PriceBreakdown">
                                                            <div className={clsx('PriceBreakdownElem', 'PriceBreakdownElemBorderTop')}>
                                                                <h4 className="Reg16">Items sub-total</h4>
                                                                <p className="Bold16">{
                                                                    requestForQuoteActive ? `${quote?.currency?.unit_sign || ''} -`
                                                                    : selectedPartsOptionsFinishedLoading && priceObject && typeof priceObject.total_line_items === 'number' ? returnCurrencySignAndOrValue(quote.currency, priceObject.total_line_items, null, true) : '-'
                                                                }</p>
                                                            </div>

                                                            <div className="PriceBreakdownElem">
                                                                <div>
                                                                    <h4 className="Reg16">{this.props.windowBreakpoint?.w > 1200 || isNaN(this.props.windowBreakpoint?.w) ? 'Minimum' : 'Min'} order value surcharge</h4>
                                                                    <MoreInfoI
                                                                        returnTooltip={() => (
                                                                            <React.Fragment>
                                                                                <p>Each order takes time to prepare, manufacture, quality check and package by the 3D People team.</p>
                                                                                <br></br>
                                                                                <p>Regardless of the size of your order, there is a minimum time spent working on it.</p>
                                                                                <br></br>
                                                                                <p>The minimum order cost of {!!(quote && quote.price && typeof quote.price.moc_rate === 'number') ? returnCurrencySignAndOrValue(quote.currency, quote.price.moc_rate, null, true) : returnCurrencySignAndOrValue(quote.currency, 30, null, true)} applies to all orders and does not include fees for shipping.</p>
                                                                            </React.Fragment>
                                                                        )}
                                                                        tooltip={null}
                                                                        delay={null}
                                                                        styleOverwrite={{
                                                                            marginLeft: 4,
                                                                            position: 'relative',
                                                                            top: -3
                                                                        }}
                                                                    />
                                                                </div>

                                                                <p className="Bold16">{
                                                                    requestForQuoteActive ? `${quote?.currency?.unit_sign || ''} -`
                                                                    : !!(parts && parts.length) && priceObject && priceObject.moc_total ? returnCurrencySignAndOrValue(quote.currency, priceObject.moc_total, null, true) : returnCurrencySignAndOrValue(quote.currency, 0, null, true)
                                                                }</p>
                                                            </div>

                                                            {!!(priceObject && priceObject?.colour_setup_splits.length) && priceObject.colour_setup_splits.sort((a, b) => a.setup_cost - b.setup_cost).map((split, index) =>
                                                                <div
                                                                    key={'PriceBreakdownColourFeeSplit_' + index}
                                                                    className="PriceBreakdownElem PriceBreakDownElemColour"
                                                                >
                                                                    {!split.sales_team &&
                                                                        <div
                                                                            className="PriceBreakdownColourPreview"
                                                                            style={{
                                                                                backgroundColor: split.hex,
                                                                                border: split.hex.startsWith('#FFF') ? 'var(--border-main)' : 'none'
                                                                            }}
                                                                        />
                                                                    }
                                                                    <div>
                                                                        <Tooltip
                                                                            title={split.label}
                                                                            arrow
                                                                        >
                                                                            <h4 className="Reg16 PriceBreakdownElemMaxWidth">
                                                                                {split.label}
                                                                            </h4>
                                                                        </Tooltip>

                                                                        <MoreInfoI
                                                                            returnTooltip={() => (
                                                                                <React.Fragment>
                                                                                    <p>A fee is applied for each
                                                                                        colour dye used in your
                                                                                        order to cover setup
                                                                                        costs.</p>
                                                                                    <br></br>
                                                                                    <p>The fees are clearly
                                                                                        displayed as you select
                                                                                        finishes and itemised in
                                                                                        your quote summary.</p>
                                                                                </React.Fragment>
                                                                            )}
                                                                            tooltip={null}
                                                                            delay={null}
                                                                            styleOverwrite={{
                                                                                marginLeft: 4,
                                                                                position: 'relative',
                                                                                top: -3
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <p className="Bold16">{returnCurrencySignAndOrValue(quote.currency, split.setup_cost, null, true)}</p>
                                                                </div>
                                                            )}

                                                            <div className="PriceBreakdownElem">
                                                                <h4 className="Reg16">Shipping</h4>
                                                                <p className="Bold16">{
                                                                    requestForQuoteActive ? `${quote?.currency?.unit_sign || ''} -`
                                                                        : !!(parts && parts.length) && priceObject && priceObject.shipping_total ? returnCurrencySignAndOrValue(quote.currency, priceObject.shipping_total, null, true) : returnCurrencySignAndOrValue(quote.currency, 0, null, true)
                                                                }</p>
                                                            </div>

                                                            <div className="PriceBreakdownElem">
                                                                <h4 className="Reg16">Order sub-total</h4>
                                                                <p className="Bold16">{
                                                                    requestForQuoteActive ? `${quote?.currency?.unit_sign || ''} -`
                                                                    : !!(parts && parts.length) && priceObject && typeof priceObject.total_excl_vat === 'number' ? returnCurrencySignAndOrValue(quote.currency, priceObject.total_excl_vat, null, true) : '-'
                                                                }</p>
                                                            </div>

                                                            <div className="PriceBreakdownElem">
                                                                <h4 className="Reg16">Vat {priceObject && typeof priceObject.vat_rate === 'number' ? priceObject.vat_rate * 100 : '20'}%</h4>
                                                                <p className="Bold16">{
                                                                    requestForQuoteActive ? `${quote?.currency?.unit_sign || ''} -`
                                                                    : !!(parts && parts.length) && priceObject && priceObject.vat_total ? returnCurrencySignAndOrValue(quote.currency, priceObject.vat_total, null, true) : returnCurrencySignAndOrValue(quote.currency, 0, null, true)
                                                                }</p>
                                                            </div>

                                                            <div className="PriceBreakdownElem">
                                                                <h4 className="Reg16">Total</h4>
                                                                <p className="Bold20">{
                                                                    requestForQuoteActive ? 'RFQ'
                                                                    : !!(parts && parts.length) && priceObject && typeof priceObject.total_incl_vat === 'number' ? returnCurrencySignAndOrValue(quote.currency, priceObject.total_incl_vat, null, true) : '-'
                                                                }</p>
                                                            </div>
                                                        </div>
                                                    }

                                                    {!quoteExpired &&
                                                        <div id="EstimatedDeliveryNoteWrap">
                                                            <Tooltip
                                                                arrow
                                                                title={
                                                                    <React.Fragment>
                                                                        <p>Your order is estimated to:</p>
                                                                        <br></br>
                                                                        <p>Ship by: <span className="TonesBlack">{estimatedDates && estimatedDates.production_estimate ? estimatedDates.production_estimate : '-'}</span></p>
                                                                        <p>Your order will leave our factory on or before this date.</p>
                                                                        <br></br>
                                                                        <p>Delivered by: <span className="TonesBlack">{estimatedDates && estimatedDates.delivery_estimate ? estimatedDates.delivery_estimate : '-'}</span></p>
                                                                        <p>{estimatedDates && estimatedDates.delivery_estimate ? 'Your estimated delivery date is based on the shipping date and the estimated delivery time.' : 'Please enter your shipping address to get a delivered by date.'}</p>
                                                                        <br></br>
                                                                        <p>You can edit the delivery date by changing production lead times, material finishes and shipping options.</p>
                                                                    </React.Fragment>
                                                                }
                                                            >
                                                                <p className="Bold16">{estimatedDates && !totalPriceLoading ? estimatedDates.production_estimate && !estimatedDates.delivery_estimate ? 'Estimated to ship by' : 'Estimated delivery by' : ''} {estimatedDates && !totalPriceLoading ? estimatedDates.production_estimate && !estimatedDates.delivery_estimate ? <span className="MainBlue">{estimatedDates.production_estimate}</span> : <span className="MainBlue">{estimatedDates.delivery_estimate}</span> : ''}</p>
                                                            </Tooltip>
                                                        </div>
                                                    }

                                                    {!quoteExpired &&
                                                        <div id="LeadTimeCountDownWrap">
                                                            <Tooltip
                                                                arrow
                                                                title="The lead time for orders placed after the 2:00pm cut off time will begin on the following business day."
                                                            >
                                                                <p className="Reg12">Lead times are valid for the next {<TimeRemaining />}</p>
                                                            </Tooltip>
                                                        </div>
                                                    }

                                                    <button
                                                        onClick={
                                                            requestForQuoteActive && !requestForQuoteButtonDisabled ? this.showRequestForQuoteModal
                                                            : !checkoutButtonDisabled && !requestForQuoteActive ? () => this.handleInitialPaymentChecks(() => this.props.history.push(`/checkout/${this.props.id}`))
                                                            : null
                                                        }
                                                        className={clsx(
                                                            requestForQuoteActive && !requestForQuoteButtonDisabled ? 'CheckoutButtonRequest' : '',
                                                            (checkoutButtonDisabled && !requestForQuoteActive) || (requestForQuoteButtonDisabled && requestForQuoteActive) ? 'CheckoutButtonLoading' :  '',
                                                            'Bold16',
                                                            'CheckoutButton'
                                                        )}
                                                    >
                                                        {requestForQuoteActive ? 'Request for Quote' : 'Proceed to Checkout'}
                                                    </button>

                                                    {(!requestForQuoteActive && !requestForQuoteButtonDisabled) &&
                                                        <p id="QuoteHelpRequest" className="Reg12">Want us to take a look first? <br></br> <span className="Bold12" onClick={this.showRequestForQuoteModal}>Submit a Request for Quote</span> <br></br>We'll review your files and often find better pricing for you.</p>
                                                    }

                                                    {requestForQuoteActive &&
                                                        <div className={clsx('QuoteWarning', 'QuoteReqForWarning')}>
                                                            <div className="QuoteWarningIconWrap">
                                                                <PriorityHighIcon className="QuoteBottomWarningIcon"/>
                                                            </div>

                                                            <p className="Bold12">To ensure competitive pricing, your quote requires our attention. Please submit a Request For Quote. <Tooltip
                                                                arrow
                                                                title={returnRFQTooltip()}
                                                            ><span className="Bold12">More Info.</span></Tooltip></p>
                                                        </div>
                                                    }

                                                    {(proceedToCheckoutWarning && (proceedToCheckoutWarning.address || proceedToCheckoutWarning.shipping)) &&
                                                        <div className="QuoteWarning">
                                                            <div className="QuoteWarningIconWrap">
                                                                <PriorityHighIcon className="QuoteBottomWarningIcon"/>
                                                            </div>

                                                            <p className="Bold12">{proceedToCheckoutWarning.address || proceedToCheckoutWarning.shipping}</p>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <FooterImgLinks
                    marginTop={this.props.windowBreakpoint?.w <= 768 ? 50 : 0}
                    paddingTop={0}
                />
            </div>
        );
    };
}

export default withRouter(Quote);