import React, {useEffect, useState, useRef, memo} from 'react';
import clsx from "clsx";

import './ImgCarousel.css';

import LoadingSVG from "../LoadingSVG/LoadingSVG";
import CarouselArrowLeft from "../../Icons/CarouselArrowLeft";
import CarouselArrowRight from "../../Icons/CarouselArrowRight";
import AspectRatioTwoToneIcon from '@mui/icons-material/AspectRatioTwoTone';
import CloseIcon from "@mui/icons-material/Close";

const areEqual = (prevProps, nextProps) => {
    // Perform a deep comparison between the old and new "images" prop
    return JSON.stringify(prevProps.images) === JSON.stringify(nextProps.images);
}

// function to return the null image
// if there is no null img passed we should use a hardcoded 404 img
// const returnNullImg = nullImage => {
//     if (nullImage && nullImage.image) {
//         return {
//             src: nullImage.image,
//             ...nullImage,
//             success: true
//         }
//     } else {
//         return {
//             src: 'https://project-mackerel-img-dump.s3.eu-west-2.amazonaws.com/carousel/404.webp',
//             alt: 'No Image',
//             success: true
//         }
//     }
// }

// given an array of urls, returns an image carousel
// it waits for all images to load before displaying the carousel
// two arrows on either side of the carousel allow the user to scroll through the images
// expand is a function that can be passed in to open the image in a modal
const ImgCarousel = memo(props => {
    const {
        fullscreen,
        images,
        // nullImage,
        expand,
        nullHex,
        handleClose,
        resetKey
    } = props;

    const [loadedImages, setLoadedImages] = useState(fullscreen ? fullscreen.images : []);
    const [loadingOver, setLoadingOver] = useState(false);
    const [position, setPosition] = useState(fullscreen ? fullscreen.position : 0);
    const [loadingError, setLoadingError] = useState(null);

    const positionRef = useRef(fullscreen ? fullscreen.position : 0);

    // function to handle next and previous position changes
    const handlePositionChange = (e, direction, currPosition) => {
        if (loadingError || (!loadedImages.length && !fullscreen) || (!loadingOver && !fullscreen)) return;
        let newPos = currPosition;

        if (direction === 'next') {
            if (currPosition === loadedImages.length - 1) {
                newPos = 0;
            } else {
                newPos = currPosition + 1;
            }
        } else {
            if (currPosition === 0) {
                newPos = loadedImages.length - 1;
            } else {
                newPos = currPosition - 1;
            }
        }

        positionRef.current = newPos;
        setPosition(newPos);
    }

    useEffect(() => {
        if (fullscreen) return;
        positionRef.current = 0;
        setPosition(0);
        setLoadingError(null);
        setLoadingOver(false);

        Promise.all(images.map(img => {
            return new Promise((resolve, reject) => {
                const image = new Image();
                image.src = img.image;
                image.alt = img.caption;
                image.onload = () => resolve({ ...img, src: img.image, success: true });
                image.onerror = () => resolve({ ...img, src: img.image, success: false });
            });
        }))
            .then(result => {
                // if none of the images load then set 404 img
                if (result.filter(img => img.success).length === 0) {
                    setLoadingError(true);
                    setLoadedImages([404])
                } else {
                    setLoadedImages(result.filter(img => img.success))
                }
                setLoadingOver(true);
            }).catch(err => {
            setLoadingError(true);
            setLoadedImages([404])
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [images]);

    // if fullscreen add event listener to escape for close
    useEffect(() => {
        if (fullscreen) {
            const handleKeyDown = e => {
                if (e.key === 'Escape') {
                    handleClose();
                }

                // event listeners for arrow keys, left right, prev next
                if (e.key === 'ArrowLeft') {
                    handlePositionChange(e, 'prev', positionRef.current);
                } else if (e.key === 'ArrowRight') {
                    handlePositionChange(e, 'next', positionRef.current);
                }
            };

            document.addEventListener('keydown', handleKeyDown);

            return () => document.removeEventListener('keydown', handleKeyDown);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fullscreen]);

    const carousel =  (
        <div
            key={resetKey + nullHex}
            className={clsx('ImgCarousel', !fullscreen && loadedImages.length && !loadingError ? 'ImgCarouselInteractive' : null)}
            onClick={e => {
                e.stopPropagation()
                if (fullscreen || !loadedImages.length || loadingError) return;

                // if not fullscreen we want to open the image in a modal
                expand({
                    fullscreen: {
                        position: positionRef.current,
                        images: loadedImages
                    }
                });
            }}
        >
            {fullscreen &&
                <div className="ImgCarouselTop">
                    <p className="ImgCarouselImgCaption Reg12">{loadedImages[positionRef.current].caption}</p>

                    <div
                        onClick={!!handleClose ? () => handleClose() : null}
                    >
                        <CloseIcon
                            className="ImgCarouselCloseIcon"
                        />
                    </div>
                </div>
            }

            {!fullscreen && !loadingOver && !loadingError &&
                <div className="ImgCarouselLoading">
                    <LoadingSVG size={40} />
                </div>
            }

            {(loadedImages.length > 1 && loadedImages[0] !== 404) &&
                <div className="ImgCarouselArrowsWrap">
                    <div
                        className="ImgCarouselArrowWrap"
                        // handle previous click, if position is 0, loop back to end
                        onClick={e => {
                            e.stopPropagation();
                            handlePositionChange(e, 'prev', positionRef.current)
                        }}
                    >
                        <CarouselArrowLeft
                            className="ImgCarouselArrow"
                        />
                    </div>

                    {/* carousel dots, one for each image, onClick change to selected */}
                    <div className="ImgCarouselDots">
                        {loadedImages.map((img, index) =>
                            <div
                                key={index + '_carousel_dot'}
                                className={clsx('ImgCarouselDot', { 'ImgCarouselDotSelected': positionRef.current === index })}
                                onClick={e => {
                                    e.stopPropagation();
                                    if (position === index) return;
                                    positionRef.current = index;
                                    setPosition(index);
                                }}
                            ></div>
                        )}
                    </div>

                    <div
                        className="ImgCarouselArrowWrap"
                        // handle next click, if position is at the end, loop back to 0
                        onClick={e => {
                            e.stopPropagation();
                            handlePositionChange(e, 'next', positionRef.current)
                        }}
                    >
                        <CarouselArrowRight
                            className="ImgCarouselArrow"
                        />
                    </div>
                </div>
            }

            {/* display the loaded image at the position value */}
            {(loadedImages.length > 0 && loadedImages[position]) &&
                <React.Fragment>
                    {loadedImages[0] === 404 &&
                        <div
                            className="ImgCarousel404"
                            style={{backgroundColor: nullHex ? nullHex : '#f0f0f0'}}
                        >
                            <p className="Bold12">Example Images Unavailable</p>
                        </div>
                    }

                    {loadedImages[0] !== 404 &&
                        <img
                            className="ImgCarouselImg"
                            src={loadedImages[position].src}
                            alt={loadedImages[position].caption}
                        />
                    }

                    {!fullscreen &&
                        <p className="ImgCarouselImgCaption Reg12">{loadedImages[position].caption}</p>
                    }

                    {(!!expand && loadedImages[0] !== 404) &&
                        <AspectRatioTwoToneIcon
                            onClick={() => expand({
                                fullscreen: {
                                    position: position,
                                    images: loadedImages
                                }
                            })}
                            className="ImgCarouselExpandIcon"
                        />
                    }
                </React.Fragment>
            }
        </div>
    );

    return fullscreen ? (
        <div
            className="ImgCarouselFullscreen FullHeightWidth"
            onClick={!!handleClose ? () => handleClose() : null}
        >
            {carousel}
        </div>
    ) : carousel;
}, (prevProps, nextProps) => {
    // re-render if the images prop changes or nullHex

   if (JSON.stringify(prevProps.images) !== JSON.stringify(nextProps.images)) {
       return false;
   } else if (prevProps.nullHex !== nextProps.nullHex) {
       return false;
   } else if (prevProps.resetKey !== nextProps.resetKey) {
       return false;
   } else {
       return true;
   }
});

export default ImgCarousel;