import React, {
    useState,
    useEffect
} from 'react';

import {useLocation} from "react-router-dom";
import {callApiAndReturnCombinedResultsData, handleError} from "../../functions/utils";

import './Login.css';
import Authenticator from "../Authenticator/Authenticator";
import LoadingSVG from "../Misc/LoadingSVG/LoadingSVG";

export default function Login(props) {
    const {
        handleLoading,
        loggedIn,
        handleNewSnackbar,
        windowBreakpoint
    } = props;

    const location = useLocation();

    const [loading, setLoading] = useState(false);
    const [industryOptions, setIndustryOptions] = useState(null);
    const [currencyOptions, setCurrencyOptions] = useState(null);

    const init = () => {
        callApiAndReturnCombinedResultsData([
            {method: 'get', path: '/fetch-config/general-options'}
        ]).then(result => {
            setIndustryOptions(result.industry_options);
            setCurrencyOptions(result.currency_options);
        }).catch(error => {
            handleError('37657ZTX', error);
        });
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <div
            id="Login"
            className={(windowBreakpoint?.w > 480 || isNaN(windowBreakpoint?.w)) ? 'FullHeightWidth' : ''}
        >
            <div id="LoginContentWrap">
                <div id="LoginContent">
                    {loading &&
                        <div id="LoginLoading">
                            <LoadingSVG size={50} />
                        </div>
                    }

                    <Authenticator
                        titleToUse={'Login to your account'}
                        childMode={'login'}
                        childModeSetLoading={setLoading}
                        noGuest={true}
                        handleNewSnackbar={handleNewSnackbar}
                        currencyOptions={currencyOptions}
                        industryOptions={industryOptions}
                        loggedIn={loggedIn}
                        location={location}
                    />
                </div>
            </div>
        </div>
    );
}