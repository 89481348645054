import React from 'react';
import clsx from 'clsx';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import "./QuantityInput.css";
import { Tooltip } from '@mui/material';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function QuantityInput(props) {

    const {
        label,
        refToUse,
        disabled,
        handleOnChange,
        handleOnBlur,
        handleOnInput,
        locked,
        addDisabled,
        quoteLocked,
        partWarning,
        handleAddOnClick,
        subtractDisabled,
        handleSubtractOnClick,
        bulkPrices,
        handleBulkPricesClick,
        rootStyle,
        mainStyle,
        partPriceLoading,
        flexed
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (

        <Tooltip
            arrow
            title={
                partWarning && !quoteLocked ? ''
                : locked ? 'Your quote is locked.'
                : ''
            }
        >
            <div
                className={clsx('QuantityInput', flexed ? 'QuantityInputFlexed' : null)}
                style={rootStyle ? rootStyle : null}
            >
                    <React.Fragment>
                        {label && <p className="Bold12">{label}</p>}

                        <div
                            style={mainStyle ? mainStyle : null}
                            className="Quantity"
                        >
                            <input
                                ref={refToUse}
                                type="tel"
                                disabled={disabled}
                                onChange={handleOnChange}
                                onBlur={handleOnBlur}
                                onInput={handleOnInput}
                                className={clsx('Bold12', locked ? 'QuantityInputLocked' : null)}
                            ></input>

                            <div className="QuantityArrowsWrap">
                                <div
                                    className={clsx(addDisabled ? 'QuantityButtonDisabled' : null)}
                                    onClick={handleAddOnClick}
                                >
                                    <KeyboardArrowUpIcon className="QuantityArrow" />
                                </div>

                                <div
                                    className={clsx(subtractDisabled ? 'QuantityButtonDisabled' : null)}
                                    onClick={handleSubtractOnClick}
                                >
                                    <KeyboardArrowDownIcon className="QuantityArrow" />
                                </div>
                            </div>
                        </div>

                        {(bulkPrices && !flexed) && <p className={clsx('Bold12', 'BulkPrices', partPriceLoading || partWarning ? 'QuantityBulkButtonDisabled' : null)} onClick={partPriceLoading ? null : handleBulkPricesClick}>Show Bulk Prices</p>}
                    </React.Fragment>
            </div>
        </Tooltip>

    );
};