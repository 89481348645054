import ErrorBoundary from '../Misc/ErrorBoundary/ErrorBoundary';
import UserInfoModal from '../Misc/UserInfoModal/UserInfoModal';
import PartViewer from '../Misc/PartViewer/PartViewer';
import UploadDropZone from '../Misc/UploadDropZone/UploadDropZone';
import ApplyOptionsToAllModal from '../Misc/ApplyOptionsToAllModal/ApplyOptionsToAllModal';
import WantToRegisterModal from '../Misc/WantToRegisterModal/WantToRegisterModal';
import DeleteUserModal from '../Misc/DeleteUserModal/DeleteUserModal';
import DismissibleNote from '../Misc/DismissibleNote/DismissibleNote';
import BulkPricesModal from '../Misc/BulkPricesModal/BulkPricesModal';
import NicknameModal from '../Misc/NicknameModal/NicknameModal';
import RequestQuote from '../Misc/RequestQuote/RequestQuote';
import UnderReview from '../Misc/UnderReview/UnderReview';
import ImgCarousel from "../Misc/ImgCarousel/ImgCarousel";

export default function ModalHandler(props) {
    const {
        active,
        deactivate,
        loading,
        handleLoading,
        windowBreakpoint,
        handleNewSnackbar,
        loggedIn,
        isAdmin
    } = props;

    if (!active) return null;

    const {
        which,
        data,
        tempData
    } = active;

    // if a handle close callback has been passed into modal handler then pass it into deactivate
    // also pass the temp data values and temp data clear value
    const handleClose = argz => {
        const {
            tempData,
            clearTempData
        } = argz ? argz : {};

        deactivate(
            !!data?.handleCloseCallback && typeof data.handleCloseCallback === 'function' ? data.handleCloseCallback : false,
            which,
            tempData || null,
            clearTempData || null
        );
    };

    if (which === 'user_info_modal') {
        return (
            <ErrorBoundary
                componentWrapped="UserInfoModal @ Upload"
            >
                <UserInfoModal
                    loggedIn={loggedIn}
                    isAdmin={isAdmin}
                    handleNewSnackbar={handleNewSnackbar}
                    handleClose={handleClose}
                    {...data}
                />
            </ErrorBoundary>
        );
    } else if (which === 'upload_drop_zone') {
        return (
            <ErrorBoundary
                componentWrapped="UploadDropZone @ Quote"
            >
                <UploadDropZone
                    loading={loading}
                    handleLoading={handleLoading}
                    windowBreakpoint={windowBreakpoint}
                    {...data}
                />
            </ErrorBoundary>
        );
    } else if (which === 'part_viewer') {
        return (
            <PartViewer
                handleClose={handleClose}
                loading={loading}
                handleLoading={handleLoading}
                windowBreakpoint={windowBreakpoint}
                partData={data}
            />
        )
    } else if (which === 'apply_options_to_all') {
        return (
            <ApplyOptionsToAllModal
                handleClose={handleClose}
                windowBreakpoint={windowBreakpoint}
                {...data}
            />
        );
    } else if (which === 'bulk_prices_modal') {
        return (
            <BulkPricesModal
                handleClose={handleClose}
                {...data}
            />
        );
    } else if (which === 'want_to_register') {
        return (
            <WantToRegisterModal
                handleClose={handleClose}
                handleNewSnackbar={handleNewSnackbar}
                loggedIn={loggedIn}
                {...data}
            />
        );
    } else if (which === 'delete_user') {
        return (
            <DeleteUserModal
                handleClose={handleClose}
                {...data}
            />
        );
    } else if (which === 'dismissible_note') {
        return (
            <DismissibleNote
                handleClose={handleClose}
                handleLoading={handleLoading}
                handleNewSnackbar={handleNewSnackbar}
                {...data}
            />
        );
    } else if (which === 'nickname_modal') {
        return (
            <NicknameModal
                handleClose={handleClose}
                {...data}
            />
        );
    } else if (which === 'request_for_quote') {
        return (
            <RequestQuote
                handleClose={handleClose}
                handleNewSnackbar={handleNewSnackbar}
                windowBreakpoint={windowBreakpoint}
                tempData={tempData}
                {...data}
            />
        );
    } else if (which === 'under_review') {
        return (
            <UnderReview
                handleNewSnackbar={handleNewSnackbar}
                {...data}
            />
        );
    } else if (which === 'img_carousel') {
        return (
            <ImgCarousel
                handleClose={handleClose}
                {...data}
            />
        );
    } else {
        return null;
    }
};