import React, {
    useState,
    useEffect,
    useRef
} from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    useLocation,
    useHistory
} from 'react-router-dom';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import Header from '../Header/Header';
import Main from '../Main/Main';
import Footer from '../Footer/Footer';
import ThemeBar from './ThemeBar';
import UserSnackBar from '../UserSnackBar/UserSnackBar';
import EventHub from '../EventHub/EventHub';
import Alien from '../Alien/Alien';
import LoadingSVG from '../Misc/LoadingSVG/LoadingSVG';
import ModalHandler from './ModalHandler';
import ConsentForm from './ConsentForm';

import General from "../../context/general";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import CloseIcon from '@mui/icons-material/Close';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    callApiAndReturnCombinedResultsData,
    handleError
} from '../../functions/utils';
import {
    attemptUpdateUserAttributes,
    handleInitNewUser,
    handleInitExistingUser
} from '../../functions/user_functions';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./App.css";
import "./Med1200.css";
import "./Small1024.css";
import "./Tab768.css";
import "./Mob480.css";

const BREAKPOINTS = [
    {bp: 'mob', w: 480},
    {bp: 'tab', w: 768},
    {bp: 'small', w: 1024},
    {bp: 'med', w: 1200},
    {bp: 'large', w: 'infinity'}
];

// regexp to parse the id param from location.pathname
const URL_ID_PARAM_REGEXP = /(^\/quote\/|^\/checkout\/|^\/confirmation\/)(\w+)$/;

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function App(props) {
    const {
        build_env
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const location = useLocation();
    const history = useHistory();

    const announcementRef = useRef();
    const themeBarRef = useRef();

    const [generalContext, setGeneralContext] = useState({});

    // announcement banner show / hide
    const [announcementShowing, setAnnouncementShowing] = useState(false);
    // array for user notifications
    const [snackPack, setSnackPack] = useState([]);
    // handle data of logged in user, if false then they are logged out
    const [loggedIn, setLoggedIn] = useState(null);
    // set value for banner id, can be set on user to prevent the banner from appearing until updated
    const [bannerId, setBannerId] = useState(null);
    // value to show admin active
    const [isAdmin, setIsAdmin] = useState(null);
    const [alienData, setAlienData] = useState(null);
    const [alienActionsVisible, setAlienActionsVisible] = useState(false);
    const [componentKeys, setComponentKeys] = useState({});

    // value for admin message
    const [alienInfo, setAlienMessage] = useState(null);
    // handle the timeout for admin message
    const [alienMessageTimeout, setAlienMessageTimeout] = useState(null);
    // last saved persist message
    const [lastSavedAdminMessage, setLastSavedAdminMessage] = useState(null);
    // boolean for hiding the overflow of the banner
    const [bannerHideOverflow, setBannerHideOverflow] = useState(false);
    // boolean to indicate if user sub was updated on quote created prior to logging in
    const [checkUpdateUserSubResolved, setCheckUpdateUserSubResolved] = useState(false);
    // loading overlay show / hide
    const [loading, setLoading] = useState(false);
    // state for the current breakpoint to use
    const [windowBreakpoint, setWindowBreakpoint] = useState(null);
    // data for the active modal
    const [activeModal, setActiveModal] = useState(null);
    // active quote id
    const [activeId, setActiveId] = useState(null);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const handleWindowResize = () => {
        let breakpointToSet = BREAKPOINTS.filter(x => !isNaN(x.w) && window.innerWidth <= x.w).sort((a, b) => a.w - b.w)[0];
        if (!breakpointToSet) breakpointToSet = BREAKPOINTS.filter(x => isNaN(x.w))[0];
        setWindowBreakpoint(breakpointToSet);
        setVhVal();
    };

    // function to set css var for viewer height (stops annoying phone issue)
    const setVhVal = () => document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

    const handleAlienData = obj => {
        if (!isAdmin) return;

        let dataToSet = {
            ...obj,
            liu: loggedIn
        };

        setAlienData(dataToSet);
    };

    // fetch announcement banner data
    const fetchBannerInfo = async () => {
        callApiAndReturnCombinedResultsData([
            {method: 'get', path: '/fetch-config/general-options'}
        ]).then(result => {
            // set the general context values top be used throughout the app
            setGeneralContext({
                termsAndConditions: result.terms_and_conditions,
                privacyPolicy: result.privacy_policy,
                cookiesPolicy: result.cookies_policy,
                footerImgLinks: result.footer_img_links
            });

            // if the announcement banner is set to show, split up the string per new line
            if (result.announcement_banner_options && result.announcement_banner_options.active) {
                // if the user is logged in and they have already closed the banner, don't show it again
                if (loggedIn && loggedIn['custom:last_banner_id'] === result.announcement_banner_options.id) return;
                // if on the challenge or login page then dont show the announcement banner
                if (/^\/login|challenge\b(?=\/)/i.test(location.pathname)) return;
                let splits = result.announcement_banner_options.label.split('\n');

                // show announcement banner
                setAnnouncementShowing(splits);
                // set banner id
                setBannerId(result.announcement_banner_options.id);
            }
        }).catch(error => {
            handleError('51078tgM', error);
        });
    };

    // handle the banner close event, if logged in update user
    const handleBannerClose = async () => {
        setAnnouncementShowing(false);

        if (loggedIn && bannerId) {
            try {
                await attemptUpdateUserAttributes({ 'custom:last_banner_id': bannerId });
            } catch (error) {
                handleNewSnackbar('something went wrong', 'error');
            }
        }
    };

    // handle new user notification
    const handleNewSnackbar = (message, type) => {
        setSnackPack(prev => [
            ...prev,
            {
                message,
                type,
                key: new Date().getTime()
            }
        ]);
    };

    const handleNewAlienMessage = (message, persist) => {
        if (alienMessageTimeout) clearTimeout(alienMessageTimeout);

        let messageToSet = Array.isArray(message) ? message : [message];

         // are we assuming an identity
         let assumeMessage = null;
         const assumedIdentity = JSON.parse(localStorage.getItem('_alien_assumed_identity'));
         if (!!assumedIdentity && assumedIdentity.length) {
            assumeMessage = ['You are assuming the identity of...', assumedIdentity[0], assumedIdentity[1]];
         }

        setAlienMessage(({
            visible: true,
            message: assumeMessage ? [...messageToSet, ...assumeMessage] : messageToSet
        }));

        if (!persist) {
            let timeout = setTimeout(handleClearAlienMessage, 10000);
            setAlienMessageTimeout(timeout);
            setLastSavedAdminMessage(null);
        } else {
            setLastSavedAdminMessage(messageToSet);
        }
    };

    const handleClearAlienMessage = () => {
        return new Promise((resolve, reject) => {
            try {
                if (alienMessageTimeout) clearTimeout(alienMessageTimeout);

                let alienMessageClone = {...alienInfo}

                setAlienMessage({
                    visible: false,
                    message: alienMessageClone.message
                });

                let timeout = setTimeout(() => {
                    setAlienMessage({ visible: false, message: null });
                    resolve();
                }, 500);

                setAlienMessageTimeout(timeout);
            } catch (error) {
                setAlienMessage({ visible: false, message: null });
                setAlienMessageTimeout(null);
                resolve();
            }
        });
    };

    const handleAlienClick = () => {
        if (!alienActionsVisible) setAlienActionsVisible(true);
        if (alienInfo && alienInfo.visible) return;

        let messageToDisplay = null;
        let persistMessage = false;

        if (lastSavedAdminMessage) {
            messageToDisplay = lastSavedAdminMessage;
            persistMessage = true;
        } else {
            let name = loggedIn && loggedIn['custom:full_name'] ? loggedIn['custom:full_name'] : null;
            messageToDisplay = [name ? `${name}, you're the b3st!` : 'you broke it!'];
            persistMessage = false;
        }

        handleNewAlienMessage(messageToDisplay, persistMessage);
    };

    const initNewUser = async () => {
        let result = await handleInitNewUser();
        if (result.success) {
            await attemptUpdateUserAttributes({
                'custom:account_number': result.data.account_number
            });
        }
    };

    const initExistingUser = async () => {
        let result = await handleInitExistingUser();
        if (result.success) {
            await attemptUpdateUserAttributes({
                'custom:account_number': result.data.account_number
            });
        }
    };

    const handleSetLoading = (bool, ignoreLock) => {
        setLoading(bool);
    };

    const toggleActiveModal = (which, data) => {
        if (which) {
            let tempData;
            try {
                tempData = sessionStorage.getItem(`mam_${activeId ? `${activeId}_` : ''}${which}`);

                if (typeof tempData === 'string') {
                    tempData = JSON.parse(tempData);
                }
            } catch (error) {
                console.log('session storage error <', error);
            }

            setActiveModal({
                which,
                data,
                tempData,
                scrollPos: window.scrollY
            });
        } else {
            setActiveModal(null);
        }
    };

    const handleModalTempStorage = (which, data, clear) => {
        try {
            let keyToUse = `mam_${activeId ? `${activeId}_` : ''}${which}`;

            if (clear) {
                if (sessionStorage.getItem(keyToUse)) {
                    sessionStorage.removeItem(keyToUse);
                }
            } else {
                sessionStorage.setItem(keyToUse, JSON.stringify(data));
            }
        } catch (error) {
            console.log('session storage error >', error);
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // on unmount clear admin message timeout
    useEffect(() => {
        handleWindowResize();

        // checkCookieEnabled();

        // add resizeEventListenerHere
        window.addEventListener('resize', handleWindowResize);

        return () => {
            if (alienMessageTimeout) clearTimeout(alienMessageTimeout);
            window.removeEventListener('resize', handleWindowResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isAdmin) {
            if (alienInfo && alienInfo.visible) handleClearAlienMessage();
            setLastSavedAdminMessage(null);
            setAlienActionsVisible(false);
        }

        if (URL_ID_PARAM_REGEXP.test(location.pathname)) {
            let valToSet = location.pathname.match(URL_ID_PARAM_REGEXP)[2];
            if (valToSet && valToSet !== activeId) setActiveId(valToSet);
        }

        if (/^\/quote\b|checkout\b(?=\/)/.test(location.pathname) && !bannerHideOverflow) {
            setBannerHideOverflow(true);
        } else if (!/^\/quote\b|checkout\b(?=\/)/.test(location.pathname) && bannerHideOverflow) {
            setBannerHideOverflow(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // when logged in has been determined
    useEffect(() => {
        if (loggedIn !== null) {
            fetchBannerInfo();

            if (loggedIn) {
                // check to see if user has a row in user db
                if (!loggedIn['custom:account_number']) {
                    initExistingUser();
                } else if (loggedIn['custom:account_number'] === 'init') {
                    initNewUser();
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loggedIn]);

    // effect to handle styling of the themeBar depending upon if the announcement is shown or hidden
    useEffect(() => {
        if (!!announcementShowing) {
            if (announcementRef && announcementRef.current && themeBarRef && themeBarRef.current) {
                if (themeBarRef.current.style.top === '0px') {
                    let rect = announcementRef.current.getBoundingClientRect();

                    // change the top position of the themeBar to be the same as the height of the announcement banner
                    themeBarRef.current.style.top = `${rect.height}px`;
                }
            }
        } else {
            if (themeBarRef && themeBarRef.current) {
                if (themeBarRef.current.style.top !== '0px') {
                    themeBarRef.current.style.top = '0px';
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [announcementShowing]);

    useEffect(() => {
        if (isAdmin) {
            handleAlienData();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAdmin]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <General.Provider value={{...generalContext}}>
            <div id="App">
                {loading !== false &&
                    <div
                        id="LoadingWrap"
                        className="FullHeightWidth"
                    >
                        <LoadingSVG
                            size={windowBreakpoint?.w <= 768 ? 55 : 70}
                        />
                    </div>
                }

                {/* if in development mode display a modal indicating which environment it is */}
                {build_env !== 'prod' && <div id="DevBuildNote"><p>{build_env ? build_env.toUpperCase() : ':P'}</p></div>}
                {/* if in development mode display the active window breakpoint */}
                {!!(build_env !== 'prod' && windowBreakpoint) && <div id="DevBreakPointNote"><p>{JSON.stringify(windowBreakpoint, null, 4)}</p></div>}

                <ConsentForm />

                <Alien
                    isAdmin={isAdmin}
                    alienData={alienData}
                    handleNewAlienMessage={handleNewAlienMessage}
                    handleClearAlienMessage={handleClearAlienMessage}
                    handleAlienClick={handleAlienClick}
                    alienInfo={alienInfo}
                    handleAlienData={handleAlienData}
                    location={location}
                    history={history}
                    setAlienActionsVisible={setAlienActionsVisible}
                    alienActionsVisible={alienActionsVisible}
                    componentKeys={componentKeys}
                    setComponentKeys={setComponentKeys}
                    windowBreakpoint={windowBreakpoint}
                />

                <ModalHandler
                    active={activeModal}
                    deactivate={(callback, which, tempStorage, clearTempStorage) => {
                        toggleActiveModal(null)
                        if (!!callback && typeof callback === 'function') {
                            callback();
                        }
                        if (tempStorage || clearTempStorage) {
                            handleModalTempStorage(which, tempStorage, clearTempStorage);
                        }
                    }}
                    loading={loading === false}
                    handleLoading={bool => handleSetLoading(bool)}
                    windowBreakpoint={windowBreakpoint}
                    handleNewSnackbar={handleNewSnackbar}
                    loggedIn={loggedIn}
                    isAdmin={isAdmin}
                />

                {!!announcementShowing && announcementShowing.length &&
                    <div id="Announcement" ref={announcementRef}>
                        <div>
                            {/* map over each line in the announcement */}
                            {announcementShowing.map((line, index) => <p key={'announcement_line_' + index}>{line}</p>)}
                        </div>

                        <CloseIcon
                            id="CloseAnnouncement"
                            onClick={() => handleBannerClose()}
                        />
                    </div>
                }

                <div id="TopThemeBar" className={bannerHideOverflow ? 'HideBannerOverflow' : null} ref={themeBarRef}>
                    <ThemeBar
                        overflow={bannerHideOverflow || (windowBreakpoint?.w <= 768)}
                    />

                    {bannerHideOverflow &&
                        <Header
                            handleNewSnackbar={handleNewSnackbar}
                            windowBreakpoint={windowBreakpoint}
                            loggedIn={loggedIn}
                            setLoading={setLoading}
                            sticky={true}
                            setComponentKeys={setComponentKeys}
                            componentKeys={componentKeys}
                        />
                    }
                </div>

                {!bannerHideOverflow &&
                    <Header
                        handleNewSnackbar={handleNewSnackbar}
                        windowBreakpoint={windowBreakpoint}
                        loggedIn={loggedIn}
                        setLoading={setLoading}
                    />
                }

                <UserSnackBar
                    setSnackPack={setSnackPack}
                    snackPack={snackPack}
                    windowBreakpoint={windowBreakpoint}
                />

                <div id="MainWrap">
                    <React.Fragment>
                        <Main
                            build_env={build_env}
                            loggedIn={loggedIn}
                            handleAlienData={handleAlienData}
                            checkUpdateUserSubResolved={checkUpdateUserSubResolved}
                            handleNewSnackbar={handleNewSnackbar}
                            isAdmin={isAdmin}
                            loading={loading === false}
                            handleNewAlienMessage={handleNewAlienMessage}
                            setLoading={handleSetLoading}
                            componentKeys={componentKeys}
                            windowBreakpoint={windowBreakpoint}
                            toggleActiveModal={toggleActiveModal}
                            setActiveId={setActiveId}
                        />

                        <Footer
                            windowBreakpoint={windowBreakpoint}
                        />
                    </React.Fragment>
                </div>

                {/* event hub catches amplify events / changes */}
                <EventHub
                    loggedIn={loggedIn}
                    setLoggedIn={setLoggedIn}
                    setIsAdmin={setIsAdmin}
                    handleNewSnackbar={handleNewSnackbar}
                    handleNewAlienMessage={handleNewAlienMessage}
                    setCheckUpdateUserSubResolved={setCheckUpdateUserSubResolved}
                    handleLoading={handleSetLoading}
                />
            </div>
        </General.Provider>
    );
};