const STYLE_ROOT = document.documentElement;

// colour values
const THEME_COLOURS = {
    success: {
        main: '#85C92B',
        light: '#FAFFF9'
    },
    blues: {
        main: '#005FFE',
        dark: '#004CCB',
        mid: '#E8F1FF',
        light: '#F3F8FF',
        trans: 'rgba(0, 37, 100, 0.3)',
        'light-trans': 'rgba(0, 37, 100, 0.1)'
    },
    warning: {
        main: '#CC0018',
        light: '#FFCBCB',
        trans: 'rgba(222, 0, 0, 0.08)',
        yellow: '#E5BA2F',
        'light-converting': '#FFFEED',
        converting: '#F5F25F',
        'sick-yellow': '#E8FC87',
        'light-sick-yellow': 'rgba(245, 255, 197, 1)'
    },
    tones: {
        black: '#000000',
        'faux-black': '#19324C',
        white: '#FFFFFF',
        'off-white': '#F8F9FC',
        'dark-grey': '#666666',
        grey: '#D8D8D8',
        'light-grey': '#EFEFEF',
        'black-trans': 'rgba(0, 0, 0, 0.5)'
    }
};

// different values
const THEME_ASPECTS = {
    border: {
        main: `1px solid ${THEME_COLOURS.tones.grey}`,
        light: `1px solid ${THEME_COLOURS.tones['light-grey']}`,
        blue: `1px solid ${THEME_COLOURS.blues.main}`,
        warning: `1px solid ${THEME_COLOURS.warning.main}`,
        white: `1px solid ${THEME_COLOURS.tones.white}`,
        dotted: `1px dotted ${THEME_COLOURS.tones.grey}`,
        'dotted-blue': `1px dotted ${THEME_COLOURS.blues.main}`,
        thick: `2px solid ${THEME_COLOURS.tones.grey}`,
        'thick-blue': `2px solid ${THEME_COLOURS.blues.main}`,
        invisible: '1px solid rgba(0, 0, 0, 0)',
    },
    'box-shadow': {
        main: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        header: '0px 4px 4px rgba(0, 0, 0, 0.15)',
        bottom: '0px 20px 25px -15px #4D4D4D',
        light: '0px 8px 30px 0px rgba(0, 0, 0, 0.102)'
    },
    filter: {
        brightness: 'brightness(105%)'
    }
};

// function to set css vars
export function initTheme() {
    let theme =  { ...THEME_COLOURS, ...THEME_ASPECTS };

    // set colours
    for (const category in theme) {
        // loop over each category
        if (theme[category]) {
            // loop over each value
            for (const value in theme[category]) {
                if (theme[category][value]) {
                    // set the css var
                    STYLE_ROOT.style.setProperty(`--${category}-${value}`, theme[category][value]);
                }
            }
        }
    }
}