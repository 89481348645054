import React, {
    useState,
    useEffect
} from "react";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    Snackbar,
    IconButton,
    SnackbarContent
} from "@mui/material";

import './UserSnackBar.css';

import CloseIcon from "@mui/icons-material/Close";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function UserSnackBar(props) {
    const {
        snackPack,
        setSnackPack,
        windowBreakpoint
    } = props;

    // hide / show the snackbar
    const [open, setOpen] = useState(false);
    // set the content of the snackbar
    const [messageInfo, setMessageInfo] = useState(null);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // handle the close event for snackbar
    const handleClose = (e, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setOpen(false);
    };

    // once the snackbar has transitioned out, clear the content value
    const handleExited = () => {
        setMessageInfo(null);
    };

    // return snackbar styling based on what type it's meant to be
    const returnStyle = type => {
        let style = {
            backgroundColor: '#4CAF50'
        };

        if (type === 'error') style.backgroundColor = '#F44336';
        if (type === 'warning') style.backgroundColor = '#FF9800';
        if (type === 'info') style.backgroundColor = '#2196F3';

        return style;
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // handle incoming snackbars
    useEffect(() => {
        if (snackPack.length && !messageInfo) {
            setMessageInfo({ ...snackPack[0] });
            setSnackPack(prev => prev.slice(1));
            setOpen(true);
        } else if (snackPack.length && messageInfo && open) {
            setOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [snackPack, messageInfo, open]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <Snackbar
            key={messageInfo ? messageInfo.key : undefined}
            open={open}
            autoHideDuration={6000}
            onClose={handleClose}
            TransitionProps={{ onExited: handleExited }}
            classes={{
                root: windowBreakpoint?.w <= 480 ? 'SnackBarRoot' : null
            }}
        >
            <SnackbarContent
                style={messageInfo ? returnStyle(messageInfo.type) : {}}
                message={messageInfo ? messageInfo.message : undefined}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        sx={{ p: 0.5 }}
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                }
            />
        </Snackbar>
    );
}
