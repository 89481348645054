import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom';

import { Amplify } from 'aws-amplify';
import amplifyconfig from './amplifyconfiguration.json';

import {
    createTheme,
    ThemeProvider
} from '@mui/material/styles';

import {
    initTheme
} from './theme';

import './index.css';

import App from './App/components/App/App';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// mui theme that will wrap the application
// changing the default style of tooltips
const theme = createTheme({
    components: {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 12,
                    fontFamily: "'Montserrat', sans-serif",
                    padding: 20,
                    color: "#666666",
                    borderRadius: 3,
                    backgroundColor: "#EEF0F4",
                    boxShadow: `0px 0px 4px 0px #999999`,
                    maxWidth: 350,
                    fontWeight: 600
                },
                arrow: {
                    color: '#D8D8D8'
                }
            }
        }
    }
});

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

// init the mackie theme
initTheme();

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

Amplify.configure({
	...amplifyconfig
});

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
                <Switch>
                    <Route path='/' render={props =>
                        <App {...props} build_env={process.env.REACT_APP_BUILD_ENV || 'ヽ(ˇヘˇ)ノ'} />
                    }/>
                </Switch>
        </ThemeProvider>
    </BrowserRouter>,

    document.getElementById('root')
);
