// component to display information about the part configuration
import React, {
    useState
} from 'react';
import clsx from 'clsx';

import LoadingSVG from "../LoadingSVG/LoadingSVG";
import ImgCarousel from "../ImgCarousel/ImgCarousel";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

import { Tooltip } from "@mui/material";

import './InformationDisplay.css';

const InformationDisplay = React.memo(function InformationDisplay({
    classNames,
    styleOverwrite,
    definitions,
    materialId,
    finishId,
    colourId,
    optionsTree,
    colourVal,
    biggerDefinitions,
    handleOpenInfoDisplayImgCarousel
}) {
    let nullHex = biggerDefinitions.colour_values[colourVal].hex;

    const [activeTab, setActiveTab] = useState(0);
    const [designGuideCollapsed, setDesignGuideCollapsed] = useState({
        recent: null,
        collapsed: []
    });
    const [propertiesCollapsed, setPropertiesCollapsed] = useState([]);

    // function to handle the content of an elem
    // given two arguments, table and content
    // if table is true, return the content as a table
    // if table is false, return the content as a string
    function handleContent(table, content, className) {
        let tableJSX;
        let contentJSX;

        if (table) {
            tableJSX = parseTableToJSX(table);
        }

        if (content) {
            contentJSX = <p className={clsx(className, 'Reg12')}>{convertConfigTextToJSX(content)}</p>
        }

        return (
            <React.Fragment>
                {tableJSX}
                {contentJSX}
            </React.Fragment>
        )
    }

    // refactoring the convertMarkdownAnchorsToJSX function, expanding to accept multiple types of markdown or html tags
    // [Title for link](https://thelink.com) = if a markdown link is found, it will be converted to an anchor tag like before
    // \n = if a <br> tag is found, it will be converted to a <br> tag
    // \n! = if a <br!> tag is found, it will be converted to a <br!> tag
    // &emsp; = if &emsp; is found, it will be converted to a tab

    // another rule if string is found between **string** then it will be converted to a bold tag
    // another rule if string is found between __string__ then it will be converted to an italic tag
    function convertConfigTextToJSX(text) {
        const htmlTagsRegex = /<br>|<br!>|&emsp;/g;
        const markdownLinkRegex = /\[([^[]+)]\(([^)]+)\)/g;
        let elements = [];

        // regexp to match each of the following: <br>, <br!>, &emsp;
        // iterate over each match and replace the match with the corresponding jsx element / fix
        let match1;
        let lastIndex1 = 0;
        while ((match1 = htmlTagsRegex.exec(text)) !== null) {
            // Add the text before
            if (match1.index > lastIndex1) {
                elements.push(text.slice(lastIndex1, match1.index));
            }

            // Add the link
            if (match1[0] === '<br>') {
                elements.push(<br/>);
            } if (match1[0] === '<br!>') {
                elements.push(<br className="InformationDisplayBRMB"/>);
            } else if (match1[0] === '&emsp;') {
                elements.push(<span key={lastIndex1 + '_emsp_span'} className="InformationDisplayEMSP"></span>);
            }

            lastIndex1 = htmlTagsRegex.lastIndex;
        }

        if (lastIndex1 < text.length) {
            elements.push(text.slice(lastIndex1));
        }

        // iterate over each element and if the elem is a string and contains a markdown link, split the element into an array of strings and jsx elements, replacing the link with an anchor tag
        // then spread the array back into the elements array at the correct point
        elements.forEach((elem, index) => {
            if (typeof elem === 'string') {
                let match2;
                let lastIndex2 = 0;
                let newElements = [];

                while ((match2 = markdownLinkRegex.exec(elem)) !== null) {
                    if (match2.index > lastIndex2) {
                        newElements.push(elem.slice(lastIndex2, match2.index));
                    }

                    newElements.push(
                        <a
                            key={index + '_link'}
                            className="Bold12"
                            rel="noreferrer"
                            target="_blank"
                            href={match2[2]}
                            onClick={e => {
                                e.stopPropagation();
                            }}
                        >
                            {match2[1]}
                        </a>
                    );

                    lastIndex2 = markdownLinkRegex.lastIndex;
                }

                if (lastIndex2 < elem.length) {
                    newElements.push(elem.slice(lastIndex2));
                }

                elements[index] = newElements;
            }
        });

        elements = elements.flat();

        // do final bold and italic replacements
        elements.forEach((elem, index) => {
            if (typeof elem === 'string') {
                let boldRegex = /\*\*([^*]+)\*\*/g;
                let lastIndex = 0;
                let newElements = [];

                let match;
                while ((match = boldRegex.exec(elem)) !== null) {
                    if (match.index > lastIndex) {
                        newElements.push(elem.slice(lastIndex, match.index));
                    }

                    newElements.push(
                        <span
                            key={index + '_bold'}
                            className="InformationDisplayBOLD"
                        >{match[1]}</span>
                    );

                    lastIndex = boldRegex.lastIndex;
                }

                if (lastIndex < elem.length) {
                    newElements.push(elem.slice(lastIndex));
                }

                elements[index] = newElements;
            }
        });

        elements = elements.flat();

        // do final italic replacements
        elements.forEach((elem, index) => {
            if (typeof elem === 'string') {
                let italicRegex = /__([^_]+)__/g;
                let lastIndex = 0;
                let newElements = [];

                let match;
                while ((match = italicRegex.exec(elem)) !== null) {
                    if (match.index > lastIndex) {
                        newElements.push(elem.slice(lastIndex, match.index));
                    }

                    newElements.push(
                        <span
                            key={index + '_italic'}
                            className="InformationDisplayITALIC"
                        >{match[1]}</span>
                    );

                    lastIndex = italicRegex.lastIndex;
                }

                if (lastIndex < elem.length) {
                    newElements.push(elem.slice(lastIndex));
                }

                elements[index] = newElements;
            }
        });

        return elements.flat();
    }

    // function to parse table (matrix) from config into a table jsx element
    // the table is a 2d array, the first array is the header, the rest are the rows
    function parseTableToJSX(table, className) {
        return (
            <div className="InformationDisplayTableWrap">
                <table className={clsx('InformationDisplayTable', className)}>
                    <thead>
                        <tr>
                            {table[0].map((cell, index) =>
                                <th key={index + '_table_header'}>{cell}</th>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {table.slice(1).map((row, index) =>
                            <tr key={index + '_table_row'}>
                                {row.map((cell, index) =>
                                    <Tooltip key={index + '_table_cell_tooltip'} title={cell && cell.length > 22 ? cell : ''} arrow>
                                        <td key={index + '_table_cell'}>{cell}</td>
                                    </Tooltip>
                                )}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }

    // function to return the content of the tab
    const returnTabContent = (which, nullHexColour) => {
        try {
            let currentBranch = optionsTree[materialId][finishId][colourId];
            let values = currentBranch.info_panel[which];

            if (values) {
                if (which === 'overview') {
                    return returnOverviewTabContent(values, currentBranch.info_panel.carousel, nullHexColour);
                } else if (which === 'design_guide') {
                    return returnDesignGuideTabContent(values);
                } else if (which === 'properties') {
                    return returnPropertiesTabContent(values);
                } else {
                    return <div className="InformationDisplaySomethingWentWrong Bold12">something went wrong _1</div>
                }
            } else {
                return <div className="InformationDisplaySomethingWentWrong Bold12">something went wrong _2</div>
            }
        } catch (err) {
            console.log('caught an error: ', err);
            return <div className="InformationDisplaySomethingWentWrong Bold12">something went wrong _3</div>
        }
    };


    const returnOverviewTabContent = (values, carouselValues, nullHexColour) => {
        return (
            <React.Fragment>
                {/* image carousel */}
                <div className="OverviewInfoCarouselWrap">
                    <ImgCarousel
                        resetKey={carouselValues.join('') + nullHexColour}
                        images={carouselValues.map(value => definitions.carousel[value])}
                        nullHex={nullHexColour}
                        expand={handleOpenInfoDisplayImgCarousel}
                    />
                </div>

                {/* iterate over overview values */}
                {values.map((value, index) => {
                    const {
                        heading,
                        table,
                        content
                    } = definitions.overview[value];

                    return (
                        <div
                            key={index}
                            className="OverviewInfoValue"
                        >
                            <p className="Bold16">{heading}</p>
                            {handleContent(table, content)}
                        </div>
                    )
                })}
            </React.Fragment>
        )
    }

    const returnDesignGuideTabContent = values => values.map((value, index) => {
        const {
            heading,
            content,
            image,
            table,
            values
        } = definitions.design_guide[value];

        return (
            <div
                key={index + '_design_guide'}
                className={clsx('DesignGuideInfoValue',
                    {
                        'DesignGuideInfoValueCollapsed': designGuideCollapsed.collapsed[index] === true
                    }
                )}
                onClick={() => {
                    let newCollapsed = [...designGuideCollapsed.collapsed].map(x => !!x);
                    newCollapsed[index] = !newCollapsed[index];

                    setDesignGuideCollapsed({
                        recent: index,
                        collapsed: newCollapsed
                    });
                }}
            >
                <div className="DesignGuideInfoValueLeft">
                    <div
                        className="DesignGuideImageWrap"
                        style={
                            image ? {
                                backgroundImage: `url(${image})`
                            } : {}
                        }
                    >
                    </div>
                </div>

                <div className="DesignGuideInfoValueRight">
                    <p className="Bold12">{heading}</p>

                    {/* values wrapper */}
                    <div className="DesignGuideInfoValueValuesWrap">
                        {values.map((value, index) =>
                            <div key={index + '_dg_iv'}>
                                <div></div>
                                <p className="Reg12">{value}</p>
                            </div>
                        )}
                    </div>

                    {designGuideCollapsed.collapsed[index] === true &&
                        handleContent(table, content, 'DesignGuideInfoValueContent')
                    }
                </div>

                <div
                    className="FloatingCollapsableButton"
                >
                    {designGuideCollapsed.collapsed[index] === true ?
                        <CloseRoundedIcon className="FloatingCollapseButtonIcon FloatingCollapseButtonIconClose"/>
                        : <KeyboardArrowDownRoundedIcon className="FloatingCollapseButtonIcon"/>
                    }
                </div>
            </div>
        )
    });

    const returnPropertiesTabContent = values => values.map((value, index) => {
        const {
            heading,
            table,
            content
        } = definitions.properties[value];

        return (
            <div
                key={index + '_properties'}
                className="PropertiesInfoValue"
                onClick={() => {
                    let newCollapsed = [...propertiesCollapsed].map(x => !!x);
                    newCollapsed[index] = !newCollapsed[index];
                    setPropertiesCollapsed(newCollapsed);
                }}
            >
                <p className="Bold12">{heading}</p>

                {/* if index is collapsed */}
                {propertiesCollapsed[index] === true &&
                    handleContent(table, content)
                }

                <div
                    className="FloatingCollapsableButton"
                >
                    {propertiesCollapsed[index] === true ?
                        <CloseRoundedIcon className="FloatingCollapseButtonIcon FloatingCollapseButtonIconClose"/>
                        : <KeyboardArrowDownRoundedIcon className="FloatingCollapseButtonIcon"/>
                    }
                </div>
            </div>
        )
    });

    return (
        <div
            className={clsx('InformationDisplay', classNames)}
            style={styleOverwrite || {}}
        >
            {/* while there is no definitions we are loading */}
            {(!definitions || !optionsTree) &&
                <div className="InformationDisplayLoading">
                    <LoadingSVG
                        size={50}
                    />
                </div>
            }

            {/* if there are definitions load in the tabs */}
            {(definitions && optionsTree) &&
                <React.Fragment>
                    {/* info panel header, with top and bottom text from the config */}
                    <div className="InformationDisplayHeader">
                        <p className="Bold16">{definitions.top_text}</p>
                        <p className="Reg12">{definitions.bottom_text}</p>
                    </div>

                    <div className="InformationDisplayTabs">
                        {definitions.active_tabs.map((tab, index) => {
                            return (
                                <div
                                    key={index}
                                    className={clsx(
                                        'InformationDisplayTab',
                                        {
                                            'InformationDisplayTabActive': activeTab === index,
                                            // now to determine what border-bottom-left-radius and border-bottom-right-radius to use
                                            // if a tab is active then it should not have a border left or right, the tabs on either side should
                                            'InformationDisplayTabBorderLeft': activeTab !== index && index !== 0,
                                            'InformationDisplayTabBorderRight': activeTab !== index && activeTab === index + 1,
                                            // if tab is to the left or right of the active tab add a border-bottom-left-radius or border-bottom-right-radius
                                            'InformationDisplayTabBorderBottomLeft': activeTab !== index && activeTab === index - 1
                                        }
                                    )}
                                    onClick={() => {
                                        if (activeTab !== index) {
                                            setActiveTab(index);
                                        }
                                    }}
                                >
                                    <p className="Bold12">{tab.label}</p>
                                </div>
                            )
                        })}
                    </div>

                    {/*
                        wrapper for the content of the information display
                        this is where the content of the tab will be displayed
                        this wrapper will fill the remainder off the space available
                        if the content is too large it will scroll-y
                    */}
                    <div className="InformationDisplayContentWrap">
                        <div className="InformationDisplayContent">
                            {returnTabContent(definitions.active_tabs[activeTab].id, nullHex)}
                        </div>
                    </div>
                </React.Fragment>
            }
        </div>
    )
}, (prevProps, nextProps) => {
    // re-render the component if and of these values change
    // materialId
    // finishId
    // colourId
    // colourVal

    if (prevProps.materialId !== nextProps.materialId) {
        return false;
    } else if (prevProps.colourVal !== nextProps.colourVal) {
        return false;
    } else if (prevProps.finishId !== nextProps.finishId) {
        return false;
    } else if (prevProps.colourId !== nextProps.colourId) {
        return false;
    } else {
        return true;
    }
});

export default InformationDisplay;