 import React, {
    useEffect,
    useState,
    useRef
} from 'react';

import clsx from 'clsx';

import ReactCountryFlag from "react-country-flag"

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    Select,
    MenuItem
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import './SelectInput.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function SelectInput(props) {
    const {
        name,
        label,
        options,
        rootStyleOverwrite,
        required,
        refToUse,
        warning,
        defaultValue,
        writtenWarning,
        menuItemStyleOverwrite,
        selectStyleClass,
        rootStyleClass,
        onChangeOverwrite,
        disableIcon,
        iconStyleClass
    } = props;

    const [rootWidth, setRootWidth] = useState(null);
    const [valueToShow, setValueToShow] = useState(defaultValue);
    const rootRef = useRef();

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        if (rootRef && rootRef.current && !rootWidth) {
            let rootWidthToSet = null;
            try {
                rootWidthToSet = rootRef.current.getBoundingClientRect().width;
                setRootWidth(rootWidthToSet);
            } catch (error) {
                console.log('error caught @ rootRef width', error);
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rootRef]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    if (!options || !options.length) return null;

    let returnOption = (option, renderValue, index) => {
        let elem;
        let keyToUse;
        let nameToUse;

        if (name === 'country') {
            const {
                name,
                code
            } = option;

            keyToUse = code
            nameToUse = name;

            elem = (
                <div key={'renderValue' + index} className="SelectCountryWrap">
                    <p>{name}</p>

                    <ReactCountryFlag
                        svg={true}
                        className="SelectCountryFlag"
                        countryCode={code}
                    />
                </div>
            );
        } else {
            elem = option;
            keyToUse = option
            nameToUse = option;
        }

        if (renderValue) return elem;

        return (
            <MenuItem
                disableRipple
                key={keyToUse + index}
                name={nameToUse}
                value={option}
                classes={{
                    root: 'SelectMenuItem',
                    focusVisible: 'SelectMenuItemFocused'
                }}
                sx={menuItemStyleOverwrite ? menuItemStyleOverwrite : rootWidth ? { maxWidth: rootWidth, minWidth: rootWidth, width: rootWidth } : null}
            >
                {elem}
            </MenuItem>
        )
    };

    return (
        <div
            ref={rootRef}
            className="SelectInputWrap"
            style={rootStyleOverwrite}
        >
            <p className={clsx('Reg16', required ? 'InputRequired' : null)}>{label}</p>

            {!!(writtenWarning && writtenWarning.length) && <p className="Reg12 InputWrittenWarning">{writtenWarning}</p>}

            <Select
                variant="standard"
                disableUnderline
                disabled={!rootWidth}
                IconComponent={disableIcon ? '' : ExpandMoreIcon}
                onChange={e => {
                    setValueToShow(e.target.value);
                    if (!!onChangeOverwrite) {
                        onChangeOverwrite(e)
                    }
                }}
                inputProps={{
                    classes: {
                        icon: iconStyleClass ? iconStyleClass : 'SelectInputIcon'
                    },
                    ref: refToUse,
                    autoComplete: 'on',
                    name
                }}
                classes={{
                    root: rootStyleClass ? rootStyleClass : `SelectInputRoot ${warning ? 'SelectInputSelectWarning' : ''}`,
                    select: selectStyleClass ? selectStyleClass : `SelectInputSelect`
                }}
                MenuProps={{
                    classes: {
                        paper: 'SelectInputMenu',
                        list: 'SelectInputList'
                    }
                }}
                value={valueToShow}
                renderValue={value => returnOption(value, true)}
            >
                {options.map((option, index) => {
                    return returnOption(option, false, index)
                })}
            </Select>
        </div>
    );
};
