import React, {
    useState,
    useRef,
    useEffect
} from 'react';

import clsx from 'clsx';

import {
    returnCurrencySignAndOrValue,
    returnMinUnitPrice
} from '../../../functions/utils';

import {
    CheckCircle as SelectedOptionIcon,
    WarningRounded as WarningIcon,
    Add as MoreColoursIcon
} from '@mui/icons-material';

import {
    ClickAwayListener
} from "@mui/material";

import "./QuotePartCollapsable.css";

const EXPAND_COLOURS_FROM = 20;

export default function QuotePartCollapsable(props) {
    // need to map over the options tree and render the options
    // iterate over each nested layer of the options tree
    // each layer will have a set of integer values and a tier_title
    // a part must always have all four values selected
        // technology and material (both these values are linked)
        // surface
        // colour

    // WE HAVEN'T GOT A SYSTEM YET THAT ALLOWS FOR A TRULY DYNAMIC OPTIONS / PRICING
    // WE WOULD POTENTIALLY NEED TO STORE THE PART OPTIONS VALUES IN AN ARRAY THAT COULD BE ITERATED OVER AND LINKED TO THE NESTED OPTIONS TREE
    // SINCE VALUES ARE STORED IN NAMED VARIABLES IN THE DATABASE, WE CAN SIMULATE THE ARRAY BY PASSING IN AN ARRAY OF THE CHOSEN OPTIONS IN THE ORDER THEY APPEAR IN THE OPTIONS TREE

    const {
        SK,
        definitions,
        optionsTree,
        spec,
        xtra,
        forcedDefinitionsIndices,
        activeColourVal,
        handleOptionSelect,
        handleQuotePartOverlayRefresh,
        reducedWarnings,
        noClick,
        noMouseOver,
        basePriceValues,
        requestForQuoteTriggers,
        windowBreakpoint,
        partStatusTrigger
    } = props;

    const rootRef = useRef(null);
    const optionsRefs = useRef([]);
    const colourPreviewRefs = useRef({});

    const [tiers, setTiers] = useState([]);
    const [initialSet, setInitialSet] = useState(false);
    const [multiColourSelectOpen, setMultiColourSelectOpen] = useState(false);
    const [updatingWithMultiColour, setUpdatingWithMultiColour] = useState(false);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    /**
     * Parses the options tree based on the selected values and sets the tiers.
     * since we don't know how deep the tree goes, we need to parse it recursively and separate each layer into a array, returning an array of arrays
     */
    const parseOptionsTree = () => {
        let valuesToSet = [];

        let currentLayer = optionsTree;

        for (let val of spec) {
            let walkResult = walkOptionsTree(forcedDefinitionsIndices[valuesToSet.length], val, currentLayer);

            valuesToSet.push({
                title: walkResult.tierTitle,
                options: walkResult.values
            });

            currentLayer = walkResult.nextLayer;
        }

        setTiers(valuesToSet);
        setInitialSet(true);
    };

    // forcedDefinitionsIndices is present because it's the only way to tie definitions to the values
    // pass in selectedValue which will be the current value that has been selected, and that should be located in the options tree
    // pass in optionsLayer which will be the current nested layer to work with
    const walkOptionsTree = (forcedDefinitionsIndices, selectedValue, optionsLayer) => {
        let objToReturn = {
            values: [],
            tierTitle: null,
            nextLayer: null
        };

        for (let key in optionsLayer) {
            if (!isNaN(key) && (xtra || optionsLayer[key].visible)) {
                const {
                    index,
                    ltv,
                    pav,
                    pmv,
                    tags,
                    technology,
                    colour_value
                } = optionsLayer[key];

                // if we are returning a materials value then we want to return a preview of the colours that can be selected
                let coloursPreview = {
                    colours: [],
                    more: false
                };

                if (forcedDefinitionsIndices === 'materials') {
                    try {
                        let colourz = [];
                        // iterate over surface finishes and then colours
                        for (let surfaceFinish of Object.keys(optionsLayer[key]).filter(key => !isNaN(key))) {
                            for (let colourFinish of Object.keys(optionsLayer[key][surfaceFinish]).filter(key => !isNaN(key))) {
                                if (optionsLayer[key][surfaceFinish][colourFinish].colour_value.length) {
                                    colourz = [...colourz, ...optionsLayer[key][surfaceFinish][colourFinish].colour_value]
                                }
                            }
                        }

                        let uniqueColours = [...new Set(colourz)];
                        coloursPreview.more = uniqueColours.length > 6;
                        coloursPreview.colours = uniqueColours.splice(0, 6);
                    } catch (error) {
                        console.log('error getting colours preview', error);
                    }
                }

                objToReturn.values.push({
                    definitions: definitions[forcedDefinitionsIndices][key],
                    which: forcedDefinitionsIndices,
                    warning: reducedWarnings[forcedDefinitionsIndices].includes(parseInt(key)),
                    index,
                    ltv,
                    pav,
                    pmv,
                    tags,
                    technology,
                    defaults: definitions[forcedDefinitionsIndices][key]?.defaults,
                    colour_value,
                    colours_preview_colours: coloursPreview.colours,
                    colours_preview_more: coloursPreview.more,
                    value: parseInt(key)
                });

            } else if (key === 'tier_title') {
                objToReturn.tierTitle = optionsLayer[key];
            }
        }

        objToReturn.nextLayer = optionsLayer[selectedValue];

        return objToReturn;
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // function to return a tier in the collapsable elem
    // each tier will have dynamic values, with slight nuances based on what type of tier they are
    const returnTier = (tier, tierIndex) => {
        const {
            title,
            options
        } = tier;

        if (!optionsRefs.current[tierIndex]) optionsRefs.current[tierIndex] = [];

        return (
            <div className="QuotePartCollapsable__tier" key={'qpct__' + tierIndex}>
                <div className="Bold16">
                    {`${tierIndex + 1}. ${title}`}
                </div>

                <div className={clsx(
                    "QuotePartCollapsable__tier-options",
                    (!!multiColourSelectOpen && multiColourSelectOpen.location[0] === tierIndex && multiColourSelectOpen.vals.length > EXPAND_COLOURS_FROM) && 'QuotePartCollapsable__tier-options-expand'
                )}>
                    {options.sort((a, b) => a.index - b.index).map((option, i) => returnOption(option, i, tierIndex))}
                </div>
            </div>
        );
    };

    // function to return option in tier
    const returnOption = (option, index, tierIndex) => {
        const {
            definitions: optionDefinitions,
            which,
            warning,
            ltv,
            pav,
            pmv,
            defaults,
            colour_value,
            colours_preview_colours,
            colours_preview_more,
            value,
            tags
        } = option;

        const {
            label,
            label_2
        } = optionDefinitions ? optionDefinitions : {};

        let isMultiColourSelectAndOpen = !!multiColourSelectOpen && multiColourSelectOpen?.vals?.length > 1 && multiColourSelectOpen?.location + '' === `${tierIndex},${index}`;
        let multiColourSelectOpenInTier = !!multiColourSelectOpen && multiColourSelectOpen.location[0] === tierIndex;

        let optionHeader = returnHeaderElem(which, optionDefinitions);
        let selectedElem = !multiColourSelectOpenInTier ? returnSelectedElem(which, value) : null;
        let colourElem = !isMultiColourSelectAndOpen ? returnColourElem(which, colour_value, !!selectedElem) : null;

        let colour_val = Array.isArray(colour_value) ? colour_value[0] : colour_value;

        let isMultiColourElemAndSelected = !!selectedElem && !!colourElem && colour_value.length > 1 && activeColourVal;

        // if the option is for multi colour dye, and the option is selected then we want to override the label to read the label of the selected colour
        let labelOverride = isMultiColourElemAndSelected ? definitions.colour_values[activeColourVal].label : label;

        let fromValue;
        let coloursPreviewElem = null;
        if (which === 'materials') {
            fromValue = returnMinUnitPrice(value, basePriceValues, requestForQuoteTriggers, xtra);
            // if the option is for materials then we want to give insight into what colours can be selected further down the options tree

            // save values if they exist, because they seem to disappear when the component re-renders
            if (colours_preview_colours.length) {
                if (!colourPreviewRefs.current[tierIndex]) colourPreviewRefs.current[tierIndex] = {};
                if (!colourPreviewRefs.current[tierIndex][index]) colourPreviewRefs.current[tierIndex][index] = {};
                colourPreviewRefs.current[tierIndex][index] = {
                    colours_preview_colours: [...colours_preview_colours],
                    colours_preview_more
                };
            }

            coloursPreviewElem = returnColoursPreviewElem(colourPreviewRefs.current[tierIndex][index]?.colours_preview_colours || colours_preview_colours, colourPreviewRefs.current[tierIndex][index]?.colours_preview_more || colours_preview_more, !!selectedElem);
        }

        // if a multicolour option is selected and it has more than EXPAND_COLOURS_FROM vals, then we only want to render that option and allow it to expand across the whole "tier" space
        // this will prevent really long lists of colours from being displayed, which mess up the overall css of the component
        let hiddenBecauseOfMultiColourExpand = !!multiColourSelectOpenInTier && !isMultiColourSelectAndOpen && multiColourSelectOpen.vals.length > EXPAND_COLOURS_FROM;
        let expandBecauseOfMultiColour = !!multiColourSelectOpenInTier && isMultiColourSelectAndOpen && multiColourSelectOpen.vals.length > EXPAND_COLOURS_FROM;

        if (hiddenBecauseOfMultiColourExpand) return null;

        return (
            <ClickAwayListener
                key={'qpcto_caw__' + index + (!!coloursPreviewElem ? colours_preview_colours.length : '')}
                onClickAway={() => !expandBecauseOfMultiColour ? null : handleMultiColourSelectOpen(false)}
            >
                <div
                    ref={elem => optionsRefs.current[tierIndex][index] = {
                        el: elem,
                        mc_anchor: false,
                        label_default: label
                    }}
                    className={clsx(
                        'QuotePartCollapsable__tier-option',
                        (!!selectedElem || isMultiColourSelectAndOpen) && 'QuotePartCollapsable__tier-option-selected',
                        isMultiColourSelectAndOpen && 'QuotePartCollapsable__tier-option-multi-colour-select-open',
                        (noClick || noMouseOver) && 'QuotePartCollapsable__tier-option-no-click',
                        expandBecauseOfMultiColour && 'QuotePartCollapsable__tier-option-expand-multi-colour',
                        isMultiColourElemAndSelected && 'QuotePartCollapsable__tier-option-multi-colour-selected'
                    )}
                    onMouseEnter={() => {
                        // when a user hovers over an option, we want to remove the blue border from the option in the same tier that is already selected
                        // loop over all the options in the tier and remove the border from the selected option that isn't the current hovered option
                        if (!noMouseOver && !selectedElem && optionsRefs.current && optionsRefs.current[tierIndex]) {
                            for (let i = 0; i < optionsRefs.current[tierIndex].length; i++) {
                                if (i !== index && optionsRefs?.current[tierIndex][i]?.el) {
                                    if (optionsRefs.current[tierIndex][i].el.classList.contains('QuotePartCollapsable__tier-option-selected')) {
                                        optionsRefs.current[tierIndex][i].el.classList.add('QuotePartCollapsable__tier-option-border-blocker');
                                    }
                                }
                            }
                        }
                    }}
                    onMouseLeave={() => {
                        // when we mouse leaves the elem, loop over all other options in the tier and remove the border blocker class if it exists
                        if (!noMouseOver && optionsRefs.current && optionsRefs.current[tierIndex]) {
                            for (let i = 0; i < optionsRefs.current[tierIndex].length; i++) {
                                if (optionsRefs?.current[tierIndex][i]?.el && optionsRefs.current[tierIndex][i].el.classList.contains('QuotePartCollapsable__tier-option-border-blocker')) {
                                    optionsRefs.current[tierIndex][i].el.classList.remove('QuotePartCollapsable__tier-option-border-blocker');
                                }
                            }
                        }
                    }}
                    key={'qpcto__' + index + (!!coloursPreviewElem ? colours_preview_colours.length : '')}
                    onClick={() => {
                        if (noClick || (!!selectedElem && !isMultiColourElemAndSelected)) return;

                        if (!!colourElem && colour_value.length > 1 && !isMultiColourSelectAndOpen) {
                            // multi colour option has been selected, open up multi select
                            handleMultiColourSelectOpen(colour_value, tierIndex, index);
                        } else if (!!colourElem && colour_value.length > 1 && isMultiColourElemAndSelected) {
                            handleMultiColourSelectOpen(colour_value, tierIndex, index)
                        } else if (!isMultiColourSelectAndOpen) {
                            handleOptionClick(which, value, colour_val, defaults);
                        }
                    }}
                >
                    {expandBecauseOfMultiColour &&
                        <div
                            className="Bold12 QuotePartCollapsable__tier-option-expand-multi-colour-close"
                            onClick={() => handleMultiColourSelectOpen(false)}
                        >X</div>
                    }

                    {isMultiColourElemAndSelected &&
                        <p
                            className="Bold12 QuotePartCollapsable__tier-option-edit-multi-colour"
                        >edit{windowBreakpoint?.w <= 480 ? '' : ' colour'}</p>
                    }

                    {optionHeader && optionHeader}

                    <div
                        className={clsx(
                            'QuotePartCollapsable__tier-option-content',
                            !!optionHeader && 'QuotePartCollapsable__tier-option-content-with-header'
                        )}
                    >
                        {selectedElem && selectedElem}
                        {colourElem && colourElem}

                        <div className="QuotePartCollapsable__tier-option-content-right">
                            <div key={labelOverride} className="Bold16 QuotePartCollapsable__tier-option-content-right-label">
                                {labelOverride}
                                {warning && <WarningIcon className="QuotePartCollapsable__tier-option-content-right-warning-icon"/>}
                            </div>
                            {label_2 && <div className="Reg12">{label_2}</div>}
                            {!!fromValue && <p className="Bold12 QuotePartCollapsable__tier-option-from-value">From {returnCurrencySignAndOrValue(null, null, fromValue)}</p>}
                            {coloursPreviewElem && coloursPreviewElem}
                            {!isMultiColourSelectAndOpen && returnTagsElem(ltv, pav, pmv, which, definitions?.colour_values[colour_val], tags)}
                            {isMultiColourSelectAndOpen && returnMultiColourElems(multiColourSelectOpen.vals, which, value, defaults, expandBecauseOfMultiColour)}
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
        );
    };

    /**
     * Returns the header element for a specific category and technology.
     * @param {string} which - The category of the header element ('materials').
     * @param {string} technology - The technology associated with the header element.
     * @returns {JSX.Element|null} The header element or null if the conditions are not met.
     */
    const returnHeaderElem = (which, { tech_label_short, tech_label_long }) => {
        if (which === 'materials' && tech_label_short && tech_label_long) {
            return <div className="QuotePartCollapsable__tier-option-header Bold12">{tech_label_long} ({tech_label_short})</div>
        }

        return null;
    };

    /**
     * Returns a color element based on the provided parameters.
     *
     * @param {string} which - The type of color element ('colours').
     * @param colour_value
     * @param is_selected_option
     * @returns {JSX.Element|null} The color element or null if the parameters are invalid.
     */
    const returnColourElem = (which, colour_value, is_selected_option) => {
        if (which === 'colour_finishes' && Array.isArray(colour_value)) {
            let background;

            if (colour_value.length === 1 && definitions.colour_values[colour_value[0]]?.hex) {
                background = definitions.colour_values[colour_value[0]].hex;
            } else if (!is_selected_option && colour_value.length > 1) {
                // since there are multiple colour values, iterate over each of them and return a div with the value as the background
                // each of the elems returned will be flexed to 1
                background = (
                    <React.Fragment>
                        {colour_value.slice(0, colour_value.length > 5 ? 4 : 5).map((colourValue, index) => {
                            if (definitions.colour_values[colourValue]?.hex) {
                                return (
                                    <div
                                        key={'qpctocm__' + index}
                                        style={{
                                            background: definitions.colour_values[colourValue].hex
                                        }}
                                        className={clsx(
                                            "QuotePartCollapsable__tier-option-multi-colour"
                                        )}
                                    />
                                );
                            }

                            return null;
                        })}

                        {colour_value.length > 5 &&
                            <div
                                key={'qpctocm__plus'}
                                style={{
                                    background: 'rgba(232, 241, 255, 1)'
                                }}
                                className={clsx(
                                    "QuotePartCollapsable__tier-option-multi-colour",
                                    "QuotePartCollapsable__tier-option-multi-colour-plus"
                                )}
                            >
                                <MoreColoursIcon className="QuotePartCollapsable__tier-option-multi-colour-plus-icon" />
                            </div>
                        }
                    </React.Fragment>
                )
            } else if (activeColourVal && is_selected_option && colour_value.length > 1) {
                background = definitions.colour_values[activeColourVal].hex;
            } else {
                background = '#FFF';
            }

            return (
                <div
                    style={{
                        background: typeof background === 'string' || background instanceof String ? background : null
                    }}
                    className={clsx(
                        "QuotePartCollapsable__tier-option-colour",
                        /#FFF|#FFFFFF/i.test(background) && 'QuotePartCollapsable__tier-option-colour-border'
                    )}
                >
                    {React.isValidElement(background) && background}
                </div>
            );
        }

        return null;
    };

    /**
     * Returns a selected element based on the provided parameters.
     * @param {string} which - The identifier for the element.
     * @param {number} value - The value to compare against.
     * @returns {JSX.Element|null} - The selected element or null if not selected.
     */
    const returnSelectedElem = (which, value) => {
        value = parseInt(value, 10);

        let selected = spec[forcedDefinitionsIndices.indexOf(which)] === value;

        if (selected) {
            return (
                <div className="QuotePartCollapsable__tier-option-selected-icon-wrap">
                    <SelectedOptionIcon className="QuotePartCollapsable__tier-option-selected-icon" />
                </div>
            );
        }

        return null;
    };

    /**
     * Returns the tags element based on the provided parameters.
     *
     * given the values: ltv, pav and pmv, render the tag elements in their wrapper.
     * if the content length is the same, order by the index
     * only render tags for which = 'materials' if any of the values don't equal 0
     *
     * @param {number} ltv - The lead time value.
     * @param {number} pav - The price additional value.
     * @param {number} pmv - The price multiplier value.
     * @param {string} which - The category of the tags.
     * @returns {JSX.Element|null} The tags element or null if there are no tags.
     */
    const returnTagsElem = (ltv, pav, pmv, which, colour_val, tags_xtra) => {
        let tags = [];

        // ltv : lead time value
        if ((which === 'materials' && ltv !== 0) || which !== 'materials') {
            tags.push({
                content: `+${ltv} Day${!ltv || ltv > 1 ? 's' : ''}`
            });
        }

        // when it comes to returning a tag for pav and pmv we will want to combine them both into one
        // if pmv & pav == 0, Free
        // if both, +10% +£0.30
        // if just pmv, +10%
        // if just pav, +£0.30
        if (which !== 'materials') {
            if (pav === 0 && pmv === 0) {
                tags.push({
                    content: 'Free'
                });
            } else if (pav > 0 && pmv > 0) {
                tags.push({
                    content: `+${pmv * 100}% +${returnCurrencySignAndOrValue(null, null, pav, false, false)}`
                });
            } else if (pmv > 0) {
                tags.push({
                    content: `+${pmv * 100}%`
                });
            } else if (pav > 0) {
                tags.push({
                    content: `+${returnCurrencySignAndOrValue(null, null, pav, false, false)}`
                });
            }
        }

        if (colour_val && colour_val.setup_cost) {
            tags.push({
                content: `${returnCurrencySignAndOrValue(null, null, colour_val.setup_cost, false, false, 0)} Setup`
            });
        }

        // tags_xtra : extra tags
        if (tags_xtra) {
            tags.push(...tags_xtra);
        }

        if (!tags.length) return null;

        return (
            <div className="QuotePartCollapsable__tier-option-tags">
                {tags.map(returnTag)}
            </div>
        );
    };

    /**
     * Returns a div element with the provided tag and index.
     *
     * @param {string} txt - The tag to be displayed in the div element.
     * @param {string[]} colours - The colours to be applied to the div element.
     * @param {number} index - The index used for the key of the div element.
     * @returns {JSX.Element} - The div element with the tag and index.
     */
    const returnTag = ({ content, colour }, index) => (
        <div
            className="Bold12"
            key={'qpctot__' + index}
            style={
                colour ? {
                    background: colour[0],
                    color: colour[1],
                    borderColor: colour[1]
                } : {}
            }
        >
            {content}
        </div>
    );

    // function to iterate over array of multiple colour_value ids and return a div for each
    const returnMultiColourElems = (vals, which, id, defaults, expandMode) => {
        return (
            <div className={clsx(
                "QuotePartCollapsable__tier-option-multi-colour-wrap",
                expandMode && "QuotePartCollapsable__tier-option-multi-colour-wrap-expand"
            )}>
                {vals.map((val, index) => {
                    if (definitions.colour_values[val]?.hex) {
                        return (
                            <div
                                key={'qpctocm__' + index}
                                onMouseEnter={() => {
                                    if (updatingWithMultiColour) return;

                                    // when a user hovers over a multi colour option we want to change the label of the option to read that label of the selected colour
                                    if (optionsRefs.current && optionsRefs.current[multiColourSelectOpen.location[0]] && optionsRefs.current[multiColourSelectOpen.location[0]][multiColourSelectOpen.location[1]]?.el) {
                                        try {
                                            optionsRefs.current[multiColourSelectOpen.location[0]][multiColourSelectOpen.location[1]].el.getElementsByClassName('QuotePartCollapsable__tier-option-content-right-label')[0].innerText = definitions.colour_values[val].label;
                                        } catch (error) {
                                            console.log('error changing label content', error);
                                        }
                                    }
                                }}
                                onMouseLeave={() => {
                                    if (updatingWithMultiColour) return;

                                    // set the text content of the label back to it's default
                                    if (optionsRefs.current && optionsRefs.current[multiColourSelectOpen.location[0]] && optionsRefs.current[multiColourSelectOpen.location[0]][multiColourSelectOpen.location[1]]?.el) {
                                        try {
                                            optionsRefs.current[multiColourSelectOpen.location[0]][multiColourSelectOpen.location[1]].el.getElementsByClassName('QuotePartCollapsable__tier-option-content-right-label')[0].innerText = optionsRefs.current[multiColourSelectOpen.location[0]][multiColourSelectOpen.location[1]].label_default;
                                        } catch (error) {
                                            console.log('error changing label content back', error);
                                        }
                                    }
                                }}
                                onClick={() => handleOptionClick(which, id, val, defaults)}
                            >
                                <div>
                                    <div
                                        style={{
                                            background: definitions.colour_values[val].hex,
                                            border: definitions.colour_values[val].hex && definitions.colour_values[val].hex.startsWith('#FFF') ? 'var(--border-main)' : 'none'
                                        }}
                                    />
                                </div>
                            </div>
                        );
                    }

                    return null;
                })}
            </div>
        );
    };

    // function to return an array of colour previews
    // should list up to 6 colours and then a ...
    // shorten is a boolean indicating whether to shorten the list of colours to fit the space
    const returnColoursPreviewElem = (colours, more, shorten) => {
        return (
            <div
                key={'qpctocp__' + colours.length + more + shorten}
                className="QuotePartCollapsable__tier-option-colours-preview-wrap"
            >
                {colours.splice(0, shorten ? 5 : 6).map((colour, index) => {
                    if (definitions.colour_values[colour]?.hex) {
                        return (
                            <div
                                key={'qpctocp__' + index}
                                style={{
                                    background: definitions.colour_values[colour].hex,
                                    border: definitions.colour_values[colour].hex && definitions.colour_values[colour].hex.startsWith('#FFF') ? 'var(--border-main)' : 'none'
                                }}
                                className="QuotePartCollapsable__tier-option-colours-preview"
                            />
                        );
                    }

                    return null;
                })}

                {more &&
                    <div className="QuotePartCollapsable__tier-option-colours-preview-etc">
                        <div/>
                        <div/>
                        <div/>
                    </div>
                }
            </div>
        )
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    /**
     * Array of classnames for the columns in QuotePartCollapsable component.
     * @type {string[]}
     */
    let columnsClassnames = [
        'QuotePartCollapsable__root-one-column',
        'QuotePartCollapsable__root-two-columns',
        'QuotePartCollapsable__root-three-columns'
    ];

    /**
     * Clears all the classnames from the target element and adds a specific classname at the given index.
     * @param {HTMLElement} target - The target element to modify.
     * @param {number} index - The index of the classname to add.
     */
    const clearAllColumnsClassnames = (target, index) => {
        for (let className of columnsClassnames) {
            if (target.classList.contains(className)) {
                target.classList.remove(className);
            }
        }

        for (let i = 0; i <= columnsClassnames.length; i++) {
            if (i === index) {
                target.classList.add(columnsClassnames[i]);
            } else {
                target.classList.remove(columnsClassnames[i]);
            }
        }
    };

    /**
     * Listens for changes in grid columns and applies appropriate classnames to the element.
     */
    const gridColumnsListener = () => {
        let elem = rootRef.current;

        if (!elem) return;

        let width = rootRef.current?.clientWidth;

        let elemStyle = window.getComputedStyle(elem);
        let paddingWidth = parseInt(elemStyle.paddingLeft, 10) + parseInt(elemStyle.paddingRight, 10);

        width -= paddingWidth;

        if (width <= 510 && !elem.classList.contains(columnsClassnames[0])) {
            clearAllColumnsClassnames(elem, 0);
        } else if (width > 550 && width <= 770 && !elem.classList.contains(columnsClassnames[1])) {
            clearAllColumnsClassnames(elem, 1);
        } else if (width > 770 && !elem.classList.contains(columnsClassnames[2])) {
            clearAllColumnsClassnames(elem, 2);
        }
    };

    // function to handle a user clicking on the multi colour select option
    // gets passed an array of colour values, the option tier index and the option index
    const handleMultiColourSelectOpen = (colour_values, tierIndex, optionIndex) => {
        // set the colour values in the state
        setMultiColourSelectOpen({
            vals: colour_values,
            location: [tierIndex, optionIndex],
            // close action should set the values back to what they were before the multicolour was opened
            closeAction: () => handleOptionClick('colour_finishes', spec[forcedDefinitionsIndices.indexOf('colour_finishes')], activeColourVal, null)
        });

        // refresh the quote part overlay as the content size might have changed
        handleQuotePartOverlayRefresh();
    };

    // wrap the option select function so that if we need to pass a callback we can
    const handleOptionClick = (which, value, colour_val, defaults) => {
        if (which === 'colour_finishes' && multiColourSelectOpen) setUpdatingWithMultiColour(true);

        handleOptionSelect(
            SK,
            which,
            value,
            colour_val,
            defaults,
            spec,
            !!multiColourSelectOpen ? () => {
                setMultiColourSelectOpen(false);
                setUpdatingWithMultiColour(false);
                handleQuotePartOverlayRefresh();
            } : handleQuotePartOverlayRefresh,
            handleQuotePartOverlayRefresh
        );
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        parseOptionsTree(true);
        gridColumnsListener();

        window.addEventListener('resize', gridColumnsListener);

        return () => {
            window.removeEventListener('resize', gridColumnsListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (initialSet) {
            parseOptionsTree();
         }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spec]);

    useEffect(() => {
        if (initialSet) {
            parseOptionsTree();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multiColourSelectOpen]);

    useEffect(() => {
        if (initialSet) {
            parseOptionsTree();
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [partStatusTrigger]);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            ref={rootRef}
            className="QuotePartCollapsable__root"
        >
            {!!tiers.length && tiers.map(returnTier)}
        </div>
    );
};