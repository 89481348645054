import React, {
    useEffect,
    useState,
    useRef
} from 'react';

import {
    handleAlienAllShip,
    handleAlienFetchInfo,
    handleAlienCustomShip,
    handleAlienUnsetCustomShip,
    handleAlienPushOrder,
    handleAlienDismissibleNote,
    handleAlienCustomLeadTime,
    handleAlienAssumeIdentity
} from '../../functions/user_functions';
import {
    handleApiReq,
    handlePushToDataLayer,
    isNumber
} from '../../functions/utils';

import './Alien.css';

const pathnameRegExp = /^\/(\w+)(?=\b|\/)/;

export default function Alien(props) {
    const {
        isAdmin,
        alienData,
        handleNewAlienMessage,
        handleClearAlienMessage,
        handleAlienClick,
        handleAlienData,
        alienActionsVisible,
        setAlienActionsVisible,
        alienInfo,
        location,
        history,
        componentKeys,
        setComponentKeys
    } = props;

    // const location = useLocation();

    const [where, setWhere] = useState(null);
    const [currentId, setCurrentId] = useState(null);
    const [alienActionLoading, setAlienActionLoading] = useState(false);
    const [alienIntermediate, setAlienIntermediate] = useState(false);
    const [alienIntermediateWarning, setAlienIntermediateWarning] = useState(false);
    const [assumingIdentity, setAssumingIdentity] = useState(false);

    const alienIntermediateInputRef = useRef();
    const alienIntermediateExtraRef = useRef();

    const alienActions = {
        all: [
            {
                id: 'assume_user_identity',
                emoji: '🧬',
                label: 'assume user identity',
                handler: () => handleAlienIntermediate('assume_user_identity', () => handleAlienAction('assume_user_identity', handleAlienAssumeIdentity, false, ({ success, data }) => {
                    if (!data || !success) {
                        return;
                    }

                    if (data.revert) {
                        localStorage.removeItem('_alien_assumed_identity');
                    } else {
                        localStorage.setItem('_alien_assumed_identity', JSON.stringify(data));
                    }
                    // refresh the page
                    setTimeout(() => {
                        window.location.reload();
                    }, 100);
                }), 550),
                sort_val: 1
            },
            {
                id: 'close',
                emoji: '❌',
                label: null,
                handler: () => setAlienActionsVisible(false)
            },
        ],
        quote: [
            {
                id: 'fetch_info',
                emoji: '🔭',
                disabled: (!alienData || !alienData.quote_id),
                label: 'fetch alien info',
                handler: () => handleAlienAction('fetch_info', handleAlienFetchInfo),
                sort_val: 2
            },
            {
                id: 'all_ship',
                emoji: '🚀',
                disabled: (!alienData || !alienData.quote_id),
                label: alienData && alienData.aas ? 'restore default shipping methods' : 'enable all shipping methods',
                handler: () => handleAlienAction('all_ship', handleAlienAllShip, true),
                sort_val: 3
            },
            {
                id: 'custom_ship',
                emoji: '🛸',
                disabled: (!alienData || !alienData.quote_id || !alienData.sso),
                label: (!alienData || !alienData.sso) ? 'set custom shipping price (no selected shipping option)' : 'set custom shipping price',
                handler: () => handleAlienIntermediate('custom_ship', () => handleAlienAction('custom_ship', handleAlienCustomShip, true), 475),
                sort_val: 4
            },
            {
                id: 'unset_custom_ship',
                emoji: '🌍',
                disabled: (!alienData || !alienData.quote_id || !alienData.css),
                label: (!alienData || !alienData.css) ? 'unset custom shipping price (no custom shipping price)' : 'unset custom shipping price',
                handler: () => handleAlienAction('unset_custom_ship', handleAlienUnsetCustomShip, true),
                sort_val: 5
            },
            {
                id: 'handle_dismissible_note',
                emoji: '📟',
                disabled: (!alienData || !alienData.quote_id),
                label: 'enter/alter dismissible note',
                handler: () => handleAlienIntermediate('handle_dismissible_note', () => handleAlienAction('handle_dismissible_note', handleAlienDismissibleNote), 550),
                sort_val: 6
            },
            {
                id: 'custom_lead_time',
                emoji: '⚗️',
                disabled: (!alienData || !alienData.quote_id || !alienData.locked),
                label: (!alienData || !alienData.locked) ? 'set custom lead time (quote must be locked)' : 'set custom lead time',
                handler: () => handleAlienIntermediate('custom_lead_time', () => handleAlienAction('custom_lead_time', handleAlienCustomLeadTime, true), 380),
                sort_val: 7
            }
        ],
        checkout: [
            {
                id: 'fetch_info',
                emoji: '🔭',
                disabled: (!alienData || !alienData.quote_id),
                label: 'fetch alien info',
                handler: () => handleAlienAction('fetch_info', handleAlienFetchInfo),
                sort_val: 1
            },
            {
                id: 'push_order',
                emoji: '🪐',
                disabled: (!alienData || !alienData.quote_id),
                label: 'push order into the system',
                handler: () => handleAlienAction('push_order', handleAlienPushOrder, false, async () => {
                    handleClearAlienMessage();

                    handleApiReq(
                        'get',
                        `/crud/checkout-event-data/${alienData.quote_id}?alien=true`
                    ).then(result => {
                        handleNewAlienMessage(result.message);

                        if (result.success) {
                            handlePushToDataLayer(result.data, true);
                        }

                        setTimeout(() => history.push(`/confirmation/${alienData.quote_id}?sc=true`), 3000);
                    }).catch(error => {
                        console.error('error caught @ get post alien push data', error);
                        handleNewAlienMessage(['Something went wrong...', 'Data has not been sent to gmt.', 'Redirecting...', 'D:']);

                        setTimeout(() => history.push(`/confirmation/${alienData.quote_id}?sc=true`), 3000);
                    });
                }),
                sort_val: 2
            }
        ],
        confirmation: [
            {
                id: 'fetch_info',
                emoji: '🔭',
                disabled: (!alienData || !alienData.quote_id),
                label: 'fetch alien info',
                handler: () => handleAlienAction('fetch_info', handleAlienFetchInfo),
                sort_val: 1
            }
        ]
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const returnAlienAction = ({ id, emoji, label, handler, disabled }, fadeIn, fadeOut) => {
        let otherLoading = !!(alienActionLoading && alienActionLoading !== id)
        let isLoading = !!(alienActionLoading && alienActionLoading === id);

        return (
            <div
                onClick={(otherLoading || isLoading) ? null : handler}
                key={'alien-action-' + id}
                style={{
                    transitionDelay: `0s, ${fadeIn && alienActionsVisible ? `0.${fadeIn}` : fadeOut && !alienActionsVisible ? `0.${fadeOut}` : '0'}s`
                }}
                className={`AlienActionWrap${alienActionsVisible ? ' AlienActionsFadeIn' : ' AlienActionsFadeOut'}${otherLoading || disabled ? ' AlienActionOtherLoading' : ''}${isLoading ? ' AlienActionIsLoading' : ''}`}
            >
                <p className={`AlienAction${isLoading ? ' AlienActionLoading' : ''}`}>{isLoading ? '⚙️' : emoji}</p>
                {label && <p className='AlienActionLabel'>{label}</p>}
            </div>
        );
    };

    const handleAlienAction = async (id, func, refresh, callback) => {
        try {
            setAlienActionLoading(id);

            setAlienIntermediateWarning(false);
            await handleClearAlienMessage();

            let warning = false;
            let intermediateValues = false;
            if (id === 'custom_ship') {
                intermediateValues = {};

                let val = alienIntermediateInputRef && alienIntermediateInputRef.current ? alienIntermediateInputRef.current.value : null;

                if (!val) warning = 'please enter a value';
                if (!warning && isNaN(val)) warning = 'please enter a valid number';
                if (!warning && !/^\d+((\.\d{0,2}$)|$)/.test(val)) warning = 'please keep to 2 decimal places';

                if (!warning) {
                    intermediateValues.selected_shipping_option = null;
                    intermediateValues.selected_shipping_option_label = null;
                    try {
                        const {
                            service_code,
                            stripe_json
                        } = alienData.sso;

                        intermediateValues.selected_shipping_option = service_code;
                        intermediateValues.selected_shipping_option_label = (stripe_json && stripe_json.shipping_rate_data && stripe_json.shipping_rate_data.display_name) ? stripe_json.shipping_rate_data.display_name : null;
                    } catch (error) {
                        console.log('alien action error:', error);
                        warning = 'something went wrong';
                    }

                    if (!warning && (!intermediateValues.selected_shipping_option || !intermediateValues.selected_shipping_option_label)) warning = 'something went wrong';

                    if (!warning) {
                        intermediateValues.set_price = parseFloat(val) * 100;
                    }
                }
            } else if (id === 'handle_dismissible_note') {
                intermediateValues = {};

                let val = alienIntermediateInputRef && alienIntermediateInputRef.current ? alienIntermediateInputRef.current.value : null;
                if (val) val = val.trim();
                intermediateValues.note = val && val.length ? val : null;
            } else if (id === 'custom_lead_time') {
                intermediateValues = {};

                let val = alienIntermediateInputRef && alienIntermediateInputRef.current ? alienIntermediateInputRef.current.value : null;
                if (val) val = val.trim();
                val = val && val.length ? parseFloat(val) : val;
                val = val && !isNaN(val) && val > 999 ? 999 : val;

                intermediateValues.clt = val ? val : null;
            } else if (id === 'assume_user_identity') {
                intermediateValues = {};

                let val = alienIntermediateInputRef?.current?.value;

                if (typeof val === 'string') {
                    if (val) val = val.trim();

                    intermediateValues.quex = val;
                } else {
                    warning = 'something went wrong';
                }
            }

            if (warning) {
                setAlienIntermediateWarning(warning);
                return;
            }

            if (intermediateValues) setAlienIntermediate(false);

            let alienResult = await func({ ...alienData, values: intermediateValues });
            handleNewAlienMessage(alienResult.message, true);
            setAlienActionLoading(false);

            if (refresh) {
                setComponentKeys({
                    ...componentKeys,
                    [where]: componentKeys[where] ? componentKeys[where] + 1 : 1
                });
            }

            if (callback) {
                setTimeout(() => callback(alienResult), 3000);
            }
        } catch (error) {
            console.log('alien action error:', error);
            setAlienActionLoading(false);
            handleNewAlienMessage(['Oops. Looks like something went wrong.', '🔥👽🔥']);
        }
    };

    const handleAlienIntermediate = async (id, proceed, width) => {
        setAlienIntermediateWarning(false);
        setAlienActionLoading(id);
        setAlienIntermediate({
            id,
            proceed,
            width
        });
        await handleClearAlienMessage();
    };

    const returnAlienIntermediate = id => {
        if (id === 'custom_ship') {
            const {
                c,
                sso
            } = alienData;

            const {
                stripe_json
            } = sso;

            return (
                <div id="AlienIntermediateCustomShip">
                    <p>Set custom shipping price.</p>
                    <p className='AlienIntermediateMarginTop'>Shipping method: {(stripe_json && stripe_json.shipping_rate_data && stripe_json.shipping_rate_data.display_name) ? stripe_json.shipping_rate_data.display_name : '?'}</p>
                    <p>Current price: £{(stripe_json && stripe_json.shipping_rate_data && stripe_json.shipping_rate_data.fixed_amount) ? (stripe_json.shipping_rate_data.fixed_amount.amount / 100).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2}) : '?'}</p>
                    <br></br>
                    <p className='AlienIntermediateMarginTop'>{`Enter new price (price entered will be in GBP £)`}</p>
                    {!!(c && c.label !== 'GBP' && stripe_json.shipping_rate_data?.fixed_amount?.amount) && <p ref={alienIntermediateExtraRef}>Price converts to: {c.unit_sign}{(c.value * (stripe_json.shipping_rate_data.fixed_amount.amount / 100)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}</p>}
                    <input
                        ref={alienIntermediateInputRef}
                        onInput={e => {
                            e.target.value = e.target.value.replace(/[^\d.]/g, '');
                            if (alienIntermediateExtraRef?.current) {
                                if (isNumber(e.target.value)) {
                                    alienIntermediateExtraRef.current.innerText = `Price converts to: ${c.unit_sign}${(c.value * parseFloat(e.target.value)).toLocaleString('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 2})}`
                                } else {
                                    alienIntermediateExtraRef.current.innerText = `Price converts to: ¯\\_(ツ)_/¯`
                                }
                            }
                        }}
                    ></input>
                </div>
            );
        } else if (id === 'handle_dismissible_note') {
            const {
                dn
            } = alienData;

            return (
                <div id="AlienIntermediateDismissibleNote">
                    <p>Enter/Alter dismissible note.</p>
                    <p className='AlienIntermediateMarginTop'>The user will have to acknowledge and dismiss this note from the quote page.</p>
                    <br></br>
                    <p className='AlienIntermediateMarginTop'>To clear existing note delete all text from input field.</p>
                    <textarea
                        className='HideScrollbar'
                        defaultValue={dn}
                        ref={alienIntermediateInputRef}
                    ></textarea>
                </div>
            );
        } else if (id === 'custom_lead_time') {
            const {
                clt
            } = alienData;

            return (
                <div id="AlienIntermediateCustomLeadTime">
                    <p>Set custom lead time.</p>
                    <p>Current custom lead time: {!!(clt && typeof clt === 'number') ? clt + ' days.' : '_'}</p>
                    <br></br>
                    <p className='AlienIntermediateMarginTop'>To remove custom lead time, leave input field empty.</p>
                    <p>{`Enter new lead time in days. (max 999)`}</p>
                    <input
                        ref={alienIntermediateInputRef}
                        onInput={e => e.target.value = e.target.value.replace(/[^\d]/g, '')}
                    ></input>
                </div>
            );
        } else if (id === 'assume_user_identity') {
            let defaultValue = alienData.que || '';
            if (defaultValue === alienData.liu.email) {
                defaultValue = '';
            }

            return (
                <div id="AlienIntermediateAssumeUserIdentity">
                    <p>Assume user identity.</p>
                    <br></br>
                    <p>Enter the email of the user you want to assume.</p>
                    <p>To cancel assuming user identity leave the input field empty.</p>
                    <input
                        autoComplete='off'
                        name="aliens-exist-in-my-mind"
                        ref={alienIntermediateInputRef}
                        defaultValue={defaultValue}
                    ></input>
                </div>
            );
        } else {
            return '🆗';
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    useEffect(() => {
        if (location && location.pathname) {
            if (pathnameRegExp.test(location.pathname)) {
                // determine where the user is on the app and only present actions allowed for that location
                setWhere(location.pathname.match(pathnameRegExp)[1]);

                if (!location.pathname.startsWith('/quote/') && alienData && alienData.ahZzZ) {
                    console.log('___mod 1___');
                    let alienDataClone = { ...alienData };
                    delete alienDataClone.ahZzZ;
                    handleAlienData(alienDataClone);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    useEffect(() => {
        if (alienData?.quote_id && currentId !== alienData.quote_id) {
            setCurrentId(alienData.quote_id);
        } else if (!alienData?.quote_id && currentId) {
            setCurrentId(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alienData]);

    useEffect(() => {
        if (isAdmin && location && where === 'quote' && currentId) {
            handleAlienAction('fetch_info', handleAlienFetchInfo);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [where, currentId]);

    useEffect(() => {
        // on mount determine if user is assuming identity
        const assumedIdentity = JSON.parse(localStorage.getItem('_alien_assumed_identity'));

        if (assumedIdentity) {
            setAssumingIdentity(assumedIdentity);
        }
    }, []);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    if (!isAdmin || !location) return null;

    return (
        <React.Fragment>
            {(!!assumingIdentity && !alienIntermediate) &&
                <div id="AlienAssuming">
                    <div id="AlienAssumingData">
                        <div>
                            {assumingIdentity.length && assumingIdentity.slice(0, -2).reverse().map((val, idx) => <p key={'_a_a_a_' + idx}>{idx === 0 ? `👽 ${val} 👽` : val}</p>)}

                            {(alienData && alienData.ahZzZ) &&
                                <p>🤫 <span className="AlienAssumingHiddenP">quote hidden</span> 🤫</p>
                            }
                        </div>
                    </div>
                </div>
            }

            {(!assumingIdentity && !alienIntermediate && (alienData && alienData.ahZzZ)) &&
                <div id="AlienWholeWrap">
                        <div
                            id="AlienAssumingHidden"
                            style={{
                                top: 5,
                                left: 5
                            }}
                        >
                            <div>
                                <p>🤫 <span className="AlienAssumingHiddenP">quote hidden</span> 🤫</p>
                            </div>
                        </div>
                </div>
            }

            <div
                id="AlienWrap"
            >
                <div
                    id="AlienTerminal"
                    className={!!(alienInfo && alienInfo.visible) ? 'AlienMessageFadeIn' : 'AlienMessageFadeOut'}
                >
                    <div onClick={handleClearAlienMessage}>x</div>
                    {alienInfo && alienInfo.message && alienInfo.message.length && alienInfo.message.map((message, index) =>
                        <p
                            key={'alien_message_' + index}
                            style={{
                                animationName: 'adminType',
                                animationDuration: '0.3s',
                                animationTimingFunction: 'steps(30)',
                                animationDelay: `${index ? 0.3 * index : 0}s`,
                                animationIterationCount: '1',
                                animationDirection: 'normal',
                                animationFillMode: 'forwards'
                            }}
                        >{message}</p>)
                    }
                </div>

                <div id="AlienButtonWrap">
                    <p onClick={handleAlienClick}>{typeof assumingIdentity[2] !== 'undefined' ? assumingIdentity[2] : '👽'}</p>
                </div>

                <div
                    id="AlienActionsWrap"
                >
                    {where && alienActions[where] && alienActions[where].sort((a, b) => b.sort_val - a.sort_val).map((alienAction, idx) => returnAlienAction(alienAction, alienActions[where].length - idx, idx + 1))}
                    {where && alienActions[where] && alienActions.all.map(alienAction => returnAlienAction(alienAction, 0, alienActions[where].length + 1))}
                    {where && !alienActions[where] && alienActions.all.map(alienAction => returnAlienAction(alienAction, 0, alienActions.all.length))}
                </div>
            </div>

            {alienIntermediate &&
                <div
                    id="AlienIntermediateWrap"
                    className="FullHeightWidth"
                    onClick={e => {
                        e.stopPropagation();
                        if (e.target.id === 'AlienIntermediateWrap') {
                            setAlienIntermediate(false);
                            setAlienActionLoading(false);
                        }
                    }}
                >
                    <div
                        style={{ width: alienIntermediate.width || 500 }}
                    >
                        <div
                            id="AlienIntermediateClose"
                            onClick={() => {
                                setAlienIntermediate(false);
                                setAlienActionLoading(false);
                            }}
                        >X</div>

                        {returnAlienIntermediate(alienIntermediate.id)}

                        {alienIntermediateWarning &&
                            <div id="AlienIntermediateWarning">{alienIntermediateWarning}</div>
                        }

                        <div
                            id="AlienIntermediateProceed"
                            onClick={alienIntermediate.proceed}
                        >proceed</div>
                    </div>
                </div>
            }
        </React.Fragment>
    );
};