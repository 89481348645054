import React, {
    useState
} from 'react';

import clsx from 'clsx';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import Authenticator from '../../Authenticator/Authenticator';
import LoadingSVG from '../LoadingSVG/LoadingSVG';

import {
    Paper
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import './WantToRegisterModal.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function WantToRegisterModal(props) {
    const {
        handleClose,
        handleNewSnackbar,
        loggedIn,
        industryOptions,
    } = props;

    const [showAuthenticator, setShowAuthenticator] = useState(false);
    const [authLoading, setAuthLoading] = useState(false);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            id="WantToRegisterWrap"
            className="FullHeightWidth"
        >
            <Paper
                elevation={6}
                id="WantToRegisterModal"
                className={clsx('Reg12', showAuthenticator ? 'WantWantToRegisterModalExpand' : null)}
            >
                {authLoading &&
                    <div id="WantToRegisterModalLoading">
                        <LoadingSVG
                            size={50}
                        />
                    </div>
                }

                <CloseIcon
                    id="WantToRegisterModalClose"
                    onClick={handleClose}
                />

                {!showAuthenticator &&
                    <React.Fragment>
                        <p>
                            <span onClick={() => setShowAuthenticator('register')}>Register</span> or <span onClick={() => setShowAuthenticator('login')}>Login</span> to save this order to your account.
                        </p>
                        <p>With a 3D People account you can:</p>
                        <ul>
                            <li>View your past orders</li>
                            <li>Re-order parts</li>
                            <li>Save quotes</li>
                            <li>Download invoices</li>
                        </ul>
                    </React.Fragment>
                }

                {showAuthenticator &&
                    <Authenticator
                        childMode={showAuthenticator}
                        childModeCallback={handleClose}
                        childModeBackMessage={showAuthenticator === 'register' ? "Don't register." : "Don't login."}
                        childModeSetLoading={setAuthLoading}
                        handleNewSnackbar={handleNewSnackbar}
                        industryOptions={industryOptions}
                        loggedIn={loggedIn}
                    />
                }
            </Paper>
        </div>
    );
};