import React from 'react';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    handleApiReq,
    handleError
} from '../../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import WarningAmberIcon from '@mui/icons-material/WarningAmber';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import './DismissibleNote.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function DismissibleNote(props) {
    const {
        handleClose,
        handleLoading,
        handleNewSnackbar,
        quoteId,
        dismissibleNote
    } = props;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const handleDismissNote = () => {
        handleLoading(true);
        
        handleApiReq(
            'put',
            '/crud',
            {
                action: 'DISMISS_NOTE',
                quote_id: quoteId
            }
        ).then(result => {
            handleLoading(false);
            if (result.success) {
                handleClose();
            } else {
                handleNewSnackbar('something went wrong', 'error');
            }
        }).catch(error => {
            handleLoading(false);
            handleError('56588MCr', error);
        });
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            id="DismissibleNoteModalWrap"
            className="FullHeightWidth"
        >
            <div>
                <WarningAmberIcon id="OverlayWarningModalIcon" />

                <p className="Bold16">Attention!</p>

                <div id="DismissibleNoteModalNoteWrap">
                    <p className="Bold14">{dismissibleNote}</p>
                </div>

                <div id="DismissibleNoteModalButtonWrap">
                    <button className="Bold16" onClick={handleDismissNote}>Continue</button>
                </div>
            </div>
        </div>
    );
};