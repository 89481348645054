import React from "react";

export default function MeasureIcon(props) {
    const { 
        size,
        rootStyle,
        className,
        id
    } = props;

    let width = size ? size : 25;
    let height = size ? size : 25;

    return (
        <svg
            width={width + 'px'}
            id={id}
            className={className}
            height={height + 'px'}
            viewBox={`0 0 25 25`}
            style={rootStyle}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path 
                d="M17.1253 1.00738L1.00737 17.1253C0.451014 17.6816 0.451013 18.5836 1.00737 19.14L4.95625 23.0889C5.5126 23.6452 6.41463 23.6452 6.97098 23.0889L23.0889 6.971C23.6452 6.41464 23.6452 5.51261 23.0889 4.95626L19.14 1.00738C18.5836 0.451025 17.6816 0.451025 17.1253 1.00738Z"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path 
                d="M2.67969 15.4483L6.06082 18.8294"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path 
                d="M5.87109 12.2571L8.35945 14.7455"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path 
                d="M9.06152 9.06589L12.4427 12.447"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path 
                d="M12.2529 5.87473L14.7413 8.36309"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
            <path
                d="M15.4448 2.68356L18.826 6.06469"
                strokeWidth="0.5"
                strokeMiterlimit="10"
            />
        </svg>
    );
};