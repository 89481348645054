import React, {
    useContext,
    useRef,
    useState
} from "react";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import clsx from 'clsx';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -

import {
    attemptLogout
} from '../../../functions/user_functions';

import {
    handlePushToDataLayer,
    validateEmailAddress
} from '../../../functions/utils'

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import Authenticator from "../../Authenticator/Authenticator";
import LoadingSVG from "../LoadingSVG/LoadingSVG";
import TextInput from "../TextInput/TextInput";
import CurrencySelector from "../CurrencySelector/CurrencySelector";

import General from "../../../context/general";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import CloseIcon from '@mui/icons-material/Close';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import "./UserInfoModal.css";

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function UserInfoModal(props) {
    const {
        loggedIn,
        isAdmin,
        handleNewSnackbar,
        userInfo,
        setUserInfo,
        handleNextStage,
        handleClose,
        industryOptions,
        currencyOptions,
        detectedCurrency
    } = props;

    const {
        termsAndConditions,
        privacyPolicy
    } = useContext(General);

    const inputRef = useRef();
    const consentRef = useRef();

    const [currentEmail, setCurrentEmail] = useState(null);
    const [userInfoFormMessage, setUserInfoFormMessage] = useState(false);
    const [emailInvalid, setEmailInvalid] = useState(false);
    // handle hide / show of authenticator form
    const [showAuthenticator, setShowAuthenticator] = useState('login');
    // loading for auth events
    const [authLoading, setAuthLoading] = useState(false);
    // boolean to reveal extra info below form
    const [hideExtraInfo, setHideExtraInfo] = useState(loggedIn ? false : true);
    const [currencyInit, setCurrencyInit] = useState(detectedCurrency);
    const [consentRequiredWarning, setConsentRequiredWarning] = useState(false);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const handleUserInputInfo = (value, emailX) => {
        setUserInfo({
            email: emailX ? value : userInfo.email
        }, updateMessage);
    };

    const updateMessage = val => {
        let message = !val.length ? 'Please enter an email address'
        : !validateEmailAddress(val) ? 'Please enter a valid email address'
        : null;

        setCurrentEmail(val);
        setUserInfoFormMessage(message);
        setEmailInvalid(!!message);
    };

    const checkConsent = callback => {
        setConsentRequiredWarning(false);

        if (!consentRef?.current?.checked) {
            setUserInfoFormMessage('You are required to tick the box below');
            setConsentRequiredWarning(true);
        } else {
            callback();
        }
    };

    // handle user logout click
    const handleLogout = async () => {
        let logoutResult = await attemptLogout();

        if (!logoutResult.success) {
            handleNewSnackbar(logoutResult.message);
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    const userInfoFormButtonDisabled = !currentEmail || !currentEmail.length || !!emailInvalid;

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    return (
        <div
            id="UserInfoModal"
            className="FullHeightWidth"
        >
            <div
                id="UserInfoInputWrap"
                className={!!loggedIn ? isAdmin ? 'AdminInfoInputWrapAdmin' : 'UserInfoInputWrapLoggedIn' : null}
            >
                {authLoading &&
                    <div id="UserInfoInputLoading">
                        <LoadingSVG
                            size={50}
                        />
                    </div>
                }

                <CloseIcon
                    id="UserInfoModalClose"
                    onClick={handleClose}
                />

                {!loggedIn && !showAuthenticator &&
                    <React.Fragment>
                        <h3 className="Bold14">Continue as a guest.</h3>
                        <p className="Reg12 Wanna">Have an account? <span onClick={() => {
                            setShowAuthenticator('login');
                            setHideExtraInfo(true);
                        }}
                        className="UserInfoButtonExtra">Login.</span></p>
                        <p className="Reg12 Wanna">Want to register? <span onClick={() => {
                            setShowAuthenticator('register');
                            setHideExtraInfo(true);
                        }}
                        className="UserInfoButtonExtra">Register.</span></p>

                        <form
                            autoComplete="on"
                            action=""
                            onKeyDown={e => {
                                if (e.code === 'Enter' && userInfoFormButtonDisabled) {
                                    e.preventDefault();
                                    e.stopPropagation(e);
                                    return;
                                }
                            }}
                            noValidate={true}
                            data-form-type="other"
                            className="Bold14"
                        >
                            {userInfoFormMessage && <div className="Reg12 UserModalFormWarning">{userInfoFormMessage}</div>}

                            <TextInput
                                label="Your Email Address"
                                type="email"
                                name="email"
                                autoComplete="email"
                                refToUse={inputRef}
                                warning={emailInvalid}
                                rootStyleOverwrite={{ marginBottom: 20, width: '100%', marginTop: 10 }}
                                onBlur={e => {
                                    if (validateEmailAddress(e.target.value)) handlePushToDataLayer({
                                        event: 'email_entered',
                                        email: e.target.value
                                    });
                                }}
                                onChange={e => handleUserInputInfo(e.target.value, true)}
                                lpignore={true}
                            />

                            <button
                                onClick={e => {
                                    e.preventDefault();
                                    checkConsent(() => handleNextStage(null, currencyInit));
                                }}
                                className={clsx('Bold16', userInfoFormButtonDisabled ? "UserInfoFormButtonDisabled" : null)}
                            >Continue to your instant quote</button>
                        </form>
                    </React.Fragment>
                }

                {!loggedIn && showAuthenticator &&
                    <Authenticator
                        childMode={showAuthenticator}
                        titleToUse={{ login: 'Continue with your 3D People account.', register: 'Register a 3D People account.' }}
                        childModeCallback={() => {
                            setShowAuthenticator(false);
                            setHideExtraInfo(false);
                        }}
                        childModeBackMessage={'Continue as a guest?'}
                        childModeSetLoading={setAuthLoading}
                        handleNewSnackbar={handleNewSnackbar}
                        loggedIn={loggedIn}
                        industryOptions={industryOptions}
                    />
                }

                {!!loggedIn && !isAdmin &&
                    <React.Fragment>
                        <h3 className="Bold14">{`Continue as ${loggedIn.email}`}</h3>
                        <p className="Reg12">Not you? <span onClick={handleLogout} className={clsx('Reg12', 'UserInfoButtonExtra')}>Logout.</span></p>

                        {userInfoFormMessage && <div className="Reg12 UserModalFormWarning">{userInfoFormMessage}</div>}

                        <div id="UserInfoButtonLoggedInWrap">
                            <button
                                id="UserInfoButtonLoggedIn"
                                className="Bold16"
                                onClick={e => {
                                    e.preventDefault();
                                    checkConsent(() => handleNextStage(null, currencyInit));
                                }}
                            >Continue to your instant quote</button>
                        </div>
                    </React.Fragment>
                }

                {!!loggedIn && isAdmin &&
                    <React.Fragment>
                        <p>Not you? <span onClick={handleLogout} className="UserInfoButtonExtra">Logout.</span></p>

                        <form
                            action=""
                            onKeyDown={e => {
                                if (e.code === 'Enter' && userInfoFormButtonDisabled) {
                                    e.preventDefault();
                                    e.stopPropagation(e);
                                    return;
                                }
                            }}
                            noValidate={true}
                            data-form-type="other"
                        >
                            <div className="Reg12">{userInfoFormMessage}</div>

                            <TextInput
                                label="Customer Email Address"
                                type="email"
                                name="email"
                                autoComplete="off"
                                refToUse={inputRef}
                                warning={userInfoFormMessage}
                                rootStyleOverwrite={{ marginBottom: 20, width: '100%', marginTop: 10 }}
                                onBlur={e => {
                                    if (validateEmailAddress(e.target.value)) handlePushToDataLayer({
                                        event: 'email_entered',
                                        email: e.target.value
                                    });
                                }}
                                onChange={e => handleUserInputInfo(e.target.value, true)}
                                lpignore={true}
                            />

                            <button
                                onClick={e => {
                                    e.preventDefault();
                                    handleNextStage();
                                }}
                                className={clsx(userInfoFormButtonDisabled ? "UserInfoFormButtonDisabled" : null)}
                            >Continue with customer email</button>
                        </form>

                        <p id="AdminEmail">Continue quote as <span onClick={() => handleNextStage(loggedIn.email)}>{loggedIn.email}</span></p>
                    </React.Fragment>
                }

                {!!(!isAdmin && !hideExtraInfo) &&
                    <div className="Reg12">
                        <div
                            id="UserInfoModalConsentWrap"
                        >
                            <div
                                id="UserInfoConsentRequiredWarning"
                                className={consentRequiredWarning ? 'UserInfoConsentRequiredWarningActive' : null}
                            >
                                <input
                                    type="checkbox"
                                    ref={consentRef}
                                    onChange={() => setConsentRequiredWarning(false)}
                                >
                                </input>
                            </div>

                            <p>By ticking this box I agree to the <a className="Bold12" href={termsAndConditions} rel="noreferrer" target="_blank">Terms of Use</a> and <a className="Bold12" href={privacyPolicy} rel="noreferrer" target="_blank">Privacy Policy</a>.</p>
                        </div>

                        <br></br>

                        {detectedCurrency &&
                            <React.Fragment>
                                <div id="UserInfoCurrencySelectorWrap">
                                    <p>Currency has been set to </p>

                                    <CurrencySelector
                                        options={currencyOptions}
                                        value={(currencyOptions && detectedCurrency && currencyOptions.some(option => option.label === detectedCurrency)) ? currencyOptions.filter(option => option.label === detectedCurrency)[0].select_option_label : null}
                                        rootStyleOverwrite={{
                                            display: 'inline-block',
                                            marginLeft: 5
                                        }}
                                        rootStyleClass={'UserInfoCurrencySelectorRoot'}
                                        selectStyleClass={'UserInfoCurrencySelectorSelect'}
                                        iconStyleClass={'UserInfoCurrencySelectorIcon'}
                                        menuItemStyleOverwrite={{
                                            color: 'var(--tones-dark-grey)',
                                            padding: '5px 10px !important',
                                            fontSize: 12
                                        }}
                                        handleOnChange={e => setCurrencyInit(e.target.value)}
                                    />
                                </div>

                                <br></br>
                            </React.Fragment>
                        }

                        <p>If you have any doubt whether your design complies with our Terms of Sale, please email 3D People prior to uploading any files.</p>
                    </div>
                }
            </div>
        </div>
    );
};