import React, {
    useState,
    useRef,
    useEffect,
    useContext
} from "react";

import {
    useHistory
} from 'react-router-dom';

import ReCAPTCHA from "react-google-recaptcha";
import clsx from 'clsx';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import LoadingSVG from '../Misc/LoadingSVG/LoadingSVG';
import TextInput from '../Misc/TextInput/TextInput';
import SelectInput from '../Misc/SelectInput/SelectInput';
import CurrencySelector from "../Misc/CurrencySelector/CurrencySelector";

import General from "../../context/general";

import {
    IconButton,
    Badge,
    Paper,
    ClickAwayListener
} from '@mui/material';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import {
    hasPasswordFailed,
    attemptRegister,
    attemptVerify,
    attemptLogin,
    attemptLogout,
    attemptForgotPasswordInitial,
    attemptForgotPasswordFinal,
    attemptResendVerificationInitial,
    attemptCheckReCAPTCHA
} from '../../functions/user_functions';

import {
    validateEmailAddress,
    clearRefValue,
    handleApiReq,
    getSetCurrencyLSValue
} from '../../functions/utils';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

import './Authenticator.css';

// (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

export default function Authenticator(props) {
    const {
        handleNewSnackbar,
        titleToUse,
        loggedIn,
        childMode,
        childModeCallback,
        childModeSwitchCallback,
        childModeBackMessage,
        childModeSetLoading,
        disableModeSwitch,
        industryOptions,
        hamburger,
        setBurgerOpen,
        currencyOptions,
        location,
        handleLoading,
        handleError,
        setComponentKeys,
        componentKeys
    } = props;

    const {
        termsAndConditions,
        privacyPolicy
    } = useContext(General);

    const history = useHistory();

    // user popper hide / show
    const [userPopperOpen, setUserPopperOpen] = useState(false);
    // set value for either register / login options
    const [loginRegisterModalOpen, setLoginRegisterModalOpen] = useState(null);
    // set value for warning message on login / register form
    const [message, setMessage] = useState(null);
    // highlight which input warning is for
    const [emailWarning, setEmailWarning] = useState(false);
    const [passwordWarning, setPasswordWarning] = useState(false);
    const [nameWarning, setNameWarning] = useState(false);
    const [phoneNoWarning, setPhoneNoWarning] = useState(false);
    const [verifyWarning, setVerifyWarning] = useState(false);
    const [industryWarning, setIndustryWarning] = useState(false);
    // toggle alternate form stages
    const [registrationStage, setRegistrationStage] = useState(false);
    const [forgotPasswordStage, setForgotPasswordStage] = useState(false);
    const [resendVerifyCodeStage, setResendVerifyCodeStage] = useState(false);
    // set temporary data in order to properly login user after registration
    const [tempRegData, setTempRegData] = useState(null);
    // toggle when component is loading
    const [loading, setLoading] = useState(false);
    const [currencyLabel, setCurrencyLabel] = useState(null);

    const [consentRequiredWarning, setConsentRequiredWarning] = useState(false);

    const popperRef = useRef();
    const emailRef = useRef();
    const industryRef = useRef();
    const passwordRef = useRef();
    const password2Ref = useRef();
    const verifyRef = useRef();
    const fullNameRef = useRef();
    const phoneNoRef = useRef();
    const reCAPTCHARef = useRef();
    const consentRef = useRef();

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // handle resetting of into pertaining to the login / registration form
    const resetInfo = retainStage => {
        setMessage(null);
        setEmailWarning(false);
        setPasswordWarning(false);
        setVerifyWarning(false);
        setNameWarning(false);
        setPhoneNoWarning(false);

        // if retainStage is true then the current alternate form stage will be kept
        if (retainStage) {
            if (retainStage === 'resendVerification') {
                setForgotPasswordStage(false);
                setRegistrationStage(false);
            } else if (retainStage === 'forgotPassword') {
                setResendVerifyCodeStage(false);
                setRegistrationStage(false);
            } else if (retainStage === 'registrationStage') {
                setForgotPasswordStage(false);
                setResendVerifyCodeStage(false);
            }
        } else {
            setForgotPasswordStage(false);
            setResendVerifyCodeStage(false);
            setRegistrationStage(false);
        }
    };

    // handle login / registration form hide show
    const handleModalOpenClose = open => {
        setLoginRegisterModalOpen(open);
        setUserPopperOpen(false);
    };

    // check the values of appropriate inputs and give warnings for incorrect values / formatting
    const checkValues = async () => {
        // values of all input refs
        let emailValue = emailRef && emailRef.current ? emailRef.current.value.trim() : null;
        // lowercase the email as Auth / cognito will see a difference between Email@email.com & email@email.com
        if (emailValue) emailValue = emailValue.toLowerCase();
        let passwordValue = passwordRef && passwordRef.current ? passwordRef.current.value.trim() : null;
        let password2Value = password2Ref && password2Ref.current ? password2Ref.current.value.trim() : null;
        let verifyValue = verifyRef && verifyRef.current ? verifyRef.current.value.trim() : null;
        let nameValue = fullNameRef && fullNameRef.current ? fullNameRef.current.value.trim() : null;
        let phoneNoValue = phoneNoRef && phoneNoRef.current ? phoneNoRef.current.value.trim() : null;
        let industryValue = industryRef && industryRef.current && industryRef.current.value ? industryRef.current.value.trim() : null;
        let reCAPTCHAValue = reCAPTCHARef && reCAPTCHARef.current ? reCAPTCHARef.current.getValue() : null;

        let warning = false;

        if (loginRegisterModalOpen) {
            if (loginRegisterModalOpen === 'register') {
                if (resendVerifyCodeStage) {
                    if (resendVerifyCodeStage === 'initial') {
                        warning = !emailValue || !emailValue.length ? 'Please enter an email address'
                            : !validateEmailAddress(emailValue) ? 'Please enter a valid email address'
                            : false;

                        if (warning) {
                            setEmailWarning(true);
                            setMessage(<div className="UserModalFormWarning">{warning}</div>);
                        } else {
                            setEmailWarning(false);
                            setMessage(null);
                            await handleNext(emailValue);
                        }
                    } else {
                        warning = !verifyValue || !verifyValue.length ? 'Please enter verification code'
                            : false;

                        if (warning) {
                            setVerifyWarning(/verification/i.test(warning));
                            setMessage(<div className="UserModalFormWarning">{warning}</div>);
                        } else {
                            setVerifyWarning(false);
                            setMessage(null);
                            await handleNext(verifyValue);
                        }
                    }
                } else if (registrationStage === 'initial') {
                    if (!nameValue || !nameValue.length) {
                        warning = 'Please enter a name';
                    } else if (!phoneNoValue || !phoneNoValue.length) {
                        warning = 'Please enter a phone number';
                    } else if (!industryValue || !industryValue.length) {
                        warning = 'Please select an industry';
                    }

                    if (warning) {
                        setNameWarning(/name/i.test(warning));
                        setPhoneNoWarning(/phone number/i.test(warning));
                        setIndustryWarning(/industry/i.test(warning));
                        setMessage(<div className="UserModalFormWarning">{warning}</div>);
                    } else {
                        setNameWarning(false);
                        setPhoneNoWarning(false);
                        setIndustryWarning(false);
                        setMessage(null);
                        await handleNext(nameValue, phoneNoValue, industryValue);
                    }
                } else if (registrationStage === 'secondary') {
                    warning = !emailValue || !emailValue.length ? 'Please enter an email address'
                        : !validateEmailAddress(emailValue) ? 'Please enter a valid email address'
                        : false;

                    if (!warning) {
                        if (passwordValue && password2Value) {
                            if (passwordValue !== password2Value) {
                                warning = 'Passwords do not match';
                            } else {
                                let passwordFailed = hasPasswordFailed(passwordValue);
                                if (passwordFailed) {
                                    warning = passwordFailed;
                                }
                            }
                        } else {
                            warning = 'Please enter a password and confirm it';
                        }
                    }

                    if (warning) {
                        setEmailWarning(/email/i.test(warning));
                        setPasswordWarning(/password/i.test(warning));
                        setMessage(<div className="UserModalFormWarning">{warning}</div>);
                    } else {
                        setEmailWarning(false);
                        setPasswordWarning(false);
                        setMessage(null);
                        await handleNext(emailValue, passwordValue);
                    }
                } else if (registrationStage === 'verify') {
                    warning = !verifyValue || !verifyValue.length ? 'Please enter verification code'
                        : false;

                    if (warning) {
                        setVerifyWarning(/verification/i.test(warning));
                        setMessage(<div className="UserModalFormWarning">{warning}</div>);
                    } else {
                        setVerifyWarning(false);
                        setMessage(null);
                        await handleNext(verifyValue);
                    }
                } else {
                    if (!reCAPTCHAValue || !reCAPTCHAValue.length) {
                        warning = 'Please provide proof of humanity'
                    }

                    if (!warning) {
                        // send captcha token to the backend to verify humanity
                        let reCAPTCHAPass = await attemptCheckReCAPTCHA(childModeSetLoading || setLoading, reCAPTCHAValue);

                        if (!reCAPTCHAPass) {
                            warning = '01101100 01101111 01101100';
                        }
                    }

                    if (warning) {
                        setMessage(<div className="UserModalFormWarning">{warning}</div>);
                    } else {
                        setMessage(null);
                        await handleNext();
                    }
                }
            } else {
                if (forgotPasswordStage) {
                    if (forgotPasswordStage === 'initial') {
                        warning = !emailValue || !emailValue.length ? 'Please enter an email address'
                            : !validateEmailAddress(emailValue) ? 'Please enter a valid email address'
                            : false;

                        if (warning) {
                            setEmailWarning(true);
                            setMessage(<div className="UserModalFormWarning">{warning}</div>);
                        } else {
                            if (emailValue.length) {
                                setEmailWarning(false);
                                setMessage(null);
                                await handleNext(emailValue);
                            }
                        }
                    } else {
                        warning = !passwordValue || !passwordValue.length ? 'Please enter a new password'
                            : false;

                        if (!warning) {
                            warning = !password2Value || !password2Value.length ? 'Please confirm new password'
                                : false;
                        }

                        if (!warning) {
                            warning = !verifyValue || !verifyValue.length ? 'Please enter verification code'
                                : false;
                        }

                        if (!warning) {
                            if (passwordValue && password2Value) {
                                if (passwordValue !== password2Value) {
                                    warning = 'Passwords do not match';
                                } else {
                                    let passwordFailed = hasPasswordFailed(passwordValue);
                                    if (passwordFailed) {
                                        warning = passwordFailed;
                                    }
                                }
                            } else {
                                warning = 'Please enter a password and confirm it';
                            }
                        }

                        if (warning) {
                            setVerifyWarning(/verification/i.test(warning));
                            setPasswordWarning(/password/i.test(warning));
                            setEmailWarning(/email/i.test(warning));
                            setMessage(<div className="UserModalFormWarning">{warning}</div>);
                        } else {
                            setVerifyWarning(false);
                            setPasswordWarning(false);
                            setEmailWarning(false);
                            setMessage(null);
                            await handleNext(passwordValue, verifyValue);
                        }
                    }
                } else {
                    warning = !emailValue || !emailValue.length ? 'Please enter an email address'
                        : !validateEmailAddress(emailValue) ? 'Please enter a valid email address'
                        : false;

                    if (!warning) {
                        warning = !passwordValue || !passwordValue.length ? 'Please enter a password'
                            : false;
                    }

                    if (warning) {
                        setPasswordWarning(/password/i.test(warning));
                        setEmailWarning(/email/i.test(warning));
                        setMessage(<div className="UserModalFormWarning">{warning}</div>);
                    } else {
                        setPasswordWarning(false);
                        setEmailWarning(false);
                        setMessage(null);
                        await handleNext(emailValue, passwordValue);
                    }
                }
            }
        }
    };

    // handle next stage of event, input values were correct so invoke the correct Auth method
    const handleNext = async (val1, val2, val3) => {
        if (loginRegisterModalOpen === 'register') {
            if (resendVerifyCodeStage) {
                if (resendVerifyCodeStage === 'initial') {
                    const result = await attemptResendVerificationInitial(childModeSetLoading || setLoading, val1);

                    setTempRegData({
                        username: val1
                    });

                    if (result.success) {
                        setResendVerifyCodeStage('final');
                    } else {
                        if (result.message) setMessage(<div className="UserModalFormWarning">{result.message}</div>);
                    }
                } else {
                    if (!tempRegData) return;

                    const result = await attemptVerify(childModeSetLoading || setLoading, tempRegData.username, val1);

                    if (result.success) {
                        handleModalOpenClose('login');
                        resetInfo();
                        if (hamburger) setBurgerOpen(false);

                        handleNewSnackbar('register successful');
                    } else {
                        if (result.message) setMessage(<div className="UserModalFormWarning">{result.message}</div>);
                    }
                }
            } else if (registrationStage) {
                if (registrationStage === 'initial') {
                    setTempRegData({
                        name: val1,
                        phone_no: val2,
                        industry: val3
                    });

                    setRegistrationStage('secondary');
                } else if (registrationStage === 'secondary' && tempRegData) {
                    setTempRegData({
                        ...tempRegData,
                        username: val1,
                        password: val2
                    });

                    const result = await attemptRegister(childModeSetLoading || setLoading, val1, val2, tempRegData.name, tempRegData.phone_no, tempRegData.industry);

                    if (result.success) {
                        setRegistrationStage('verify');
                    } else {
                        if (result.message) {
                            if (result.message === 'An account with this email already exists') {
                                setMessage(<div className="UserModalFormWarning">{result.message}, <span className="AuthenticatorActiveMessage" onClick={() => handleActiveMessage('login', 'forgot')}>forgot password?</span></div>);
                            } else {
                                setMessage(<div className="UserModalFormWarning">{result.message}</div>);
                            }
                        }
                    }
                } else if (registrationStage === 'verify' && tempRegData) {
                    const result = await attemptVerify(childModeSetLoading || setLoading, tempRegData.username, val1);

                    if (result.success) {
                        let resultX = await attemptLogin(childModeSetLoading || setLoading, tempRegData.username, tempRegData.password);

                        if (resultX.success) {
                            // clear temp login data
                            setTempRegData(null);

                            if (childModeCallback) {
                                childModeCallback({
                                    success: true,
                                    ...resultX
                                });
                            } else {
                                handleModalOpenClose(false);
                            }
                        } else {
                            handleNewSnackbar('auto login failed - please login manually', 'warning');
                            handleModalOpenClose('login');
                            resetInfo();
                        }
                    } else {
                        if (result.message) setMessage(<div className="UserModalFormWarning">{result.message}</div>);
                    }
                }
            } else {
                setRegistrationStage('initial');
            }
        } else {
            if (forgotPasswordStage) {
                if (forgotPasswordStage === 'initial') {
                    const result = await attemptForgotPasswordInitial(childModeSetLoading || setLoading, val1);

                    setTempRegData({
                        username: val1
                    });

                    if (result.success) {
                        setForgotPasswordStage('final');
                    } else {
                        if (result.message) setMessage(<div className="UserModalFormWarning">{result.message}</div>);
                    }
                } else {
                    if (!tempRegData) return;

                    const result = await attemptForgotPasswordFinal(childModeSetLoading || setLoading, tempRegData.username, val1, val2);

                    if (result.success) {
                        setForgotPasswordStage(null);
                        clearRefValue(passwordRef);
                        handleNewSnackbar('password successfully reset');
                    } else {
                        if (result.message) setMessage(<div className="UserModalFormWarning">{result.message}</div>);
                    }
                }
            } else {
                const result = await attemptLogin(childModeSetLoading || setLoading, val1, val2);

                if (result.success) {
                    if (childModeCallback) {
                        childModeCallback({
                            success: true,
                            ...result
                        });
                    } else {
                        handleModalOpenClose(false);
                    }
                    if (hamburger) setBurgerOpen(false);
                } else {
                    if (result.message) {
                        if (result.message === 'This account needs to be verified first') {
                            setMessage(<div className="UserModalFormWarning">{result.message}, <span className="AuthenticatorActiveMessage" onClick={() => handleActiveMessage('register', 'resend')}>resend verification code?</span></div>)
                        } else {
                            setMessage(<div className="UserModalFormWarning">{result.message}</div>);
                        }
                    }
                }
            }
        }
    };

    // handle logout
    // haven's seen this fail yet
    const handleLogout = async () => {
        let result = await attemptLogout();

        if (result.success) {
            setUserPopperOpen(false);
        } else {
            handleNewSnackbar('something went wrong', 'error');
        }
    };

    // handle alt stage forgot password
    const handleForgotPassword = () => {
        resetInfo('forgotPassword');
        setForgotPasswordStage('initial');
    };

    // handle alt stage resend verification code
    const handleResendVerificationCode = () => {
        resetInfo('resendVerification');
        setResendVerifyCodeStage('initial');
    };

    // handle a switch between register and login mode
    const handleModalModeSwitch = which => {
        resetInfo();
        if (childModeSwitchCallback) childModeSwitchCallback(which);
        setLoginRegisterModalOpen(which);
    };

    const handleActiveMessage = (which, extra) => {
        resetInfo();
        setLoginRegisterModalOpen(which);

        if (which === 'login' && extra === 'forgot') {
            setForgotPasswordStage('initial');
        }

        if (which === 'register' && extra === 'resend') {
            setResendVerifyCodeStage('initial');
        }
    };

    const returnIconLetter = () => {
        if (userPopperOpen) return null;
        if (!loggedIn) return '?';
        let strToReturn = '';
        if (loggedIn['custom:full_name']) strToReturn += loggedIn['custom:full_name'].substring(0, 1).toUpperCase();
        if (loggedIn.email && !strToReturn.length) strToReturn += loggedIn.email.substring(0, 1).toUpperCase();
        return strToReturn;
    };

    const checkConsent = callback => {
        setConsentRequiredWarning(false);

        if (!consentRef?.current?.checked) {
            setMessage(<div className="UserModalFormWarning">You are required to tick the box below</div>);

            setConsentRequiredWarning(true);
        } else {
            callback();
        }
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // label for user button
    const userLabel = () => {
        return loggedIn && loggedIn.email ? `${loggedIn.email} is logged in` : 'user is not logged in';
    };

    // return the user button
    const returnButton = open => {
        return (
            <IconButton
                onClick={!hamburger ? () => setUserPopperOpen(!userPopperOpen) : !loggedIn ? () => handleModalOpenClose('login') : () => history.push('/user')}
                aria-label={userLabel()}
                id={!open ? "UnOpenIconButton" : "OpenIconButton"}
                className={hamburger ? 'HamburgerIconButton': null}
                disableRipple={true}
            >
                <Badge badgeContent={returnIconLetter()} color="secondary">
                    <AccountCircleIcon
                        ref={popperRef}
                        id={!open ? "UnOpenAccountIcon" : "OpenAccountIcon"}
                        className={hamburger ? 'HamburgerAccountIcon': null}
                    />
                </Badge>
            </IconButton>
        );
    };

    // return different form inputs if visible

    const returnEmailInput = visible => {
        if (visible) {
            return (
                <TextInput
                    label="Email Address"
                    type="email"
                    name="email"
                    autoComplete="email"
                    maxlength={250}
                    required={true}
                    refToUse={emailRef}
                    warning={emailWarning}
                    rootStyleOverwrite={{ marginBottom: 20, width: '100%' }}
                />
            );
        } else return null;
    };

    const returnPasswordInput = visible => {
        if (visible) {
            return (
                <TextInput
                    label={forgotPasswordStage === 'final' ? 'New Password' : 'Password'}
                    type="password"
                    name="password"
                    maxlength={250}
                    autoComplete="password"
                    refToUse={passwordRef}
                    required={true}
                    warning={passwordWarning}
                    rootStyleOverwrite={{ marginBottom: 20, width: '100%' }}
                />
            );
        } else return null;
    };

    const returnPasswordInput2 = visible => {
        if (visible) {
            return (
                <TextInput
                    label="Confirm Password"
                    type="password"
                    name="password"
                    maxlength={250}
                    autoComplete="password"
                    required={true}
                    refToUse={password2Ref}
                    warning={passwordWarning}
                    rootStyleOverwrite={{ marginBottom: 20, width: '100%' }}
                />
            );
        } else return null;
    };

    const returnVerificationInput = visible => {
        if (visible) {
            return (
                <TextInput
                    label="Verification Code"
                    type=""
                    name="verification_code"
                    autoComplete="one-time-code"
                    required={true}
                    maxlength={250}
                    refToUse={verifyRef}
                    warning={verifyWarning}
                    rootStyleOverwrite={{ marginBottom: 20, width: '100%' }}
                />
            );
        } else return null;
    };

    const returnRECAPTCHA = visible => {
        if (visible) {
            return (
                <div id="ReCAPTCHAWrappa">
                    <ReCAPTCHA
                        ref={reCAPTCHARef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                    />
                </div>
            );
        }
    };

    const returnUserDataInputs = visible => {
        if (visible) {
            return (
                <React.Fragment>
                    <TextInput
                        label="Full Name"
                        type="text"
                        name="full_name"
                        maxlength={250}
                        required={true}
                        autoComplete="name"
                        refToUse={fullNameRef}
                        warning={nameWarning}
                        rootStyleOverwrite={{ marginBottom: 20, width: '100%' }}
                    />

                    <TextInput
                        label="Phone Number"
                        type="tel"
                        name="phone_number"
                        autoComplete="tel"
                        maxlength={250}
                        required={true}
                        refToUse={phoneNoRef}
                        warning={phoneNoWarning}
                        pattern={[/[^\d+-]/, 'g']}
                        rootStyleOverwrite={{ marginBottom: 20, width: '100%' }}
                    />

                    <SelectInput
                        label="Industry"
                        name="industry"
                        options={industryOptions}
                        required={true}
                        refToUse={industryRef}
                        warning={industryWarning}
                        rootStyleOverwrite={{ marginBottom: 20, width: '100%' }}
                        menuItemStyleOverwrite={{ width: '100%', color: 'var(--tones-dark-grey)' }}
                    />
                </React.Fragment>
            );
        } else return null;
    };

    // return the wrapping form, exists as a function as childMode only wants to render the form
    const returnForm = () => {
        return (
            <React.Fragment>
                {(titleToUse && loginRegisterModalOpen &&  titleToUse[loginRegisterModalOpen]) && <h3 className="Bold16">{titleToUse[loginRegisterModalOpen]}</h3>}

                <form
                    autoComplete="on"
                    action=""
                    noValidate={true}
                    data-form-type="other"
                    className="Reg12"
                >
                    {!!message && message}
                    {!message && <p></p>}

                    {returnEmailInput(emailInputVisible)}

                    {returnRECAPTCHA(reCAPTCHAVisible)}

                    {returnUserDataInputs(userDataInputsVisible)}

                    {returnPasswordInput(passwordInputVisible)}

                    {returnPasswordInput2(passwordInput2Visible)}

                    {returnVerificationInput(verificationInputVisible)}

                    <button
                        id="UserModalRButton"
                        className="Bold16"
                        onClick={e => {
                            e.preventDefault();

                            if (registrationStage === 'secondary') {
                                checkConsent(checkValues);
                            } else {
                                checkValues();
                            }
                        }}
                    >
                        {
                            loginRegisterModalOpen === 'login' && !forgotPasswordStage ? 'Login'
                            : forgotPasswordStage === 'initial' ? 'Send Code'
                            : forgotPasswordStage === 'final' ? 'Reset'
                            : resendVerifyCodeStage === 'initial' ? 'Resend Code'
                            : resendVerifyCodeStage === 'final' ? 'Verify'
                            : registrationStage === 'initial' ? 'Continue'
                            : registrationStage === 'secondary' ? 'Register'
                            : registrationStage === 'verify' ? 'Verify'
                            : 'Continue'
                        }
                    </button>

                    {registrationStage === 'secondary' &&
                        <div
                            id="AuthenticatorConsentWrap"
                        >
                            <div
                                id="AuthenticatorConsentRequiredWarning"
                                className={consentRequiredWarning ? 'AuthenticatorConsentRequiredWarningActive' : null}
                            >
                                <input
                                    type="checkbox"
                                    ref={consentRef}
                                    onChange={() => setConsentRequiredWarning(false)}
                                >
                                </input>
                            </div>

                            <p>By ticking this box I agree to the <a className="Bold12" href={termsAndConditions} rel="noreferrer" target="_blank">Terms of Use</a> and <a className="Bold12" href={privacyPolicy} rel="noreferrer" target="_blank">Privacy Policy</a>.</p>
                        </div>
                    }

                    <div id="ExtraBits">
                        {childMode && childModeCallback && childModeBackMessage &&
                            <h5
                                onClick={() => childModeCallback(null)}
                                className={clsx('Reg12', 'ForgotSomething')}
                            >{childModeBackMessage}</h5>
                        }

                        {loginRegisterModalOpen === 'login' && !forgotPasswordStage &&
                            <React.Fragment>
                                {!disableModeSwitch &&
                                    <h5
                                        onClick={() => handleModalModeSwitch('register')}
                                        className={clsx('Reg12', 'ForgotSomething')}
                                    >Need to register?</h5>
                                }

                                <h5
                                    onClick={handleForgotPassword}
                                    className={clsx('Reg12', 'ForgotSomething')}
                                >Forgot password?</h5>
                            </React.Fragment>
                        }

                        {loginRegisterModalOpen === 'register' && !resendVerifyCodeStage &&
                            <React.Fragment>
                                {!disableModeSwitch &&
                                    <h5
                                        onClick={() => handleModalModeSwitch('login')}
                                        className={clsx('Reg12', 'ForgotSomething')}
                                    >Already registered?</h5>
                                }

                                <h5
                                    onClick={handleResendVerificationCode}
                                    className={clsx('Reg12', 'ForgotSomething')}
                                >Resend verification code?</h5>
                            </React.Fragment>
                        }

                        {(forgotPasswordStage || resendVerifyCodeStage) &&
                            <h5
                                onClick={() => forgotPasswordStage ? setForgotPasswordStage(false) : setResendVerifyCodeStage(false)}
                                className={clsx('Reg12', 'ForgotSomething')}
                            >Back to {forgotPasswordStage ? 'login' : 'register'}?</h5>
                        }
                    </div>
                </form>
            </React.Fragment>
        );
    };

    const handlePopperAction = callback => {
        setUserPopperOpen(false);
        callback();
    };

    const handleCurrencyChange = e => {
        // if on upload / confirmation do nothing
        let match = /\/(?:quote\b)\/(\w+)$/.test(location.pathname) ? location.pathname.match(/\/(?:quote\b)\/(\w+)$/)[1] : false;
        if (!match) return;

        let matchedVal = e.target.value.match(/^\w+/)[0];
        if (!matchedVal) return;

        handleLoading(true);

        handleApiReq(
            'put',
            '/crud',
            {
                action: 'UPDATE_QUOTE_CURRENCY',
                quote_id: match,
                attach_config_data: true,
                val: matchedVal
            }
        ).then(result => {
            // refresh quote using the key on the component
            handleLoading(false);
            if (result.success) {
                // refresh quote component
                setComponentKeys({
                    ...componentKeys,
                    quote: componentKeys.quote ? componentKeys.quote + 1 : 1
                });
            } else {
                handleNewSnackbar('something went wrong', 'error');
            }
        }).catch(error => {
            handleLoading(false);
            handleNewSnackbar('something went wrong', 'error');
            handleError('21710NzD', error);
        });
    };

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // items for user nav bar
    const loggedInNavOptions = [
        {
            label: 'New Quote',
            action: () => history.push('/upload')
        },
        {
            label: 'My Quotes',
            action: () => history.push('/quotes')
        },
        {
            label: 'My Orders',
            action: () => history.push('/orders')
        }
    ];

    // items for user menu
    const loggedInMenuItems = [
        {
            label: 'My Account',
            icon: <SettingsIcon className={clsx('LoggedInMenuItemIcon', history.location.pathname === '/user' ? 'LoggedInMenuItemIconActive' : null)}/>,
            action: () => history.push('/user')
        },
        {
            hr: 1
        },
        {
            label: 'Logout',
            icon: <LogoutIcon className="LoggedInMenuItemIcon"/>,
            action: handleLogout
        }
    ];

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // on mount
    useEffect(() => {
        if (childMode) {
            setLoginRegisterModalOpen(childMode);
        }

        function checkLSData() {
            let val = getSetCurrencyLSValue();
            setCurrencyLabel(!!val ? val.disabled ? null : val.select_option_label : 'GBP £');
        }

        window.addEventListener('storage', checkLSData);

        checkLSData();

        return () => {
            window.removeEventListener('storage', checkLSData);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // (╯°益°)╯彡┻━┻ -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- -- --

    // input visible booleans
    let emailInputVisible = (loginRegisterModalOpen === 'register' && (registrationStage === 'secondary' || resendVerifyCodeStage === 'initial')) || (loginRegisterModalOpen !== 'register' && forgotPasswordStage !== 'final');
    let reCAPTCHAVisible = loginRegisterModalOpen === 'register' && !registrationStage && !resendVerifyCodeStage;
    let passwordInputVisible = registrationStage === 'secondary' || (loginRegisterModalOpen === 'login' && forgotPasswordStage !== 'initial');
    let passwordInput2Visible = (loginRegisterModalOpen === 'register' && registrationStage === 'secondary' && !resendVerifyCodeStage) || forgotPasswordStage === 'final';
    let verificationInputVisible = registrationStage === 'verify' || forgotPasswordStage === 'final' || resendVerifyCodeStage === 'final';
    let userDataInputsVisible = loginRegisterModalOpen === 'register' && registrationStage === 'initial' && !forgotPasswordStage && !resendVerifyCodeStage;

    if (childMode) {
        return returnForm();
    }

    return (
        <React.Fragment>
            {hamburger &&
                <div id="UserMenu">
                    {!loggedIn &&
                        <React.Fragment>
                            <a target="_blank" rel="noreferrer" href={window.location.origin + '/upload'} onClick={() => setBurgerOpen(false)} className="UserMenuItem Bold12">Start New Quote</a>
                            <p onClick={() => {
                                handleModalOpenClose('login');
                            }} className="UserMenuItem Bold12">Login</p>
                            <p onClick={() => {
                                handleModalOpenClose('register');
                            }} className="UserMenuItem Bold12">Register</p>
                        </React.Fragment>
                    }

                    {loggedIn &&
                        <React.Fragment>
                            {loggedInNavOptions && loggedInNavOptions.length && loggedInNavOptions.map(elem =>
                                <p key={elem.label + 'NavBarOption'} className="UserMenuItem Bold12" onClick={elem.action ? () => {
                                    elem.action();
                                    setBurgerOpen(false);
                                } : null}>{elem.label}</p>
                            )}

                            <p className="UserMenuItem Bold12" onClick={() => {
                                handleLogout();
                                setBurgerOpen(false);
                            }}>Logout</p>
                        </React.Fragment>
                    }

                    {returnButton(!!loggedIn)}

                    {!!(/^\/quote\b/.test(location.pathname) && !!currencyLabel && currencyOptions?.length > 1) &&
                        <CurrencySelector
                            options={currencyOptions}
                            handleOnChange={handleCurrencyChange}
                            value={currencyLabel}
                            rootStyleClass={'CurrencySelectorRootHamburger'}
                            selectStyleClass={'CurrencySelectorSelect Bold12Important'}
                            iconStyleClass={'CurrencySelectorIcon'}
                            rootStyleOverwrite={{
                                position: 'absolute',
                                bottom: '22px',
                                right: '0px'
                            }}
                            menuItemStyleOverwrite={{
                                color: 'var(--tones-dark-grey)',
                                padding: '8px 15px !important',
                                fontSize: 12
                            }}
                        />
                    }
                </div>
            }

            {!hamburger &&
                <div id="UserButton">
                    {!userPopperOpen &&
                        <React.Fragment>
                            {!loggedIn &&
                                <a id="StartNewQuoteButton" className="Bold14" target="_blank" rel="noreferrer" href={window.location.origin + '/upload'}>
                                    Start New Quote
                                </a>
                            }

                            {loggedIn &&
                                <nav id="UserNavBar">
                                    {loggedInNavOptions && loggedInNavOptions.length && loggedInNavOptions.map(elem =>
                                        <div key={elem.label + 'NavBarOption'} className="Bold14">
                                            <p onClick={elem.action ? elem.action : null}>{elem.label}</p>
                                        </div>
                                    )}
                                </nav>
                            }

                            {!!(/^\/quote\b/.test(location.pathname) && !!currencyLabel && currencyOptions?.length > 1) &&
                                <CurrencySelector
                                    options={currencyOptions}
                                    handleOnChange={handleCurrencyChange}
                                    value={currencyLabel}
                                    rootStyleClass={'CurrencySelectorRoot'}
                                    selectStyleClass={'CurrencySelectorSelect Bold14Important'}
                                    iconStyleClass={'CurrencySelectorIcon'}
                                    rootStyleOverwrite={!loggedIn ? {
                                        marginLeft: 13
                                    } : null}
                                    menuItemStyleOverwrite={{
                                        color: 'var(--tones-dark-grey)',
                                        padding: '8px 15px !important',
                                        fontSize: 13
                                    }}
                                />
                            }

                            {returnButton()}
                        </React.Fragment>
                    }

                    {/* user menu */}
                    {!!(userPopperOpen && !loginRegisterModalOpen) &&
                        <React.Fragment>
                            {loggedIn &&
                                <React.Fragment>
                                    <nav id="UserNavBar">
                                        {loggedInNavOptions && loggedInNavOptions.length && loggedInNavOptions.map(elem =>
                                            <div key={elem.label + 'UserNavOption'} className="Bold14">
                                                <p onClick={elem.action ? elem.action : null}>{elem.label}</p>
                                            </div>
                                        )}
                                    </nav>

                                    <div id="UserNavSpacer"></div>
                                </React.Fragment>
                            }

                            <ClickAwayListener onClickAway={() => setUserPopperOpen(false)}>
                                <Paper
                                    elevation={6}
                                    id="UserPopper"
                                    className={clsx(!loggedIn ? 'FixedHeightPopper' : null)}
                                >
                                    {returnButton(true)}

                                    {!loggedIn &&
                                        <div id="LogInRegisterWrap" className="Bold14">
                                            <div onClick={() => handleModalOpenClose('login')}>
                                                Log In
                                            </div>

                                            <div onClick={() => handleModalOpenClose('register')}>
                                                Register
                                            </div>
                                        </div>
                                    }

                                    {loggedIn &&
                                        <div id="LoggedInWrap">
                                            <div id="UserName">
                                                <p className="Bold12">{loggedIn && loggedIn['custom:account_number'] ? loggedIn['custom:account_number'] : ''}</p>
                                                <p className="Bold14">{loggedIn && loggedIn.email ? loggedIn.email : ''}</p>
                                            </div>

                                            <div id="LoggedInMenu" className="Reg12">
                                                {loggedInMenuItems && loggedInMenuItems.length && loggedInMenuItems.map(elem => {
                                                    const {
                                                        label,
                                                        icon,
                                                        action,
                                                        hr
                                                    } = elem;

                                                    if (hr) return <hr key={hr + 'menu-hr'}></hr>;
                                                    return (
                                                        <div
                                                            key={label + 'menu-item'}
                                                            onClick={action ? () => handlePopperAction(action) : null}
                                                            className="LoggedInMenuItem"
                                                        >
                                                            <p>{label}</p>

                                                            {icon && icon}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    }
                                </Paper>
                            </ClickAwayListener>
                        </React.Fragment>
                    }
                </div>
            }

            {/* register / login modal */}
            {loginRegisterModalOpen &&
                <div
                    id="UserModal"
                    className="FullHeightWidth"
                >
                    <div id="UserModalContent">
                        {loading &&
                            <div id="UserModalContentLoading">
                                <LoadingSVG
                                    size={50}
                                />
                            </div>
                        }

                        <CloseIcon
                            id="UserInfoModalClose"
                            onClick={() => handleModalOpenClose()}
                        />

                        <p className="Bold16">{
                            loginRegisterModalOpen === 'login' && !forgotPasswordStage ? 'Enter your email address and password to login.'
                            : registrationStage === 'initial' ? <React.Fragment>Create your account <span className="Reg16">1/2</span></React.Fragment>
                            : registrationStage === 'secondary' ? <React.Fragment>Create your account <span className="Reg16">2/2</span></React.Fragment>
                            : registrationStage === 'verify' ? `Enter the verification code sent to ${tempRegData && tempRegData.username ? tempRegData.username : 'your email'}`
                            : forgotPasswordStage === 'initial' ? 'Enter your email address to receive reset code'
                            : forgotPasswordStage === 'final' ? 'Enter new password and reset code to complete the process'
                            : resendVerifyCodeStage === 'initial' ? 'Enter the email address you registered with'
                            : resendVerifyCodeStage === 'final' ? `Enter the verification code sent to ${tempRegData && tempRegData.username ? tempRegData.username : 'your email'}`
                            : 'Complete the captcha to continue.'}
                        </p>

                        {returnForm()}
                    </div>
                </div>
            }
        </React.Fragment>
    );
};